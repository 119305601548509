import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
//import * as TabilityTypes from 'types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useQuery, useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';
import { Link, useLocation, useParams, useHistory } from 'react-router-dom';
import { parseISO } from 'date-fns';
import * as outcomeUtils from 'utils/outcomeUtils';
import * as planUtils from 'utils/planUtils';
import * as checkinUtils from 'utils/checkinUtils';
import * as workspaceUtils from 'utils/workspaceUtils';
import * as amplitudeUtils from 'utils/amplitude';
import * as dataSourceUtils from 'utils/dataSourceUtils';
import * as membershipUtils from 'utils/membershipUtils';

// API
import * as remoteApi from 'api/remote';

// Routes
import * as routes from 'routes';

import { setGlobalModalContent } from 'state/actions/globalUIActions';

import { PanelContent, PanelHeader, ModalPanelGrid } from 'components/GlobalPanel';

import PlanPermissionSync from 'components/PlanPermissionSync';
import CheckinComments from '../shared/CheckinComments';
import CheckinsTab from './CheckinsTab';
import CheckinBlock from 'components/CheckinBlock';
import Analysis from './Analysis';
import InitiativesTab from './InitiativesTab';
import OutcomeLinksTab from './OutcomeLinksTab';
import OutcomeContributors from './OutcomeContributors';
import TagPickerOutcome from 'components/TagPickerOutcome';
import MarkdownContent from 'components/MarkdownContent';

import DropdownMenu from 'components/DropdownMenu';

import Loader from 'components/Loader';
import ReactTooltip from 'react-tooltip';
import UpgradeIcon from 'components/_assets/UpgradeIcon';
import KoalaColorBlock from 'koala/components/ColorBlock';
import KoalaButton from 'koala/components/Button';
import KoalaIconButton from 'koala/components/IconButton';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import PlanIconLabel from 'components/PlanIconLabel';
import { Checkin, Outcome } from 'types';
import KoalaInlineTextInput from 'koala/components/InlineTextInput';
import KoalaTextBadge from 'koala/components/TextBadge';
import UserSelectButton from 'components/UserSelectButton';

import KoalaProgressBar from 'koala/components/ProgressBar';

import CheckinsChart from 'components/Chart';
import Relationships from './Relationships';
import TeamRelationships from './TeamRelationships';
import OutcomeWeight from 'components/OutcomeWeight';
import KoalaIcon from 'koala/components/Icons';
import { useTranslation } from 'react-i18next';
import { formatDistanceToNowLocale } from 'utils/dateUtils';
import { useHotkeys } from 'react-hotkeys-hook';
import ReadOnlyButton from 'components/ReadOnlyButton';
import ShareButton from 'components/ShareButton';
import KoalaTextButton from 'koala/components/TextButton';
import Confettis from './Confettis';

const LayoutContainer = styled.div`
  display: flex;
  min-height: 100%;
  @media ${theme.devices.tablet} {
    flex-direction: column;
  }
`;

/*
const ChartContent = styled.div`
  padding: ${theme.spacing.x2};
  border-bottom: 1px solid ${theme.colors.blockBorder};
`;
*/

const MainContent = styled.div`
  border-right: 1px solid ${theme.colors.blockBorder};
  min-height: 100%;
  padding: ${theme.spacing.x2};
  flex: 1;

  .objective-link {
    display: flex;
    align-items: center;
    gap: ${theme.spacing.half};
  }

  @media ${theme.devices.tablet} {
    border: 0;
  }
`;

const MetaContent = styled.div`
  min-width: 40rem;
  max-width: 40rem;
  width: 40rem;
  @media ${theme.devices.tablet} {
    width: 100%;
    max-width: 100%;
  }
`;

const MetaContentBlock = styled.div`
  border-bottom: 1px solid ${theme.colors.blockBorder};

  .icon-btn--square {
    transition: transform 0.2s ease;
    &:focus {
      outline: none;
      border: 0;
    }
  }

  &.expanded {
    .clickable {
      .icon-btn--square {
        transform: rotate(90deg);
      }
    }
  }

  h4 {
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 800;
  }

  b {
    display: block;
    margin: ${theme.spacing.x2} 0 ${theme.spacing.x1} 0;

    &:first-of-type {
      margin-top: 0;
    }
  }

  &.no-bottom-border {
    border: 0;
  }
`;

const MetaContentLine = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  gap: ${theme.spacing.x1};
  label {
    color: ${theme.colors.subtleText};
    width: 10rem;
    min-width: 10rem;
  }

  &.with-actions {
    justify-content: space-between;
    > div {
      display: flex;
      align-items: center;
      gap: ${theme.spacing.half};
    }
  }
  margin-bottom: ${theme.spacing.x1};
  &:last-of-type {
    margin-bottom: 0;
  }
  &.clickable {
    cursor: pointer;
    margin-bottom: 0;
    &:hover {
      background: ${theme.colors.N3};
    }
  }

  padding: ${theme.spacing.x1} ${theme.spacing.x2};
`;

const MetaContentMain = styled.div`
  padding: ${theme.spacing.x1} ${theme.spacing.x2};
`;

const LoadingContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const ConfidenceAndTitle = styled.div`
  margin-top: 2px;
  display: flex;
  gap: ${theme.spacing.x1};
  align-items: flex-start;
  width: 100%;

  .outcome-title {
    position: relative;
    bottom: 4px;
  }

  .text-badge span {
    overflow: initial;
  }
`;

const HeaderActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const HeaderTitle = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
  .plan-link {
    font-weight: 600;
    color: ${theme.colors.subtleText};
  }

  @media ${theme.devices.mobile} {
    .hide-mobile {
      display: none;
    }
  }
`;

const HeaderActionsMeta = styled.div`
  display: flex;
  align-items: center;

  button {
    margin-left: ${theme.spacing.x1};

    .mob-view {
      display: none;
    }
    .normal-view {
      display: initial;
    }
  }

  @media ${theme.devices.mobile} {
    .hide-mobile {
      display: none;
    }
    .pending-flag {
      margin-right: ${theme.spacing.x2};
    }

    button {
      margin-left: 0rem;

      &.panel-create-checkin {
        margin-right: ${theme.spacing.x1};
      }
      .mob-view {
        display: initial;
      }
      .normal-view {
        display: none;
      }
    }
  }
`;

const ProgressBlock = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x2};
  > div {
  }
`;

const Spacer = styled.div`
  padding-bottom: ${theme.spacing.x4};
`;

const CommentsContainer = styled.div``;

const NotStartedBlock = styled.div`
  padding: ${theme.spacing.x2};
  margin: ${theme.spacing.x2} 0;
  border-radius: 8px;
  display: flex;
  background: #f3f3f3;

  > div {
    &:first-child {
      img {
        width: 4rem;
      }
      margin-right: ${theme.spacing.x3};
    }
  }

  p {
    display: flex;
    gap: ${theme.spacing.x2};
  }
`;

const CompletedBlock = styled.div`
  padding: ${theme.spacing.x2};
  margin: ${theme.spacing.x2} 0;
  border-radius: 8px;
  display: flex;
  background: ${theme.colors.infoBg};

  &.warning {
    background: ${theme.colors.warningBg};
  }

  > div {
    &:first-child {
      img {
        width: 3.2rem;
      }
      margin-right: ${theme.spacing.x3};
    }
  }
`;

const UpgradeOption = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ContributorsListing = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
  align-items: center;
`;

const CheckinButtonsContainer = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
  margin-top: ${theme.spacing.x2};
`;

const InfoBlock = styled.div`
  border: 1px solid ${theme.colors.blockBorder};
  border-radius: 4px;

  &.pinned {
    background: ${theme.colors.N3};
  }
`;
const InfoBlockContent = styled.div`
  padding: ${theme.spacing.x1} ${theme.spacing.x2};
`;

const InitiativesContainer = styled.div`
  margin: ${theme.spacing.x2} 0;
`;

const MainHeader = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
  margin-top: ${theme.spacing.x1};
`;

const SectionTitle = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.half};
  padding-bottom: ${theme.spacing.x1};
  margin-top: ${theme.spacing.x2};

  h4 {
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 700;
  }
`;

const SourceLink = styled.a`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 2.2rem;
  max-width: 20rem;
`;

const ProgressBarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
  > div {
    width: 100%;
  }
  margin-bottom: ${theme.spacing.x3};
`;

const ProgressBarMetrics = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${theme.spacing.x1};
`;

interface Props {
  outcomeId: string;
}

function OutcomePanel(props: Props) {
  const dispatch = useDispatch();
  const queryCache = useQueryCache();
  const { outcomeId } = props;
  const location = useLocation();
  const history = useHistory();
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const isReadOnly = membershipUtils.isReadOnly(currentMembership);

  const hasEssentialsSubscription =
    workspaceUtils.hasEssentialsSubscription(currentWorkspace) || currentWorkspace.pricing_version >= 2;

  const hasPremiumSubscription = workspaceUtils.hasPremiumSubscription(currentWorkspace);
  const hasTrial = workspaceUtils.hasTrial(currentWorkspace);
  const [hasEditPermission, setHasEditPermission] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const { t, i18n } = useTranslation();
  const translationKey = 'panels.outcome';
  const [outcomeArray, setOutcomeArray] = useState<Element[]>([]);

  const storageOutcomePanelSectionss = `__tbty_outcome_panel_sections`;
  const storedOutcomePanelSectionsValue = localStorage.getItem(storageOutcomePanelSectionss);
  const storedOutcomePanelSections = storedOutcomePanelSectionsValue ? JSON.parse(storedOutcomePanelSectionsValue) : {};

  const [showDetails, setShowDetails] = useState(storedOutcomePanelSections['detailsOpened'] || false);
  const [showLinks, setShowLinks] = useState(storedOutcomePanelSections['linksOpened'] || false);
  const [showCheckins, setShowCheckins] = useState(storedOutcomePanelSections['checkinsOpened'] || false);
  const [showCoaching, setShowCoaching] = useState(storedOutcomePanelSections['coachingOpened'] || false);

  useEffect(() => {
    localStorage.setItem(
      storageOutcomePanelSectionss,
      JSON.stringify({
        detailsOpened: showDetails,
        linksOpened: showLinks,
        checkinsOpened: showCheckins,
        coachingOpened: showCoaching,
      }),
    );
  }, [showDetails, showLinks, showCheckins, showCoaching, storageOutcomePanelSectionss]);

  const expandedClass = (itemState: boolean) => {
    if (itemState) {
      return 'expanded';
    }
    return '';
  };

  useHotkeys('K', () => handleChangeOutcome('back'), { scopes: ['panels'] });
  useHotkeys('J', () => handleChangeOutcome('next'), { scopes: ['panels'] });
  useHotkeys('C', () => handleUpdateProgress(), { scopes: ['panels'] });
  useHotkeys(
    'E',
    () => {
      if (hasEditPermission) {
        history.push(editHashRoute);
      }
    },
    { scopes: ['panels'] },
  );

  useEffect(() => {
    const outcomeArray = Array.from(document.querySelectorAll('div.outcome-row'));
    setOutcomeArray(outcomeArray);
  }, []);

  useEffect(() => {
    amplitudeUtils.sendAmplitudeData('app.outcome.view', {
      'Tability Platform': 'web',
      'Tability Version': 2,
    });
  }, []);

  // Construct the query key using the plan Id
  const queryKey = [queryKeys.currentOutcome, outcomeId];
  const staleTime = queryKeys.staleTime;

  // Get the outcome details
  const { data: outcomeResponse } = useQuery(queryKey, remoteApi.fetchOutcomeDetails, {
    staleTime,
  });

  const outcome: Outcome = outcomeResponse ? outcomeResponse.data : null;

  const handleAddDependency = (e: React.MouseEvent) => {
    e.preventDefault();
    dispatch(setGlobalModalContent(`${blockId}:add.dependency`));
  };

  const [manageOutcomeMutation] = useMutation(remoteApi.manageOutcome, {
    onSuccess: (data, variables) => {
      queryCache.invalidateQueries([queryKeys.currentOutcome, outcomeId]);
      queryCache.invalidateQueries([queryKeys.outcomes]);
      if (variables.outcome.completed) {
        setShowConfetti(true);
      }
    },
  });

  const [archiveOutcomeMutation] = useMutation(remoteApi.archiveOutcome, {
    onSuccess: () => {
      queryCache.invalidateQueries([queryKeys.currentOutcome, outcomeId]);
      queryCache.invalidateQueries([queryKeys.outcomes]);
    },
  });

  const [skipCheckinMutation] = useMutation(remoteApi.skipCheckin, {
    onSuccess: () => {
      queryCache.invalidateQueries([queryKeys.currentOutcome, outcomeId]);
      queryCache.invalidateQueries([queryKeys.outcomes]);
    },
  });

  const [syncOutcomeDataSourceMutation] = useMutation(remoteApi.syncOutcomeDataSource, {
    onSuccess: () => {
      queryCache.invalidateQueries([queryKeys.currentOutcome, outcomeId]);
      queryCache.invalidateQueries([queryKeys.checkins]);
    },
  });

  const [updateOutcomeMutation, { isLoading: isUpdating }] = useMutation(remoteApi.updateOutcome, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.outcomes);
      queryCache.invalidateQueries([queryKeys.currentOutcome, outcomeId]);
    },
  });

  // Get the checkins

  // Construct the query key using the plan Id
  const queryKeyCheckins = [queryKeys.checkins, outcomeId];

  // Get the plan details
  const { data: checkinsResponse } = useQuery(queryKeyCheckins, remoteApi.fetchCheckins, {
    staleTime,
  });

  let checkins: Checkin[] = [];
  if (checkinsResponse) {
    checkins = checkinsResponse.data;
  }

  // Construct the query key using the plan Id
  const queryKeyInitiatives = [queryKeys.initiatives, outcomeId];

  // Get the plan details
  const { data: initiativesResponse }: any = useQuery(queryKeyInitiatives, remoteApi.fetchInitiatives, {
    staleTime: 60 * 1000 * 3,
  });

  let initiatives = [];
  if (initiativesResponse) {
    initiatives = initiativesResponse.data;
  }

  // Now we can display the app with the Chrome
  if (!outcome) {
    return (
      <LoadingContainer>
        <Loader size="big" />
      </LoadingContainer>
    );
  }

  let prevId = '';
  let nextId = '';

  if (outcomeId && outcomeArray?.length > 0) {
    const lastIndex = outcomeArray.length - 1;
    const currentOutcomeIndex = outcomeArray.findIndex((el) => {
      return el.id === outcomeId;
    });
    prevId = currentOutcomeIndex > 0 ? outcomeArray[currentOutcomeIndex - 1].id : outcomeArray[lastIndex].id;
    nextId = currentOutcomeIndex < lastIndex ? outcomeArray[currentOutcomeIndex + 1].id : outcomeArray[0].id;
  }

  const { plan } = outcome;

  const blockId = `outcome:${outcome.nano_slug}`;
  const editHashRoute = `#${blockId}:edit`;
  const editRemoteHashRoute = `#${blockId}:edit_remote`;
  // const alignHashRoute = `#${blockId}:align`;
  const moveHashRoute = `${blockId}:move`;

  const handleUpdateProgress = () => {
    dispatch(setGlobalModalContent(`${blockId}:create.checkin`));
  };

  const handlePresent = (e: React.MouseEvent) => {
    const presentPath = routes.WORKSPACE_PLAN_PREZ_OUTCOMES_ROUTE.replace(':workspaceSlug', workspaceSlug)
      .replace(':planId', plan.nano_slug)
      .replace(':outcomeId', outcome.nano_slug);
    e.preventDefault();
    window.open(presentPath, '_blank');
  };

  const handleSkipCheckin = (e: any) => {
    e.preventDefault();
    skipCheckinMutation({
      outcomeId: outcome.id,
    });
  };

  const handleSyncDataSource = (e: any) => {
    e.preventDefault();
    syncOutcomeDataSourceMutation({
      outcomeId: outcome.id,
    });
  };

  const shareOutcome = () => {
    dispatch(setGlobalModalContent(`${blockId}:share`));
  };

  const handleCancel = (e: any) => {
    e.preventDefault();
    history.push(location.pathname);
  };

  const handleMarkOutcomeCompleted = (e: any) => {
    e.preventDefault();
    completeOutcome();
  };

  const completeOutcome = () => {
    manageOutcomeMutation({
      outcomeId: outcome.id,
      outcome: {
        completed: true,
      },
    });
  };

  const uncompleteOutcome = () => {
    manageOutcomeMutation({
      outcomeId: outcome.id,
      outcome: {
        completed: false,
      },
    });
  };

  const archiveOutcome = () => {
    archiveOutcomeMutation({
      outcomeId: outcome.id,
      outcome: {
        archived: true,
      },
    });
  };

  const unarchiveOutcome = () => {
    archiveOutcomeMutation({
      outcomeId: outcome.id,
      outcome: {
        archived: false,
      },
    });
  };

  const handleTitleChange = (value: string) => {
    const params = {
      title: value,
    };

    updateOutcomeMutation({
      outcomeId,
      outcome: params,
    });
  };

  const currentCheckin = checkins ? checkins[0] : null;

  // Calculate the distance between now and when the checkin was due
  const today = new Date();

  // Use the checkin date, or the plan start date if we don't have the checkin date
  // Fall back to today if start date is not set on the plan. This could happen if for some reason a user has removed
  // the start date of a plan after tracking is enabled.
  const planStartAt = plan.start_at ? parseISO(plan.start_at) : today;

  const dateReference = currentCheckin ? parseISO(currentCheckin.checkin_date) : planStartAt;

  const currentColor = currentCheckin ? currentCheckin.confidence : 'grey';

  const outcomeSeemsCompleted =
    !outcome.completed && outcomeUtils.hasTarget(outcome) && outcome.outcome_progress_prct * 100 > 90;
  const outcomeLabel = translate(currentWorkspace, CustomTermKey.OUTCOME, 1).toLowerCase();
  const menuItems = [];
  if (hasEditPermission) {
    menuItems.push(
      <span key="edit" data-action="edit" data-route={editHashRoute}>
        {t('shared.edit')}
      </span>,
      <span key="move" data-action="move" data-route={moveHashRoute}>
        {t('shared.move', { label: outcomeLabel })}
      </span>,
    );
  }
  if (hasEssentialsSubscription) {
    menuItems.push(
      <span key="edit-remote" data-action="edit_remote">
        {t(`${translationKey}.checkinsApi`)}
      </span>,
    );
  } else {
    menuItems.push(
      <UpgradeOption key="edit-remote" data-action="edit_remote">
        <span>{t(`${translationKey}.checkinsApi`)}</span>
        <UpgradeIcon />
      </UpgradeOption>,
    );
  }
  if (hasEditPermission) {
    if (!outcome.archived) {
      menuItems.push(
        <span key="archive" data-action="archive">
          {t(`shared.archive`)}
        </span>,
      );
    } else {
      menuItems.push(
        <span key="unarchive" data-action="unarchive">
          {t(`shared.unarchive`)}
        </span>,
      );
    }
    if (!outcome.completed) {
      menuItems.push(
        <span key="complete" data-action="complete">
          {t(`shared.outcomes.markComplete`)}
        </span>,
      );
    } else {
      menuItems.push(
        <span key="open" data-action="open">
          {t(`shared.outcomes.reopen`)}
        </span>,
      );
    }
    menuItems.push(
      <span key="audit-log" data-action="audit-log">
        {t('shared.audit')}
      </span>,
    );
  }
  menuItems.push(
    <span key="download-chart" data-action="download-chart">
      {t(`shared.outcomes.downloadChart`)}
    </span>,
  );

  const handleMenuSelection = (value: any) => {
    const action = value.props['data-action'];
    switch (action) {
      case 'complete':
        completeOutcome();
        break;
      case 'open':
        uncompleteOutcome();
        break;
      case 'archive':
        archiveOutcome();
        break;
      case 'unarchive':
        unarchiveOutcome();
        break;
      case 'edit':
        const editRoute = value.props['data-route'];
        history.push(editRoute);
        break;
      case 'edit_remote':
        history.push(editRemoteHashRoute);
        break;
      case 'align':
        const alignRoute = value.props['data-route'];
        history.push(alignRoute);
        break;
      case 'move':
        dispatch(setGlobalModalContent(`${blockId}:move`));
        break;
      case 'download-chart':
        dispatch(setGlobalModalContent(`${blockId}:download.chart`));
        break;
      case 'audit-log':
        dispatch(setGlobalModalContent(`audit:${outcome.nano_slug}:show`));
    }
  };

  const handleAssignUser = (value: string) => {
    const membership_id = value;
    if (membership_id) {
      const params = {
        membership_id,
      };
      updateOutcomeMutation({
        outcomeId,
        outcome: params,
      });
    }
  };

  const handleUnassignUser = () => {
    const params = {
      membership_id: null,
    };

    updateOutcomeMutation({
      outcomeId,
      outcome: params,
    });
  };

  const handleChangeOutcome = (type: 'back' | 'next') => {
    if (type === 'back') {
      history.push(`#outcome:${prevId}:show`);
    } else {
      history.push(`#outcome:${nextId}:show`);
    }
  };

  const showMessage = () => {
    if (outcome.is_pending_checkin) {
      return (
        <CompletedBlock className="warning">
          <div>
            <img
              src="https://res.cloudinary.com/tability/image/upload/v1600083943/static_assets/tabby_surprised_tranev.png"
              alt="Seems completed"
            />
          </div>
          <div>
            <p>
              <b>{t(`${translationKey}.checkinDue`)}</b>{' '}
              {t(`${translationKey}.updateProgress`, { label: outcomeLabel })}
            </p>
            <CheckinButtonsContainer>
              <KoalaButton onClick={handleUpdateProgress} size="small">
                {t('shared.createCheckin')}
              </KoalaButton>
              <KoalaButton onClick={handleSkipCheckin} appearance="subtle" size="small">
                {t('shared.skipCheckin')}
              </KoalaButton>
            </CheckinButtonsContainer>
          </div>
        </CompletedBlock>
      );
    }
    if (!currentCheckin) {
      return (
        <NotStartedBlock>
          <div>
            <img
              src="https://res.cloudinary.com/tability/image/upload/v1600083943/static_assets/tabby_surprised_tranev.png"
              alt="Surprised Tabby"
            />
          </div>
          <div>
            <p>{t('shared.checkin.noCheckins')}</p>
            <p>
              <KoalaButton onClick={handleUpdateProgress} appearance="subtle">
                📈 {t('shared.checkin.createButton')}
              </KoalaButton>
              {!outcome.data_source_origin && hasEditPermission && (
                <KoalaButton isLink to={`#outcome:${outcome.nano_slug}:edit:data_source`} appearance="subtle">
                  🔌 {t(`panels.outcome.addDataSource`)}
                </KoalaButton>
              )}
              {(hasPremiumSubscription || hasTrial) &&
                outcome.data_source_origin &&
                outcome.data_source_origin !== 'link' && (
                  <KoalaButton onClick={handleSyncDataSource} appearance="primary">
                    {t(`panels.outcome.syncDataSource`)}
                  </KoalaButton>
                )}
            </p>
            {hasEditPermission && (
              <p style={{ display: 'flex', alignItems: 'center', gap: '0.4rem' }}>
                {t('panels.outcome.editDetailsInfo', { label: outcomeLabel })}
                <KoalaTextButton isLink to={`#outcome:${outcome.nano_slug}:edit`}>
                  {t('panels.outcome.editDetails')}
                </KoalaTextButton>
              </p>
            )}
          </div>
        </NotStartedBlock>
      );
    }
    if (outcomeSeemsCompleted) {
      return (
        <CompletedBlock>
          <div>
            <img
              src="https://res.cloudinary.com/tability/image/upload/v1600083943/static_assets/tabby_happy_ldzb8v.png"
              alt="Seems completed"
            />
          </div>
          <div>
            <p>
              <b>Did you reach your target?</b>
            </p>
            <p>
              You can mark this {translate(currentWorkspace, CustomTermKey.OUTCOME, 1).toLowerCase()} as completed to
              stop getting reminders.
            </p>
            <p>
              <KoalaButton onClick={handleMarkOutcomeCompleted} appearance="subtle">
                Mark as completed
              </KoalaButton>
            </p>
          </div>
        </CompletedBlock>
      );
    }
    return null;
  };

  const currentConfidence = currentCheckin ? currentCheckin.confidence : 'grey';
  const confidenceColor = checkinUtils.confidenceToColor(currentConfidence);
  let recentCheckinByHuman = null;
  let showPinnedCheckin = false;
  if (currentCheckin && !currentCheckin.is_remote) {
    // If the current checkin is not remote, then the most recent checkin is human-made
    // No pin needed
    recentCheckinByHuman = currentCheckin;
  } else {
    // Find the first checkin that is not remote
    recentCheckinByHuman = checkins.find((checkin) => !checkin.is_remote);

    // If we're here it means that the recentCheckinByHuman should be displayed as pinned
    showPinnedCheckin = true;
  }

  const outcomeLink = `https://${process.env.REACT_APP_DOMAIN}${routes.WORKSPACE_PLAN_TRACK_ROUTE.replace(
    ':workspaceSlug',
    workspaceSlug,
  ).replace(':planId', plan.nano_slug)}#outcome:${outcome.nano_slug}:show`;
  return (
    <ModalPanelGrid>
      <PanelHeader>
        <PlanPermissionSync planId={plan.id} setHasEditPermission={setHasEditPermission} />
        <HeaderActions>
          <HeaderTitle>
            <Link className="plan-link hide-mobile" to={`/${workspaceSlug}/plans/${plan.nano_slug}/outcomes`}>
              <PlanIconLabel plan={plan} size="small" />
            </Link>
          </HeaderTitle>
          <HeaderActionsMeta>
            {isReadOnly && <ReadOnlyButton label={t('shared.createCheckin')} appearance="primary" />}
            {!isReadOnly && (
              <>
                <KoalaButton
                  onClick={handleUpdateProgress}
                  className="panel-create-checkin"
                  dataFor="outcome-create-checkin-tooltip"
                  appearance={outcome.is_pending_checkin ? 'primary' : 'secondary'}
                >
                  <span className="normal-view">{t('shared.createCheckin')}</span>
                  <span className="mob-view">{t(`${translationKey}.checkin`)}</span>
                </KoalaButton>
                <ReactTooltip
                  id="outcome-create-checkin-tooltip"
                  place="bottom"
                  type="dark"
                  className="tooltip"
                  effect="solid"
                  delayShow={200}
                >
                  {t(`${translationKey}.createTooltip`, { outcome: outcomeLabel })}
                </ReactTooltip>
              </>
            )}

            <KoalaIconButton
              iconName="present"
              className="hide-mobile"
              dataFor="present-chart-icon"
              onClick={handlePresent}
            />
            <ReactTooltip
              place="bottom"
              type="dark"
              id="present-chart-icon"
              className="tooltip"
              effect="solid"
              delayShow={200}
            >
              {t('shared.present')}
            </ReactTooltip>
            <ShareButton
              id={outcome.id}
              link={outcomeLink}
              shareCallback={shareOutcome}
              isSecondary={outcome.is_pending_checkin}
            />
            {!isReadOnly && (
              <DropdownMenu
                trigger={<KoalaIconButton iconName="ellipsis" />}
                onSelection={handleMenuSelection}
                items={menuItems}
              />
            )}
            <KoalaIconButton onClick={handleCancel} iconName="close" />
          </HeaderActionsMeta>
        </HeaderActions>
      </PanelHeader>
      <PanelContent className="outcome">
        <LayoutContainer>
          <MainContent>
            <Link to={`#objective:${outcome.objective.nano_slug}:show`} className="objective-link">
              <KoalaIcon iconName="flag" iconAppearance={theme.colors.T80} iconSize={1.6} />
              {outcome.objective.title}
            </Link>
            {showConfetti && <Confettis />}
            <MainHeader>
              {prevId && prevId !== nextId && (
                <>
                  <KoalaIconButton
                    dataFor={`previous-out-${outcome.id}`}
                    iconName="leftArrow"
                    size="small"
                    onClick={() => handleChangeOutcome('back')}
                  />
                  <ReactTooltip id={`previous-out-${outcome.id}`} place="bottom" className="tooltip" delayShow={200}>
                    {t(`${translationKey}.previous`, { outcome: outcomeLabel })}
                  </ReactTooltip>
                </>
              )}
              <ConfidenceAndTitle>
                <KoalaColorBlock
                  color={confidenceColor}
                  size="small"
                  isPending={outcome.is_pending_checkin}
                  isCompleted={outcome.completed}
                  dataFor={`${outcome.nano_slug}-confidence`}
                />
                {outcome.is_pending_checkin && (
                  <ReactTooltip
                    place="bottom"
                    type="dark"
                    id={`${outcome.nano_slug}-confidence`}
                    className="tooltip"
                    effect="solid"
                    delayShow={200}
                  >
                    <b>{t(`${translationKey}.checkinDue`)}</b>
                    <br />
                    {t(`${translationKey}.lastUpdate`, { time: formatDistanceToNowLocale(dateReference, i18n, true) })}
                  </ReactTooltip>
                )}
                <OutcomeWeight outcome={outcome} />
                <KoalaInlineTextInput
                  placeholder={
                    t(`${translationKey}.enterOutcomeTitle`, { label: outcomeLabel }) ?? `Enter ${outcomeLabel} title`
                  }
                  text={outcome.title}
                  canEdit={hasEditPermission}
                  handleChangeText={handleTitleChange}
                  isUpdating={isUpdating}
                  className="outcome-title"
                />
                {outcome.completed && (
                  <KoalaTextBadge variant="blue-light" isLowercase>
                    {t('shared.status.completed')}
                  </KoalaTextBadge>
                )}
                {outcome.archived && (
                  <KoalaTextBadge variant="violet-light" isLowercase>
                    {t('shared.status.archived')}
                  </KoalaTextBadge>
                )}
              </ConfidenceAndTitle>
              {nextId && prevId !== nextId && (
                <>
                  <KoalaIconButton
                    dataFor={`next-out-${outcome.id}`}
                    iconName="rightArrow"
                    size="small"
                    onClick={() => handleChangeOutcome('next')}
                  />

                  <ReactTooltip id={`next-out-${outcome.id}`} place="bottom" className="tooltip" delayShow={200}>
                    {t(`${translationKey}.next`, { outcome: outcomeLabel })}
                  </ReactTooltip>
                </>
              )}
            </MainHeader>

            {planUtils.hasTimeline(plan) && (
              <CheckinsChart checkins={checkins} outcome={outcome} initiatives={initiatives} />
            )}
            {outcomeUtils.hasTarget(outcome) && (
              <ProgressBarWrapper>
                <ProgressBarMetrics>
                  <small className="subtle">Goal</small>
                  <small className="subtle">{Math.round(outcome.outcome_progress_prct * 100)}%</small>
                </ProgressBarMetrics>
                <ProgressBlock>
                  <KoalaProgressBar
                    appearance="secondary"
                    value={outcome.outcome_progress_prct * 100}
                    confidence={currentColor}
                  />
                </ProgressBlock>
                <ProgressBarMetrics>
                  <span className="subtle">{outcomeUtils.formattedFrom(outcome)}</span>
                  <span className="subtle">{outcomeUtils.formattedTo(outcome)}</span>
                </ProgressBarMetrics>
              </ProgressBarWrapper>
            )}
            {currentCheckin && (
              <SectionTitle>
                <h4>{t(`${translationKey}.latestCheckin`)}</h4>
              </SectionTitle>
            )}
            {showMessage()}
            {currentCheckin?.is_remote && (
              <>
                <InfoBlock>
                  <InfoBlockContent>
                    <CheckinBlock checkin={currentCheckin} outcome={outcome} showProgressPrct className="primary" />
                    <CommentsContainer>
                      <CheckinComments checkin={currentCheckin} />
                    </CommentsContainer>
                  </InfoBlockContent>
                </InfoBlock>
              </>
            )}
            {recentCheckinByHuman && (
              <>
                {showPinnedCheckin && ( // Only display this title if we also have a synced checkin
                  <SectionTitle>
                    <KoalaIcon iconName="pin" />
                    <h4>{t(`${translationKey}.pinnedCheckin`)}</h4>
                    <KoalaIcon iconName="tooltip" iconSize="small" data-tip data-for="explain-pin" />
                    <ReactTooltip
                      place="bottom"
                      type="dark"
                      className="tooltip"
                      effect="solid"
                      id="explain-pin"
                      delayHide={500}
                    >
                      {t(`${translationKey}.pinnedInfo`)}
                    </ReactTooltip>
                  </SectionTitle>
                )}
                <InfoBlock className={showPinnedCheckin ? 'pinned' : ''}>
                  <InfoBlockContent>
                    <CheckinBlock
                      checkin={recentCheckinByHuman}
                      outcome={outcome}
                      showProgressPrct={currentCheckin?.id === recentCheckinByHuman.id}
                      className="primary"
                    />
                    <CommentsContainer>
                      <CheckinComments checkin={recentCheckinByHuman} />
                    </CommentsContainer>
                  </InfoBlockContent>
                </InfoBlock>
              </>
            )}
            <InitiativesContainer className="outcome-initiatives-container">
              <SectionTitle>
                <h4>{translate(currentWorkspace, CustomTermKey.INITIATIVE, 2)}</h4>
              </SectionTitle>
              <InitiativesTab outcome={outcome} isReadOnly={isReadOnly} />
            </InitiativesContainer>
          </MainContent>
          <MetaContent>
            {!isReadOnly && (
              <MetaContentBlock>
                {outcome.data_source_origin && (
                  <MetaContentLine className="with-actions">
                    <h4>{t(`${translationKey}.dataConnector`)}</h4>
                    <div>
                      <KoalaButton
                        isLink
                        to={`#outcome:${outcome.nano_slug}:edit:data_source`}
                        appearance="secondary"
                        size="small"
                        className="outcome-panel-add-datasource-button"
                      >
                        {dataSourceUtils.dataSourceOriginToLabel(outcome.data_source_origin, t)}
                      </KoalaButton>
                      {outcome.data_source_origin === 'link' && outcome.data_source_meta?.url && (
                        <SourceLink
                          href={outcome.data_source_meta?.url}
                          target="_blank"
                          className="primary"
                          rel="nofollower noreferrer"
                          title={outcome.data_source_meta?.url}
                        >
                          {outcome.data_source_meta?.url}
                        </SourceLink>
                      )}
                      {(hasPremiumSubscription || hasTrial) && outcome.data_source_origin !== 'link' && (
                        <>
                          <KoalaIconButton
                            iconName="clock"
                            size="xsmall"
                            onClick={handleSyncDataSource}
                            dataFor="sync-datasource"
                          />
                          <ReactTooltip id="sync-datasource" place="bottom" type="dark" effect="solid" delayShow={200}>
                            {t(`${translationKey}.syncDataSource`)}
                          </ReactTooltip>
                        </>
                      )}
                    </div>
                  </MetaContentLine>
                )}
                {!outcome.data_source_origin && (
                  <MetaContentLine className="with-actions">
                    <h4>{t(`${translationKey}.dataConnector`)}</h4>
                    <KoalaButton
                      isLink
                      to={`#outcome:${outcome.nano_slug}:edit:data_source`}
                      appearance="secondary"
                      size="small"
                      className="outcome-panel-add-datasource-button"
                    >
                      {t(`panels.outcome.addDataSource`)}
                    </KoalaButton>
                  </MetaContentLine>
                )}
              </MetaContentBlock>
            )}
            <MetaContentBlock className={`outcome-relationships-container`}>
              <MetaContentLine className="outcome-relationships-header with-actions">
                <h4>{t(`${translationKey}.relationships`)}</h4>
                {hasEditPermission && (
                  <KoalaButton
                    className="outcome-panel-add-relationship-button"
                    onClick={handleAddDependency}
                    appearance="secondary"
                    size="small"
                  >
                    {t(`${translationKey}.addRelationship`)}
                  </KoalaButton>
                )}
              </MetaContentLine>
              <MetaContentMain>
                <Relationships outcome={outcome} hasEditPermission={hasEditPermission} />
              </MetaContentMain>
            </MetaContentBlock>
            <MetaContentBlock className={expandedClass(showDetails)}>
              <MetaContentLine className="clickable" onClick={() => setShowDetails(!showDetails)}>
                <KoalaIconButton
                  iconName="rightChevron"
                  size="xsmall"
                  edge="square"
                  onClick={() => setShowDetails(!showDetails)}
                />
                <h4>{t(`${translationKey}.details`)}</h4>
              </MetaContentLine>
              {showDetails && (
                <>
                  <MetaContentLine>
                    <label>{t('panels.owner')}</label>
                    <UserSelectButton
                      canEdit={hasEditPermission}
                      handleAssignUser={handleAssignUser}
                      handleUnassignUser={handleUnassignUser}
                      selectedUser={outcome.membership ? outcome.membership.user : null}
                    />
                  </MetaContentLine>
                  <MetaContentLine>
                    <label>{t('shared.contributors')}</label>
                    <ContributorsListing>
                      <OutcomeContributors outcome={outcome} canEdit={hasEditPermission} />
                    </ContributorsListing>
                  </MetaContentLine>
                  {!isReadOnly && (
                    <>
                      <MetaContentLine>
                        <label>{t('shared.teams')}</label>
                        <ContributorsListing>
                          <TeamRelationships outcome={outcome} canEdit={hasEditPermission} />
                        </ContributorsListing>
                      </MetaContentLine>
                      <MetaContentLine>
                        <label>{t('shared.tags')}</label>
                        <TagPickerOutcome outcome={outcome} disabled={!hasEditPermission} />
                      </MetaContentLine>
                    </>
                  )}
                  {outcome.description && (
                    <MetaContentLine>
                      <MarkdownContent source={outcome.description} />
                    </MetaContentLine>
                  )}
                </>
              )}
            </MetaContentBlock>
            <MetaContentBlock className={expandedClass(showLinks)}>
              <MetaContentLine className="clickable" onClick={() => setShowLinks(!showLinks)}>
                <KoalaIconButton
                  iconName="rightChevron"
                  size="xsmall"
                  edge="square"
                  onClick={() => setShowLinks(!showLinks)}
                />
                <h4>{t(`panels.shared.links`)}</h4>
              </MetaContentLine>
              {showLinks && (
                <MetaContentMain>
                  <OutcomeLinksTab outcome={outcome} isReadOnly={isReadOnly} />
                </MetaContentMain>
              )}
            </MetaContentBlock>
            {!isReadOnly && (
              <MetaContentBlock className={expandedClass(showCoaching)}>
                <MetaContentLine className="clickable" onClick={() => setShowCoaching(!showCoaching)}>
                  <KoalaIconButton
                    iconName="rightChevron"
                    size="xsmall"
                    edge="square"
                    onClick={() => setShowCoaching(!showCoaching)}
                  />
                  <h4>{t(`${translationKey}.coaching`)}</h4>
                </MetaContentLine>
                {showCoaching && (
                  <MetaContentMain>
                    <Analysis outcome={outcome} />
                  </MetaContentMain>
                )}
              </MetaContentBlock>
            )}
            <MetaContentBlock className={expandedClass(showCheckins)}>
              <MetaContentLine className="clickable" onClick={() => setShowCheckins(!showCheckins)}>
                <KoalaIconButton
                  iconName="rightChevron"
                  size="xsmall"
                  edge="square"
                  onClick={() => setShowCheckins(!showCheckins)}
                />
                <h4>{t(`${translationKey}.checkins`)}</h4>
              </MetaContentLine>
              {showCheckins && (
                <MetaContentMain>
                  <CheckinsTab checkins={checkins} outcome={outcome} />
                </MetaContentMain>
              )}
            </MetaContentBlock>
            <Spacer />
          </MetaContent>
        </LayoutContainer>
      </PanelContent>
    </ModalPanelGrid>
  );
}

export default OutcomePanel;
