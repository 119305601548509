import React, { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components';
import * as TabilityTypes from 'types';
import { useMutation } from 'react-query';
import theme from 'theme';
import { Helmet } from 'react-helmet';
import * as workspaceUtils from 'utils/workspaceUtils';

// API
import * as remoteApi from 'api/remote';

// Components
import { ChromeContent, ChromeSideNav } from 'components/Chrome';
import SettingsNarrowWrapper from 'components/SettingsNarrowWrapper';
import ContentTitle from 'components/ContentTitle';
import WorkspaceSettingsNav from 'components/WorkspaceSettingsNav';
import KoalaButton from 'koala/components/Button';
import StripeLogo from './powered_stripe.svg';
import { Trans, useTranslation } from 'react-i18next';
import RadioField from 'components/RadioField';

const PreviewGrid = styled.div`
  display: grid;
  grid-template-columns: 15rem 1fr auto;
  width: 100%;
  gap: ${theme.spacing.x1};
`;

const StripeLogoContainer = styled.div`
  img {
    height: 3.2rem;
  }
`;

const PlanOptions = styled.div`
  display: flex;
  gap: ${theme.spacing.x3};
  // hide radio button and put it behind label so not clickable
  input[type='radio'] {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }

  label {
    cursor: pointer;
    display: grid;
    grid-template-rows: auto auto auto 1fr;

    flex: 1;
    gap: ${theme.spacing.x1};
    border: 1px solid ${theme.colors.blockBorder};
    border-radius: 4px;
    padding: ${theme.spacing.x2};
    transition: all 0.2s ease;

    &.disabled {
      cursor: not-allowed;
      filter: grayscale(0.7);
      opacity: 0.7;
    }

    &:hover {
      border: 1px solid ${theme.colors.B50};
    }

    span {
      width: 100%;
      &.header {
        font-size: 2rem;
        font-weight: 800;
      }

      &.price {
        b {
          font-size: 3.2rem;
          font-weight: 600;
        }
        font-size: 1.8rem;
        font-weight: 500;
      }

      &.text {
        font-size: 1.2rem;
        line-height: 1.8;
      }
    }
  }

  input[type='radio']:checked + label {
    background: ${theme.colors.B5};
    border: 1px solid ${theme.colors.B50};
  }
`;

const CurrentSubscription = styled.div`
  font-size: 2rem;
  display: flex;
  gap: ${theme.spacing.x2};

  margin: ${theme.spacing.x3} 0;
`;

const UpdateSubscription = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x2};
  padding-bottom: ${theme.spacing.x4};
  h3 {
    margin-top: ${theme.spacing.x2};
    font-weight: 800;
  }
  .info {
    font-size: 2rem;
    text-align: center;
  }
  a {
    color: ${theme.colors.blue};
  }
`;

const ConfirmSubscription = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x2};
  padding-bottom: ${theme.spacing.x4};
`;

const PreviewSubscription = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: ${theme.spacing.x2};

  .header {
    font-weight: 800;
  }
`;

const Actions = styled.div`
  display: flex;
  gap: ${theme.spacing.x2};
`;

const ReadonlyInfo = styled.div`
  padding: ${theme.spacing.x2};
  background: ${theme.colors.V5};
  border: 1px solid ${theme.colors.V60};
  border-radius: 4px;
`;

const SeatCount = styled.div`
  display: flex;
  gap: ${theme.spacing.x2};
  justify-content: space-between;
  .input-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing.x2};
    > div {
      display: flex;
      gap: ${theme.spacing.x1};
      align-items: center;
      font-size: 2rem;
      input {
        width: 15rem;
        padding: ${theme.spacing.x2};
        height: 4rem;
        font-size: 2.4rem;
      }
    }

    .error {
      background: ${theme.colors.Y5};
      color: ${theme.colors.O80};
      border-radius: 4px;
      font-size: 1.4rem;
      padding: ${theme.spacing.half} ${theme.spacing.x1};
    }
  }
  .readonly-info {
    flex: 1;
  }
`;

const IntervalWrapper = styled.div`
  width: 20rem;
  margin: 0 auto;
`;

declare global {
  interface Window {
    Stripe: any;
    Rewardful: any;
  }
}

interface Props {
  workspace: TabilityTypes.Workspace;
}

function PricingVersion(props: Props) {
  const { workspace } = props;
  const { t } = useTranslation();

  let defaultPlanType = '';
  let defaultBillingInterval = 'monthly';
  if (workspace.billing_subscription_plan) {
    const planParts = workspace.billing_subscription_plan.split('-');
    defaultPlanType = planParts[2];
    defaultBillingInterval = planParts[3];
  }

  const [billingInterval, setBillingInterval] = useState<string>(defaultBillingInterval);
  const [planType, setPlanType] = useState<string>(defaultPlanType);
  const [selectedPlan, setSelectedPlan] = useState(workspace.billing_subscription_plan);
  const [previewInvoice, setPreviewInvoice]: any = useState(null);
  const [, setStripeCustomer]: any = useState(null);
  const [hasPaymentMethod, setHasPaymentMethod] = useState(true);
  const [showPlanOptions, setShowPlanOptions] = useState(false);

  useEffect(() => {
    if (workspace.billing_subscription_plan) {
      setBillingInterval(workspace.billing_subscription_plan?.includes('monthly') ? 'monthly' : 'annual');
      setPlanType(workspace.billing_subscription_plan?.includes('basic') ? 'basic' : 'premium');
      setSelectedPlan(workspace.billing_subscription_plan);
    }
  }, [workspace.billing_subscription_plan]);

  const handleChangeBillingInterval = (e: any) => {
    const interval = e.target.value;
    setBillingInterval(interval);
    setSelectedPlan(`tability-v5-${planType}-${interval}`);
  };

  const handleChangePlanType = (e: any) => {
    const type = e.target.value;
    setPlanType(type);
    setSelectedPlan(`tability-v5-${type}-${billingInterval}`);
  };

  const defaultQuantity = workspace.billing_user_limit === 0 ? 1 : workspace.billing_user_limit;
  const [selectedQuantity, setSelectedQuantity] = useState(defaultQuantity);

  const finalQuantity = selectedQuantity < 1 ? 1 : selectedQuantity;

  const [isSubscriptionUpdated, setIsSubscriptionUpdated] = useState(false);

  // Workspace mutations
  const [createWorkspaceCheckoutSessionMutation, { isLoading: isLoadingCheckout }]: [any, any] = useMutation(
    remoteApi.createWorkspaceCheckoutSession,
    {
      onSuccess: (response) => {
        const { data } = response;
        const { session_id } = data;

        const stripe = window.Stripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
        stripe
          .redirectToCheckout({
            sessionId: session_id,
          })
          .then((result: any) => {
            if (result.error) {
              alert(result.error.message);
            }
          });
      },
    },
  );

  // Workspace mutations
  const [previewWorkspaceSubscriptionMutation, { isLoading: isLoadingPreview }]: [any, any] = useMutation(
    remoteApi.previewWorkspaceSubscription,
    {
      onSuccess: (response) => {
        const { data } = response;
        const { preview, customer } = data;
        setPreviewInvoice(preview);
        setStripeCustomer(customer);
      },
    },
  );

  // Workspace mutations
  const [updateWorkspaceSubscriptionMutation, { isLoading: isUpdatingSubscription }]: [any, any] = useMutation(
    remoteApi.updateWorkspaceSubscription,
    {
      onSuccess: () => {
        setHasPaymentMethod(true);
        setIsSubscriptionUpdated(true);
        setTimeout(() => {
          window.location.replace(`/${workspace.slug}/settings/billing`);
        }, 3000);
      },
      onError: (error: any) => {
        const errorMsg = error.response.data.message;
        if (errorMsg === 'Sorry, you need to add a credit card to your account') {
          setHasPaymentMethod(false);
        }
      },
    },
  );

  const handlePreview = () => {
    const quantity = selectedPlan === 'tability-v4-free' ? 5 : selectedQuantity;
    const params = {
      workspaceSlug: workspace.slug,
      body: {
        price_id: selectedPlan,
        quantity,
      },
    };
    previewWorkspaceSubscriptionMutation(params);
  };

  const handleUpdateSubscription = () => {
    const quantity = selectedPlan === 'tability-v4-free' ? 5 : selectedQuantity;
    const params = {
      workspaceSlug: workspace.slug,
      body: {
        price_id: selectedPlan,
        quantity,
      },
    };
    updateWorkspaceSubscriptionMutation(params);
  };

  const handleSubscribe = () => {
    const params = {
      workspaceSlug: workspace.slug,
      body: {
        price_id: selectedPlan,
        quantity: selectedQuantity,
      },
    };
    createWorkspaceCheckoutSessionMutation(params);
  };

  // Workspace mutations
  const [createWorkspacePortalSessionMutation, { isLoading: isLoadingPortal }]: [any, any] = useMutation(
    remoteApi.createWorkspacePortalSession,
    {
      onSuccess: (response) => {
        const { data } = response;
        const { session_url } = data;

        window.location.href = session_url;
      },
    },
  );

  const handleShowPortal = () => {
    const params = {
      workspaceSlug: workspace.slug,
    };
    createWorkspacePortalSessionMutation(params);
  };

  // Create our number formatter.
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  const hasPlan = workspaceUtils.hasSubscription(workspace);

  const costMapping: any = {
    'tability-v4-free': 0,
    'tability-v4-basic': 4,
    'tability-v4-plus': 5,
    'tability-v4-premium': 8,
    'tability-v5-basic-monthly': 6,
    'tability-v5-basic-annual': 5,
    'tability-v5-premium-monthly': 10,
    'tability-v5-premium-annual': 8,
  };
  const readonlyMapping: any = {
    'tability-v4-free': 0,
    'tability-v4-basic': 0,
    'tability-v4-plus': 0,
    'tability-v4-premium': 2,
    'tability-v5-basic-monthly': 0,
    'tability-v5-basic-annual': 0,
    'tability-v5-premium-monthly': 2,
    'tability-v5-premium-annual': 2,
  };
  const aiMapping: any = {
    'tability-v4-free': 1000,
    'tability-v4-basic': 2000,
    'tability-v4-plus': 2500,
    'tability-v4-premium': 4000,
    'tability-v5-basic-monthly': 2000,
    'tability-v5-basic-annual': 2000,
    'tability-v5-premium-monthly': 4000,
    'tability-v5-premium-annual': 4000,
  };

  let monthlyCost = 0;
  let annualCost = 0;
  let aiCredits = 0;
  let readonlySeats = 0;

  if (selectedPlan && finalQuantity) {
    monthlyCost = finalQuantity * costMapping[selectedPlan];
    aiCredits = finalQuantity * aiMapping[selectedPlan];
    readonlySeats = finalQuantity * readonlyMapping[selectedPlan];
    annualCost = monthlyCost * 12;
  }

  if (isSubscriptionUpdated) {
    return (
      <Fragment>
        <Helmet>
          <script src="https://js.stripe.com/v3/" />
          <title>
            {workspace.name} | {t('workspaceSettingsBilling.plans.title')} | Tability
          </title>
        </Helmet>
        <ChromeSideNav>
          <WorkspaceSettingsNav />
        </ChromeSideNav>
        <ChromeContent>
          <SettingsNarrowWrapper>
            <ContentTitle>
              <h1>{t('workspaceSettingsBilling.v5.confirmSubscription')}</h1>
            </ContentTitle>
            <ConfirmSubscription>
              <h3>{t('workspaceSettingsBilling.v5.subscriptionUpdated')}</h3>
              <p>{t('workspaceSettingsBilling.v5.redirecting')}</p>
            </ConfirmSubscription>
          </SettingsNarrowWrapper>
        </ChromeContent>
      </Fragment>
    );
  }

  // TODO Fix has payment detection

  // const hasPaymentMethod =
  //   stripeCustomer && stripeCustomer.invoice_settings && stripeCustomer.invoice_settings.default_payment_method;

  if (previewInvoice) {
    let paymentDate = null;
    if (previewInvoice.next_payment_attempt) {
      // get the payment date as "23 May 2024"
      paymentDate = new Date(previewInvoice.next_payment_attempt * 1000).toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
      });
    }
    return (
      <Fragment>
        <Helmet>
          <script src="https://js.stripe.com/v3/" />
          <title>
            {workspace.name} | {t('workspaceSettingsBilling.plans.title')} | Tability
          </title>
        </Helmet>
        <ChromeSideNav>
          <WorkspaceSettingsNav />
        </ChromeSideNav>
        <ChromeContent>
          <SettingsNarrowWrapper>
            <ContentTitle>
              <h1>{t('workspaceSettingsBilling.v5.confirmSubscription')}</h1>
            </ContentTitle>
            <ConfirmSubscription>
              <h3>{t('workspaceSettingsBilling.v5.summary')}</h3>
              <PreviewGrid>
                <div>
                  <b>{t('workspaceSettingsBilling.v5.subscriptionType')}</b>
                </div>
                <div>{workspaceUtils.planIdToString(selectedPlan)}</div>
                <div>
                  <Trans
                    i18nKey="workspaceSettingsBilling.v5.priceUsers"
                    values={{ price: costMapping[selectedPlan] }}
                    components={{ b: <b /> }}
                  />
                </div>
                <div>
                  <b>{t('workspaceSettingsBilling.v5.numberSeats')}</b>
                </div>
                <div>{selectedQuantity}</div>
                <div>
                  <Trans
                    i18nKey="workspaceSettingsBilling.v5.price"
                    components={{ b: <b /> }}
                    values={{ price: monthlyCost }}
                  />
                </div>
                <div>
                  <b>{t('workspaceSettingsBilling.v5.readonlySeats')}</b>
                </div>
                <div>{readonlySeats}</div>
                <div>
                  {readonlySeats > 0 && <>{t('workspaceSettingsBilling.v5.included')}</>}
                  {readonlySeats === 0 && <>–</>}
                </div>
                <div>
                  <b>{t('workspaceSettingsBilling.v5.credits')}</b>
                </div>
                <div>{t('workspaceSettingsBilling.v5.creditsPrice', { credits: aiCredits })}</div>
                <div>{t('workspaceSettingsBilling.v5.included')}</div>
              </PreviewGrid>
              {!paymentDate && <h3>{t('workspaceSettingsBilling.v5.changes')}</h3>}
              {paymentDate && (
                <>
                  <h3>{t('workspaceSettingsBilling.v5.changesNextInvoice', { date: paymentDate })}</h3>
                </>
              )}
              <PreviewGrid>
                {previewInvoice.lines.data.map((line: any, index: number) => {
                  return (
                    <>
                      <div></div>
                      <div>{line.description}</div>
                      <div>{formatter.format(line.amount / 100)}</div>
                    </>
                  );
                })}
                <div />
                {!paymentDate && (
                  <div>
                    <b>{t('workspaceSettingsBilling.v5.toPay')}</b>
                  </div>
                )}
                {paymentDate && (
                  <div>
                    <b>{t('workspaceSettingsBilling.v5.toPayNextInvoice', { date: paymentDate })}</b>
                  </div>
                )}
                <div>{formatter.format(previewInvoice.total / 100)}</div>
              </PreviewGrid>
              {!hasPaymentMethod && (
                <>
                  <div>{t('workspaceSettingsBilling.v5.paymentDetailsMissing')}</div>
                  <Actions>
                    <KoalaButton
                      onClick={() => handleShowPortal()}
                      disabled={isLoadingPortal}
                      loading={isLoadingPortal}
                      appearance="subtle"
                    >
                      {t('workspaceSettingsBilling.v5.addPayment')}
                    </KoalaButton>
                  </Actions>
                </>
              )}
              {hasPaymentMethod && (
                <Actions>
                  <KoalaButton
                    onClick={() => handleUpdateSubscription()}
                    disabled={isUpdatingSubscription}
                    loading={isUpdatingSubscription}
                  >
                    {t('workspaceSettingsBilling.v5.confirm')}
                  </KoalaButton>
                  <KoalaButton onClick={() => setPreviewInvoice(null)} appearance="secondary">
                    {t('shared.cancel')}
                  </KoalaButton>
                </Actions>
              )}
              <StripeLogoContainer>
                <img src={StripeLogo} alt="Powered by Stripe" />
              </StripeLogoContainer>
            </ConfirmSubscription>
          </SettingsNarrowWrapper>
        </ChromeContent>
      </Fragment>
    );
  }

  const reviewDisabled = !selectedPlan || !selectedQuantity || selectedQuantity < 1;

  const basicPrice = billingInterval === 'monthly' ? 6 : 5;
  const premiumPrice = billingInterval === 'monthly' ? 10 : 8;

  // If we're on an annual plan, we ask people to contact support
  if (['tability-v5-basic-annual', 'tability-v5-premium-annual'].includes(workspace.billing_subscription_plan)) {
    return (
      <Fragment>
        <ChromeSideNav>
          <WorkspaceSettingsNav />
        </ChromeSideNav>
        <ChromeContent>
          <SettingsNarrowWrapper>
            <ContentTitle>
              <h1>{t('workspaceSettingsBilling.plans.title')}</h1>
            </ContentTitle>
            <CurrentSubscription>
              <p>
                <Trans
                  i18nKey="workspaceSettingsBilling.currentPlan"
                  values={{ plan: workspaceUtils.billingSubscriptionToString(workspace) }}
                  components={{ b: <b /> }}
                />
              </p>
              <p>{t('workspaceSettingsBilling.v5.annualContactSupport')}</p>
              <p>
                <KoalaButton onClick={() => window.Intercom('show')}>
                  {t('workspaceSettingsBilling.v5.contactSupport')}
                </KoalaButton>
              </p>
            </CurrentSubscription>
          </SettingsNarrowWrapper>
        </ChromeContent>
      </Fragment>
    );
  }
  return (
    <Fragment>
      <Helmet>
        <script src="https://js.stripe.com/v3/" />
        <title>
          {workspace.name} | {t('workspaceSettingsBilling.plans.title')} | Tability
        </title>
      </Helmet>
      <ChromeSideNav>
        <WorkspaceSettingsNav />
      </ChromeSideNav>
      <ChromeContent>
        <SettingsNarrowWrapper>
          <ContentTitle>
            <h1>{t('workspaceSettingsBilling.plans.title')}</h1>
          </ContentTitle>
          <CurrentSubscription>
            <div>
              <Trans
                i18nKey="workspaceSettingsBilling.currentPlan"
                values={{ plan: workspaceUtils.billingSubscriptionToString(workspace) }}
                components={{ b: <b /> }}
              />
            </div>
            {selectedPlan && (
              <KoalaButton onClick={() => setShowPlanOptions(true)} appearance="secondary">
                {t('workspaceSettingsBilling.v5.changePlan')}
              </KoalaButton>
            )}
          </CurrentSubscription>
          <UpdateSubscription>
            {(!selectedPlan || showPlanOptions) && (
              <>
                <h3>{t('workspaceSettingsBilling.v5.newPlan')}</h3>
                <IntervalWrapper>
                  <RadioField>
                    <div className="radio-group">
                      <input
                        type="radio"
                        id="monthly"
                        checked={billingInterval === 'monthly'}
                        name="billingInterval"
                        value={'monthly'}
                        onChange={handleChangeBillingInterval}
                      />
                      <label htmlFor="monthly">{t('workspaceSettingsBilling.v5.monthlyInterval')}</label>
                      <input
                        type="radio"
                        id="annual"
                        checked={billingInterval === 'annual'}
                        name="billingInterval"
                        value={'annual'}
                        onChange={handleChangeBillingInterval}
                      />
                      <label htmlFor="annual">{t('workspaceSettingsBilling.v5.annualInterval')}</label>
                    </div>
                  </RadioField>
                </IntervalWrapper>
                <PlanOptions>
                  <input
                    type="radio"
                    id="basic"
                    value={'basic'}
                    checked={planType === 'basic'}
                    name="planType"
                    onChange={handleChangePlanType}
                  />
                  <label htmlFor="basic">
                    <span className="header">{t('workspaceSettingsBilling.v5.basic')}</span>
                    <span>{t('workspaceSettingsBilling.v5.basicSummary')}</span>
                    <span className="price">
                      <Trans
                        i18nKey="workspaceSettingsBilling.v5.priceUsers"
                        components={{ b: <b /> }}
                        values={{ price: basicPrice }}
                      />
                    </span>
                    <span className="text">{t('workspaceSettingsBilling.v5.basicInfo')}</span>
                    <span className="text">
                      {t('workspaceSettingsBilling.v5.creditPerUser', { count: 2000 })}
                      <br />
                      <Trans i18nKey="workspaceSettingsBilling.v5.basicFeatures" components={{ br: <br /> }} />
                    </span>
                  </label>
                  <input
                    type="radio"
                    id="premium"
                    value={'premium'}
                    checked={planType === 'premium'}
                    name="planType"
                    onChange={handleChangePlanType}
                  />
                  <label htmlFor="premium">
                    <span className="header">{t('workspaceSettingsBilling.v5.premium')}</span>
                    <span>{t('workspaceSettingsBilling.v5.premiumSummary')}</span>
                    <span className="price">
                      <Trans
                        i18nKey="workspaceSettingsBilling.v5.priceUsers"
                        components={{ b: <b /> }}
                        values={{ price: premiumPrice }}
                      />
                    </span>
                    <span className="text">{t('workspaceSettingsBilling.v5.premiumInfo')}</span>
                    <span className="text">
                      {t('workspaceSettingsBilling.v5.creditPerUser', { count: 4000 })}
                      <br />
                      <Trans i18nKey="workspaceSettingsBilling.v5.premiumFeatures" components={{ br: <br /> }} />
                    </span>
                  </label>
                </PlanOptions>
                <a href="https://www.tability.io/pricing" target="_blank" rel="noopener noreferrer">
                  {t('workspaceSettingsBilling.compareButton')}
                </a>
              </>
            )}
            {!selectedPlan && <p className="info"> {t('workspaceSettingsBilling.v5.selectPlan')}</p>}
            {selectedPlan && (
              <>
                <h3>{t('workspaceSettingsBilling.v5.chooseNumberSeats')}</h3>
                <SeatCount>
                  <div className="input-container">
                    <div>
                      {false && (
                        <select
                          defaultValue={selectedQuantity}
                          value={selectedQuantity}
                          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                            setSelectedQuantity(parseInt(e.target.value));
                          }}
                        >
                          <option value="">{t('workspaceSettingsBilling.v4.selectOption')}</option>
                          {Array.from({ length: 500 }, (_, i) => i + 10).map((value) => (
                            <option key={value}>{value}</option>
                          ))}
                        </select>
                      )}
                      <input
                        value={selectedQuantity}
                        onChange={(e) => setSelectedQuantity(parseInt(e.target.value))}
                        type="number"
                        min={1}
                      />
                      &nbsp; {t('workspaceSettingsBilling.v5.seats').toLowerCase()}
                    </div>
                    {selectedQuantity < 1 ? <span className="error">Minimum 1 seats</span> : null}
                    <p>
                      <Trans
                        i18nKey="workspaceSettingsBilling.v5.needMore"
                        components={{ a: <a href="mailto:sales@tability.io">link</a> }}
                      />
                    </p>
                  </div>
                  <div className="readonly-info">
                    <ReadonlyInfo>
                      <p>
                        <b>{t('workspaceSettingsBilling.v5.readonlySeatsInfoTitle')}</b>
                      </p>
                      <p>{t('workspaceSettingsBilling.v5.readonlySeatsInfoDescription')}</p>
                    </ReadonlyInfo>
                  </div>
                </SeatCount>

                <h3>{t('workspaceSettingsBilling.v5.newSubscription')}</h3>

                <PreviewSubscription>
                  <div className="header">{t('workspaceSettingsBilling.v5.plan')}</div>
                  <div>{workspaceUtils.planIdToString(selectedPlan)}</div>
                  <div className="header">{t('workspaceSettingsBilling.v5.seats')}</div>
                  <div>{finalQuantity}</div>
                  <div className="header">{t('workspaceSettingsBilling.v5.readonlySeats')}</div>
                  <div>{readonlySeats}</div>
                  <div className="header">{t('workspaceSettingsBilling.v5.credits')}</div>
                  <div>{t('workspaceSettingsBilling.v5.creditsPrice', { credits: aiCredits.toLocaleString() })}</div>
                  <div className="header">{t('workspaceSettingsBilling.v5.subscriptionCost')}</div>
                  <div>
                    <Trans
                      i18nKey="workspaceSettingsBilling.v5.price"
                      components={{ b: <b /> }}
                      values={{ price: monthlyCost.toLocaleString() }}
                    />
                    {billingInterval === 'annual' && (
                      <Trans
                        i18nKey="workspaceSettingsBilling.v5.priceAnnual"
                        components={{ b: <b /> }}
                        values={{ price: annualCost.toLocaleString() }}
                      />
                    )}
                  </div>
                </PreviewSubscription>
                <div>
                  {hasPlan && (
                    <KoalaButton
                      onClick={() => handlePreview()}
                      disabled={isLoadingPreview || reviewDisabled}
                      loading={isLoadingPreview}
                    >
                      {t('workspaceSettingsBilling.v5.reviewConfirm')}
                    </KoalaButton>
                  )}

                  {!hasPlan && (
                    <KoalaButton
                      onClick={() => handleSubscribe()}
                      disabled={isLoadingCheckout || reviewDisabled}
                      loading={isLoadingCheckout}
                    >
                      {t('workspaceSettingsBilling.v5.reviewUpgrade')}
                    </KoalaButton>
                  )}
                </div>
                <StripeLogoContainer>
                  <img src={StripeLogo} alt="Powered by Stripe" />
                </StripeLogoContainer>
              </>
            )}
          </UpdateSubscription>
        </SettingsNarrowWrapper>
      </ChromeContent>
    </Fragment>
  );
}

export default PricingVersion;
