import { KoalaSizes, KoalaTextBadgeVariants } from 'koala/shared/global';
import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { colors } from 'koala/shared/styles';
import { Link } from 'react-router-dom';

const StyledTextBadge = styled.span<{ backgroundColor?: string; textColor?: string; maxWidth?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  max-width: ${(props) => props.maxWidth ?? '15rem'};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-shrink: 0;

  background-color: ${({ backgroundColor }) => backgroundColor ?? colors.N60};
  color: ${({ textColor }) => textColor ?? colors.N0};

  &.text-badge--lowercase {
    font-weight: 500;
    font-size: 12px;
    letter-spacing: unset;
    text-transform: unset;
  }
  &.text-badge--uppercase {
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: 1px;
    font-size: 1rem;
  }

  &.text-badge--small {
    height: 2rem;
    min-width: 2rem;
    line-height: 2rem;
    padding: 0 0.8rem;
    &.text-badge--lowercase {
      font-size: 10px;
    }
  }
  &.text-badge--medium {
    padding: 0 0.8rem;
    height: 2.4rem;
    min-width: 2.4rem;
    line-height: 2.4rem;
  }
  &.text-badge--large {
    padding: 0 0.8rem;
    height: 2.8rem;
    min-width: 2.8rem;
    line-height: 2.8rem;
    &.text-badge--lowercase {
      font-size: 14px;
      padding: 0 1.2rem;
    }
  }

  &.text-badge--circle {
    border-radius: 1.2rem;
    &.text-badge--large {
      border-radius: 1.8rem;
    }
  }
  &.text-badge--square {
    border-radius: 0.4rem;
  }

  &.variant--neutral {
    background-color: ${colors.N60};
    color: ${colors.N0};
  }

  &.variant--neutral-light {
    background-color: ${colors.N10};
    color: ${colors.N70};
  }

  &.variant--neutral-outlined {
    background-color: transparent;
    border: 1px solid ${colors.N30};
    color: ${colors.N60};
  }

  &.variant--blue {
    background-color: ${colors.B50};
    color: ${colors.N0};
  }

  &.variant--blue-light {
    background-color: ${colors.B5};
    color: ${colors.B50};
  }

  &.variant--blue-outlined {
    background-color: transparent;
    border: 1px solid ${colors.B30};
    color: ${colors.B50};
  }

  &.variant--green {
    background-color: ${colors.G60};
    color: ${colors.N0};
  }

  &.variant--green-light {
    background-color: ${colors.G5};
    color: ${colors.G70};
  }

  &.variant--green-outlined {
    background-color: transparent;
    border: 1px solid ${colors.G30};
    color: ${colors.G50};
  }

  &.variant--violet {
    background-color: ${colors.V60};
    color: ${colors.N0};
  }

  &.variant--violet-light {
    background-color: ${colors.V5};
    color: ${colors.V60};
  }

  &.variant--violet-outlined {
    background-color: transparent;
    border: 1px solid ${colors.V30};
    color: ${colors.V50};
  }

  &.variant--red {
    background-color: ${colors.R70};
    color: ${colors.N0};
  }

  &.variant--red-light {
    background-color: ${colors.R5};
    color: ${colors.R80};
  }

  &.variant--red-outlined {
    background-color: transparent;
    border: 1px solid ${colors.R30};
    color: ${colors.R50};
  }

  &.variant--teal {
    background-color: ${colors.T40};
    color: ${colors.N100};
  }

  &.variant--teal-light {
    background-color: ${colors.T20};
    color: ${colors.T100};
  }

  &.variant--teal-outlined {
    background-color: transparent;
    border: 1px solid ${colors.T30};
    color: ${colors.T70};
  }

  &.variant--yellow {
    background-color: ${colors.Y40};
    color: ${colors.N100};
  }

  &.variant--yellow-light {
    background-color: ${colors.Y20};
    color: ${colors.Y100};
  }

  &.variant--yellow-outlined {
    background-color: transparent;
    border: 1px solid ${colors.Y40};
    color: ${colors.Y70};
  }

  &.variant--orange-outlined {
    background-color: transparent;
    border: 1px solid ${colors.O50};
    color: ${colors.O50};
  }

  &.variant--urgent {
    background-color: ${colors.R50};
    color: ${colors.N0};
  }
  /*
  Commented this because it adds a border on the dashboard notification badge
  &.text-badge--custom-color {
    border: 1px solid ${({ textColor }) => textColor ?? colors.N0};
  }
  */
  &.clickable {
    :hover {
      filter: brightness(0.9);
      cursor: pointer;
    }
  }
`;

export const StyledLink = styled(Link)`
  border: none;
  text-decoration: none;
  :hover {
    filter: brightness(0.9);
    cursor: pointer;
  }
  :focus {
    border: 2px solid ${colors.B50};
    outline-offset: 2px;
  }
`;

const ButtonInner = styled.span`
  width: 100%;
  min-width: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;

  .text-badge-label {
    min-width: 0px;
    text-overflow: ellipsis;
    overflow-wrap: anywhere;
    overflow: hidden;
    display: flex;
    flex: 1;
    align-items: center;
  }
`;

export interface ButtonProps {
  children: ReactNode;
  className?: string;
  style?: React.CSSProperties;
  edge?: 'square' | 'circle';
  isLowercase?: boolean;
  variant?: (typeof KoalaTextBadgeVariants)[number];
  maxWidth?: string;
  /** set the hex value for the badge background (Note: overriden by variant) */
  backgroundColor?: string;
  /** set the hex value for the badge text (Note: overriden by variant) */
  textColor?: string;
  size?: (typeof KoalaSizes)[number];
  linkTo?: string;
  dataFor?: string;
  suffixIcon?: JSX.Element;
  onClick?: (e: React.MouseEvent) => void;
}

const KoalaTextBadge: FC<ButtonProps> = ({ ...props }) => {
  const {
    children,
    className,
    style,
    variant,
    dataFor,
    maxWidth,
    isLowercase,
    edge,
    size,
    textColor,
    backgroundColor,
    linkTo,
    suffixIcon,
    onClick,
  } = props;
  let buttonInner = (
    <ButtonInner>
      <span className="text-badge-label">{children}</span>
      {suffixIcon}
    </ButtonInner>
  );

  let variantClass = '';
  if (variant) {
    variantClass = `variant--${variant}`;
  } else if (backgroundColor && textColor) {
    variantClass = 'text-badge--custom-color';
  }

  let buttonClass = `text-badge ${className ?? ''} ${variantClass}`;
  buttonClass += ` text-badge--${size ?? 'medium'} text-badge--${edge ?? 'circle'} text-badge--${
    isLowercase ? 'lowercase' : 'uppercase'
  }`;

  if (onClick) {
    buttonClass += ' clickable';
  }

  if (linkTo) {
    return (
      <StyledLink
        to={linkTo}
        className={`text-badge-link ${className}`}
        onClick={(e) => e.stopPropagation()}
        data-tip={dataFor ? true : false}
        data-for={dataFor}
      >
        <StyledTextBadge className={buttonClass} {...{ style, textColor, backgroundColor, maxWidth }}>
          {buttonInner}
        </StyledTextBadge>
      </StyledLink>
    );
  }

  return (
    <StyledTextBadge
      className={buttonClass}
      {...{ style, textColor, backgroundColor, maxWidth }}
      data-tip={dataFor ? true : false}
      data-for={dataFor}
      onClick={onClick}
    >
      {buttonInner}
    </StyledTextBadge>
  );
};

KoalaTextBadge.defaultProps = {
  edge: 'square',
  size: 'medium',
  isLowercase: false,
  maxWidth: '15rem',
};

export default KoalaTextBadge;
