import { differenceInCalendarDays, parseISO } from 'date-fns/esm';
import * as TabilityTypes from 'types';
import theme from 'theme';
import { TFunction } from 'i18next';
import IconList from 'koala/shared/images/icons/IconList';

// Check if plan has a timeline
const isBlank = (variable: any) => {
  if (typeof variable === 'undefined' || variable === null) {
    return true;
  }
  return false;
};

export const planTypeToLabel = (plan_type: string, t: TFunction) => {
  if (plan_type === 'simplified') {
    return t('shared.planTypes.simplified');
  }

  return t('shared.planTypes.classic');
};

export const hasTimeline = (plan: TabilityTypes.Plan) => {
  return !isBlank(plan.start_at) && !isBlank(plan.finish_at);
};

export const hasEditPermission = (plan: TabilityTypes.Plan, membership: TabilityTypes.Membership) => {
  if (!plan || !membership) {
    return false;
  }

  if (membership.role === 'readonly') {
    return false;
  }

  // Return true if the plan has edit permission for all
  if (plan.global_permission_type === 'edit') {
    return true;
  }

  // Return true if the membership is admin/owner
  if (['admin', 'owner'].includes(membership.role)) {
    return true;
  }

  return plan.user_plan_permission === 'edit';
};

export const isInPlanTimeline = (startDate: Date, finishDate: Date, toCheck: Date) => {
  return toCheck >= startDate && toCheck <= finishDate;
};

export const planTimelineProgress = (
  plan: TabilityTypes.Plan,
): { daysLeft: number | undefined; prctTime: number | undefined } => {
  const { start_at, finish_at } = plan;
  let daysLeft;
  let prctTime;
  if (start_at === null || finish_at === null) {
    return { daysLeft, prctTime };
  }
  const startDate = new Date(start_at);
  const endDate = new Date(finish_at);
  const totalDays = differenceInCalendarDays(endDate, startDate);
  const daysElapsed = differenceInCalendarDays(Date.now(), startDate);
  daysLeft = Math.max(0, totalDays - daysElapsed); // Days left shouldn't be negative
  prctTime = (daysElapsed / totalDays) * 100;
  prctTime = Math.min(Math.max(0, prctTime), 100); // Set progress prct between 0 and 100
  return { daysLeft, prctTime };
};

export interface ConfidenceData {
  x: number;
  y: number;
  color: string;
  legendTitle: string;
}

export const getConfidenceData = (plan: TabilityTypes.Plan) => {
  const data: ConfidenceData[] = [];
  let dataIndex = 1;

  if (plan.green_outcomes_count) {
    data.push({
      x: dataIndex++,
      y: plan.green_outcomes_count,
      color: theme.colors.green,
      legendTitle: 'on track',
    });
  }
  if (plan.yellow_outcomes_count) {
    data.push({
      x: dataIndex++,
      y: plan.yellow_outcomes_count,
      color: theme.colors.yellow,
      legendTitle: 'at risk',
    });
  }
  if (plan.red_outcomes_count) {
    data.push({
      x: dataIndex++,
      y: plan.red_outcomes_count,
      color: theme.colors.red,
      legendTitle: 'off track',
    });
  }
  if (plan.grey_outcomes_count) {
    data.push({
      x: dataIndex++,
      y: plan.grey_outcomes_count,
      color: theme.colors.grey,
      legendTitle: 'pending',
    });
  }

  return data;
};

export const getOutcomeProgressData = (plan: TabilityTypes.Plan) => ({
  progressPercentage: plan.outcome_progress_prct * 100,
  colorType: 'B',
  centerLabel: `${Math.round(plan.outcome_progress_prct * 100)}%`,
  getCenterIcon: IconList['flag'],
});

export const getInitiativeProgressData = (plan: TabilityTypes.Plan) => ({
  progressPercentage: plan.initiative_progress_prct * 100,
  colorType: 'B',
  centerLabel: `${plan.closed_initiatives_count}/${plan.total_initiatives_count}`,
  getCenterIcon: IconList['task'],
});

export const getTimelineProgressData = (plan: TabilityTypes.Plan, t: TFunction) => {
  const planStartAtISO = plan && plan.start_at ? parseISO(plan.start_at) : null;
  const planFinishAtISO = plan && plan.finish_at ? parseISO(plan.finish_at) : null;
  let planDaysCount = planFinishAtISO && planStartAtISO ? differenceInCalendarDays(planFinishAtISO, planStartAtISO) : 0;
  let daysSpent = planStartAtISO ? differenceInCalendarDays(new Date(), planStartAtISO) : 0;
  let daysLeft = planFinishAtISO ? Math.max(0, differenceInCalendarDays(planFinishAtISO, new Date())) : 0;
  let days_progress_prct = planDaysCount ? (daysSpent / planDaysCount) * 100 : 0;

  const timelineProgressData = {
    progressPercentage: days_progress_prct,
    colorType: 'B',
    getCenterIcon: IconList['clock'],
  };

  return { timelineProgressData, daysLeft };
};
