import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import * as TabilityTypes from 'types';
import ReactTooltip from 'react-tooltip';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { shallowEqual, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';

// API
import * as remoteApi from 'api/remote';

import { parseISO, differenceInCalendarDays } from 'date-fns';

import KoalaIcon from 'koala/components/Icons';

import ProgressChart from './ProgressChart';
import ConfidenceChart from './ConfidenceChart';
import NCSChart from './NCSChart';
import { Trans, useTranslation } from 'react-i18next';

const PlanSection = styled.div`
  border: 1px solid ${theme.colors.blockBorder};
  border-radius: 4px;
  background: #fff;
  position: relative;

  @media ${theme.devices.tablet} {
    &.hide-mobile {
      display: none;
    }
  }
`;

const PlanSectionHeader = styled.div`
  padding: ${theme.spacing.x1} ${theme.spacing.x2};
  border-bottom: 1px solid ${theme.colors.blockBorder};
  display: flex;
  justify-content: space-between;
  align-items: center;
  .title {
    font-size: 1rem;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  &.collapsed {
    border-bottom: 0;
  }
  gap: ${theme.spacing.x2};
`;

const PlanSectionContent = styled.div`
  padding: ${theme.spacing.x2};
  &.no-padding {
    padding: 0;
  }
`;

// Routes

const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  &.with-border-bottom {
    border-bottom: 1px solid ${theme.colors.blockBorder};
    margin-bottom: ${theme.spacing.x1};
  }
  &.with-border-right {
    border-right: 1px solid ${theme.colors.blockBorder};
    padding-right: ${theme.spacing.x1};
  }
  &.with-border-left {
    margin-left: ${theme.spacing.x1};
  }
  h4 {
    display: flex;
    gap: ${theme.spacing.half};
    align-items: center;
    min-height: 2.4rem;
    margin-bottom: ${theme.spacing.x1};
  }
`;

const ChartsWrapper = styled.div`
  display: flex;
  margin-bottom: ${theme.spacing.x1};
  > div {
    flex: 1;
  }
  @media ${theme.devices.tablet} {
    flex-direction: column;
  }
`;

interface Props {
  plan: TabilityTypes.Plan;
}

function Tracker(props: Props) {
  const { plan } = props;
  const [trends, setTrends] = useState([]);
  const { t } = useTranslation();
  // Fetch trends data
  useQuery([queryKeys.currentPlan, plan.id, 'trends'], remoteApi.fetchPlanTrends, {
    staleTime: 0,
    onSuccess: (response) => {
      setTrends(response.data);
    },
  });

  const planFinishAt = plan && plan.finish_at ? parseISO(plan.finish_at) : null;
  let differenceInDays = planFinishAt ? differenceInCalendarDays(planFinishAt, new Date()) : 0;
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  if (differenceInDays < 0) {
    differenceInDays = 0;
  }

  if (!plan.checked_in) {
    return null;
  }

  return (
    <>
      <PlanSection className="plan-insights">
        <PlanSectionHeader>
          <span className="title">{t('workspacePlan.planTrendsTitle')}</span>
        </PlanSectionHeader>
        <PlanSectionContent>
          <ChartsWrapper>
            <DataContainer className="with-border-bottom">
              <h4>
                {t('workspacePlan.insights.compareProgress', {
                  outcomes: translate(currentWorkspace, CustomTermKey.OUTCOME, 2),
                  initiatives: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2),
                })}
              </h4>
              <ProgressChart trends={trends} plan={plan} />
            </DataContainer>
          </ChartsWrapper>
          <ChartsWrapper>
            <DataContainer className="with-border-bottom">
              <h4>{t('workspacePlan.insights.confidence')}</h4>
              <ConfidenceChart trends={trends} plan={plan} />
            </DataContainer>
          </ChartsWrapper>
          <ChartsWrapper>
            <DataContainer>
              <h4>
                {t('shared.ncs.full')}
                <KoalaIcon iconName="tooltip" iconSize="small" data-tip data-for="explain-ncs2" />
              </h4>
              <ReactTooltip
                place="bottom"
                type="dark"
                className="tooltip"
                effect="solid"
                id="explain-ncs2"
                delayHide={500}
                clickable={true}
              >
                <Trans i18nKey="shared.ncs.tooltip" components={{ br: <br /> }} />{' '}
                <a
                  href="https://www.tability.io/odt-articles/nps-but-for-your-okrs-introducing-the-net-confidence-score-ncs"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('shared.learnMore')}
                </a>
              </ReactTooltip>
              <NCSChart trends={trends} plan={plan} />
            </DataContainer>
          </ChartsWrapper>
        </PlanSectionContent>
      </PlanSection>
    </>
  );
}

export default React.memo(Tracker);
