import React, { useRef, useCallback, useEffect } from 'react';
import ReactCanvasConfetti from 'react-canvas-confetti';

function StreakLadder() {
  const refAnimationInstance: any = useRef(null);
  const makeShot = useCallback((particleRatio, opts) => {
    refAnimationInstance.current &&
      refAnimationInstance.current({
        ...opts,
        origin: { y: 0.7 },
        particleCount: Math.floor(200 * particleRatio),
        colors: ['#0A5055', '#F4CDC9', '#151515', '#F5C02B'],
      });
  }, []);

  const fire = useCallback(() => {
    makeShot(0.1, {
      spread: 26,
      startVelocity: 55,
    });

    makeShot(0.1, {
      spread: 60,
    });

    makeShot(0.15, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  }, [makeShot]);

  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance;
  }, []);

  useEffect(() => {
    if (refAnimationInstance && refAnimationInstance.current) {
      fire();
    }
  }, [refAnimationInstance, fire]);

  return (
    <>
      <ReactCanvasConfetti
        refConfetti={getInstance}
        style={{ position: 'fixed', width: '100%', height: '100%', top: 0, left: 0, pointerEvents: 'none' }}
      />
    </>
  );
}

export default StreakLadder;
