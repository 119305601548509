import React, { ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import * as checkinUtils from 'utils/checkinUtils';
import * as membershipUtils from 'utils/membershipUtils';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';
import { Link, useHistory } from 'react-router-dom';

import { setGlobalModalContent } from 'state/actions/globalUIActions';

// API
import * as remoteApi from 'api/remote';

import DropdownMenu from 'components/DropdownMenu';
import MarkdownContent from 'components/MarkdownContent';
import KoalaIconButton from 'koala/components/IconButton';
import Reactions from 'components/Reactions';
import { CachedReaction, Outcome, Membership, Workspace } from 'types';
import KoalaAvatar from 'koala/components/Avatar';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import { formatDistanceToNowLocale } from 'utils/dateUtils';
import PlanIconLabel from 'components/PlanIconLabel';
import KoalaColorBlock from 'koala/components/ColorBlock';
import KoalaButton from 'koala/components/Button';
import KoalaIcon from 'koala/components/Icons';
import NewsblockCheckinComments from './NewsblockCheckinComments';
import OutcomeCurrentValueLabel from 'components/OutcomeCurrentValueLabel';
import ShareIconButton from 'components/ShareIconButton';
import * as routes from 'routes';

const CheckinContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x2};

  .actions {
    opacity: 1;
  }

  &:hover {
    .actions {
      opacity: 1;
    }
  }

  @media ${theme.devices.mobile} {
    padding: ${theme.spacing.x2};
    column-gap: ${theme.spacing.x2};
  }
`;

const Gutter = styled.div`
  grid-area: gutter;
  display: flex;
  gap: ${theme.spacing.x2};
  align-items: flex-start;
`;
const GutterText = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
  width: 100%;
`;

const Meta = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 3.8rem;
`;

const Content = styled.div`
  grid-area: content;
  .subtle {
    padding: ${theme.spacing.x1} 0;
  }

  .ql-editor.ql-display-only {
    padding: 0;
  }
`;

const ReactionsContainer = styled.div`
  display: flex;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: ${theme.spacing.x1};
  align-items: start;
  color: ${theme.colors.subtleText};

  .plan-title {
    color: ${theme.colors.subtleText};
  }

  .time-block {
    height: 2rem;
  }
  .actions-block {
    justify-content: end;
  }
  > div {
    display: flex;
    align-items: center;
  }

  @media ${theme.devices.mobile} {
    margin-right: 1.6rem;
  }
`;

const CommentsContainer = styled.div`
  padding: 0 ${theme.spacing.x2};
`;

const Wrapper = styled.div`
  background: ${theme.colors.N0};
  border: 1px solid ${theme.colors.blockBorder};
  border-radius: 4px;
  padding: ${theme.spacing.x2};
`;

const OutcomeLine = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.half};
  a {
    color: ${theme.colors.N60};
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 2rem;
    &:hover {
      text-decoration: underline;
    }
  }
`;
const SmallActions = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing.half};
`;

const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing.x1};
  .action-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }
`;

interface Props {
  checkin: any;
  className?: string;
  showProgressPrct?: boolean;
  compact?: boolean;
  hideActions?: boolean;
}

function CheckinBlock(props: Props) {
  const dispatch = useDispatch();
  const queryCache = useQueryCache();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { checkin, hideActions } = props;
  const outcome: Outcome = checkin.outcome;
  const user = checkin.membership ? checkin.membership.user : null;
  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const currentWorkspace: Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const isReadOnly = membershipUtils.isReadOnly(currentMembership);
  const [showComment, setShowComment] = useState(false);

  // Checkin mutations
  const [deleteCheckinMutation] = useMutation(remoteApi.deleteCheckin, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.outcomes);
      queryCache.invalidateQueries(queryKeys.checkins);
      if (outcome) {
        queryCache.invalidateQueries([queryKeys.currentOutcome, outcome.nano_slug]);
      }
    },
  });

  const handleMenuSelection = (value: ReactElement) => {
    const action = value.props['data-action'];
    const checkinId = value.props['data-id'];
    const blockId = `checkin:${checkinId}`;
    switch (action) {
      case 'edit':
        dispatch(setGlobalModalContent(`${blockId}:edit`));
        break;
      case 'delete':
        if (window.confirm('Delete this checkin?')) {
          deleteCheckinMutation(checkinId);
        }
        break;
    }
  };

  // get reactions
  const [reactions, setReactions] = useState<CachedReaction[]>([]);
  useEffect(() => {
    if (checkin.cached_reactions && typeof checkin.cached_reactions !== 'string') {
      setReactions(checkin.cached_reactions);
    } else {
      setReactions([]);
    }
  }, [checkin]);

  const [addReactionMutation] = useMutation(remoteApi.createCheckinReaction, {
    onSuccess: (response) => {
      setReactions(response.data);
      queryCache.invalidateQueries(queryKeys.checkins);
      queryCache.invalidateQueries(queryKeys.currentCheckin);
    },
  });
  const [deleteReactionMutation] = useMutation(remoteApi.deleteReaction, {
    onSuccess: (response) => {
      setReactions(response.data);
      queryCache.invalidateQueries(queryKeys.checkins);
      queryCache.invalidateQueries(queryKeys.currentCheckin);
    },
  });

  const addReaction = (emotion: string) => {
    addReactionMutation({ checkinId: checkin.id, emotion: emotion });
  };
  const removeReaction = (reactionId: string) => {
    deleteReactionMutation(reactionId);
  };

  const handleNewComment = () => {
    setShowComment(true);
  };

  const handleAddLike = () => {
    if (!hasLike) {
      addReaction(':+1:');
    }
  };

  if (!outcome) {
    return null;
  }

  const formattedScore = checkin ? checkinUtils.checkinScore(checkin, outcome, t) : 'Pending';

  let menu_items: ReactElement[] = [];

  menu_items = [
    <span key="edit" data-action="edit" data-id={checkin.id}>
      {t('shared.edit')}
    </span>,
    <span key="delete" data-action="delete" data-id={checkin.id}>
      {t('shared.delete')}
    </span>,
  ];

  const handleAvatarClicked = (membership: Membership) => {
    history.push(`/${currentWorkspace.slug}/people/${membership.id}`);
  };

  const avatarSize = props.compact ? 2.4 : 4;

  const hashPath = `#outcome:${outcome.nano_slug}:show`;

  let checkinLink = checkin.plan_nano_slug
    ? `https://${process.env.REACT_APP_DOMAIN}${routes.WORKSPACE_PLAN_TRACK_ROUTE.replace(
        ':workspaceSlug',
        currentWorkspace.slug,
      ).replace(':planId', checkin.plan_nano_slug)}#checkin:${checkin.nano_slug}:show`
    : '';
  const hasLike = reactions.find((r) => r.emotion === ':+1:' && r.membership_id === currentMembership.id);

  return (
    <Wrapper>
      <CheckinContainer className={`checkin ${props.className}`}>
        <Gutter>
          <Link to={`/${currentWorkspace.slug}/people/${checkin.membership.id}`}>
            <KoalaAvatar
              user={user}
              size={avatarSize}
              handleClick={user ? () => handleAvatarClicked(checkin.membership) : undefined}
            />
          </Link>
          <GutterText>
            <OutcomeLine>
              <KoalaColorBlock
                color={checkinUtils.confidenceToColor(
                  outcome.current_checkin ? outcome.current_checkin.confidence : '',
                )}
                size="xsmall"
              />
              <Link to={hashPath}>{outcome.title}</Link>
            </OutcomeLine>
            <OutcomeCurrentValueLabel outcome={outcome} checkin={checkin} />
          </GutterText>

          <ReactTooltip
            place="bottom"
            type="dark"
            className="tooltip"
            effect="solid"
            id={`progress-checkin-${checkin.id}`}
          >
            {formattedScore}
          </ReactTooltip>
          <Meta>
            <ShareIconButton id={checkin.id} link={checkinLink} />
            {!hideActions && !isReadOnly && (
              <>
                {menu_items.length > 0 && (
                  <DropdownMenu
                    trigger={<KoalaIconButton iconName="ellipsis" className="actions" />}
                    onSelection={handleMenuSelection}
                    items={menu_items}
                  />
                )}
              </>
            )}
          </Meta>
        </Gutter>

        {checkin.body && (
          <Content className="checkin-block-content">
            {checkin.body && <MarkdownContent source={checkin.body} />}
            {!checkin.body && <p className="subtle">{t('publicPlan.noComment')}</p>}
            {checkin.is_remote && (
              <p className="subtle">
                --
                <br />
                <em>{t('publicPlan.remote')}</em>
              </p>
            )}
          </Content>
        )}

        <SmallActions>
          <KoalaButton onClick={handleNewComment} appearance="secondary" size="small">
            {t('shared.reply')}
          </KoalaButton>

          {!hideActions && (
            <ReactionsContainer>
              <Reactions
                reactions={reactions}
                addCallback={addReaction}
                removeCallback={removeReaction}
                addButtonType="like"
                size="small"
              />
            </ReactionsContainer>
          )}
        </SmallActions>

        <Footer className="checkin-block-footer">
          <Link
            className="plan-link hide-mobile"
            to={`/${currentWorkspace.slug}/plans/${outcome.plan.nano_slug}/outcomes`}
          >
            <PlanIconLabel plan={outcome.plan} size="xsmall" crop={25} />
          </Link>
          <div className="time-block">{formatDistanceToNowLocale(checkin.checkin_date, i18n, true, true)}</div>
        </Footer>

        <ActionContainer>
          <KoalaButton appearance="secondary" className="action-btn" onClick={handleAddLike}>
            <KoalaIcon iconName="like" iconAppearance={hasLike ? 'subtle' : 'secondary'} />
          </KoalaButton>
          <KoalaButton appearance="secondary" className="action-btn" onClick={handleNewComment}>
            <KoalaIcon iconName="comment" />
          </KoalaButton>
        </ActionContainer>
      </CheckinContainer>
      <CommentsContainer>
        <NewsblockCheckinComments
          checkin={checkin}
          showNewComment={showComment}
          handleShowNewComment={setShowComment}
        />
      </CommentsContainer>
    </Wrapper>
  );
}

export default React.memo(CheckinBlock);
