import React from 'react';
import styled from 'styled-components';
import theme from 'theme';

const ProgressBarContainer = styled.div`
  height: 4px;
  width: 100%;
  position: relative;
  background: ${theme.colors.B5};
  border-radius: 2px;
  overflow: hidden;
`;

const ProgressBarInner = styled.div<{ value: number }>`
  height: 100%;
  background: ${theme.colors.B50};
  border-radius: 2px;
  width: ${(props) => props.value}%;
`;

const EmptyBlock = styled.div`
  width: 50px;
  text-align: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${theme.spacing.x1};
  width: 100%;
`;

const Label = styled.div`
  font-size: 1.1rem;
  font-weight: 600;
  color: ${theme.colors.B50};
  width: 4.5rem;
`;

interface Props {
  percentage: number;
  totalCount: number;
  label: string;
}

function ProgressBarV2(props: Props) {
  const { percentage, totalCount, label } = props;

  if (totalCount === 0) {
    return <EmptyBlock>-</EmptyBlock>;
  }

  return (
    <Wrapper>
      <Label>{label}</Label>
      <ProgressBarContainer>
        <ProgressBarInner value={percentage} />
      </ProgressBarContainer>
    </Wrapper>
  );
}
export default ProgressBarV2;
