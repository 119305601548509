import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import * as TabilityTypes from 'types';
import { useHistory, Link } from 'react-router-dom';

import KoalaAvatar from 'koala/components/Avatar';
import * as outcomeUtils from 'utils/outcomeUtils';
import * as checkinUtils from 'utils/checkinUtils';
import KoalaColorBlock from 'koala/components/ColorBlock';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import KoalaIcon from 'koala/components/Icons';
import KoalaTextBadge from 'koala/components/TextBadge';
import OutcomeCard from 'components/OutcomeCard';

export const BlockGutter = styled.div`
  position: relative;
  grid-area: gutter;
  display: flex;

  align-items: center;
`;

export const BlockContent = styled.div`
  line-height: 2.4rem;
  font-weight: 400;
  grid-area: content;
  background: transparent;
  margin: 0;
  min-width: 1rem;
  gap: ${theme.spacing.half};

  .block-title {
    display: flex;
  }
  display: flex;
  justify-content: space-between;
  p {
    margin-top: ${theme.spacing.x1};
  }

  a {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:hover {
      text-decoration: none;
    }
  }
`;

const BlockProgress = styled.div`
  position: relative;
  grid-area: progress;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: ${theme.spacing.x1};
`;

const BlockGrid = styled.div`
  display: grid;
  width: 100%;
  cursor: pointer;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto;
  grid-template-areas: 'gutter content progress';
  align-items: center;
  column-gap: 0.8rem;
  padding: 0.2rem ${theme.spacing.half};
  border-radius: 0px;

  &:hover {
    background: ${theme.colors.N3};
  }
  &.selected,
  &:active,
  &:focus {
    background: ${theme.colors.B5};
  }

  &.no-hover-bg {
    &:hover {
      background: transparent;
    }
  }

  .avatar-tooltip {
    padding: 0px;
    background: transparent;
    border: none;
    &.show {
      opacity: 1 !important;
    }
  }
`;

const CommentButton = styled.div`
  display: flex;
  align-items: center;
  color: ${theme.colors.subtleText};

  .icon {
    background-color: transparent;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 0.2rem;
  }

  .value {
    margin-left: 0.4rem;
    cursor: pointer;
  }

  &:hover {
    .icon {
      background: #d1d1d1;
    }
  }
`;

interface Props {
  outcome: TabilityTypes.Outcome;
  isSelected: boolean;
}

function OutcomeBlock(props: Props) {
  const history = useHistory();
  const { outcome, isSelected } = props;
  const { membership } = outcome;
  const { t } = useTranslation();

  const blockId = `outcome:${outcome.nano_slug}`;
  const hashPath = `#${blockId}:show`;

  const handleOutcomeClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(hashPath);
  };

  const currentCheckin = outcome.current_checkin;
  const className = isSelected ? 'selected' : '';

  const currentConfidence = currentCheckin ? currentCheckin.confidence : 'grey';
  const confidenceColor = checkinUtils.confidenceToColor(currentConfidence);
  const formattedScore = currentCheckin
    ? checkinUtils.checkinScore(currentCheckin, outcome, t)
    : outcomeUtils.startingScore(outcome, t);

  return (
    <BlockGrid onClick={handleOutcomeClick} className={`${className}`}>
      <BlockGutter>
        <KoalaColorBlock
          color={confidenceColor}
          size="small"
          isPending={outcome.is_pending_checkin}
          isCompleted={outcome.completed}
          dataFor={`outcome-block-${outcome.nano_slug}`}
        />
        <ReactTooltip
          type="light"
          effect="solid"
          clickable={true}
          delayHide={200}
          delayShow={200}
          className="avatar-tooltip"
          arrowColor="transparent"
          id={`outcome-block-${outcome.nano_slug}`}
        >
          <OutcomeCard outcome={outcome} />
        </ReactTooltip>
      </BlockGutter>
      <BlockContent>
        <Link to={hashPath}>{outcome.title}</Link>
        {currentCheckin && currentCheckin.checkin_comments_count > 0 && (
          <CommentButton>
            <div className="icon">
              <KoalaIcon iconName="comment" iconSize="xsmall" />
            </div>
            <div className="value">{currentCheckin.checkin_comments_count}</div>
          </CommentButton>
        )}
      </BlockContent>
      <BlockProgress>
        <KoalaTextBadge
          isLowercase={true}
          variant={checkinUtils.confidenceToVariant(currentConfidence)}
          edge="circle"
          size="small"
          style={{ border: 'none' }}
          maxWidth="10rem"
          dataFor={`progress-${outcome.nano_slug}`}
        >
          {formattedScore}
        </KoalaTextBadge>

        <ReactTooltip
          type="light"
          effect="solid"
          clickable={true}
          delayHide={200}
          delayShow={200}
          className="avatar-tooltip"
          arrowColor="transparent"
          id={`progress-${outcome.nano_slug}`}
        >
          <OutcomeCard outcome={outcome} />
        </ReactTooltip>
        <KoalaAvatar user={membership ? membership.user : null} size={2} />
      </BlockProgress>
    </BlockGrid>
  );
}

export default React.memo(OutcomeBlock);
