import KoalaIconButton from 'koala/components/IconButton';
import { KoalaSelectOption } from 'koala/components/Select';
import React, { Fragment, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import theme from 'theme';
import FormField from 'components/FormField';
import PlanSummaryWidgetDetails from './WidgetDetails/PlanSummaryWidgetDetails';
import ObjectiveSummaryWidgetDetails from './WidgetDetails/ObjectiveSummaryWidgetDetails';
import OutcomeSummaryWidgetDetails from './WidgetDetails/OutcomeSummaryWidgetDetails';
import UserProfileWidgetDetails from './WidgetDetails/UserProfileDetails';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { shallowEqual, useSelector } from 'react-redux';
import InitiativeSummaryWidgetDetails from './WidgetDetails/InitiativeSummaryWidgetDetails';
import ObjectiveListWidgetDetails from './WidgetDetails/ObjectiveListWidgetDetails';
import OutcomeListWidgetDetails from './WidgetDetails/OutcomeListWidgetDetails';
import InitiativeListWidgetDetails from './WidgetDetails/InitiativeListWidgetDetails';
import OutcomeTaskGraphWidgetDetails from './WidgetDetails/OutcomeTaskGraphWidgetDetails';
import PlanConfidenceGraphWidgetDetails from './WidgetDetails/PlanConfidenceGraphWidgetDetails';
import PlanNCSGraphWidgetDetails from './WidgetDetails/PlanNCSGraphWidgetDetails';
import OutcomeGraphWidgetDetails from './WidgetDetails/OutcomeGraphWidgetDetails';
import { DashboardWidget } from 'types';
import { useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';
import * as remoteApi from 'api/remote';
import TextBlockWidgetDetails from './WidgetDetails/TextBlockWidgetDetails';
import OutcomeStatsWidgetDetails from './WidgetDetails/OutcomeStatsWidgetDetails';
import OutcomeDistributionWidgetDetails from './WidgetDetails/OutcomeDistributionWidgetDetails';
import WidgetOptionCard from './WidgetOptionCard';

const Mask = styled.div<{ showSearchPanel: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: ${(props) => (props.showSearchPanel ? '50' : '-1')};
  opacity: ${(props) => (props.showSearchPanel ? '1' : '0')};
  transition: 0.2s ease-out;
`;

const ContentWrapper = styled.div<{ showSearchPanel: boolean }>`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 45rem;
  z-index: 60;
  transform: translateX(${(props) => (props.showSearchPanel ? '0%' : '100%')});
  transition: 0.2s ease-out;

  @media ${theme.devices.mobile} {
    max-width: 40rem;
    width: 100%;
  }
`;

const Content = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: auto;
  padding: 3.2rem;

  background: #fff;
`;

const SearchHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #151515;
  font-weight: 700;
  font-size: 2rem;
  margin-bottom: ${theme.spacing.x3};
`;

interface Props {
  handleClosePanel: () => void;
  showPanel: boolean;
  widget: DashboardWidget;
  dashboardId: string;
  resetTimer: number;
  setResetTimer: (resetTimer: number) => void;
}

function WidgetEditPanel(props: Props) {
  const { widget, handleClosePanel, showPanel, dashboardId, resetTimer, setResetTimer } = props;
  const { t } = useTranslation();
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const [widgetType, setWidgetType] = useState<KoalaSelectOption | undefined>();
  const [widgetTitle, setWidgetTitle] = useState<string>('');
  const queryCache = useQueryCache();

  // useEffect to reset the widget type and title when resetTimer changes
  useEffect(() => {
    setWidgetType(undefined);
    setWidgetTitle('');
  }, [resetTimer]);

  const insightOptions = useMemo(
    () => [
      { label: t('workspaceDashboards.planSummary'), value: 'plan-summary' },
      {
        label: t('workspaceDashboards.objectiveSummary', {
          objective: translate(currentWorkspace, CustomTermKey.OBJECTIVE, 1),
        }),
        value: 'objective-summary',
      },
      {
        label: t('workspaceDashboards.outcomeSummary', {
          outcome: translate(currentWorkspace, CustomTermKey.OUTCOME, 1),
        }),
        value: 'outcome-summary',
      },
      {
        label: t('workspaceDashboards.initiativeSummary', {
          initiative: translate(currentWorkspace, CustomTermKey.INITIATIVE, 1),
        }),
        value: 'initiative-summary',
      },
      { label: t('workspaceDashboards.userProfile'), value: 'user-profile' },
      { label: t('workspaceDashboards.textBlock'), value: 'text-block' },
      {
        label: t('workspaceDashboards.objectiveList', {
          objective: translate(currentWorkspace, CustomTermKey.OBJECTIVE, 1),
        }),
        value: 'objective-list',
      },
      {
        label: t('workspaceDashboards.outcomeList', {
          outcome: translate(currentWorkspace, CustomTermKey.OUTCOME, 1),
        }),
        value: 'outcome-list',
      },
      {
        label: t('workspaceDashboards.outcomeStats', {
          outcome: translate(currentWorkspace, CustomTermKey.OUTCOME, 1),
        }),
        value: 'outcome-stats',
      },
      {
        label: t('workspaceDashboards.outcomeDistribution', {
          outcome: translate(currentWorkspace, CustomTermKey.OUTCOME, 1),
        }),
        value: 'outcome-distribution',
      },
      {
        label: t('workspaceDashboards.initiativeList', {
          initiative: translate(currentWorkspace, CustomTermKey.INITIATIVE, 1),
        }),
        value: 'initiative-list',
      },
      {
        label: t('workspaceDashboards.OutcomeTaskGraph', {
          outcomes: translate(currentWorkspace, CustomTermKey.OUTCOME, 2),
          tasks: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2),
        }),
        value: 'outcome-task-graph',
      },
      {
        label: t('workspaceDashboards.PlanConfidenceGraph'),
        value: 'plan-confidence-graph',
      },
      {
        label: t('workspaceDashboards.PlanNCSGraph'),
        value: 'plan-ncs-graph',
      },
      {
        label: t('workspaceDashboards.OutcomeGraph', {
          outcome: translate(currentWorkspace, CustomTermKey.OUTCOME, 1),
        }),
        value: 'outcome-graph',
      },
    ],
    [t, currentWorkspace],
  );

  useEffect(() => {
    const option = insightOptions.find((option) => option.value === widget.widget_type);
    setWidgetType(option);
    setWidgetTitle(widget.title ?? '');
  }, [widget, insightOptions]);

  const [updateDashboardWidget, { isLoading: isUpdatingWidget }] = useMutation(remoteApi.updateDashboardWidget, {
    onSuccess: () => {
      queryCache.invalidateQueries([queryKeys.dashboardWidgets, dashboardId]);
      setResetTimer(Date.now());
      handleClosePanel();
    },
  });

  const handleUpdateWidget = (
    type: string,
    source?: string,
    source_id?: string,
    widgetId?: string,
    widgetMeta?: any,
  ) => {
    const dashboard_widget = {
      widget_type: type,
      source_id: source_id,
      source_type: source,
      title: widgetTitle,
      widget_meta: widgetMeta,
    };
    const params = {
      dashboardWidgetId: widgetId,
      dashboard_widget,
    };
    updateDashboardWidget(params);
  };

  const widgetDetails = () => {
    const widgetProps = {
      isLoading: isUpdatingWidget,
      widget,
      isEditing: true,
      handleSaveWidget: handleUpdateWidget,
    };
    switch (widgetType?.value) {
      case 'plan-summary':
        return <PlanSummaryWidgetDetails {...widgetProps} />;
      case 'objective-summary':
        return <ObjectiveSummaryWidgetDetails {...widgetProps} />;
      case 'outcome-summary':
        return <OutcomeSummaryWidgetDetails {...widgetProps} />;
      case 'initiative-summary':
        return <InitiativeSummaryWidgetDetails {...widgetProps} />;
      case 'user-profile':
        return <UserProfileWidgetDetails {...widgetProps} />;
      case 'text-block':
        return <TextBlockWidgetDetails {...widgetProps} />;
      case 'objective-list':
        return <ObjectiveListWidgetDetails {...widgetProps} />;
      case 'outcome-list':
        return <OutcomeListWidgetDetails {...widgetProps} />;
      case 'outcome-stats':
        return <OutcomeStatsWidgetDetails {...widgetProps} />;
      case 'outcome-distribution':
        return <OutcomeDistributionWidgetDetails {...widgetProps} />;
      case 'initiative-list':
        return <InitiativeListWidgetDetails {...widgetProps} />;
      case 'outcome-task-graph':
        return <OutcomeTaskGraphWidgetDetails {...widgetProps} />;
      case 'plan-confidence-graph':
        return <PlanConfidenceGraphWidgetDetails {...widgetProps} />;
      case 'plan-ncs-graph':
        return <PlanNCSGraphWidgetDetails {...widgetProps} />;
      case 'outcome-graph':
        return <OutcomeGraphWidgetDetails {...widgetProps} />;
      default:
        return null;
    }
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setWidgetTitle(e.target.value);
  };

  const titlePlaceholder: string = widgetType?.label ?? t('workspaceDashboards.widgetTitlePlaceholder');

  return (
    <Fragment>
      <Mask onClick={handleClosePanel} showSearchPanel={showPanel} />
      <ContentWrapper showSearchPanel={showPanel}>
        <Content>
          <SearchHeader>
            <p>{t('workspaceDashboards.editWidget')}</p>
            <KoalaIconButton onClick={handleClosePanel} iconName="close" />
          </SearchHeader>

          <FormField>
            <label>{t('workspaceDashboards.widgetType')}</label>
            {widgetType && <WidgetOptionCard type={widgetType.value.toString()} />}
          </FormField>

          <FormField>
            <label>{t('workspaceDashboards.widgetTitle')}</label>
            <input value={widgetTitle} onChange={handleTitleChange} placeholder={titlePlaceholder} />
          </FormField>

          {widgetDetails()}
        </Content>
      </ContentWrapper>
    </Fragment>
  );
}

export default WidgetEditPanel;
