import React, { useEffect, useState, ChangeEvent } from 'react';
import * as TabilityTypes from 'types';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';
import styled from 'styled-components';
import theme from 'theme';
import { Helmet } from 'react-helmet';
import * as amplitudeUtils from 'utils/amplitude';
import { useHistory } from 'react-router-dom';

// Actions
import { sessionActions } from 'state/actions/';
import { setIsEmptyWorkspace } from 'state/actions/sessionActions';
import { setGlobalModalContent } from 'state/actions/globalUIActions';

// API
import * as remoteApi from 'api/remote';
import * as routes from 'routes';

// Images + components
import { startOfQuarter, endOfQuarter } from 'date-fns';
// import ReadyToGo from './checkins.png';
import KoalaTextButton from 'koala/components/TextButton';
import KoalaButton from 'koala/components/Button';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';

import AICreate from './ai_create.png';
import MagicImport from './magic_import.png';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;
const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin-top: ${theme.spacing.x8};

  width: 100%;
  gap: ${theme.spacing.x8};

  a {
    color: ${theme.colors.blue};
  }
  h1 {
    font-size: 2.8rem;
    font-weight: 500;
    margin-bottom: ${theme.spacing.x4};
  }

  h3 {
    margin: ${theme.spacing.x2} 0;
  }

  @media ${theme.devices.laptop} {
    display: flex;
    width: 100%;
    gap: ${theme.spacing.x1};
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-width: initial;
    margin-top: ${theme.spacing.x4};
    padding: ${theme.spacing.x4};

    .hide-mobile {
      display: none;
    }

    .special-mobile {
      position: relative;
      bottom: ${theme.spacing.x3};
    }
  }
`;

const Content = styled.div`
  width: 80rem;
  margin: 0 auto;
  @media ${theme.devices.laptop} {
    margin-top: 0;
  }

  textarea {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !important;
    font-size: 1.4rem;
    line-height: 1.4;
    padding: ${theme.spacing.x1};
    margin: ${theme.spacing.x1} 0;
    min-height: 10rem;
    width: 50rem;
    border-radius: 4px;
    border: 1px solid #949494;
    &:placeholder {
      color: #949494;
      font-size: 1.4rem;
    }
  }
`;

const Footer = styled.div`
  margin-bottom: ${theme.spacing.x3};

  button.secondary {
    color: ${theme.colors.subtleText};
    text-decoration: none;
    font-weight: normal;
  }
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x2};
  margin-top: ${theme.spacing.x4};
`;

const Separator = styled.div`
  border-bottom: 1px solid ${theme.colors.N10};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${theme.spacing.x3};
  span {
    background: #fff;
    padding: 0 ${theme.spacing.x2};
    display: block;
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 600;
    color: ${theme.colors.N60};
    position: relative;
    top: 11px;
  }
`;

const ExamplesContainer = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
  flex-wrap: wrap;
`;

const TabbyThinking = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: ${theme.spacing.x2};
  img {
    width: 16rem;
  }
`;
const WritingImg = 'https://res.cloudinary.com/tability/image/upload/v1670996158/static_assets/tabby-typing_vjitqx.gif';

const ExperienceOptionsContainer = styled.div`
  display: flex;
  gap: ${theme.spacing.x3};
  width: 100%;
`;
const ExperienceOption = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing.x3} ${theme.spacing.x4};
  gap: ${theme.spacing.x2};
  align-items: center;

  border: solid 1px ${theme.colors.N20};
  border-radius: 8px;

  img {
    height: 173px;
  }

  :hover {
    background: ${theme.colors.B5};
    cursor: pointer;
  }
  :active {
    background: ${theme.colors.N10};
  }
`;

const WorkspaceLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${theme.spacing.x2};
`;

const AIWrapper = styled.div`
  max-width: 50rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
`;

interface Props {
  workspace: TabilityTypes.Workspace;
}

function Inbox(props: Props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { workspace } = props;
  const isEmptyWorkspace = useSelector((state: any) => state.session.isEmptyWorkspace);
  const [objectiveDescription, setObjectiveDescription] = useState('');
  const [showAIOptions, setShowAIOptions] = useState(false);

  useEffect(() => {
    amplitudeUtils.sendAmplitudeData('app.onboarding.done', {
      'Tability Platform': 'web',
      'Tability Version': 2,
    });
  }, []);

  const handleLogout = (e: any) => {
    dispatch(sessionActions.logout());
  };

  const handleMagicImport = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(setGlobalModalContent(`onboarding::import.content`));
  };

  /*
  let mvpTemplateId = 'qp3tjTVhNKhi';
  if (process.env.REACT_APP_DOMAIN === 'tability.app') {
    mvpTemplateId = 'qp3tjTVhNKhi';
  }
  */

  // Functions to update the outcome
  /*
  const [populatePlanMutation, { isLoading: isPopulating }]: [any, any] = useMutation(remoteApi.populatePlan, {
    onSuccess: (_, params) => {
      const { planId } = params;
      const planRoute = routes.WORKSPACE_PLAN_WRITE_ROUTE.replace(':workspaceSlug', workspace.slug).replace(
        ':planId',
        planId,
      );
      history.push(`${planRoute}?onboarding=1`);
      if (isEmptyWorkspace) {
        dispatch(setIsEmptyWorkspace(false));
      }
    },
  });
  */

  // Functions to update the outcome
  const [populateAIPlanMutation, { isLoading: isPopulatingAI }]: [any, any] = useMutation(remoteApi.populateAIPlan, {
    onSuccess: (_, params) => {
      const { planId } = params;
      const planRoute = routes.WORKSPACE_PLAN_WRITE_ROUTE.replace(':workspaceSlug', workspace.slug).replace(
        ':planId',
        planId,
      );
      history.push(`${planRoute}?onboarding=1`);
      if (isEmptyWorkspace) {
        dispatch(setIsEmptyWorkspace(false));
      }
    },
    onError: (_, params) => {
      const { planId } = params;
      const planRoute = routes.WORKSPACE_PLAN_WRITE_ROUTE.replace(':workspaceSlug', workspace.slug).replace(
        ':planId',
        planId,
      );
      history.push(`${planRoute}?onboarding=1`);
      if (isEmptyWorkspace) {
        dispatch(setIsEmptyWorkspace(false));
      }
    },
  });

  // Mutation that will create the workspace in the backend
  const [createPlanMutation, { isLoading: isCreatingPlan }]: [any, any] = useMutation(remoteApi.createPlan, {
    onSuccess: (response: any) => {
      const createdPlan = response.data;
      // If there's a template, we populate the content
      /*if (mvpTemplateId) { // TODO: UNSKIP
        const params = {
          planId: createdPlan.nano_slug,
          template: mvpTemplateId,
        };
        populatePlanMutation(params);
      } else {
        */
      if (objectiveDescription) {
        // TODO: UNSKIP
        const params = {
          planId: createdPlan.nano_slug,
          objective_description: objectiveDescription,
        };
        populateAIPlanMutation(params);
      } else {
        // Otherwise, redirect to plan!
        const planRoute = routes.WORKSPACE_PLAN_WRITE_ROUTE.replace(':workspaceSlug', workspace.slug).replace(
          ':planId',
          createdPlan.nano_slug,
        );
        history.push(`${planRoute}?onboarding=1`);

        // Unflag the workspace as empty if it was
        if (isEmptyWorkspace) {
          dispatch(setIsEmptyWorkspace(false));
        }
      }
    },
  });

  const handleObjectiveDescriptionChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    const inputName = e.target.value;
    setObjectiveDescription(inputName);
  };

  // Get the starting date from the plan start_at and reminderConfig setting
  const getRemindersStartAt = (start_at: Date) => {
    if (!start_at) {
      return null;
    }

    // Clone the starting date
    const date = new Date(start_at.getTime());

    // The date offset is the default weekly_reminder_day set at the workspace level.
    const dateOffset = workspace.weekly_reminder_day;

    // Calculate difference between current date and default reminder day.
    const daysUntilOffset = (dateOffset - date.getDay()) % 7;

    // Return the first "weekly_reminder_day" after the start_at date.
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() + daysUntilOffset + 7);
  };

  const handleCreateNewPlan = (e: any) => {
    e.preventDefault();
    const refDate = Date.now();
    const start_at = startOfQuarter(refDate);
    const finish_at = endOfQuarter(refDate);
    const title = `${workspace.name} OKRs`;
    const reminders_start_at = getRemindersStartAt(start_at);
    const mutationParams = {
      workspaceSlug: workspace.slug,
      plan: {
        // state: 'published',
        title,
        reminders_start_at,
        reminders_period: 'week',
        reminders_interval: 1,
        reminders_hour: workspace.weekly_reminder_hour,
        start_at,
        finish_at,
      },
    };
    createPlanMutation(mutationParams);
  };

  // const handleShowOnboardingTemplateDirectory = () => {
  //   const action = `onboarding::show.templates`;
  //   dispatch(setGlobalModalContent(action));
  // };

  const isDisabled = isCreatingPlan || isPopulatingAI;

  return (
    <Wrapper>
      <Helmet>
        <title>
          {workspace.name} {t('workspaceOnboardingDone.helmetTitle')}
        </title>
      </Helmet>
      <Container>
        <Content>
          <h1>You're ready! Let's add goals to your workspace</h1>
          {isPopulatingAI && (
            <TabbyThinking>
              <img src={WritingImg} alt="Tabby thinking" />
              <h3>Please wait while Tabby is generating your goals</h3>
            </TabbyThinking>
          )}
          {!isPopulatingAI && (
            <>
              {!showAIOptions && (
                <>
                  <ExperienceOptionsContainer>
                    <ExperienceOption onClick={() => setShowAIOptions(true)}>
                      <h3>Create new goals with AI</h3>
                      <img src={AICreate} alt={'Create goals with AI'} />
                      <p>No goals? No problem. Create and refine your perfect plan in seconds.</p>
                      <KoalaButton onClick={() => setShowAIOptions(true)}>Generate goals with AI</KoalaButton>
                    </ExperienceOption>
                    <ExperienceOption onClick={handleMagicImport}>
                      <h3>Import existing goals</h3>
                      <img src={MagicImport} alt={'Import your data'} />
                      <p>Import your plan instantly from a spreadsheet or doc.</p>
                      <KoalaButton onClick={handleMagicImport}>Bring your data</KoalaButton>
                    </ExperienceOption>
                  </ExperienceOptionsContainer>
                  <WorkspaceLink>
                    <KoalaTextButton isLink to={`/${workspace.slug}`} appearance="subtle">
                      Take me to the workspace
                    </KoalaTextButton>
                  </WorkspaceLink>
                </>
              )}
              {showAIOptions && (
                <AIWrapper>
                  <h3>Create goals with AI</h3>
                  <p>What is your main objective for the next 3 months?</p>
                  <textarea
                    placeholder={t('workspaceOnboardingDone.objectiveDescriptionPlaceholder') || ''}
                    value={objectiveDescription}
                    onChange={handleObjectiveDescriptionChange}
                  />
                  <small className="subtle">{t('workspaceOnboardingDone.objectiveDescriptionHelper')}</small>
                  <Separator>
                    <span>Or use a suggestion</span>
                  </Separator>
                  <ExamplesContainer>
                    <KoalaButton
                      appearance="subtle"
                      size="small"
                      onClick={() => setObjectiveDescription('Achieve product-market fit')}
                    >
                      Achieve product-market fit
                    </KoalaButton>
                    <KoalaButton
                      appearance="subtle"
                      size="small"
                      onClick={() => setObjectiveDescription('Double our MRR')}
                    >
                      Double our MRR
                    </KoalaButton>
                    <KoalaButton
                      appearance="subtle"
                      size="small"
                      onClick={() => setObjectiveDescription('OKRs for Product')}
                    >
                      OKRs for Product
                    </KoalaButton>
                    <KoalaButton
                      appearance="subtle"
                      size="small"
                      onClick={() => setObjectiveDescription('OKRs for Marketing')}
                    >
                      OKRs for Marketing
                    </KoalaButton>
                    <KoalaButton
                      appearance="subtle"
                      size="small"
                      onClick={() => setObjectiveDescription('OKRs for Sales')}
                    >
                      OKRs for Sales
                    </KoalaButton>
                    <KoalaButton
                      appearance="subtle"
                      size="small"
                      onClick={() => setObjectiveDescription('OKRs for Engineering')}
                    >
                      OKRs for Engineering
                    </KoalaButton>
                    <KoalaButton
                      appearance="subtle"
                      size="small"
                      onClick={() => setObjectiveDescription('OKRs for Customer Success')}
                    >
                      OKRs for Customer Success
                    </KoalaButton>
                    <KoalaButton
                      appearance="subtle"
                      size="small"
                      onClick={() => setObjectiveDescription('OKRs for Support')}
                    >
                      OKRs for Support
                    </KoalaButton>
                  </ExamplesContainer>
                  <Actions>
                    <div data-tip data-for="generate-button">
                      <KoalaButton
                        loading={isDisabled}
                        // disabled={isDisabled || !objectiveDescription}
                        disabled={isDisabled || !objectiveDescription}
                        onClick={handleCreateNewPlan}
                        size="large"
                      >
                        {t('workspaceOnboardingDone.generateButtonLabel')}
                      </KoalaButton>
                      {!objectiveDescription && (
                        <ReactTooltip id="generate-button" place="bottom" type="dark" effect="solid">
                          {t('workspaceOnboardingDone.objectiveDescriptionMissing')}
                        </ReactTooltip>
                      )}
                    </div>
                    <KoalaTextButton onClick={() => setShowAIOptions(false)}>Go back</KoalaTextButton>
                  </Actions>
                </AIWrapper>
              )}
            </>
          )}
        </Content>
      </Container>
      <Footer>
        <KoalaTextButton onClick={handleLogout} appearance="subtle">
          {t('shared.logout')}
        </KoalaTextButton>
      </Footer>
    </Wrapper>
  );
}

export default Inbox;
