import { archivePlan, copyPlan, searchPlans } from 'api/remote';
import queryKeys from 'config/queryKeys';
import React, { ChangeEvent, Fragment } from 'react';
import { useInfiniteQuery, useMutation, useQueryCache } from 'react-query';
import { Plan, Workspace, Membership } from 'types';
import parse from 'parse-link-header';
import theme from 'theme';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Routes
import {
  WORKSPACE_PLAN_WRITE_ROUTE,
  WORKSPACE_PLAN_TRACK_ROUTE,
  WORKSPACE_PLAN_SETTINGS_ROUTE,
  WORKSPACE_PLAN_TEMPLATE_ROUTE,
} from 'routes';
import { useSelector, shallowEqual } from 'react-redux';

import styled from 'styled-components';
import EmptyStatePanel from 'components/EmptyStatePanel';
import DropdownMenu from 'components/DropdownMenu';
import { hasEssentialsSubscription } from 'utils/workspaceUtils';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import UpgradeIcon from 'components/_assets/UpgradeIcon';
import KoalaIconButton from 'koala/components/IconButton';
import KoalaIcon from 'koala/components/Icons';
import KoalaButton from 'koala/components/Button';
import PlanIconLabel from 'components/PlanIconLabel';
import KoalaTextBadge from 'koala/components/TextBadge';
import PlanStatus from 'components/PlanStatus';
import { capitaliseFirst, formatLocale } from 'utils/dateUtils';
import KoalaCheckbox from 'koala/components/Checkbox';
import ProgressBarV2 from 'components/ProgressBarV2';
import ConfidenceBar from 'components/ConfidenceBar';

const PlanContainer = styled.div`
  border-bottom: 1px solid ${theme.colors.N10};

  &.item {
    .draft {
      opacity: 0.5;
    }
    &:hover {
      background: ${theme.colors.N3};
      opacity: 1;
      cursor: pointer;
      .show-actions {
        background: ${theme.colors.N3};
      }
    }

    &:active,
    &:focus {
      background: ${theme.colors.B5};
      .show-actions {
        background: ${theme.colors.B5};
      }
    }
  }
`;
const TableGrid = styled.div`
  display: grid;
  position: relative;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto;
  grid-gap: ${theme.spacing.x1};
  padding: 1.2rem ${theme.spacing.x2};

  @media ${theme.devices.laptop} {
    grid-template-columns: 1fr 25rem;
  }
  @media ${theme.devices.tablet} {
    grid-template-columns: 1fr 13rem;
    .hide-mobile {
      display: none;
    }
  }
  @media ${theme.devices.mobile} {
    grid-template-columns: 1fr 4rem;
  }

  .actions {
    display: none;
  }

  .show-actions {
    display: flex;
    background-color: white;
  }

  &:hover {
    .actions {
      display: flex;
    }
  }
`;

const UpgradeOption = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LoadMore = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${theme.spacing.x1} 0;
`;

const TitleBlock = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
  gap: ${theme.spacing.x1};

  .plan-title {
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    min-width: 1rem;
    width: 100%;
    font-size: 14px;
    line-height: 2rem;
  }
  .superlocked {
    path {
      fill: ${theme.colors.orange};
    }
  }
  a {
    min-width: 1rem;
    :hover {
      text-decoration: underline;
    }
  }
`;
const MetaBlock = styled.div`
  display: grid;
  grid-template-columns: 8rem 8rem 8rem 10rem 10rem;
  justify-content: end;
  gap: ${theme.spacing.x2};
  align-items: center;
  @media ${theme.devices.laptop} {
    display: flex;
  }
`;
const StatBlock = styled.div`
  @media ${theme.devices.tablet} {
    display: none;
  }
`;
const TimelineBlock = styled.div`
  width: 10rem;
  div {
    width: fit-content;
  }

  @media ${theme.devices.laptop} {
    display: none;
  }
`;
const StatusBlock = styled.div`
  min-width: 8rem;
  @media ${theme.devices.mobile} {
    display: none;
  }
`;
const ActionBlock = styled.div`
  position: absolute;
  right: ${theme.spacing.x1};
  top: 0.8rem;
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
  background: ${theme.colors.N3};
  padding-left: ${theme.spacing.x1};
`;
interface Props {
  workspace: Workspace;
  searchTerm: string;
  teamIds: string[];
  insightFilter: string;
  selectedPlans: Plan[];
  setSelectedPlans: (newPlans: Plan[]) => void;
  hideDonePlans: boolean;
  showWatched: boolean;
}
function PlansListSearch(props: Props) {
  const { workspace, searchTerm, teamIds, insightFilter, selectedPlans, setSelectedPlans, hideDonePlans, showWatched } =
    props;
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const queryCache = useQueryCache();

  const currentMembership: Membership = useSelector((state: any) => state.session.currentMembership, shallowEqual);

  // Plan mutations
  const [copyPlanMutation] = useMutation(copyPlan, {
    onSuccess: (response) => {
      const createdPlan = response.data;
      const planRoute = WORKSPACE_PLAN_WRITE_ROUTE.replace(':workspaceSlug', workspace.slug).replace(
        ':planId',
        createdPlan.nano_slug,
      );
      history.push(planRoute);
    },
  });

  const getNextPage = (response: any) => {
    if (response && response.headers && response.headers.link) {
      const links = response.headers.link;
      const parsed = parse(links);
      if (parsed && parsed.next) {
        return parsed.next.page;
      }
    }
    return null;
  };

  const insightFilterParam = () => {
    if (insightFilter === 'negative_ncs') {
      return {
        is_in_progress: true,
        ncs_below: 0,
      };
    }
    if (insightFilter === 'positive_ncs') {
      return {
        is_in_progress: true,
        ncs_above: 0,
      };
    }
    if (insightFilter === 'no_checkins') {
      return {
        is_in_progress: true,
        without_checkins: true,
      };
    }
    if (insightFilter === 'no_outcome_progress') {
      return {
        is_in_progress: true,
        outcome_progress_prct_equal: 0,
        outcomes_count_above: 0,
      };
    }
    if (insightFilter === 'no_initiative_progress') {
      return {
        is_in_progress: true,
        initiative_progress_prct_equal: 0,
        initiatives_count_above: 0,
      };
    }
    if (insightFilter === 'no_outcomes') {
      return {
        is_in_progress: true,
        outcomes_count_equal: 0,
      };
    }
    if (insightFilter === 'too_many_outcomes') {
      return {
        is_in_progress: true,
        outcomes_count_above: 16,
      };
    }
    return null;
  };

  const watched = showWatched ? currentMembership.id : null;

  const filter = { title: searchTerm, team_id: teamIds, done: !hideDonePlans, ...insightFilterParam(), watched };
  const params = {
    workspaceSlug: workspace.slug,
    filter,
  };
  const {
    isFetchingMore,
    data: plansResponse,
    fetchMore,
    canFetchMore,
  } = useInfiniteQuery([queryKeys.plans, 'search', params], searchPlans, {
    getFetchMore: (lastGroup, allGroups) => {
      return getNextPage(lastGroup);
    },
  });
  const handleItemClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    const planId = e.currentTarget.dataset.id ?? '';
    const planState = e.currentTarget.dataset.state;

    const routeTarget = planState === 'published' ? WORKSPACE_PLAN_TRACK_ROUTE : WORKSPACE_PLAN_WRITE_ROUTE;
    const planRoute = routeTarget.replace(':workspaceSlug', workspace.slug).replace(':planId', planId);
    history.push(planRoute);
  };

  let plans: any = []; // Initialize the plans as an empty array
  if (plansResponse) {
    plans = plansResponse;
  }
  const handleCreateSubPlan = (parent_id: string) => {
    dispatch(setGlobalModalContent(`plan:${parent_id}:create.plan`));
  };
  const handleCopyPlan = (planId: string) => {
    copyPlanMutation(planId);
  };
  const handleEdit = (planId: string) => {
    const planRoute = WORKSPACE_PLAN_SETTINGS_ROUTE.replace(':workspaceSlug', workspace.slug).replace(
      ':planId',
      planId,
    );
    history.push(planRoute);
  };

  const [archivePlanMutation] = useMutation(archivePlan, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.plans);
    },
  });
  const handleArchivePlan = (planId: string) => {
    const params = {
      archived: true,
    };
    const mutationParams = {
      planId,
      plan: params,
    };
    archivePlanMutation(mutationParams);
  };
  const handleMenuSelection = (value: React.ReactElement) => {
    const action = value.props['data-action'];
    const planId = value.props['data-plan-id'];
    const blockId = `plan:${planId}`;
    switch (action) {
      case 'create-plan':
        handleCreateSubPlan(planId);
        break;
      case 'copy-plan':
        handleCopyPlan(planId);
        break;
      case 'upgrade-copy-plan':
        const upgradeModalHash = 'workspace:tability20-essentials:upgrade';
        dispatch(setGlobalModalContent(upgradeModalHash));
        break;
      case 'delete-plan':
        dispatch(setGlobalModalContent(`${blockId}:delete`));
        break;
      case 'move-plan':
        dispatch(setGlobalModalContent(`${blockId}:move`));
        break;
      case 'edit-plan':
        handleEdit(planId);
        break;
      case 'archive-plan':
        if (window.confirm('Are you sure that you want to archive this plan?')) {
          handleArchivePlan(planId);
        }
        break;
    }
  };

  const handleCheckbox = (e: ChangeEvent<HTMLInputElement>, isSelected: boolean, plan: Plan) => {
    e.stopPropagation();
    e.preventDefault();
    let newList = isSelected
      ? selectedPlans.filter((selectedPlan) => selectedPlan !== plan)
      : selectedPlans.concat(plan);
    setSelectedPlans(newList);
  };

  // Check if the workspace has the right subscription
  const hasEssentials = hasEssentialsSubscription(workspace) || workspace.pricing_version >= 2;
  const planOptions = (p: Plan) => {
    if (hasEssentials) {
      return [
        <span data-action="edit-plan" data-plan-id={p.nano_slug}>
          {t('workspacePlans.options.settings')}
        </span>,
        <span data-action="create-plan" data-plan-id={p.id}>
          {t('workspacePlans.options.subPlan')}
        </span>,
        <span data-action="move-plan" data-plan-id={p.nano_slug}>
          {t('workspacePlans.options.move')}
        </span>,
        <span data-action="copy-plan" data-plan-id={p.nano_slug}>
          {t('workspacePlans.options.copy')}
        </span>,
        <span data-action="archive-plan" data-plan-id={p.nano_slug}>
          {t('workspacePlans.options.archive')}
        </span>,
        <span data-action="delete-plan" data-plan-id={p.nano_slug}>
          {t('workspacePlans.options.delete')}
        </span>,
      ];
    }

    return [
      <span data-action="edit-plan" data-plan-id={p.nano_slug}>
        {t('workspacePlans.options.settings')}
      </span>,
      <span data-action="create-plan" data-plan-id={p.id}>
        {t('workspacePlans.options.subPlan')}
      </span>,
      <span data-action="move-plan" data-plan-id={p.nano_slug}>
        {t('workspacePlans.options.move')}
      </span>,
      <UpgradeOption data-action="upgrade-copy-plan" data-plan-id={p.nano_slug}>
        <span> {t('workspacePlans.options.copy')}</span>
        <span>
          <UpgradeIcon />
        </span>
      </UpgradeOption>,
      <span data-action="archive-plan" data-plan-id={p.nano_slug}>
        {t('workspacePlans.options.archive')}
      </span>,
      <span data-action="delete-plan" data-plan-id={p.nano_slug}>
        {t('workspacePlans.options.delete')}
      </span>,
    ];
  };

  // This checks if we have zero results for plans.
  // Could definitely be more elegant.
  const noResultsFound = plans.length > 0 && plans[0].data.length === 0;
  if (noResultsFound) {
    return <EmptyStatePanel>{t('workspacePlans.noSearchResult')}</EmptyStatePanel>;
  }

  const showActionClassName = selectedPlans.length > 0 ? 'show-actions' : 'actions';

  return (
    <Fragment>
      {plans.map((group: any, i: number) => {
        return (
          <Fragment key={i}>
            {group.data.map((p: Plan, index: number) => {
              const outcomeProgress = p.outcome_progress_prct * 100;
              const initiativeProgress = p.initiative_progress_prct * 100;

              const stateToRouteMapping = {
                draft: WORKSPACE_PLAN_WRITE_ROUTE,
                published: WORKSPACE_PLAN_TRACK_ROUTE,
                template: WORKSPACE_PLAN_TEMPLATE_ROUTE,
              };

              let routeMappingState = p.state || 'draft';
              const routeTarget = stateToRouteMapping[routeMappingState as keyof typeof stateToRouteMapping];

              const planRoute = routeTarget.replace(':workspaceSlug', workspace.slug).replace(':planId', p.nano_slug);

              const planLocked = p.global_permission_type !== 'edit';
              const superlockedClassName = p.global_permission_type === 'none' ? 'superlocked' : '';
              const isSelected = selectedPlans.includes(p);
              return (
                <Fragment key={p.id}>
                  <PlanContainer className="item">
                    <TableGrid
                      className={`item ${p.state}`}
                      data-id={p.nano_slug}
                      data-state={p.state}
                      key={p.nano_slug}
                      onClick={handleItemClick}
                    >
                      <TitleBlock>
                        <Link to={planRoute} onClick={(e) => e.stopPropagation()}>
                          <PlanIconLabel plan={p} size="small" />
                        </Link>
                        {planLocked && (
                          <KoalaIcon iconName="locked" iconSize="small" className={superlockedClassName} />
                        )}
                      </TitleBlock>
                      <MetaBlock>
                        <StatBlock>
                          <ProgressBarV2
                            percentage={outcomeProgress}
                            totalCount={p.total_outcomes_count}
                            label={`${Math.round(outcomeProgress)}%`}
                          />
                        </StatBlock>
                        <StatBlock>
                          <ProgressBarV2
                            percentage={initiativeProgress}
                            totalCount={p.total_initiatives_count}
                            label={`${p.closed_initiatives_count}/${p.total_initiatives_count}`}
                          />
                        </StatBlock>
                        <StatBlock>
                          <ConfidenceBar
                            greenCount={p.green_outcomes_count}
                            greyCount={p.grey_outcomes_count}
                            redCount={p.red_outcomes_count}
                            totalCount={p.total_outcomes_count}
                            yellowCount={p.yellow_outcomes_count}
                            ncs={Math.round(p.ncs)}
                          />
                        </StatBlock>
                        <TimelineBlock>
                          {p.start_at && p.finish_at && (
                            <div>
                              <KoalaTextBadge isLowercase={true} variant="neutral-light" size="small">
                                {capitaliseFirst(formatLocale(p.start_at, 'MMM', i18n))}
                                &nbsp;&#10142;&nbsp;
                                {capitaliseFirst(formatLocale(p.finish_at, 'MMM yyyy', i18n))}
                              </KoalaTextBadge>
                            </div>
                          )}
                        </TimelineBlock>
                        <StatusBlock>
                          <PlanStatus plan={p} />
                        </StatusBlock>
                      </MetaBlock>
                      <ActionBlock onClick={(e) => e.stopPropagation()} className={showActionClassName}>
                        <KoalaCheckbox
                          checked={isSelected}
                          size="small"
                          edge="square"
                          handleChange={(e) => handleCheckbox(e, isSelected, p)}
                        />

                        <KoalaButton
                          className="hide-mobile"
                          size="small"
                          onClick={() => handleCreateSubPlan(p.id)}
                          appearance="subtle"
                        >
                          {t('workspacePlans.addSubPlan')}
                        </KoalaButton>
                        <DropdownMenu
                          trigger={<KoalaIconButton iconName="ellipsis" />}
                          onSelection={handleMenuSelection}
                          items={planOptions(p)}
                        />
                      </ActionBlock>
                    </TableGrid>
                  </PlanContainer>
                </Fragment>
              );
            })}
          </Fragment>
        );
      })}
      {canFetchMore && (
        <LoadMore>
          <KoalaButton
            onClick={() => fetchMore()}
            loading={!!isFetchingMore}
            disabled={!!isFetchingMore}
            appearance="secondary"
          >
            {t('shared.loadMore')}
          </KoalaButton>
        </LoadMore>
      )}
    </Fragment>
  );
}

export default PlansListSearch;
