import React, { ClipboardEvent, Fragment, KeyboardEvent, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';
import styled from 'styled-components';
import theme from 'theme';
import { Link, useHistory } from 'react-router-dom';
import * as workspaceUtils from 'utils/workspaceUtils';

// API
import * as remoteApi from 'api/remote';

import { setGlobalModalContent } from 'state/actions/globalUIActions';

// Components
import CopyTextInput from 'components/CopyTextInput';
import FormField from 'components/FormField';
import Loader from 'components/Loader';
import { ModalGrid, ModalHeader, ModalContent, ModalFooter } from 'components/GlobalModal';
import KoalaButton from 'koala/components/Button';
import KoalaTextButton from 'koala/components/TextButton';
import KoalaIconButton from 'koala/components/IconButton';
import { useTranslation } from 'react-i18next';
import KoalaTextBadge from 'koala/components/TextBadge';
import { Workspace } from 'types';

const InviteBox = styled.div`
  border: 1px solid ${theme.colors.inputBorder};
  border-radius: ${theme.spacing.half};
  margin-top: ${theme.spacing.x1};
  padding: ${theme.spacing.half};
  width: 100%;
  display: flex;
  gap: ${theme.spacing.x1};
  align-items: center;
  flex-wrap: wrap;

  input {
    flex: 1;
    border: none;
    min-width: 6rem;
    padding-left: 0;
  }

  .text-badge-label {
    display: flex;
    gap: ${theme.spacing.half};
    align-items: center;
  }
`;

const Form = styled.form`
  .email-input {
    display: block;
    margin-bottom: ${theme.spacing.x1};
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const Success = styled.div`
  border-radius: 8px;
  background-color: ${theme.colors.successBg};
  padding: ${theme.spacing.x2};
`;

const Warning = styled.div`
  border-radius: 8px;
  margin-top: ${theme.spacing.x3};
  background-color: ${theme.colors.warningBg};
  padding: ${theme.spacing.x2};
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
`;

const Container = styled.div`
  h3 {
    margin-bottom: ${theme.spacing.x1};
  }

  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x3};
`;

interface Props {}

function InviteTeam(props: Props) {
  const dispatch = useDispatch();
  const queryCache = useQueryCache();
  const history = useHistory();
  const { t } = useTranslation();
  const translationKey = 'modals.inviteTeam';

  const currentWorkspace: Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);

  const [invitesSent, setInvitesSent] = useState(false);
  const [errorUserLimitExceeded, setErrorUserLimitExceeded] = useState(false);
  const [emailList, setEmailList] = useState<string[]>([]);
  const [invalidEntries, setInvalidEntries] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState('');

  // Readonly logic
  const [readOnlyInvitesSent, setReadOnlyInvitesSent] = useState(false);
  const [errorReadOnlyUserLimitExceeded, setErrorReadOnlyUserLimitExceeded] = useState(false);
  const [readOnlyEmailList, setReadOnlyEmailList] = useState<string[]>([]);
  const [invalidReadOnlyEntries, setInvalidReadOnlyEntries] = useState<string[]>([]);
  const [readOnlyInputValue, setReadOnlyInputValue] = useState('');

  const [createMembershipsMutation, { isLoading }] = useMutation(remoteApi.createWorkspaceMemberships, {
    onMutate: () => {
      setInvitesSent(false);
    },
    onSuccess: () => {
      // Delete membership querycache
      queryCache.invalidateQueries(queryKeys.memberships);
      setInvitesSent(true);
    },
    onError: (error: any) => {
      const errorText = error.response.data.error;
      if (errorText.includes('user limit') && error.response.status === 401) {
        setErrorUserLimitExceeded(true);
      } else if (errorText.includes('missing')) {
        // if all users are already added, show success
        setInvitesSent(true);
      }
    },
  });

  const [createFreeMembershipsMutation, { isLoading: isLoadingReadOnly }] = useMutation(
    remoteApi.createWorkspaceFreeMemberships,
    {
      onMutate: () => {
        setInvitesSent(false);
      },
      onSuccess: () => {
        // Delete membership querycache
        queryCache.invalidateQueries(queryKeys.memberships);
        setReadOnlyInvitesSent(true);
      },
      onError: (error: any) => {
        const errorText = error.response.data.error;
        if (errorText.includes('user limit') && error.response.status === 401) {
          setErrorReadOnlyUserLimitExceeded(true);
        } else if (errorText.includes('missing')) {
          // if all users are already added, show success
          setReadOnlyInvitesSent(true);
        }
      },
    },
  );

  // Now we can display the app with the Chrome
  if (!currentWorkspace) {
    return (
      <LoadingContainer>
        <Loader size="medium" />
      </LoadingContainer>
    );
  }

  const handleCancel = (e: any) => {
    e.preventDefault();
    dispatch(setGlobalModalContent(''));
  };

  const handleUpgrade = (e: any) => {
    e.preventDefault();
    const route = `/${currentWorkspace.slug}/settings/billing/plans`;
    history.push(route);
    dispatch(setGlobalModalContent(''));
  };

  const handleUpgradeV2 = (e: any) => {
    e.preventDefault();
    const route = `/${currentWorkspace.slug}/settings/billing/plans`;
    history.push(route);
    dispatch(setGlobalModalContent(''));
  };

  const checkIsEmail = (input: string) => {
    const isValidEmail = input
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );
    return !!isValidEmail;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const params = {
      workspaceSlug: currentWorkspace.slug,
      emails: emailList,
    };
    createMembershipsMutation(params);
  };

  const handleReadOnlySubmit = (e: any) => {
    e.preventDefault();
    const params = {
      workspaceSlug: currentWorkspace.slug,
      emails: readOnlyEmailList,
    };
    createFreeMembershipsMutation(params);
  };

  const handleInputEvent = (event: React.SyntheticEvent<HTMLElement>) => {
    const isReadOnly = event.currentTarget.dataset.readonly === 'true';
    const contextEmailList = isReadOnly ? readOnlyEmailList : emailList;
    const contextSetEmailList = isReadOnly ? setReadOnlyEmailList : setEmailList;
    const contextInputValue = isReadOnly ? readOnlyInputValue : inputValue;
    const contextSetInputValue = isReadOnly ? setReadOnlyInputValue : setInputValue;
    const contextSetInvalidEntries = isReadOnly ? setInvalidReadOnlyEntries : setInvalidEntries;
    const newValue = contextInputValue.trim();
    if (newValue === '') {
      return;
    }

    const alreadyExists = contextEmailList.find((e) => e === newValue);
    if (alreadyExists) {
      contextSetInputValue('');
      return;
    }
    if (!checkIsEmail(newValue)) {
      contextSetInvalidEntries((prev) => [...prev, newValue]);
    }
    contextSetEmailList((prev) => [...prev, newValue]);
    contextSetInputValue('');
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Tab' || event.key === ',' || event.key === 'Enter') {
      event.preventDefault();
      handleInputEvent(event);
    }
  };

  const handleBlur = (event: React.SyntheticEvent<HTMLElement>) => {
    handleInputEvent(event);
  };

  const handleRemoveEmail = (email: string) => {
    setEmailList((prev) => prev.filter((e) => e !== email));
    setInvalidEntries((prev) => prev.filter((e) => e !== email));
  };

  const handleRemoveReadOnlyEmail = (email: string) => {
    setReadOnlyEmailList((prev) => prev.filter((e) => e !== email));
    setInvalidReadOnlyEntries((prev) => prev.filter((e) => e !== email));
  };

  const removeInvalidEmails = () => {
    const newEmailList = emailList.filter((email) => !invalidEntries.includes(email));
    setEmailList(newEmailList);
    setInvalidEntries([]);
  };

  const removeInvalidReadOnlyEmails = () => {
    const newReadOnlyEmailList = readOnlyEmailList.filter((email) => !invalidReadOnlyEntries.includes(email));
    setReadOnlyEmailList(newReadOnlyEmailList);
    setInvalidReadOnlyEntries([]);
  };

  const handlePaste = (event: ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault(); // Prevent default paste behavior
    const isReadOnly = event.currentTarget.dataset.readonly === 'true';
    const contextSetEmailList = isReadOnly ? setReadOnlyEmailList : setEmailList;
    const contextSetInvalidEntries = isReadOnly ? setInvalidReadOnlyEntries : setInvalidEntries;

    const pastedText = event.clipboardData.getData('Text');
    const pastedItems = pastedText
      .split(',')
      .filter((item) => item.trim() !== '')
      .map((item) => item.trim());
    pastedItems.forEach((item) => {
      if (!checkIsEmail(item)) {
        contextSetInvalidEntries((prev) => [...prev, item]);
      }
    });
    contextSetEmailList((prev) => [...prev, ...pastedItems]);
  };

  const { invite_link_token_enabled, invite_link_token } = currentWorkspace;
  const inviteLink = `https://${process.env.REACT_APP_DOMAIN}/invite/${invite_link_token}`;
  const settingsUrl = `/${currentWorkspace.slug}/settings/security`;

  const workspaceUsersLimitExceeded = workspaceUtils.hasExceededUserLimit(currentWorkspace);
  const upgradeLabel = workspaceUtils.hasSubscription(currentWorkspace)
    ? t('shared.upgrade.addMoreSeats')
    : t('shared.upgrade.startTrial');
  const isOwner = currentMembership.role === 'owner';

  if (workspaceUsersLimitExceeded || errorUserLimitExceeded || errorReadOnlyUserLimitExceeded) {
    return (
      <ModalGrid>
        <ModalHeader>
          <h2>{t(`${translationKey}.title`)}</h2>
          <KoalaIconButton iconName="close" onClick={handleCancel} />
        </ModalHeader>
        <ModalContent>
          <Warning>
            <h3>
              <span role="img" aria-label="yikes!">
                😬
              </span>{' '}
              {t(`${translationKey}.quota`)}
            </h3>
            <p>{t(`${translationKey}.quotaInfo`)}</p>
            {currentWorkspace.pricing_version !== 2 && <div>{t(`${translationKey}.quotaUpgrade`)}</div>}
            {currentWorkspace.pricing_version === 2 && <div>{t(`${translationKey}.moreUserUpgrade`)}</div>}
            {isOwner && currentWorkspace.pricing_version !== 2 && (
              <p>
                <KoalaButton onClick={handleUpgrade} appearance="subtle">
                  {upgradeLabel}
                </KoalaButton>
              </p>
            )}
            {isOwner && currentWorkspace.pricing_version === 2 && (
              <p>
                <KoalaButton onClick={handleUpgradeV2} appearance="subtle">
                  {t('shared.upgrade.upgradeWorkspace')}
                </KoalaButton>
              </p>
            )}
          </Warning>
        </ModalContent>
        <ModalFooter>
          <KoalaTextButton onClick={handleCancel}>{t('shared.cancel')}</KoalaTextButton>
        </ModalFooter>
      </ModalGrid>
    );
  }

  const hasPaidSeatsLimit = currentWorkspace.billing_user_limit > 0;
  const hasFreeSeatsLimit = currentWorkspace.billing_free_user_limit > 0;
  const paidSeatsLeft = currentWorkspace.billing_user_limit - currentWorkspace.memberships_count;
  const freeSeatsLeft = currentWorkspace.billing_free_user_limit - currentWorkspace.free_memberships_count;

  return (
    <ModalGrid>
      <ModalHeader>
        <h2>{t(`${translationKey}.title`)}</h2>
        <KoalaIconButton iconName="close" onClick={handleCancel} />
      </ModalHeader>
      <ModalContent>
        <Container>
          <div>
            {invite_link_token_enabled && (
              <Fragment>
                <h3 style={{ marginBottom: theme.spacing.x1 }}>{t(`${translationKey}.share`)}</h3>

                <p className="light">{t(`${translationKey}.linkInfo`)}</p>
                <CopyTextInput text={inviteLink} />
                {['admin', 'owner'].includes(currentMembership.role) && (
                  <small>
                    <Link className="primary" to={settingsUrl}>
                      {t(`${translationKey}.configure`)}
                    </Link>
                  </small>
                )}
              </Fragment>
            )}
          </div>
          <div>
            <h3>{t(`${translationKey}.inviteIndividual`)}</h3>
            {invitesSent && (
              <Success>
                <p>{t(`${translationKey}.invitesSet`)}</p>
                <p>
                  <KoalaButton onClick={handleCancel} appearance="subtle">
                    {t('shared.close')}
                  </KoalaButton>
                </p>
              </Success>
            )}
            {!invitesSent && (
              <Form onSubmit={handleSubmit}>
                <FormField>
                  <p className="subtle">
                    {t(`${translationKey}.emailInfo`)}
                    {hasPaidSeatsLimit && (
                      <span className="subtle"> ({t(`${translationKey}.seatsLeft`, { count: paidSeatsLeft })})</span>
                    )}
                  </p>
                  <InviteBox>
                    {emailList.map((email, i) => (
                      <KoalaTextBadge
                        isLowercase
                        variant={invalidEntries.includes(email) ? 'red-outlined' : 'neutral-outlined'}
                        maxWidth="fit-content"
                        className="email-item"
                        key={i}
                      >
                        {email}
                        <KoalaIconButton iconName="close" size="xsmall" onClick={() => handleRemoveEmail(email)} />
                      </KoalaTextBadge>
                    ))}
                    <input
                      name="body"
                      value={inputValue}
                      onKeyDown={handleKeyDown}
                      onBlur={handleBlur}
                      onPaste={handlePaste}
                      onChange={(e) => setInputValue(e.target.value)}
                    />
                  </InviteBox>
                  {invalidEntries.length > 0 && (
                    <div>
                      {t(`${translationKey}.invalidEmails`, { count: invalidEntries.length })}{' '}
                      <KoalaTextButton type="button" onClick={removeInvalidEmails}>
                        {t(`${translationKey}.removeErrors`)}
                      </KoalaTextButton>
                    </div>
                  )}
                </FormField>
                <KoalaButton
                  submit
                  disabled={isLoading || emailList.length === 0 || invalidEntries.length > 0}
                  loading={isLoading}
                >
                  {t('shared.invite')}
                </KoalaButton>
              </Form>
            )}
          </div>
          {currentWorkspace.billing_free_user_limit > 0 && (
            <div>
              <h3>{t(`${translationKey}.inviteReadOnly`)}</h3>
              {readOnlyInvitesSent && (
                <Success>
                  <p>{t(`${translationKey}.invitesSet`)}</p>
                  <p>
                    <KoalaButton onClick={handleCancel} appearance="subtle">
                      {t('shared.close')}
                    </KoalaButton>
                  </p>
                </Success>
              )}
              {!readOnlyInvitesSent && freeSeatsLeft <= 0 && <p>{t(`${translationKey}.allFreeSeatsUsed`)}</p>}
              {!readOnlyInvitesSent && freeSeatsLeft > 0 && (
                <Form onSubmit={handleReadOnlySubmit}>
                  <FormField>
                    <p className="subtle">
                      {t(`${translationKey}.inviteReadOnlyInfo`)}
                      {hasFreeSeatsLimit && (
                        <span className="subtle"> ({t(`${translationKey}.seatsLeft`, { count: freeSeatsLeft })})</span>
                      )}
                    </p>

                    <p className="subtle">{t(`${translationKey}.emailInfo`)}</p>
                    <InviteBox>
                      {readOnlyEmailList.map((email, i) => (
                        <KoalaTextBadge
                          isLowercase
                          variant={invalidReadOnlyEntries.includes(email) ? 'red-outlined' : 'neutral-outlined'}
                          maxWidth="fit-content"
                          className="email-item"
                          key={i}
                        >
                          {email}
                          <KoalaIconButton
                            iconName="close"
                            size="xsmall"
                            onClick={() => handleRemoveReadOnlyEmail(email)}
                          />
                        </KoalaTextBadge>
                      ))}
                      <input
                        name="body"
                        value={readOnlyInputValue}
                        data-readonly={true}
                        onKeyDown={handleKeyDown}
                        onBlur={handleBlur}
                        onPaste={handlePaste}
                        onChange={(e) => setReadOnlyInputValue(e.target.value)}
                      />
                    </InviteBox>
                    {invalidReadOnlyEntries.length > 0 && (
                      <div>
                        {t(`${translationKey}.invalidEmails`, { count: invalidReadOnlyEntries.length })}{' '}
                        <KoalaTextButton type="button" onClick={removeInvalidReadOnlyEmails}>
                          {t(`${translationKey}.removeErrors`)}
                        </KoalaTextButton>
                      </div>
                    )}
                  </FormField>
                  <KoalaButton
                    submit
                    disabled={isLoadingReadOnly || readOnlyEmailList.length === 0 || invalidReadOnlyEntries.length > 0}
                    loading={isLoadingReadOnly}
                  >
                    {t('shared.invite')}
                  </KoalaButton>
                </Form>
              )}
            </div>
          )}
        </Container>
      </ModalContent>
      <ModalFooter>
        <KoalaTextButton onClick={handleCancel}>{t('shared.cancel')}</KoalaTextButton>
      </ModalFooter>
    </ModalGrid>
  );
}

export default InviteTeam;
