import InitiativeWorkStatus from 'components/InitiativeWorkStatus';
import MarkdownContent from 'components/MarkdownContent';
import { EmojiData, Picker } from 'emoji-mart';
import KoalaAvatar from 'koala/components/Avatar';
import KoalaIconButton from 'koala/components/IconButton';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryCache } from 'react-query';
import styled from 'styled-components';
import theme from 'theme';
import { CachedReaction, InitiativeComment, Membership, User } from 'types';
import { formatDistanceToNowLocale } from 'utils/dateUtils';
import * as remoteApi from 'api/remote';
import queryKeys from 'config/queryKeys';
import BlockActions from 'components/BlockActions';
import { shallowEqual, useSelector } from 'react-redux';
import { CustomEmojisList } from 'components/Reactions/CustomEmojis';
import KoalaLoader from 'koala/components/Loader';
import Reactions from 'components/Reactions';
import ShareIconButton from 'components/ShareIconButton';
import * as routes from 'routes';
import { useHistory, useParams } from 'react-router-dom';

const InitiativeCommentContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    'avatar header timestamp'
    '. content content'
    '. reactions reactions';
  column-gap: ${theme.spacing.x1};
  align-items: center;
  padding-top: ${theme.spacing.x1};

  .block-actions {
    opacity: 0;
    top: ${theme.spacing.half};
    padding: ${theme.spacing.half};
  }

  &:hover {
    .block-actions {
      opacity: 1;
    }
  }
`;

const AvatarSection = styled.div`
  grid-area: avatar;
`;

const TitleSection = styled.div`
  grid-area: header;
  display: inline-block;

  .initiative-work-status {
    display: inline-block;
  }
`;

const TimestampSection = styled.div`
  grid-area: timestamp;
  color: ${theme.colors.N60};
  font-size: 1.1rem;
`;

const ContentSection = styled.div`
  grid-area: content;
  padding-top: ${theme.spacing.x1};
  border: 1px solid ${theme.colors.blockBorder};
  border-radius: 4px;
  padding: ${theme.spacing.x1};
  margin: ${theme.spacing.half} 0;
`;

const ReactionSection = styled.div`
  grid-area: reactions;
`;

const PickerContainer = styled.div`
  height: 0px;
  width: 0px;
  position: relative;
`;

const PickerInner = styled.div`
  width: 320px;
  height: fit-content;
  position: absolute;
  left: -320px;
  top: 15px;

  button:hover {
    background: ${theme.colors.N5};
  }
`;

interface Props {
  comment: InitiativeComment;
  setIsEditing: (value: boolean) => void;
  setShowNewReply: (value: boolean) => void;
  isSetNewState: boolean;
}

function DisplayInitiativeComment(props: Props) {
  const { comment, setIsEditing, setShowNewReply, isSetNewState } = props;
  const { membership } = comment;
  const user = membership ? membership.user : null;
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const translationKey = 'modals.initiative';

  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const emojiRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const queryCache = useQueryCache();
  const currentUser: User = useSelector((state: any) => state.session.currentUser, shallowEqual);
  const currentMembership: Membership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const [reactions, setReactions] = useState<CachedReaction[]>([]);

  useEffect(() => {
    if (comment.cached_reactions && typeof comment.cached_reactions !== 'string') {
      setReactions(comment.cached_reactions);
    } else {
      setReactions([]);
    }
  }, [comment]);

  const [addReactionMutation] = useMutation(remoteApi.createInitiativeCommentReaction, {
    onSuccess: () => {
      queryCache.invalidateQueries([queryKeys.initiativeComments, comment.initiative_id]);
    },
  });

  const [deleteReactionMutation] = useMutation(remoteApi.deleteReaction, {
    onSuccess: () => {
      queryCache.invalidateQueries([queryKeys.initiativeComments, comment.initiative_id]);
    },
  });

  const [deleteCommentMutation, { isLoading: isDeleting }] = useMutation(remoteApi.deleteInitiativeComment, {
    onSuccess: () => {
      queryCache.invalidateQueries([queryKeys.initiativeComments, comment.initiative_id]);
    },
  });

  const handleReply = () => {
    setShowNewReply(true);
  };

  const handleReaction = () => {
    setShowEmojiPicker((prev) => !prev);
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleDelete = () => {
    deleteCommentMutation(comment.id);
  };

  const addEmoji = (emojiData: EmojiData | string, e: React.SyntheticEvent) => {
    e.stopPropagation();
    setShowEmojiPicker(false);
    if (typeof emojiData === 'string') {
      addReactionMutation({ initiativeCommentId: comment.id, emotion: emojiData });
    } else if (typeof emojiData !== 'string' && emojiData.colons) {
      addReactionMutation({ initiativeCommentId: comment.id, emotion: emojiData.colons });
    }
  };

  const addReaction = (emotion: string) => {
    addReactionMutation({ initiativeCommentId: comment.id, emotion: emotion });
  };
  const removeReaction = (reactionId: string) => {
    deleteReactionMutation(reactionId);
  };

  const handleAvatarClicked = (membership: Membership) => {
    history.push(`/${workspaceSlug}/people/${membership.id}`);
  };

  const userCustomEmojis = currentUser && currentUser.referrals_count ? CustomEmojisList : undefined;

  if (isDeleting) {
    return <KoalaLoader />;
  }
  const canEdit = user && currentUser.id === user.id;
  const canDelete = canEdit || currentMembership.role === 'owner' || currentMembership.role === 'admin';

  const link = `https://${process.env.REACT_APP_DOMAIN}${routes.WORKSPACE_PLAN_TRACK_ROUTE.replace(
    ':workspaceSlug',
    workspaceSlug,
  ).replace(':planId', comment.plan_nano_slug)}#initiative:${comment.initiative_id}:show`;
  return (
    <InitiativeCommentContainer>
      <AvatarSection>
        <KoalaAvatar
          user={user}
          handleClick={comment.membership ? () => handleAvatarClicked(comment.membership) : undefined}
        />
      </AvatarSection>
      <TitleSection>
        <b>{user?.fullname}</b> {isSetNewState ? t(`${translationKey}.setStatusTo`) : ''}{' '}
        <InitiativeWorkStatus providedState={comment.work_state} showArrow={false} />
      </TitleSection>
      <TimestampSection>{formatDistanceToNowLocale(comment.created_at, i18n, true, false)}</TimestampSection>
      {comment.body && (
        <ContentSection>
          <MarkdownContent source={comment.body} />
        </ContentSection>
      )}
      {reactions && reactions.length > 0 && (
        <ReactionSection>
          <Reactions reactions={reactions} addCallback={addReaction} removeCallback={removeReaction} />
        </ReactionSection>
      )}

      <BlockActions className="block-actions">
        <KoalaIconButton edge="square" iconName="comment" onClick={handleReply} size="small" />
        <KoalaIconButton edge="square" iconName="emoji" onClick={handleReaction} size="small" />
        <ShareIconButton id={comment.id} link={link} edge="square" size="small" />
        {canEdit && <KoalaIconButton edge="square" iconName="edit" onClick={handleEdit} size="small" />}
        {canDelete && <KoalaIconButton edge="square" iconName="trash" onClick={handleDelete} size="small" />}
        <PickerContainer>
          <PickerInner ref={emojiRef} onClick={(e) => e.stopPropagation()} className="emoji-picker-inner-container">
            {showEmojiPicker ? (
              <Picker
                set="twitter"
                title="Pick emoji"
                emoji="point_up"
                style={{
                  position: 'relative',
                  zIndex: 99,
                  marginTop: `${theme.spacing.half}`,
                  width: '100%',
                }}
                onClick={addEmoji}
                custom={userCustomEmojis}
              />
            ) : null}
          </PickerInner>
        </PickerContainer>
      </BlockActions>
    </InitiativeCommentContainer>
  );
}

export default React.memo(DisplayInitiativeComment);
