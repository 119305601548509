import React from 'react';
import styled from 'styled-components';
import { Checkin, Outcome } from 'types';
import * as checkinUtils from 'utils/checkinUtils';
import * as outcomeUtils from 'utils/outcomeUtils';
import { useTranslation } from 'react-i18next';
import KoalaTextBadge from 'koala/components/TextBadge';

const CurrentValueContainer = styled.div`
  .avatar-tooltip {
    padding: 0px;
    background: transparent;
    border: none;
    &.show {
      opacity: 1 !important;
    }
  }
`;

interface Props {
  outcome: Outcome;
  checkin?: Checkin | null;
  size?: 'small' | 'medium' | 'large';
}

function OutcomeCurrentValueLabel(props: Props) {
  const { outcome, size, checkin } = props;
  const { t } = useTranslation();

  const labelSize = size || 'medium';
  const formattedScore = checkin
    ? checkinUtils.checkinScore(checkin, outcome, t)
    : outcomeUtils.startingScore(outcome, t);
  const currentConfidence = checkin ? checkin.confidence : 'grey';

  return (
    <CurrentValueContainer>
      <KoalaTextBadge
        isLowercase={true}
        variant={checkinUtils.confidenceToVariant(currentConfidence)}
        edge="circle"
        style={{ border: 'none', width: 'fit-content' }}
        maxWidth="10rem"
        size={labelSize}
      >
        {formattedScore}
      </KoalaTextBadge>
    </CurrentValueContainer>
  );
}

export default OutcomeCurrentValueLabel;
