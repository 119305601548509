import queryKeys from 'config/queryKeys';
import KoalaButton from 'koala/components/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import { Membership, Plan } from 'types';
import * as remoteApi from 'api/remote';
import styled from 'styled-components';
import theme from 'theme';
import KoalaAvatar from 'koala/components/Avatar';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import KoalaLoader from 'koala/components/Loader';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import KoalaIconButton from 'koala/components/IconButton';
import KoalaTextButton from 'koala/components/TextButton';
import { useHistory } from 'react-router-dom';

const Container = styled.div`
  margin-bottom: ${theme.spacing.x2};
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
  align-items: start;
  span.icon {
    display: inline-block;
    margin-right: ${theme.spacing.x1};
  }
`;

const AvatarStack = styled.div`
  display: flex;
  justify-content: start;
  padding-left: 8px;

  .avatar {
    margin-left: -8px;
  }
`;

const AdditionalAvatars = styled.span`
  display: flex;
  align-items: center;
  font-size: 12px;
  color: ${theme.colors.N60};
`;

const SubscribedSection = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: ${theme.spacing.half};
`;

const InlineBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: ${theme.spacing.half};
`;

interface Props {
  plan: Plan;
}

function PlanSubscribers(props: Props) {
  const { plan } = props;
  const { t } = useTranslation();
  const queryCache = useQueryCache();
  const dispatch = useDispatch();
  const currentMembership: Membership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const history = useHistory();
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  const planSubscriptionQueryKey = [queryKeys.planSubscriptions, plan.id];
  const staleTime = queryKeys.staleTime;
  // Get the plan subscription
  const { isFetching: isFetchingPlanSubscriptions, data: planSubscriptionsData } = useQuery(
    planSubscriptionQueryKey,
    remoteApi.fetchPlanSubscriptions,
    {
      staleTime,
    },
  );

  const planSubscriptions = planSubscriptionsData?.data ?? [];
  const currentSubscription = planSubscriptions.find(
    (subscriber: any) => subscriber.membership_id === currentMembership.id,
  );

  const [createCurrentUserPlanSubscriptionMutation] = useMutation(remoteApi.createCurrentUserPlanSubscription, {
    onSuccess: () => {
      queryCache.invalidateQueries(planSubscriptionQueryKey);
    },
  });

  const [deletePlanSubscriptionMutation] = useMutation(remoteApi.deletePlanSubscription, {
    onSuccess: () => {
      queryCache.invalidateQueries(planSubscriptionQueryKey);
    },
  });

  const handleSubscribe = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    createCurrentUserPlanSubscriptionMutation(plan.id);
  };

  const handleManageSubscribers = () => {
    dispatch(setGlobalModalContent(`plan:${plan.id}:subscribers`));
  };

  const handleRemoveSubscription = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (currentSubscription) {
      deletePlanSubscriptionMutation(currentSubscription.id);
    }
  };

  const subscriptionCount = planSubscriptions ? planSubscriptions.length : 0;
  const notShown = subscriptionCount - 5;

  const isSubscribed = planSubscriptions.find((subscriber: any) => subscriber.membership_id === currentMembership.id);

  const handleAvatarClicked = (membership: Membership) => {
    history.push(`/${currentWorkspace.slug}/people/${membership.id}`);
  };

  if (isFetchingPlanSubscriptions) {
    return (
      <Container>
        <KoalaLoader />
      </Container>
    );
  }

  return (
    <Container>
      {planSubscriptions.length > 0 && (
        <InlineBlock>
          <AvatarStack>
            {planSubscriptions.map((subscriber: any, index: number) => {
              if (index >= 5) {
                return null;
              }
              return (
                <KoalaAvatar
                  key={index}
                  user={subscriber.membership.user}
                  addBorder={true}
                  handleClick={subscriber.membership ? () => handleAvatarClicked(subscriber.membership) : undefined}
                />
              );
            })}
            {notShown > 0 && <AdditionalAvatars>+{notShown}</AdditionalAvatars>}
          </AvatarStack>
          <KoalaTextButton onClick={handleManageSubscribers} size="small">
            {t('workspacePlan.manageSubscribers')}
          </KoalaTextButton>
        </InlineBlock>
      )}
      {subscriptionCount === 0 && (
        <KoalaButton prefixIcon="personPlus" appearance="secondary" size="small" onClick={handleManageSubscribers}>
          {t('workspacePlan.addSubscribers')}
        </KoalaButton>
      )}
      {!isSubscribed && (
        <>
          <KoalaButton appearance="subtle" size="small" onClick={handleSubscribe}>
            {t('workspacePlan.subscribe')}
          </KoalaButton>
        </>
      )}
      {isSubscribed && (
        <SubscribedSection>
          <small className="subtle">{t('workspacePlan.alreadySubscribed')}</small>
          <KoalaIconButton onClick={handleRemoveSubscription} iconName="close" size="xsmall" />
        </SubscribedSection>
      )}
    </Container>
  );
}

export default PlanSubscribers;
