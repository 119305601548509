import React from 'react';
import styled from 'styled-components';
import theme from 'theme';

const ProgressBarContainer = styled.div`
  height: 4px;
  width: 100%;
  position: relative;
  background: ${theme.colors.N10};
  border-radius: 2px;
  overflow: hidden;
  display: flex;
  justify-content: end;
`;

const ProgressBarInner = styled.div<{ value: number; color: string }>`
  height: 100%;
  background: ${(props) => props.color};
  width: ${(props) => props.value}%;
`;

const EmptyBlock = styled.div`
  width: 50px;
  text-align: center;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: ${theme.spacing.x1};
  width: 100%;
`;

const Label = styled.div`
  font-size: 1.1rem;
  font-weight: 600;
  color: ${theme.colors.subtleText};
  white-space: nowrap;
  min-width: 4.3rem;
  text-align: right;
  &.green {
    color: ${theme.colors.G50};
  }
  &.yellow {
    color: ${theme.colors.O50};
  }
  &.red {
    color: ${theme.colors.R50};
  }
`;

interface Props {
  totalCount: number;
  greyCount: number;
  redCount: number;
  yellowCount: number;
  greenCount: number;
  ncs: number;
}
function ConfidenceBar(props: Props) {
  const { totalCount, greenCount, greyCount, redCount, yellowCount, ncs } = props;

  if (totalCount === 0) {
    return <EmptyBlock>-</EmptyBlock>;
  }

  const greyPercentage = (greyCount / totalCount) * 100;
  const redPercentage = (redCount / totalCount) * 100;
  const yellowPercentage = (yellowCount / totalCount) * 100;
  const greenPercentage = (greenCount / totalCount) * 100;

  let colorClass = 'green';

  if (ncs < 30) {
    colorClass = 'yellow';
  }
  if (ncs < 0) {
    colorClass = 'red';
  }

  return (
    <Wrapper>
      <Label className={colorClass}>{ncs} NCS</Label>
      <ProgressBarContainer>
        <ProgressBarInner value={greyPercentage} color={theme.colors.N10} />
        <ProgressBarInner value={greenPercentage} color={theme.colors.G50} />
        <ProgressBarInner value={yellowPercentage} color={theme.colors.Y50} />
        <ProgressBarInner value={redPercentage} color={theme.colors.R50} />
      </ProgressBarContainer>
    </Wrapper>
  );
}
export default ConfidenceBar;
