import { colors } from 'koala/shared/styles';
import React, { FC } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  /* max-width: 3rem; */
  .block--green {
    background-color: ${colors.G50};
  }
  .block--yellow {
    background-color: ${colors.Y50};
  }
  .block--red {
    background-color: ${colors.R50};
  }
  .block--grey {
    background-color: ${colors.N50};
  }
`;

const PreviousBlock = styled.div`
  width: 0.6rem;
  height: 1.6rem;
  border-radius: 2px;
  margin-right: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.N50};
`;

const CurrentBlock = styled.div`
  background-color: ${colors.N50};
  height: 2rem;
  width: 2rem;
  border-radius: 4px;
`;

const PendingFlag = styled.div`
  height: 1rem;
  width: 1rem;
  background: ${colors.R50};
  border-radius: 50%;
  position: relative;
  left: 1.2rem;
  top: -0.3rem;
`;

interface ConfidenceBlockProps {
  showPreviousConfidence?: boolean;
  previousConfidence?: string;
  currentConfidence?: string;
  pendingFlagId?: string;
  showPendingFlag?: boolean;

  className?: string;
  style?: React.CSSProperties;
}

const KoalaConfidenceBlock: FC<ConfidenceBlockProps> = ({ children = 'Text', ...props }) => {
  const {
    className,
    style,
    previousConfidence,
    showPreviousConfidence,
    currentConfidence,
    pendingFlagId,
    showPendingFlag,
  } = props;

  const prevClass = `block--${previousConfidence ?? 'grey'}`;
  const currentClass = `block--${currentConfidence ?? 'grey'}`;

  return (
    <Container className={className} style={style}>
      {showPreviousConfidence && <PreviousBlock className={prevClass} />}
      <CurrentBlock className={currentClass}>
        {showPendingFlag && <PendingFlag data-tip data-for={pendingFlagId ?? 'confidence-block-pending'} />}
      </CurrentBlock>
    </Container>
  );
};

KoalaConfidenceBlock.defaultProps = {
  showPreviousConfidence: false,
};

export default KoalaConfidenceBlock;
