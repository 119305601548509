import React, { useEffect } from 'react';
//import * as TabilityTypes from 'types';
import { useDispatch } from 'react-redux';
import { useMutation } from 'react-query';
import styled from 'styled-components';
import theme from 'theme';
import { Helmet } from 'react-helmet';
import * as amplitudeUtils from 'utils/amplitude';

// API
import * as remoteApi from 'api/remote';

// Actions
import { sessionActions } from 'state/actions/';
import KoalaButton from 'koala/components/Button';
import KoalaTextButton from 'koala/components/TextButton';
import TeamImage from './_assets/Team.png';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  gap: ${theme.spacing.x2};
`;
const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: ${theme.spacing.x8};

  width: 100%;
  gap: ${theme.spacing.x8};

  a {
    color: ${theme.colors.blue};
  }
  h1 {
    font-size: 2.8rem;
    font-weight: 500;
    margin-bottom: ${theme.spacing.x1};
  }

  h3 {
    margin: ${theme.spacing.x2} 0;
  }

  @media ${theme.devices.laptop} {
    display: flex;
    width: 100%;
    gap: ${theme.spacing.x1};
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-width: initial;
    margin-top: ${theme.spacing.x4};
    padding: ${theme.spacing.x4};

    .hide-mobile {
      display: none;
    }

    .special-mobile {
      position: relative;
      bottom: ${theme.spacing.x3};
    }
  }
`;
const About = styled.div`
  width: 50rem;
`;
const Content = styled.div`
  justify-self: flex-end;
  width: 35rem;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .hotdeal {
    background: ${theme.colors.Y10};
    padding: ${theme.spacing.x1};
    border-radius: 4px;
  }
  @media ${theme.devices.laptop} {
    margin-top: 0;
  }
`;

const Instruction = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  img {
    height: 50rem;
  }
  @media ${theme.devices.laptop} {
    display: none;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;

  margin-top: ${theme.spacing.x3};

  @media (max-width: 800px) {
    margin-top: ${theme.spacing.x4};
    input,
    select {
      &.small {
        width: 100%;
      }

      &.tiny {
        width: 50%;
      }
    }
  }
`;

const Actions = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 0;
  gap: ${theme.spacing.x1};
`;

const ActionButtons = styled.div`
  display: flex;
  width: 100%;
  button {
    width: 100%;
    justify-content: center;
  }
`;

const Footer = styled.div`
  margin-bottom: ${theme.spacing.x3};

  button.secondary {
    color: ${theme.colors.subtleText};
    text-decoration: none;
    font-weight: normal;
  }
`;

const WelcomeOffer = styled.div`
  background: ${theme.colors.Y40};
  padding: ${theme.spacing.half} ${theme.spacing.x1};
  font-weight: 800;
  font-size: 1.2rem;
  text-transform: uppercase;
  border-radius: 8px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${theme.spacing.x2};

  p {
    font-size: 1.8rem;
    line-height: 1.4;
  }
`;

interface Props {
  workspace: any;
}

function Inbox(props: Props) {
  const dispatch = useDispatch();
  const { workspace } = props;

  // const doneUrl = `/${workspace.slug}/onboarding/done`;
  //const doneUrl = `/${workspace.slug}/plans?onboarding=1`; // Hardcoded Intercom product tour

  useEffect(() => {
    amplitudeUtils.sendAmplitudeData('app.onboarding.team', {
      'Tability Platform': 'web',
      'Tability Version': 2,
    });
  }, []);

  // Workspace mutations
  const [createWorkspaceTrialSessionMutation, { isFetching }]: [any, any] = useMutation(
    remoteApi.createWorkspaceTrialSession,
    {
      onSuccess: (response) => {
        const { data } = response;
        const { session_id } = data;

        const stripe = window.Stripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
        stripe
          .redirectToCheckout({
            sessionId: session_id,
          })
          .then((result: any) => {
            if (result.error) {
              alert(result.error.message);
            }
          });
      },
    },
  );

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const params = {
      workspaceSlug: workspace.slug,
    };
    createWorkspaceTrialSessionMutation(params);
  };

  const handleLogout = (e: any) => {
    dispatch(sessionActions.logout());
  };

  return (
    <Wrapper>
      <Helmet>
        <script src="https://js.stripe.com/v3/" />
        <title>{workspace.name} | Start your 14 day trial| Tability</title>
      </Helmet>
      <Container>
        <Content>
          <Header>
            <WelcomeOffer>Start your 14-day trial</WelcomeOffer>
            <h2>Start your Premium trial</h2>
            <div>
              <p>Cancel or switch plan anytime.</p>
            </div>
          </Header>
          <Form onSubmit={handleSubmit}>
            <Actions>
              <ActionButtons>
                <KoalaButton submit disabled={isFetching} loading={isFetching}>
                  Start your 14-day trial
                </KoalaButton>
              </ActionButtons>
            </Actions>
          </Form>
        </Content>
        <About>
          <Instruction>
            <img src={TeamImage} alt="Team members on Tability" />
          </Instruction>
        </About>
      </Container>
      <Footer>
        <KoalaTextButton onClick={handleLogout} appearance="subtle">
          Logout
        </KoalaTextButton>
      </Footer>
    </Wrapper>
  );
}

export default Inbox;
