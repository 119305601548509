import InitiativeDueDate from 'components/InitiativeDueDate';
import UserSelectButton from 'components/UserSelectButton';
import KoalaCheckbox from 'koala/components/Checkbox';
import React, { useEffect, useState } from 'react';
import { useMutation, useQueryCache } from 'react-query';
import { Link, useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'theme';
import { Initiative, Workspace, Membership, WorkspaceSettings } from 'types';
import * as remoteApi from 'api/remote';
import queryKeys from 'config/queryKeys';
import KoalaStackedAvatar from 'koala/components/StackedAvatars';
import DropdownMenu from 'components/DropdownMenu';
import { getWorkStateDropdownData, getWorkStateParams } from 'utils/initiativeUtils';

import KoalaAvatar from 'koala/components/Avatar';
import PlanIconLabel from 'components/PlanIconLabel';
import * as routes from 'routes';
import * as integrationUtils from 'utils/integrationUtils';
import * as workspaceUtils from 'utils/workspaceUtils';
import * as membershipUtils from 'utils/membershipUtils';
import KoalaTextBadge from 'koala/components/TextBadge';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import InitiativeWorkStatus from 'components/InitiativeWorkStatus';
import ReactTooltip from 'react-tooltip';

const Row = styled.div`
  padding: 1.2rem ${theme.spacing.x2};
  display: flex;
  justify-content: space-between;
  gap: ${theme.spacing.x1};

  &.clickable {
    cursor: pointer;

    :hover {
      background: ${theme.colors.N3};
    }
    :active,
    :focus,
    &.selected {
      background: ${theme.colors.B5};
    }

    .title:hover {
      text-decoration: underline;
    }
  }

  &.heirarchy {
    display: grid;
    grid-template-columns: 1fr 30rem 25rem;
    gap: ${theme.spacing.x1};

    .initiative-details {
      min-width: 15rem;
      justify-content: space-between;
    }

    @media ${theme.devices.smallDesktop} {
      grid-template-columns: 1fr 25rem 25rem;
    }
    @media ${theme.devices.laptop} {
      grid-template-columns: 1fr 13rem 13rem;
    }
    @media ${theme.devices.tablet} {
      grid-template-columns: 1fr 13rem;
    }
  }

  &.compact {
    padding: ${theme.spacing.half};
  }
`;
const InitiativeDetails = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
  .title {
    width: 100%;
    min-width: 1rem;
    display: block;
    overflow: hidden;
    word-break: break-word;
  }
  &.closed {
    .title {
      text-decoration: line-through;
    }
  }
`;

const InitiativeHeirarchy = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};

  @media ${theme.devices.tablet} {
    display: none;
  }
`;
const InitiativeMeta = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
  justify-content: flex-end;
  align-items: center;

  .initiative-state {
    min-width: 10rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .initiative-due-date {
    display: flex;
    align-items: center;
  }

  @media ${theme.devices.laptop} {
    .initiative-due-date {
      display: none;
    }
  }
`;
const Owners = styled.div`
  display: flex;
  min-width: 6.5rem;
  align-items: center;
  justify-content: left;
  height: 2rem;
  .contributors {
    margin-left: -12px;
  }
`;
const IntegrationIcon = styled.img`
  width: 2rem;
  height: 2rem;
`;

const IntegrationAvatar = styled.div`
  width: 2.6rem;
  height: 2.6rem;
  display: flex;
  padding-left: 3px;
  align-items: center;
`;
const BlockPlan = styled.div`
  display: flex;
  height: 2.2rem;
  gap: ${theme.spacing.half};
  line-height: 2.2rem;
  .plan-title {
    flex: 1;
    min-width: 1rem;
    max-width: 11rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
  }

  @media ${theme.devices.tablet} {
    display: none;
  }
`;

interface Props {
  initiative: Initiative;
  showHeirarchy?: boolean;
  showMeta?: boolean;
  isSelected?: boolean;
  isReadOnly?: boolean;
  handleClick?: (e: React.MouseEvent) => void;
  allowLink?: boolean;
  hideOwners?: boolean;
  hideDueDate?: boolean;
  compact?: boolean;
}

function InitiativeRow(props: Props) {
  const {
    initiative,
    handleClick,
    isSelected,
    showHeirarchy,
    showMeta,
    isReadOnly: propsIsReadOnly,
    allowLink,
    hideDueDate,
    hideOwners,
    compact,
  } = props;
  const [checked, setChecked] = useState(initiative.state !== 'open');
  const queryCache = useQueryCache();
  const history = useHistory();
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const currentWorkspace: Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const currentWorkspaceSettings: WorkspaceSettings = useSelector(
    (state: any) => state.session.currentWorkspaceSettings,
    shallowEqual,
  );
  const currentMembership: Membership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const isMembershipReadOnly = membershipUtils.isReadOnly(currentMembership);
  const isReadOnly = propsIsReadOnly || isMembershipReadOnly;
  const { t } = useTranslation();
  const queryKeyInitiatives = [queryKeys.initiatives, initiative.outcome_id];
  const [updateInitiativeMutation, { isLoading }] = useMutation(remoteApi.updateInitiative, {
    onSuccess: (response) => {
      const responseInitiative = response.data;
      setChecked(responseInitiative.state !== 'open');
      queryCache.invalidateQueries(queryKeyInitiatives);
      queryCache.invalidateQueries([queryKeys.initiatives, 'search']);
      queryCache.invalidateQueries([queryKeys.initiatives, currentMembership.id]);
      queryCache.invalidateQueries([queryKeys.initiativeComments, initiative.id]);
    },
  });

  useEffect(() => {
    setChecked(initiative.state !== 'open');
  }, [setChecked, initiative.state]);

  const showHashRoute = `#initiative:${initiative.nano_slug}:show`;

  const statusItems = getWorkStateDropdownData(t, currentWorkspaceSettings);
  const filteredStates = statusItems;

  const handleStopPropagation = (e: any) => {
    e.stopPropagation();
  };
  const handleOutcomeClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(`#outcome:${initiative.outcome.nano_slug}:show`);
  };

  const plan = initiative.plan;
  const planUrl = plan
    ? routes.WORKSPACE_PLAN_TRACK_ROUTE.replace(':workspaceSlug', workspaceSlug).replace(':planId', plan.id)
    : '';

  let initiativeClass = `item initiative-row ${isSelected ? 'selected' : ''} ${showHeirarchy ? 'heirarchy' : ''} ${
    isReadOnly ? 'read-only' : 'clickable'
  }`;
  if (compact) {
    initiativeClass += ' compact';
  }

  const handleInitiativeStateChange = (value: any, initiative: Initiative) => {
    const action = value.props['data-action'];
    const params = getWorkStateParams(action);
    updateInitiativeMutation({ initiativeId: initiative.id, initiative: params });
  };

  const handleCheckboxChange = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    // Toggle the state between open and closed
    const state = initiative.state === 'open' ? 'closed' : 'open';
    const work_state = initiative.state === 'open' ? 'done' : 'backlog';
    const params = {
      state,
      work_state,
    };
    updateInitiativeMutation({
      initiativeId: initiative.id,
      initiative: params,
    });
  };

  const handleAssignUser = (value: string, initiative: Initiative) => {
    const membership_id = value;
    if (membership_id) {
      const params = {
        membership_id,
      };

      updateInitiativeMutation({
        initiativeId: initiative.id,
        initiative: params,
      });
    }
  };

  const handleUnassignUser = (initiative: Initiative) => {
    const params = {
      membership_id: null,
    };
    updateInitiativeMutation({
      initiativeId: initiative.id,
      initiative: params,
    });
  };

  const handleDateChanged = (date: Date) => {
    const params = {
      due_at: date,
    };
    updateInitiativeMutation({ initiativeId: initiative.id, initiative: params });
  };

  let title = initiative.title;
  let dueDate = initiative.due_at;
  let imageSource = null;
  let isIntegration = false;
  let isClosed = initiative.state === 'closed';
  let integrationStatus = '';
  let integrationType = initiative.integration_type;

  if (initiative.integration_type && initiative.integration_remote_id) {
    isIntegration = true;
    title = initiative.integration_title;
    imageSource = integrationUtils.getIntegrationImageSource(initiative.integration_type);
    dueDate = integrationUtils.getDueDate(initiative);
    isClosed = integrationUtils.isIntegrationClosed(initiative);
    integrationStatus = integrationUtils.getIntegrationStatus(initiative);
    integrationType = integrationUtils.getIntegrationType(initiative);
  }
  const closedClass = isClosed ? 'closed' : '';

  const allowDateEdit =
    !isReadOnly &&
    !isIntegration &&
    (workspaceUtils.hasStarterSubscription(currentWorkspace) || currentWorkspace?.pricing_version >= 2);
  const editWarning = t('panels.initiative.avatarEditWarning', {
    integration: integrationUtils.getIntegrationType(initiative),
  });

  let currentHandleClick = (e: React.MouseEvent) => {
    if (handleClick) {
      handleClick(e);
    } else {
      if (allowLink) {
        return;
      }
      history.push(showHashRoute);
    }
  };

  return (
    <Row className={initiativeClass} onClick={currentHandleClick}>
      <InitiativeDetails className={`initiative-details ${closedClass}`}>
        {!isIntegration && (
          <KoalaCheckbox
            checked={checked}
            size="small"
            handleChange={handleCheckboxChange}
            isClickable={!isReadOnly}
            className="initiative-checkbox"
            loading={isLoading}
          />
        )}
        {isIntegration && imageSource && <IntegrationIcon src={imageSource} />}
        {allowLink && (
          <Link to={showHashRoute} className="title">
            {title}
          </Link>
        )}
        {!allowLink && <div className="title">{title}</div>}
      </InitiativeDetails>
      {showHeirarchy && (
        <InitiativeHeirarchy className="initiative-heirarchy">
          <BlockPlan className="initiative-plan">
            <Link className="subtle" to={planUrl} onClick={handleStopPropagation}>
              <PlanIconLabel plan={plan} size="small" />
            </Link>
            <div className="subtle">/</div>

            <a href="/" className="subtle plan-title" onClick={handleOutcomeClick}>
              {initiative.outcome.title}
            </a>
          </BlockPlan>
        </InitiativeHeirarchy>
      )}
      {showMeta && (
        <InitiativeMeta className="initiative-meta">
          {!hideDueDate && (
            <div className="initiative-due-date">
              <InitiativeDueDate
                dueAt={dueDate}
                state={initiative.state}
                dataFor={`${initiative.nano_slug}-dueDate`}
                handleDateChange={allowDateEdit ? handleDateChanged : undefined}
              />
              {isIntegration && (
                <ReactTooltip
                  id={`${initiative.nano_slug}-dueDate`}
                  place="top"
                  effect="solid"
                  type="dark"
                  delayShow={300}
                >
                  {t(`panels.initiative.integrationDueDate`, { integrationType })}
                </ReactTooltip>
              )}
            </div>
          )}

          {!hideOwners && (
            <Owners className="initiative-owners">
              {isIntegration && (
                <IntegrationAvatar>
                  <KoalaAvatar
                    integrationType={initiative.integration_type}
                    integrationAssignee={integrationUtils.getIntegrationUser(initiative)}
                    size={2}
                    tooltipText={editWarning}
                  />
                </IntegrationAvatar>
              )}
              {!isIntegration && (
                <>
                  <UserSelectButton
                    canEdit={!isReadOnly}
                    handleAssignUser={(value) => handleAssignUser(value, initiative)}
                    selectedUser={initiative.membership ? initiative.membership.user : null}
                    handleUnassignUser={() => handleUnassignUser(initiative)}
                    size="small"
                    defaultLink={
                      initiative.membership ? `/${currentWorkspace.slug}/people/${initiative.membership.id}` : undefined
                    }
                  />
                  <KoalaStackedAvatar
                    owner={initiative.membership ? initiative.membership.user : null}
                    contributors={initiative.contributors}
                    size="small"
                    hideOwner={true}
                    className="contributors"
                    direction="right"
                    showNumber={true}
                  />
                </>
              )}
            </Owners>
          )}
          <div className="initiative-state">
            {isIntegration && integrationStatus && (
              <KoalaTextBadge variant={isClosed ? 'neutral-light' : 'blue-light'} isLowercase size="small">
                {integrationStatus}
              </KoalaTextBadge>
            )}
            {!isIntegration && isReadOnly && <InitiativeWorkStatus initiative={initiative} showArrow={false} />}
            {!isIntegration && !isReadOnly && (
              <DropdownMenu
                trigger={<InitiativeWorkStatus initiative={initiative} showArrow={true} />}
                items={filteredStates}
                onSelection={(e: any) => handleInitiativeStateChange(e, initiative)}
              />
            )}
          </div>
        </InitiativeMeta>
      )}
    </Row>
  );
}

export default InitiativeRow;
