import KoalaIconButton from 'koala/components/IconButton';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import theme from 'theme';
import FormField from 'components/FormField';
import PlanSummaryWidgetDetails from './WidgetDetails/PlanSummaryWidgetDetails';
import ObjectiveSummaryWidgetDetails from './WidgetDetails/ObjectiveSummaryWidgetDetails';
import OutcomeSummaryWidgetDetails from './WidgetDetails/OutcomeSummaryWidgetDetails';
import UserProfileWidgetDetails from './WidgetDetails/UserProfileDetails';
import InitiativeSummaryWidgetDetails from './WidgetDetails/InitiativeSummaryWidgetDetails';
import ObjectiveListWidgetDetails from './WidgetDetails/ObjectiveListWidgetDetails';
import OutcomeListWidgetDetails from './WidgetDetails/OutcomeListWidgetDetails';
import InitiativeListWidgetDetails from './WidgetDetails/InitiativeListWidgetDetails';
import OutcomeTaskGraphWidgetDetails from './WidgetDetails/OutcomeTaskGraphWidgetDetails';
import PlanConfidenceGraphWidgetDetails from './WidgetDetails/PlanConfidenceGraphWidgetDetails';
import PlanNCSGraphWidgetDetails from './WidgetDetails/PlanNCSGraphWidgetDetails';
import OutcomeGraphWidgetDetails from './WidgetDetails/OutcomeGraphWidgetDetails';
import TextBlockWidgetDetails from './WidgetDetails/TextBlockWidgetDetails';
import OutcomeStatsWidgetDetails from './WidgetDetails/OutcomeStatsWidgetDetails';
import OutcomeDistributionWidgetDetails from './WidgetDetails/OutcomeDistributionWidgetDetails';
import WidgetOptionCard from './WidgetOptionCard';
import KoalaButton from 'koala/components/Button';

const Mask = styled.div<{ showSearchPanel: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: ${(props) => (props.showSearchPanel ? '50' : '-1')};
  opacity: ${(props) => (props.showSearchPanel ? '1' : '0')};
  transition: 0.2s ease-out;
`;

const ContentWrapper = styled.div<{ showSearchPanel: boolean }>`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 45rem;
  z-index: 60;
  transform: translateX(${(props) => (props.showSearchPanel ? '0%' : '100%')});
  transition: 0.2s ease-out;

  @media ${theme.devices.mobile} {
    max-width: 40rem;
    width: 100%;
  }
`;

const Content = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: auto;
  padding: 3.2rem;
  background: #fff;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x3};
`;

const SearchHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #151515;
  font-weight: 700;
  font-size: 2rem;
`;

const WidgetOptionGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${theme.spacing.x2};
`;

interface Props {
  showPanel: boolean;
  handleClosePanel: () => void;
  resetTimer: number;
  addWidgetCallback: (dashboardParams: {
    widget_type: string;
    source_id?: string;
    source_type: string;
    title: string;
    widget_meta?: string;
  }) => void;
  isAddingWidget: boolean;
}

function WidgetAddPanel(props: Props) {
  const { handleClosePanel, showPanel, addWidgetCallback, isAddingWidget, resetTimer } = props;
  const { t } = useTranslation();

  // useEffect to reset the widget type and title when resetTimer changes
  useEffect(() => {
    setWidgetType('');
    setWidgetTitle('');
  }, [resetTimer]);

  const [widgetType, setWidgetType] = useState('');
  const [widgetTitle, setWidgetTitle] = useState('');

  const handleAddWidget = (type: string, source: string, source_id?: string, widgetID?: string, widgetMeta?: any) => {
    const dashboard_widget = {
      widget_type: type,
      source_id: source_id,
      source_type: source,
      title: widgetTitle,
      widget_meta: widgetMeta,
    };
    addWidgetCallback(dashboard_widget);
  };

  const widgetDetails = () => {
    switch (widgetType) {
      case 'plan-summary':
        return <PlanSummaryWidgetDetails handleSaveWidget={handleAddWidget} isLoading={isAddingWidget} />;
      case 'objective-summary':
        return <ObjectiveSummaryWidgetDetails handleSaveWidget={handleAddWidget} isLoading={isAddingWidget} />;
      case 'outcome-summary':
        return <OutcomeSummaryWidgetDetails handleSaveWidget={handleAddWidget} isLoading={isAddingWidget} />;
      case 'initiative-summary':
        return <InitiativeSummaryWidgetDetails handleSaveWidget={handleAddWidget} isLoading={isAddingWidget} />;
      case 'user-profile':
        return <UserProfileWidgetDetails handleSaveWidget={handleAddWidget} isLoading={isAddingWidget} />;
      case 'objective-list':
        return <ObjectiveListWidgetDetails handleSaveWidget={handleAddWidget} isLoading={isAddingWidget} />;
      case 'outcome-list':
        return <OutcomeListWidgetDetails handleSaveWidget={handleAddWidget} isLoading={isAddingWidget} />;
      case 'outcome-stats':
        return <OutcomeStatsWidgetDetails handleSaveWidget={handleAddWidget} isLoading={isAddingWidget} />;
      case 'outcome-distribution':
        return <OutcomeDistributionWidgetDetails handleSaveWidget={handleAddWidget} isLoading={isAddingWidget} />;
      case 'initiative-list':
        return <InitiativeListWidgetDetails handleSaveWidget={handleAddWidget} isLoading={isAddingWidget} />;
      case 'outcome-task-graph':
        return <OutcomeTaskGraphWidgetDetails handleSaveWidget={handleAddWidget} isLoading={isAddingWidget} />;
      case 'plan-confidence-graph':
        return <PlanConfidenceGraphWidgetDetails handleSaveWidget={handleAddWidget} isLoading={isAddingWidget} />;
      case 'plan-ncs-graph':
        return <PlanNCSGraphWidgetDetails handleSaveWidget={handleAddWidget} isLoading={isAddingWidget} />;
      case 'outcome-graph':
        return <OutcomeGraphWidgetDetails handleSaveWidget={handleAddWidget} isLoading={isAddingWidget} />;
      case 'text-block':
        return <TextBlockWidgetDetails handleSaveWidget={handleAddWidget} isLoading={isAddingWidget} />;
      default:
        break;
    }
  };

  const handleWidgetOptionClick = (type: string) => {
    setWidgetType(type);
  };

  const titlePlaceholder = t('workspaceDashboards.widgetTitlePlaceholder') ?? 'Enter title for widget';

  return (
    <Fragment>
      <Mask onClick={handleClosePanel} showSearchPanel={showPanel} />
      <ContentWrapper showSearchPanel={showPanel}>
        <Content>
          <SearchHeader>
            <p>{t('workspaceDashboards.add')}</p>
            <KoalaIconButton onClick={handleClosePanel} iconName="close" />
          </SearchHeader>
          {widgetType !== '' && (
            <div>
              <KoalaButton onClick={() => setWidgetType('')} prefixIcon="leftArrow" appearance="secondary">
                {t('workspaceDashboards.backToWidgets')}
              </KoalaButton>
            </div>
          )}

          {widgetType === '' && (
            <WidgetOptionGrid>
              <WidgetOptionCard type="plan-summary" handleClick={handleWidgetOptionClick} />
              <WidgetOptionCard type="objective-summary" handleClick={handleWidgetOptionClick} />
              <WidgetOptionCard type="outcome-summary" handleClick={handleWidgetOptionClick} />
              <WidgetOptionCard type="initiative-summary" handleClick={handleWidgetOptionClick} />
              <WidgetOptionCard type="user-profile" handleClick={handleWidgetOptionClick} />
              <WidgetOptionCard type="objective-list" handleClick={handleWidgetOptionClick} />
              <WidgetOptionCard type="outcome-list" handleClick={handleWidgetOptionClick} />
              <WidgetOptionCard type="outcome-stats" handleClick={handleWidgetOptionClick} />
              <WidgetOptionCard type="outcome-distribution" handleClick={handleWidgetOptionClick} />
              <WidgetOptionCard type="plan-ncs-graph" handleClick={handleWidgetOptionClick} />
              <WidgetOptionCard type="plan-confidence-graph" handleClick={handleWidgetOptionClick} />
              <WidgetOptionCard type="outcome-task-graph" handleClick={handleWidgetOptionClick} />
              <WidgetOptionCard type="initiative-list" handleClick={handleWidgetOptionClick} />
              <WidgetOptionCard type="text-block" handleClick={handleWidgetOptionClick} />
              <WidgetOptionCard type="outcome-graph" handleClick={handleWidgetOptionClick} />
            </WidgetOptionGrid>
          )}

          <div>
            {widgetType && (
              <>
                <FormField>
                  <label>{t('workspaceDashboards.widgetType')}</label>
                  <WidgetOptionCard type={widgetType} />
                </FormField>
                <FormField>
                  <label>{t('workspaceDashboards.widgetTitle')}</label>
                  <input
                    value={widgetTitle}
                    onChange={(e) => setWidgetTitle(e.target.value)}
                    placeholder={titlePlaceholder}
                  />
                </FormField>
              </>
            )}

            {widgetDetails()}
          </div>
        </Content>
      </ContentWrapper>
    </Fragment>
  );
}

export default WidgetAddPanel;
