import React, { useState, useEffect } from 'react';
//import * as TabilityTypes from 'types';
import { useDispatch } from 'react-redux';
import { useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';
import styled from 'styled-components';
import theme from 'theme';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as amplitudeUtils from 'utils/amplitude';

// Components
import FormField from 'components/FormField';

// API
import * as remoteApi from 'api/remote';

// Actions
import { sessionActions } from 'state/actions/';
import KoalaButton from 'koala/components/Button';
import KoalaTextButton from 'koala/components/TextButton';
import TeamImage from './_assets/Team.png';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  gap: ${theme.spacing.x2};
`;
const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: ${theme.spacing.x8};

  width: 100%;
  gap: ${theme.spacing.x8};

  a {
    color: ${theme.colors.blue};
  }
  h1 {
    font-size: 2.8rem;
    font-weight: 500;
    margin-bottom: ${theme.spacing.x1};
  }

  h3 {
    margin: ${theme.spacing.x2} 0;
  }

  @media ${theme.devices.laptop} {
    display: flex;
    width: 100%;
    gap: ${theme.spacing.x1};
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-width: initial;
    margin-top: ${theme.spacing.x4};
    padding: ${theme.spacing.x4};

    .hide-mobile {
      display: none;
    }

    .special-mobile {
      position: relative;
      bottom: ${theme.spacing.x3};
    }
  }
`;
const About = styled.div`
  width: 50rem;
`;
const Content = styled.div`
  justify-self: flex-end;
  width: 35rem;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .hotdeal {
    background: ${theme.colors.Y10};
    padding: ${theme.spacing.x1};
    border-radius: 4px;
  }
  @media ${theme.devices.laptop} {
    margin-top: 0;
  }
`;

const Instruction = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  img {
    height: 50rem;
  }
  @media ${theme.devices.laptop} {
    display: none;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;

  margin-top: ${theme.spacing.x3};

  @media (max-width: 800px) {
    margin-top: ${theme.spacing.x4};
    input,
    select {
      &.small {
        width: 100%;
      }

      &.tiny {
        width: 50%;
      }
    }
  }
`;

const Actions = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 0;
  gap: ${theme.spacing.x1};
`;

const ActionButtons = styled.div`
  display: flex;
  width: 100%;
  button {
    width: 100%;
    justify-content: center;
  }
`;

const Footer = styled.div`
  margin-bottom: ${theme.spacing.x3};

  button.secondary {
    color: ${theme.colors.subtleText};
    text-decoration: none;
    font-weight: normal;
  }
`;

const Success = styled.div`
  border-radius: 8px;
  background-color: ${theme.colors.successBg};
  padding: ${theme.spacing.x2};
`;

const EmailList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${theme.spacing.x3};
  input {
    margin-bottom: ${theme.spacing.x2};
  }
`;

const WelcomeOffer = styled.div`
  background: ${theme.colors.Y40};
  padding: ${theme.spacing.half} ${theme.spacing.x1};
  font-weight: 800;
  font-size: 1.2rem;
  text-transform: uppercase;
  border-radius: 8px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${theme.spacing.x2};

  p {
    font-size: 1.8rem;
  }
`;

interface Props {
  workspace: any;
}

function Inbox(props: Props) {
  const dispatch = useDispatch();
  const queryCache = useQueryCache();
  const history = useHistory();
  const { workspace } = props;

  const [emails, setEmails] = useState(['']);
  const [invitesSent, setInvitesSent] = useState(false);

  const doneUrl = `/${workspace.slug}/onboarding/done`;
  //const doneUrl = `/${workspace.slug}/plans?onboarding=1`; // Hardcoded Intercom product tour

  useEffect(() => {
    amplitudeUtils.sendAmplitudeData('app.onboarding.team', {
      'Tability Platform': 'web',
      'Tability Version': 2,
    });
  }, []);

  const [createMembershipsMutation, { isFetching }]: [any, any] = useMutation(remoteApi.createWorkspaceMemberships, {
    onMutate: () => {
      setInvitesSent(false);
    },
    onSuccess: () => {
      // Delete membership querycache
      queryCache.invalidateQueries(queryKeys.memberships);
      setInvitesSent(true);
      history.push(doneUrl);
    },
    onError: (error: any) => {
      history.push(doneUrl);
    },
  });

  const handleSkip = (e: any) => {
    e.preventDefault();
    history.push(doneUrl);
  };

  const handleChange = (e: any) => {
    const value = e.target.value;
    const index = e.target.dataset.index;
    const emailsCopy = emails.slice();
    emailsCopy[index] = value;
    setEmails(emailsCopy);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const params = {
      workspaceSlug: workspace.slug,
      emails,
    };
    createMembershipsMutation(params);
  };

  const handleLogout = (e: any) => {
    dispatch(sessionActions.logout());
  };

  return (
    <Wrapper>
      <Helmet>
        <title>{workspace.name} | Invite your team | Tability</title>
      </Helmet>
      <Container>
        <Content>
          <Header>
            <WelcomeOffer>🎁 Welcome offer</WelcomeOffer>
            <h2>Get an extended trial of Premium</h2>
            <p>Invite 1 teammate now to activate a 21-day trial of Tability Premium</p>
          </Header>
          <Form onSubmit={handleSubmit}>
            {invitesSent && (
              <Success>
                <p>The invite has been sent to your teammate!</p>
              </Success>
            )}
            {!invitesSent && (
              <FormField>
                <EmailList>
                  {emails.map((email, index) => {
                    return (
                      <>
                        <label>Your teammate</label>
                        <input
                          key={index}
                          data-index={index}
                          onChange={handleChange}
                          value={email}
                          className="email-input"
                          placeholder="name@company.com"
                          type="email"
                          required
                        />
                      </>
                    );
                  })}
                </EmailList>
              </FormField>
            )}

            <Actions>
              <ActionButtons>
                <KoalaButton submit disabled={isFetching} loading={isFetching}>
                  Complete your onboarding
                </KoalaButton>
              </ActionButtons>
              <KoalaTextButton onClick={handleSkip} appearance="subtle" size="small">
                Use the limited trial
              </KoalaTextButton>
            </Actions>
          </Form>
        </Content>
        <About>
          <Instruction>
            <img src={TeamImage} alt="Team members on Tability" />
          </Instruction>
        </About>
      </Container>
      <Footer>
        <KoalaTextButton onClick={handleLogout} appearance="subtle">
          Logout
        </KoalaTextButton>
      </Footer>
    </Wrapper>
  );
}

export default Inbox;
