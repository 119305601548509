import request from 'state/utils/request';

declare module 'axios' {
  export interface AxiosRequestConfig {
    ignoreJsonHeaders?: boolean;
  }
}

export const API_DOMAIN = `https://${process.env.REACT_APP_TABILITY_API_DOMAIN}`;
export const DOWNLOADS_DOMAIN = `https://${process.env.REACT_APP_TABILITY_DOWNLOADS_DOMAIN}`;

// Auth related endpoints
export const AUTH_GOOGLE_API = `${API_DOMAIN}/auth/google/authenticate`;
export const AUTH_SIGNUP_API = `${API_DOMAIN}/auth/user/`;
export const AUTH_CHECK_CREDENTIALS_API = `${API_DOMAIN}/auth/user/check_credentials`;
export const AUTH_PASSWORD_API = `${API_DOMAIN}/auth/user/password`;
export const AUTH_ACCOUNT_API = `${API_DOMAIN}/auth/user/account`;
export const AUTH_LOGIN_API = `${API_DOMAIN}/auth/user/authenticate`;
export const AUTH_REFRESH_API = `${API_DOMAIN}/auth/user/refresh`;
export const AUTH_CONFIRM_API = `${API_DOMAIN}/auth/user/confirm`;

// API endpoints
const ACCOUNT_API = `${API_DOMAIN}/v1/me`;

const UNSUBSCRIBE_API = `${API_DOMAIN}/v1/unsubscribe/:unsubscribeToken`;

const WORKSPACES_API = `${API_DOMAIN}/v1/workspaces`;
export const WORKSPACE_DETAILS_API = `${API_DOMAIN}/v1/workspaces/:workspaceSlug`;
export const WORKSPACE_MEMBERSHIPS_API = `${API_DOMAIN}/v1/workspaces/:workspaceSlug/memberships`;

const PUBLIC_TEMPLATES_API = `${API_DOMAIN}/v1/public_templates`;
const PUBLIC_TEMPLATE_DETAILS_API = `${API_DOMAIN}/v1/public_templates/:publicTemplateId`;

const TEMPLATES_API = `${API_DOMAIN}/v1/templates`;
const TEMPLATE_DETAILS_API = `${API_DOMAIN}/v1/templates/:templateId`;

const MEMBERSHIPS_API = `${API_DOMAIN}/v1/memberships`;
const MEMBERSHIP_DETAILS_API = `${API_DOMAIN}/v1/memberships/:membershipId`;
const MEMBERSHIP_CURRENT_STREAK_API = `${API_DOMAIN}/v1/memberships/:membershipId/current_streak`;
const MEMBERSHIP_REVIEW_API = `${API_DOMAIN}/v1/memberships/:membershipId/g2_review`;
const MEMBERSHIP_PENDING_CHECKINS_API = `${API_DOMAIN}/v1/memberships/:membershipId/pending_checkins`;
const MEMBERSHIP_ACTIVE_INITIATIVES_API = `${API_DOMAIN}/v1/memberships/:membershipId/active_initiatives`;
const MEMBERSHIP_ACTIVE_OUTCOMES_API = `${API_DOMAIN}/v1/memberships/:membershipId/active_outcomes`;
const MEMBERSHIP_TEAMS_API = `${API_DOMAIN}/v1/memberships/:membershipId/teams`;
const MEMBERSHIP_MANAGER_API = `${API_DOMAIN}/v1/memberships/:membershipId/manager`;
const MEMBERSHIP_DIRECT_REPORTS_API = `${API_DOMAIN}/v1/memberships/:membershipId/direct_reports`;
const MEMBERSHIP_DASHBOARD_FAVORITES_API = `${API_DOMAIN}/v1/memberships/:membershipId/dashboard_favorites`;

const INTEGRATIONS_API = `${API_DOMAIN}/v1/workspaces/:workspaceSlug/integrations`;

const NOTIFICATIONS_API = `${API_DOMAIN}/v1/workspaces/:workspaceSlug/notifications`;
const NOTIFICATION_DETAILS_API = `${API_DOMAIN}/v1/notifications/:notificationId`;

const ATTACHMENTS_API = `${API_DOMAIN}/v1/workspaces/:workspaceSlug/attachments`;

const AI_THREADS_API = `${API_DOMAIN}/v1/workspaces/:workspaceSlug/ai_threads`;
const AI_THREADS_INITIATIVES_API = `${API_DOMAIN}/v1/outcomes/:outcomeId/ai_threads`;
const AI_THREADS_DETAILS_API = `${API_DOMAIN}/v1/ai_threads/:aiThreadId`;

// Teams
export const TEAMS_API = `${API_DOMAIN}/v1/workspaces/:workspaceSlug/teams`;
const TEAM_DETAILS_API = `${API_DOMAIN}/v1/teams/:teamId`;

const TEAM_MEMBERS_API = `${API_DOMAIN}/v1/teams/:teamId/team_members`;
const TEAM_MEMBER_DETAILS_API = `${API_DOMAIN}/v1/team_members/:teamMemberId`;

const PLAN_DOWNLOAD_PDF_API = `${DOWNLOADS_DOMAIN}/plans/:planId/pdf`;

const PLANS_API = `${API_DOMAIN}/v1/workspaces/:workspaceSlug/plans`;
const PLAN_DETAILS_API = `${API_DOMAIN}/v1/plans/:planId`;
const PLAN_REVIEWERS_API = `${API_DOMAIN}/v1/plans/:planId/reviewers`;
const PLAN_PERMISSIONS_API = `${API_DOMAIN}/v1/plans/:planId/plan_permissions`;
const PLAN_OBJECTIVES_API = `${API_DOMAIN}/v1/plans/:planId/objectives`;
const PLAN_OUTCOMES_API = `${API_DOMAIN}/v1/plans/:planId/outcomes`;
const PLAN_INITIATIVES_API = `${API_DOMAIN}/v1/plans/:planId/initiatives`;
const PLAN_REVIEW_COMMENTS_API = `${API_DOMAIN}/v1/plans/:planId/review_comments`;
const PLAN_RECOMMENDATIONS_API = `${API_DOMAIN}/v1/plans/:planId/recommendations`;

// Public plan resources

const PUBLIC_PLAN_DETAILS_API = `${API_DOMAIN}/v1/plans/:planId/public`;
const PUBLIC_PLAN_OBJECTIVES_API = `${API_DOMAIN}/v1/plans/:planId/public/objectives`;
const PUBLIC_PLAN_OUTCOMES_API = `${API_DOMAIN}/v1/plans/:planId/public/outcomes`;
const PUBLIC_OUTCOME_DETAILS_API = `${API_DOMAIN}/v1/outcomes/:outcomeId/public`;
const PUBLIC_OUTCOME_CHECKINS_API = `${API_DOMAIN}/v1/outcomes/:outcomeId/public/checkins`;
const PUBLIC_OUTCOME_INITIATIVES_API = `${API_DOMAIN}/v1/outcomes/:outcomeId/public/initiatives`;
const PUBLIC_OBJECTIVE_DETAILS_API = `${API_DOMAIN}/v1/objectives/:objectiveId/public`;
const PUBLIC_OBJECTIVE_OUTCOMES_API = `${API_DOMAIN}/v1/objectives/:objectiveId/public/outcomes`;

const REVIEWER_DETAILS_API = `${API_DOMAIN}/v1/reviewers/:reviewerId`;

const PLAN_PERMISSION_DETAILS_API = `${API_DOMAIN}/v1/plan_permissions/:planPermissionId`;
const PLAN_RECOMMENDATION_DETAILS_API = `${API_DOMAIN}/v1/recommendations/:recommendationId`;

const OBJECTIVE_DETAILS_API = `${API_DOMAIN}/v1/objectives/:objectiveId`;

const OUTCOMES_API = `${API_DOMAIN}/v1/objectives/:objectiveId/outcomes`;
const OUTCOME_DETAILS_API = `${API_DOMAIN}/v1/outcomes/:outcomeId`;
const OUTCOME_MANAGE_API = `${API_DOMAIN}/v1/outcomes/:outcomeId/manage`;

const INITIATIVES_API = `${API_DOMAIN}/v1/outcomes/:outcomeId/initiatives`;
const INITIATIVE_DETAILS_API = `${API_DOMAIN}/v1/initiatives/:initiativeId`;

const INITIATIVE_LINKS_API = `${API_DOMAIN}/v1/initiatives/:initiativeId/initiative_links`;
const INITIATIVE_LINK_DETAILS_API = `${API_DOMAIN}/v1/initiative_links/:initiativeLinkId`;

const INITIATIVE_CONTRIBUTORS_API = `${API_DOMAIN}/v1/initiatives/:initiativeId/initiative_contributors`;
const INITIATIVE_CONTRIBUTOR_DETAILS_API = `${API_DOMAIN}/v1/initiative_contributors/:initiativeContributorId`;

export const SEGMENTS_API = `${API_DOMAIN}/v1/workspaces/:workspaceSlug/segments/:segmentable_type`;
const SEGMENT_DETAILS_API = `${API_DOMAIN}/v1/segments/:segmentId`;

const OUTCOME_LINKS_API = `${API_DOMAIN}/v1/outcomes/:outcomeId/outcome_links`;
const OUTCOME_LINK_DETAILS_API = `${API_DOMAIN}/v1/outcome_links/:outcomeLinkId`;

const OUTCOME_CONTRIBUTORS_API = `${API_DOMAIN}/v1/outcomes/:outcomeId/outcome_contributors`;
const OUTCOME_CONTRIBUTOR_DETAILS_API = `${API_DOMAIN}/v1/outcome_contributors/:outcomeContributorId`;

const OUTCOME_RELATIONSHIPS_API = `${API_DOMAIN}/v1/outcomes/:outcomeId/outcome_relationships`;
const OUTCOME_RELATIONSHIP_DETAILS_API = `${API_DOMAIN}/v1/outcome_relationships/:outcomeRelationshipId`;

const RELATIONSHIPS_OUTCOME_API = `${API_DOMAIN}/v1/outcomes/:outcomeId/relationships`;
const RELATIONSHIPS_OBJECTIVE_API = `${API_DOMAIN}/v1/objectives/:objectiveId/relationships`;
const RELATIONSHIP_DETAILS_API = `${API_DOMAIN}/v1/relationships/:relationshipId`;

const FOLLOWERS_API = `${API_DOMAIN}/v1/memberships/:membershipId/followers`;
const FOLLOWING_API = `${API_DOMAIN}/v1/memberships/:membershipId/following`;
const FOLLOWER_DETAILS_API = `${API_DOMAIN}/v1/followers/:followerId`;

const OUTCOME_TEAM_RELATIONSHIPS_API = `${API_DOMAIN}/v1/outcomes/:outcomeId/team_relationships`;
const PLAN_TEAM_RELATIONSHIPS_API = `${API_DOMAIN}/v1/plans/:planId/team_relationships`;
const TEAM_RELATIONSHIP_DETAILS_API = `${API_DOMAIN}/v1/team_relationships/:teamRelationshipId`;

const CHECKINS_API = `${API_DOMAIN}/v1/outcomes/:outcomeId/checkins`;
const CHECKIN_DETAILS_API = `${API_DOMAIN}/v1/checkins/:checkinId`;
const CHECKIN_SKIP_API = `${API_DOMAIN}/v1/outcomes/:outcomeId/checkins/skip`;
const CHECKIN_VIEWERS_API = `${API_DOMAIN}/v1/checkins/:id/viewers`;

export const TAGS_API = `${API_DOMAIN}/v1/workspaces/:workspaceSlug/tags`;
export const TAG_DETAILS_API = `${API_DOMAIN}/v1/workspaces/:workspaceSlug/tags/:id`;

const AUDIT_EVENTS_API = `${API_DOMAIN}/v1/workspaces/:workspaceSlug/audit_events`;
const EVENTS_API = `${API_DOMAIN}/v1/workspaces/:workspaceSlug/events`;
const RECENT_VISITS_API = `${API_DOMAIN}/v1/workspaces/:workspaceSlug/recent_visits`;

const STANDUPS_API = `${API_DOMAIN}/v1/workspaces/:workspaceSlug/standups`;
const STANDUP_DETAILS_API = `${API_DOMAIN}/v1/standups/:standupId`;

const STANDUP_COMMENTS_API = `${API_DOMAIN}/v1/standups/:standupId/standup_comments`;
const STANDUP_COMMENT_DETAILS_API = `${API_DOMAIN}/v1/standup_comments/:standupCommentId`;

const REVIEW_COMMENT_DETAILS_API = `${API_DOMAIN}/v1/review_comments/:reviewCommentId`;

const REVIEW_REPLIES_API = `${API_DOMAIN}/v1/review_comments/:reviewCommentId/review_replies`;
const REVIEW_REPLY_DETAILS_API = `${API_DOMAIN}/v1/review_replies/:reviewReplyId`;

const CHECKIN_COMMENTS_API = `${API_DOMAIN}/v1/checkins/:checkinId/checkin_comments`;
const CHECKIN_COMMENT_DETAILS_API = `${API_DOMAIN}/v1/checkin_comments/:checkinCommentId`;

const INITIATIVE_COMMENTS_API = `${API_DOMAIN}/v1/initiatives/:initiativeId/initiative_comments`;
const INITIATIVE_COMMENT_DETAILS_API = `${API_DOMAIN}/v1/initiative_comments/:initiativeCommentId`;

const INITIATIVE_REPLIES_API = `${API_DOMAIN}/v1/initiative_comments/:initiativeCommentId/initiative_replies`;
const INITIATIVE_REPLY_DETAILS_API = `${API_DOMAIN}/v1/initiative_replies/:initiativeReplyId`;

const RETROSPECTIVE_COMMENTS_API = `${API_DOMAIN}/v1/retrospectives/:retrospectiveId/retrospective_comments`;
const RETROSPECTIVE_COMMENT_DETAILS_API = `${API_DOMAIN}/v1/retrospective_comments/:retrospectiveCommentId`;

const RETROSPECTIVE_REPLIES_API = `${API_DOMAIN}/v1/retrospective_comments/:retrospectiveCommentId/retrospective_replies`;
const RETROSPECTIVE_REPLY_DETAILS_API = `${API_DOMAIN}/v1/retrospective_replies/:retrospectiveReplyId`;

const REACTIONS_API = `${API_DOMAIN}/v1/reactions/:id`;
const CHECKIN_REACTIONS_API = `${API_DOMAIN}/v1/checkins/:checkin_id/reactions`;
const CHECKIN_COMMENTS_REACTIONS_API = `${API_DOMAIN}/v1/checkin_comments/:checkin_comment_id/reactions`;
const INITIATIVE_COMMENTS_REACTIONS_API = `${API_DOMAIN}/v1/initiative_comments/:initiative_comment_id/reactions`;
const INITIATIVE_REPLIES_REACTIONS_API = `${API_DOMAIN}/v1/initiative_replies/:initiative_reply_id/reactions`;
const REVIEW_COMMENT_REACTIONS_API = `${API_DOMAIN}/v1/review_comments/:review_comment_id/reactions`;
const REVIEW_REPLY_REACTIONS_API = `${API_DOMAIN}/v1/review_replies/:review_reply_id/reactions`;
const STANDUP_REACTIONS_API = `${API_DOMAIN}/v1/standups/:standup_id/reactions`;
const STANDUP_COMMENT_REACTIONS_API = `${API_DOMAIN}/v1/standup_comments/:standup_comment_id/reactions`;
const REETROSPECTIVE_COMMENT_REACTIONS_API = `${API_DOMAIN}/v1/retrospective_comments/:retrospective_comment_id/reactions`;
const REETROSPECTIVE_REPLY_REACTIONS_API = `${API_DOMAIN}/v1/retrospective_replies/:retrospective_reply_id/reactions`;

const PLAN_SUBSCRIPTIONS_API = `${API_DOMAIN}/v1/plans/:planId/plan_subscriptions`;
const PLAN_SUBSCRIPTION_DETAILS_API = `${API_DOMAIN}/v1/plan_subscriptions/:planSubscriptionId`;

const RETROSPECTIVES_API = `${API_DOMAIN}/v1/plans/:planId/retrospectives`;
const RETROSPECTIVE_DETAILS_API = `${API_DOMAIN}/v1/retrospectives/:retrospectiveId`;
const RETROSPECTIVE_SECTIONS_API = `${API_DOMAIN}/v1/retrospectives/:retrospectiveId/retrospective_sections`;
const RETROSPECTIVE_SECTION_DETAILS_API = `${API_DOMAIN}/v1/retrospective_sections/:retrospectiveSectionId`;

// Dashboards
const DASHBOARDS_API = `${API_DOMAIN}/v1/workspaces/:workspaceSlug/dashboards`;
const DASHBOARD_DETAILS_API = `${API_DOMAIN}/v1/dashboards/:dashboardId`;
const DASHBOARD_WIDGETS_API = `${API_DOMAIN}/v1/dashboards/:dashboardId/dashboard_widgets`;
const DASHBOARD_WIDGET_DETAILS_API = `${API_DOMAIN}/v1/dashboard_widgets/:dashboardWidgetId`;

// INTEGRATIONS
export const WORKSPACE_ZENDESK_SUPPORT_API = `${API_DOMAIN}/v1/workspaces/:workspaceSlug/services/zendesk_support`;
export const WORKSPACE_ZENDESK_SALES_API = `${API_DOMAIN}/v1/workspaces/:workspaceSlug/services/zendesk_sales`;
export const WORKSPACE_GOOGLE_API = `${API_DOMAIN}/v1/workspaces/:workspaceSlug/services/google`;
export const WORKSPACE_CHARTMOGUL_API = `${API_DOMAIN}/v1/workspaces/:workspaceSlug/services/chartmogul`;
export const WORKSPACE_FAVRO_API = `${API_DOMAIN}/v1/workspaces/:workspaceSlug/services/favro`;
export const WORKSPACE_HUBSPOT_API = `${API_DOMAIN}/v1/workspaces/:workspaceSlug/services/hubspot`;
export const WORKSPACE_PAGERDUTY_API = `${API_DOMAIN}/v1/workspaces/:workspaceSlug/services/pagerduty`;
export const WORKSPACE_AMPLITUDE_API = `${API_DOMAIN}/v1/workspaces/:workspaceSlug/services/amplitude`;
export const WORKSPACE_POSTGRESQL_API = `${API_DOMAIN}/v1/workspaces/:workspaceSlug/services/postgresql`;
export const WORKSPACE_MYSQL_API = `${API_DOMAIN}/v1/workspaces/:workspaceSlug/services/mysql`;
export const WORKSPACE_MARIADB_API = `${API_DOMAIN}/v1/workspaces/:workspaceSlug/services/mariadb`;
export const WORKSPACE_SLACK_API = `${API_DOMAIN}/v1/workspaces/:workspaceSlug/services/slack`;
export const WORKSPACE_CLICKUP_API = `${API_DOMAIN}/v1/workspaces/:workspaceSlug/services/clickup`;
export const WORKSPACE_CHARGEBEE_API = `${API_DOMAIN}/v1/workspaces/:workspaceSlug/services/chargebee`;
export const WORKSPACE_MONDAY_API = `${API_DOMAIN}/v1/workspaces/:workspaceSlug/services/monday`;
export const WORKSPACE_GITHUB_API = `${API_DOMAIN}/v1/workspaces/:workspaceSlug/services/github`;
export const WORKSPACE_SALESFORCE_API = `${API_DOMAIN}/v1/workspaces/:workspaceSlug/services/salesforce`;
export const WORKSPACE_SENTRY_API = `${API_DOMAIN}/v1/workspaces/:workspaceSlug/services/sentry`;
export const WORKSPACE_TRELLO_API = `${API_DOMAIN}/v1/workspaces/:workspaceSlug/services/trello`;
export const WORKSPACE_HELPSCOUT_API = `${API_DOMAIN}/v1/workspaces/:workspaceSlug/services/helpscout`;
export const WORKSPACE_ASANA_API = `${API_DOMAIN}/v1/workspaces/:workspaceSlug/services/asana`;
export const WORKSPACE_GITLAB_API = `${API_DOMAIN}/v1/workspaces/:workspaceSlug/services/gitlab`;
export const WORKSPACE_LINEAR_API = `${API_DOMAIN}/v1/workspaces/:workspaceSlug/services/linear`;
export const WORKSPACE_BIG_QUERY_API = `${API_DOMAIN}/v1/workspaces/:workspaceSlug/services/big_query`;
export const WORKSPACE_GOOGLE_TASKS_API = `${API_DOMAIN}/v1/workspaces/:workspaceSlug/services/google_tasks`;
export const WORKSPACE_TABLEAU_API = `${API_DOMAIN}/v1/workspaces/:workspaceSlug/services/tableau`;
export const WORKSPACE_POWER_BI_API = `${API_DOMAIN}/v1/workspaces/:workspaceSlug/services/power_bi`;

// PUBLIC TEMPLATES

export const fetchPublicTemplates = (queryKey: string, queryParams: any = {}, page: number) => {
  const params = {
    ...queryParams,
    page,
  };

  return request.get(PUBLIC_TEMPLATES_API, { params });
};

export const fetchTemplates = (queryKey: string, queryParams: any = {}, page: number) => {
  const params = {
    ...queryParams,
    page,
  };

  return request.get(TEMPLATES_API, { params });
};

export const fetchTemplateDetails = (queryKey: string, templateId: string) => {
  const url = TEMPLATE_DETAILS_API.replace(':templateId', templateId);
  return request.get(url);
};

export const fetchPublicTemplateDetails = (queryKey: string, publicTemplateId: string) => {
  const url = PUBLIC_TEMPLATE_DETAILS_API.replace(':publicTemplateId', publicTemplateId);
  return request.get(url);
};

export const createPublicTemplate = (templateParams: any) => {
  const body = {
    public_template: templateParams,
  };
  return request.post(PUBLIC_TEMPLATES_API, body);
};

export const updatePublicTemplate = (params: any) => {
  const url = PUBLIC_TEMPLATE_DETAILS_API.replace(':publicTemplateId', params.publicTemplateId);
  const body = {
    public_template: { ...params.public_template },
  };
  return request.put(url, body);
};

// UNSUBSCRIBE CALLs
export const fetchUnsubscribeNotifications = (queryKey: string, unsubscribeToken: string) => {
  const url = UNSUBSCRIBE_API.replace(':unsubscribeToken', unsubscribeToken);
  return request.get(url);
};

export const updateUnsubscribeNotifications = (params: any) => {
  const url = UNSUBSCRIBE_API.replace(':unsubscribeToken', params.unsubscribeToken);
  const body = {
    membership: { ...params.membership },
  };
  return request.put(url, body);
};

// PLANS IMPORT

export const importPlansFromCSV = (params: any) => {
  const url = WORKSPACE_DETAILS_API.replace(':workspaceSlug', params.workspaceId);
  const formData = new FormData();
  const { file } = params;
  formData.append('file', file);
  return request.post(`${url}/import_plans`, formData, { ignoreJsonHeaders: true });
};

export const importPlansFromDocX = (params: any) => {
  const url = WORKSPACE_DETAILS_API.replace(':workspaceSlug', params.workspaceId);
  const formData = new FormData();
  const { file } = params;
  formData.append('file', file);
  return request.post(`${url}/import_plans_structure`, formData, { ignoreJsonHeaders: true });
};

// AUTHENTICATION

export const authenticateWithGoogle = (body: any) => {
  const url = AUTH_GOOGLE_API;

  return request.post(url, body, {
    ignoreAuthHeaders: true,
    withCredentials: true,
  });
};

// Integrations

export const installZendeskSupport = (params: any) => {
  const url = WORKSPACE_ZENDESK_SUPPORT_API.replace(':workspaceSlug', params.workspaceSlug);

  return request.post(url, params.body);
};

export const uninstallZendeskSupport = (workspaceSlug: string) => {
  const url = WORKSPACE_ZENDESK_SUPPORT_API.replace(':workspaceSlug', workspaceSlug);

  return request.delete(url);
};

export const installZendeskSales = (params: any) => {
  const url = WORKSPACE_ZENDESK_SALES_API.replace(':workspaceSlug', params.workspaceSlug);

  return request.post(url, params.body);
};

export const uninstallZendeskSales = (workspaceSlug: string) => {
  const url = WORKSPACE_ZENDESK_SALES_API.replace(':workspaceSlug', workspaceSlug);

  return request.delete(url);
};

export const installChartMogul = (params: any) => {
  const url = WORKSPACE_CHARTMOGUL_API.replace(':workspaceSlug', params.workspaceSlug);

  return request.post(url, params.body);
};

export const uninstallChartMogul = (workspaceSlug: string) => {
  const url = WORKSPACE_CHARTMOGUL_API.replace(':workspaceSlug', workspaceSlug);

  return request.delete(url);
};

export const installFavro = (params: any) => {
  const url = WORKSPACE_FAVRO_API.replace(':workspaceSlug', params.workspaceSlug);

  return request.post(url, params.body);
};

export const uninstallFavro = (workspaceSlug: string) => {
  const url = WORKSPACE_FAVRO_API.replace(':workspaceSlug', workspaceSlug);

  return request.delete(url);
};

export const installGoogle = (params: any) => {
  const url = WORKSPACE_GOOGLE_API.replace(':workspaceSlug', params.workspaceSlug);

  return request.post(url, params.body);
};

export const uninstallGoogle = (workspaceSlug: string) => {
  const url = WORKSPACE_GOOGLE_API.replace(':workspaceSlug', workspaceSlug);

  return request.delete(url);
};

export const fetchGoogleSpreadsheets = (queryKey: string, workspaceSlug: string, keyword: string) => {
  const url = `${WORKSPACE_GOOGLE_API.replace(':workspaceSlug', workspaceSlug)}/spreadsheets?keyword=${keyword}`;

  return request.get(url);
};

export const fetchGoogleSpreadsheetDetails = (queryKey: string, workspaceSlug: string, spreadshetId: string) => {
  const url = `${WORKSPACE_GOOGLE_API.replace(':workspaceSlug', workspaceSlug)}/spreadsheets/${spreadshetId}`;

  return request.get(url);
};

export const fetchGoogleToken = (queryKey: string, workspaceSlug: string) => {
  const url = `${WORKSPACE_GOOGLE_API.replace(':workspaceSlug', workspaceSlug)}/token`;

  return request.get(url);
};

export const installAmplitude = (params: any) => {
  const url = WORKSPACE_AMPLITUDE_API.replace(':workspaceSlug', params.workspaceSlug);

  return request.post(url, params.body);
};

export const uninstallAmplitude = (workspaceSlug: string) => {
  const url = WORKSPACE_AMPLITUDE_API.replace(':workspaceSlug', workspaceSlug);

  return request.delete(url);
};

export const installPostgreSQL = (params: any) => {
  const url = WORKSPACE_POSTGRESQL_API.replace(':workspaceSlug', params.workspaceSlug);

  return request.post(url, params.body);
};

export const uninstallPostgreSQL = (workspaceSlug: string) => {
  const url = WORKSPACE_POSTGRESQL_API.replace(':workspaceSlug', workspaceSlug);

  return request.delete(url);
};

export const installMySQL = (params: any) => {
  const url = WORKSPACE_MYSQL_API.replace(':workspaceSlug', params.workspaceSlug);

  return request.post(url, params.body);
};

export const uninstallMySQL = (workspaceSlug: string) => {
  const url = WORKSPACE_MYSQL_API.replace(':workspaceSlug', workspaceSlug);

  return request.delete(url);
};

export const installMariaDB = (params: any) => {
  const url = WORKSPACE_MARIADB_API.replace(':workspaceSlug', params.workspaceSlug);

  return request.post(url, params.body);
};

export const uninstallMariaDB = (workspaceSlug: string) => {
  const url = WORKSPACE_MARIADB_API.replace(':workspaceSlug', workspaceSlug);

  return request.delete(url);
};

export const installTableau = (params: any) => {
  const url = WORKSPACE_TABLEAU_API.replace(':workspaceSlug', params.workspaceSlug);

  return request.post(url, params.body);
};
export const uninstallTableau = (workspaceSlug: string) => {
  const url = WORKSPACE_TABLEAU_API.replace(':workspaceSlug', workspaceSlug);

  return request.delete(url);
};

export const installHubspot = (params: any) => {
  const url = WORKSPACE_HUBSPOT_API.replace(':workspaceSlug', params.workspaceSlug);

  return request.post(url, params.body);
};

export const uninstallHubspot = (workspaceSlug: string) => {
  const url = WORKSPACE_HUBSPOT_API.replace(':workspaceSlug', workspaceSlug);

  return request.delete(url);
};

export const installPagerDuty = (params: any) => {
  const url = WORKSPACE_PAGERDUTY_API.replace(':workspaceSlug', params.workspaceSlug);

  return request.post(url, params.body);
};

export const uninstallPagerDuty = (workspaceSlug: string) => {
  const url = WORKSPACE_PAGERDUTY_API.replace(':workspaceSlug', workspaceSlug);

  return request.delete(url);
};

export const installSlack = (params: any) => {
  const url = WORKSPACE_SLACK_API.replace(':workspaceSlug', params.workspaceSlug);

  return request.post(url, params.body);
};

export const uninstallSlack = (workspaceSlug: string) => {
  const url = WORKSPACE_SLACK_API.replace(':workspaceSlug', workspaceSlug);

  return request.delete(url);
};

export const listSlackChannels = (queryKey: string, workspaceSlug: string) => {
  const url = `${WORKSPACE_SLACK_API.replace(':workspaceSlug', workspaceSlug)}/channels`;

  return request.get(url);
};

export const installClickup = (params: any) => {
  const url = WORKSPACE_CLICKUP_API.replace(':workspaceSlug', params.workspaceSlug);

  return request.post(url, params.body);
};

export const uninstallClickup = (workspaceSlug: string) => {
  const url = WORKSPACE_CLICKUP_API.replace(':workspaceSlug', workspaceSlug);

  return request.delete(url);
};

export const installMonday = (params: any) => {
  const url = WORKSPACE_MONDAY_API.replace(':workspaceSlug', params.workspaceSlug);

  return request.post(url, params.body);
};

export const uninstallMonday = (workspaceSlug: string) => {
  const url = WORKSPACE_MONDAY_API.replace(':workspaceSlug', workspaceSlug);

  return request.delete(url);
};

export const installChargebee = (params: any) => {
  const url = WORKSPACE_CHARGEBEE_API.replace(':workspaceSlug', params.workspaceSlug);

  return request.post(url, params.body);
};

export const uninstallChargebee = (workspaceSlug: string) => {
  const url = WORKSPACE_CHARGEBEE_API.replace(':workspaceSlug', workspaceSlug);

  return request.delete(url);
};

export const installGithub = (params: any) => {
  const url = WORKSPACE_GITHUB_API.replace(':workspaceSlug', params.workspaceSlug);

  return request.post(url, params.body);
};

export const uninstallGithub = (workspaceSlug: string) => {
  const url = WORKSPACE_GITHUB_API.replace(':workspaceSlug', workspaceSlug);

  return request.delete(url);
};

export const installSalesforce = (params: any) => {
  const url = WORKSPACE_SALESFORCE_API.replace(':workspaceSlug', params.workspaceSlug);

  return request.post(url, params.body);
};

export const uninstallSalesforce = (workspaceSlug: string) => {
  const url = WORKSPACE_SALESFORCE_API.replace(':workspaceSlug', workspaceSlug);

  return request.delete(url);
};

export const installSentry = (params: any) => {
  const url = WORKSPACE_SENTRY_API.replace(':workspaceSlug', params.workspaceSlug);

  return request.post(url, params.body);
};

export const uninstallSentry = (workspaceSlug: string) => {
  const url = WORKSPACE_SENTRY_API.replace(':workspaceSlug', workspaceSlug);

  return request.delete(url);
};

export const listSentryProjects = (queryKey: string, workspaceSlug: string) => {
  const url = `${WORKSPACE_SENTRY_API.replace(':workspaceSlug', workspaceSlug)}/projects`;

  return request.get(url);
};

export const installTrello = (params: any) => {
  const url = WORKSPACE_TRELLO_API.replace(':workspaceSlug', params.workspaceSlug);

  return request.post(url, params.body);
};

export const uninstallTrello = (workspaceSlug: string) => {
  const url = WORKSPACE_TRELLO_API.replace(':workspaceSlug', workspaceSlug);

  return request.delete(url);
};

export const listTrelloBoards = (queryKey: string, workspaceSlug: string) => {
  const url = `${WORKSPACE_TRELLO_API.replace(':workspaceSlug', workspaceSlug)}/boards`;

  return request.get(url);
};

export const listTrelloBoardLists = (queryKey: string, workspaceSlug: string, boardId: string) => {
  const url = `${WORKSPACE_TRELLO_API.replace(':workspaceSlug', workspaceSlug)}/board_lists`;

  return request.get(url, { params: { boardId } });
};

export const installHelpScout = (params: any) => {
  const url = WORKSPACE_HELPSCOUT_API.replace(':workspaceSlug', params.workspaceSlug);

  return request.post(url, params.body);
};

export const uninstallHelpScout = (workspaceSlug: string) => {
  const url = WORKSPACE_HELPSCOUT_API.replace(':workspaceSlug', workspaceSlug);

  return request.delete(url);
};

export const installGitlab = (params: any) => {
  const url = WORKSPACE_GITLAB_API.replace(':workspaceSlug', params.workspaceSlug);

  return request.post(url, params.body);
};

export const uninstallGitlab = (workspaceSlug: string) => {
  const url = WORKSPACE_GITLAB_API.replace(':workspaceSlug', workspaceSlug);

  return request.delete(url);
};

export const installLinear = (params: any) => {
  const url = WORKSPACE_LINEAR_API.replace(':workspaceSlug', params.workspaceSlug);

  return request.post(url, params.body);
};

export const uninstallLinear = (workspaceSlug: string) => {
  const url = WORKSPACE_LINEAR_API.replace(':workspaceSlug', workspaceSlug);

  return request.delete(url);
};

export const installPowerBI = (params: any) => {
  const url = WORKSPACE_POWER_BI_API.replace(':workspaceSlug', params.workspaceSlug);

  return request.post(url, params.body);
};

export const uninstallPowerBI = (workspaceSlug: string) => {
  const url = WORKSPACE_POWER_BI_API.replace(':workspaceSlug', workspaceSlug);

  return request.delete(url);
};

export const listGitlabProjects = (queryKey: string, workspaceSlug: string) => {
  const url = `${WORKSPACE_GITLAB_API.replace(':workspaceSlug', workspaceSlug)}/projects`;

  return request.get(url);
};

export const installAsana = (params: any) => {
  const url = WORKSPACE_ASANA_API.replace(':workspaceSlug', params.workspaceSlug);

  return request.post(url, params.body);
};

export const installAsanaWebhook = (params: any) => {
  const url = `${WORKSPACE_ASANA_API.replace(':workspaceSlug', params.workspaceSlug)}/webhook`;

  return request.post(url, params.body);
};

export const uninstallAsana = (workspaceSlug: string) => {
  const url = WORKSPACE_ASANA_API.replace(':workspaceSlug', workspaceSlug);

  return request.delete(url);
};

export const listAsanaProjects = (queryKey: string, workspaceSlug: string) => {
  const url = `${WORKSPACE_ASANA_API.replace(':workspaceSlug', workspaceSlug)}/projects`;

  return request.get(url);
};

export const listBigQueryProjects = (queryKey: string, workspaceSlug: string) => {
  const url = `${WORKSPACE_BIG_QUERY_API.replace(':workspaceSlug', workspaceSlug)}/projects`;

  return request.get(url);
};

export const listGoogleTasksLists = (queryKey: string, workspaceSlug: string) => {
  const url = `${WORKSPACE_GOOGLE_API.replace(':workspaceSlug', workspaceSlug)}/task_lists`;

  return request.get(url);
};

export const listMondayBoards = (queryKey: string, workspaceSlug: string) => {
  const url = `${WORKSPACE_MONDAY_API.replace(':workspaceSlug', workspaceSlug)}/boards`;

  return request.get(url);
};

export const listMondayGroups = (queryKey: string, workspaceSlug: string, board_id: string) => {
  const url = `${WORKSPACE_MONDAY_API.replace(':workspaceSlug', workspaceSlug)}/groups`;

  return request.get(url, { params: { board_id } });
};

export const listTableauWorkbooks = (queryKey: string, workspaceSlug: string) => {
  const url = `${WORKSPACE_TABLEAU_API.replace(':workspaceSlug', workspaceSlug)}/workbooks`;

  return request.get(url);
};
export const listTableauViews = (queryKey: string, workspaceSlug: string, workbook_id: string) => {
  const url = `${WORKSPACE_TABLEAU_API.replace(':workspaceSlug', workspaceSlug)}/views`;

  return request.get(url, { params: { workbook_id } });
};

export const fetchTableauViewDetails = (queryKey: string, workspaceSlug: string, view_id: string) => {
  const url = `${WORKSPACE_TABLEAU_API.replace(':workspaceSlug', workspaceSlug)}/view_details`;

  return request.get(url, { params: { view_id } });
};

export const listPowerBIGroups = (queryKey: string, workspaceSlug: string) => {
  const url = `${WORKSPACE_POWER_BI_API.replace(':workspaceSlug', workspaceSlug)}/groups`;
  return request.get(url);
};

export const listPowerBIDatasets = (queryKey: string, workspaceSlug: string, group_id: string) => {
  const url = `${WORKSPACE_POWER_BI_API.replace(':workspaceSlug', workspaceSlug)}/datasets`;
  return request.get(url, { params: { group_id } });
};

export const listFavroOrganizations = (queryKey: string, workspaceSlug: string) => {
  const url = `${WORKSPACE_FAVRO_API.replace(':workspaceSlug', workspaceSlug)}/organizations`;
  return request.get(url);
};

export const listFavroWidgets = (queryKey: string, workspaceSlug: string, organizationId: string) => {
  const url = `${WORKSPACE_FAVRO_API.replace(':workspaceSlug', workspaceSlug)}/widgets`;
  return request.get(url, { params: { organizationId } });
};

export const listFavroCollections = (queryKey: string, workspaceSlug: string, organizationId: string) => {
  const url = `${WORKSPACE_FAVRO_API.replace(':workspaceSlug', workspaceSlug)}/collections`;
  return request.get(url, { params: { organizationId } });
};

export const listFavroColumns = (queryKey: string, workspaceSlug: string, organizationId: string, widgetId: string) => {
  const url = `${WORKSPACE_FAVRO_API.replace(':workspaceSlug', workspaceSlug)}/columns`;
  return request.get(url, { params: { organizationId, widgetId } });
};

// ACCOUNT APIS =============================

export const fetchAccount = () => {
  const url = ACCOUNT_API;
  return request.get(url);
};

export const updateAccount = (params: any) => {
  const url = ACCOUNT_API;
  const body = {
    user: { ...params.user },
  };
  return request.put(url, body);
};

export const updateEmail = (params: any) => {
  const url = `${ACCOUNT_API}/email`;
  const body = {
    user: { ...params.user },
  };
  return request.put(url, body);
};

export const updateRequiredPassword = (params: any) => {
  const url = `${ACCOUNT_API}/password`;
  const body = {
    user: { ...params.user },
  };
  return request.put(url, body);
};

export const create2FA = () => {
  const url = `${ACCOUNT_API}/2fa`;
  return request.post(url);
};

export const update2FA = (params: any) => {
  const url = `${ACCOUNT_API}/2fa`;
  const body = {
    user: { ...params.user },
  };
  return request.put(url, body);
};

export const create2FABackupCodes = (params: any) => {
  const url = `${ACCOUNT_API}/2fa/codes`;
  const body = {
    user: { ...params.user },
  };
  return request.post(url, body);
};

export const updateAccountWithAvatar = (params: any) => {
  const url = ACCOUNT_API;
  const formData = new FormData();
  const { user } = params;
  formData.append('user[firstname]', user.firstname);
  formData.append('user[lastname]', user.lastname);
  if (user.avatar) {
    formData.append('user[avatar]', user.avatar);
  }

  return request.put(url, formData, {
    ignoreJsonHeaders: true,
  });
};

export const updatePassword = (params: any) => {
  const url = AUTH_ACCOUNT_API;
  const body = {
    user: { ...params.user },
  };
  return request.put(url, body);
};

export const deleteAccount = () => {
  const url = AUTH_ACCOUNT_API;
  return request.delete(url);
};

export const resendConfirmation = (email: string) => {
  const url = AUTH_CONFIRM_API;

  const body = {
    user: {
      email,
    },
  };

  return request.post(url, body);
};

export const checkCredentials = (email: string) => {
  const url = AUTH_CHECK_CREDENTIALS_API;

  const body = {
    user: {
      email,
    },
  };

  return request.post(url, body);
};

export const resetPassword = (params: any) => {
  const url = AUTH_PASSWORD_API;

  const body = {
    user: { ...params.user },
  };

  return request.post(url, body);
};

export const confirmPassword = (params: any) => {
  const url = AUTH_PASSWORD_API;

  const body = {
    user: { ...params.user },
  };

  return request.put(url, body);
};

// WORKSPACE APIS ====================================================

export const createWorkspace = (workspaceParams: any) => {
  const body = {
    workspace: workspaceParams,
  };
  return request.post(WORKSPACES_API, body);
};

export const fetchWorkspaces = (queryKey: string, filters: any = {}) => {
  const params = filters;
  return request.get(WORKSPACES_API, { params });
};

export const fetchDiscoverableWorkspaces = (queryKey: string, filters: any = {}) => {
  const params = filters;
  return request.get(`${WORKSPACES_API}/discoverable`, { params });
};

export const fetchWorkspaceDetails = (queryKey: string, workspaceSlug: string) => {
  const url = WORKSPACE_DETAILS_API.replace(':workspaceSlug', workspaceSlug);
  return request.get(url);
};

export const fetchWorkspaceSettings = (queryKey: string, workspaceSlug: string) => {
  const url = `${WORKSPACE_DETAILS_API.replace(':workspaceSlug', workspaceSlug)}/custom_settings`;
  return request.get(url);
};

export const fetchWorkspaceAdminDetails = (queryKey: string, workspaceSlug: string) => {
  const url = `${WORKSPACE_DETAILS_API}/admin`.replace(':workspaceSlug', workspaceSlug);
  return request.get(url);
};

export const fetchWorkspaceStats = (queryKey: string, workspaceSlug: string) => {
  const url = `${WORKSPACE_DETAILS_API}/stats`.replace(':workspaceSlug', workspaceSlug);
  return request.get(url);
};

export const fetchWorkspaceStreakStats = (queryKey: string, workspaceSlug: string) => {
  const url = `${WORKSPACE_DETAILS_API}/streak_ladder`.replace(':workspaceSlug', workspaceSlug);
  return request.get(url);
};

export const fetchWorkspaceCheckinsActivity = (queryKey: string, workspaceSlug: string) => {
  const url = `${WORKSPACE_DETAILS_API}/checkins_activity`.replace(':workspaceSlug', workspaceSlug);
  return request.get(url);
};

export const fetchWorkspaceViewsActivity = (queryKey: string, workspaceSlug: string) => {
  const url = `${WORKSPACE_DETAILS_API}/views_activity`.replace(':workspaceSlug', workspaceSlug);
  return request.get(url);
};

export const fetchWorkspaceInitiativesCommentedActivity = (queryKey: string, workspaceSlug: string) => {
  const url = `${WORKSPACE_DETAILS_API}/initiatives_commented_activity`.replace(':workspaceSlug', workspaceSlug);
  return request.get(url);
};

export const fetchWorkspaceInitiativesCreatedActivity = (queryKey: string, workspaceSlug: string) => {
  const url = `${WORKSPACE_DETAILS_API}/initiatives_created_activity`.replace(':workspaceSlug', workspaceSlug);
  return request.get(url);
};

export const fetchWorkspaceInitiativesClosedActivity = (queryKey: string, workspaceSlug: string) => {
  const url = `${WORKSPACE_DETAILS_API}/initiatives_closed_activity`.replace(':workspaceSlug', workspaceSlug);
  return request.get(url);
};

export const fetchWorkspacePing = (queryKey: string, workspaceSlug: string) => {
  const url = `${WORKSPACE_DETAILS_API}/ping`.replace(':workspaceSlug', workspaceSlug);
  return request.get(url);
};

export const updateWorkspace = (params: any) => {
  const url = WORKSPACE_DETAILS_API.replace(':workspaceSlug', params.workspaceId);

  const body = {
    workspace: { ...params.workspace },
  };
  return request.put(url, body, { ignoreJsonHeaders: true });
};

export const updateWorkspaceSettings = (params: any) => {
  const url = `${WORKSPACE_DETAILS_API.replace(':workspaceSlug', params.workspaceId)}/custom_settings`;
  const body = {
    settings: { ...params.settings },
  };
  return request.put(url, body, { ignoreJsonHeaders: true });
};

export const updateWorkspaceAvatar = (params: any) => {
  const url = WORKSPACE_DETAILS_API.replace(':workspaceSlug', params.workspaceId);
  const formData = new FormData();
  const { workspace } = params;
  formData.append('workspace[theme_icon]', workspace.theme_icon);
  return request.put(url, formData, { ignoreJsonHeaders: true });
};

export const resetWorkspaceDummyData = (workspaceId: string) => {
  const url = `${WORKSPACE_DETAILS_API.replace(':workspaceSlug', workspaceId)}/dummy`;
  return request.post(url);
};

export const updateWorkspaceSSO = (params: any) => {
  const url = `${WORKSPACE_DETAILS_API.replace(':workspaceSlug', params.workspaceId)}/sso`;
  const body = {
    workspace: { ...params.workspace },
  };
  return request.put(url, body);
};

export const resetWorkspaceInviteLink = (workspaceSlug: string) => {
  const url = `${WORKSPACE_DETAILS_API}/invite_link`.replace(':workspaceSlug', workspaceSlug);
  return request.post(url);
};

export const deleteWorkspace = (workspaceSlug: string) => {
  const url = WORKSPACE_DETAILS_API.replace(':workspaceSlug', workspaceSlug);
  return request.delete(url);
};

export const joinWorkspace = (workspaceSlug: string) => {
  const url = `${WORKSPACE_DETAILS_API.replace(':workspaceSlug', workspaceSlug)}/join`;

  return request.post(url);
};

export const createWorkspaceCheckoutSession = (params: any) => {
  const url = `${WORKSPACE_DETAILS_API.replace(':workspaceSlug', params.workspaceSlug)}/checkout`;

  return request.post(url, params.body);
};

export const createWorkspaceTrialSession = (params: any) => {
  const url = `${WORKSPACE_DETAILS_API.replace(':workspaceSlug', params.workspaceSlug)}/trial`;

  return request.post(url);
};

export const previewWorkspaceSubscription = (params: any) => {
  const url = `${WORKSPACE_DETAILS_API.replace(':workspaceSlug', params.workspaceSlug)}/preview_subscription`;

  return request.post(url, params.body);
};

export const updateWorkspaceSubscription = (params: any) => {
  const url = `${WORKSPACE_DETAILS_API.replace(':workspaceSlug', params.workspaceSlug)}/subscription`;

  return request.put(url, params.body);
};

export const createWorkspacePortalSession = (params: any) => {
  const url = `${WORKSPACE_DETAILS_API.replace(':workspaceSlug', params.workspaceSlug)}/portal`;

  return request.post(url, params.body);
};

export const shareLinkViaEmail = (params: any) => {
  const url = `${WORKSPACE_DETAILS_API.replace(':workspaceSlug', params.workspaceSlug)}/share`;
  const body = { ...params.share };
  return request.post(url, body);
};

// MEMBERSHIP APIS ====================================================

export const fetchCurrentUserMemberships = (queryKey: string, filters: any = {}) => {
  const params = filters;
  return request.get(MEMBERSHIPS_API, { params });
};

export const fetchWorkspaceMemberships = (queryKey: string, workspaceSlug: string, filters: any = {}, page: number) => {
  const params = {
    ...filters,
    page,
  };

  return request.get(WORKSPACE_MEMBERSHIPS_API.replace(':workspaceSlug', workspaceSlug), { params });
};

// Get the stats corresponding to the search filters
export const fetchMembershipsStats = (queryKey: string, _: string, params: any) => {
  const url = `${WORKSPACE_DETAILS_API.replace(':workspaceSlug', params.workspaceSlug)}/search/memberships/stats`;
  const body = {
    filter: params.filter,
  };
  return request.post(url, body);
};

export const exportWorkspaceMemberships = (params: any) => {
  const body = {
    ...params.filter,
  };
  const url = `${WORKSPACE_MEMBERSHIPS_API.replace(':workspaceSlug', params.workspaceSlug)}/export`;
  return request.post(url, body);
};

export const fetchMembershipDetails = (queryKey: string, membershipId: string) => {
  const url = MEMBERSHIP_DETAILS_API.replace(':membershipId', membershipId);
  return request.get(url);
};

export const fetchMembershipCurrentStreak = (queryKey: string, membershipId: string) => {
  const url = MEMBERSHIP_CURRENT_STREAK_API.replace(':membershipId', membershipId);
  return request.get(url);
};

export const fetchMembershipReviewLink = (queryKey: string, membershipId: string) => {
  const url = MEMBERSHIP_REVIEW_API.replace(':membershipId', membershipId);
  return request.get(url);
};

export const fetchMembershipPendingCheckins = (
  queryKey: string,
  membershipId: string,
  showContributing: boolean,
  params: { limit: number },
  page: number,
) => {
  const url = MEMBERSHIP_PENDING_CHECKINS_API.replace(':membershipId', membershipId);
  return request.get(url, {
    params: {
      page,
      is_contributing: showContributing,
      limit: params.limit,
    },
  });
};

export const fetchMembershipActiveOutcomes = (
  queryKey: string,
  membershipId: string,
  showContributing: boolean,
  params: { limit: number },
  page: number,
) => {
  const url = MEMBERSHIP_ACTIVE_OUTCOMES_API.replace(':membershipId', membershipId);
  return request.get(url, {
    params: {
      page,
      is_pending_checkin: false,
      is_contributing: showContributing,
      limit: params.limit,
    },
  });
};

export const fetchMembershipActiveInitiatives = (
  queryKey: string,
  membershipId: string,
  showContributing: boolean,
  page: number,
) => {
  const url = MEMBERSHIP_ACTIVE_INITIATIVES_API.replace(':membershipId', membershipId);
  return request.get(url, {
    params: {
      page,
      is_contributing: showContributing,
    },
  });
};

export const fetchMembershipTeams = (queryKey: string, membershipId: string) => {
  const url = MEMBERSHIP_TEAMS_API.replace(':membershipId', membershipId);
  return request.get(url);
};

export const fetchMembershipManager = (queryKey: string, _: string, membershipId: string) => {
  const url = MEMBERSHIP_MANAGER_API.replace(':membershipId', membershipId);
  return request.get(url);
};
export const fetchMembershipDirectReports = (queryKey: string, _: string, membershipId: string) => {
  const url = MEMBERSHIP_DIRECT_REPORTS_API.replace(':membershipId', membershipId);
  return request.get(url);
};

export const createWorkspaceMemberships = (params: any) => {
  const body = {
    emails: params.emails,
  };
  const url = WORKSPACE_MEMBERSHIPS_API.replace(':workspaceSlug', params.workspaceSlug);
  return request.post(url, body);
};

export const createWorkspaceFreeMemberships = (params: any) => {
  const body = {
    emails: params.emails,
  };
  const url = `${WORKSPACE_MEMBERSHIPS_API.replace(':workspaceSlug', params.workspaceSlug)}/free`;
  return request.post(url, body);
};

export const updateMembership = (params: any) => {
  const url = MEMBERSHIP_DETAILS_API.replace(':membershipId', params.membershipId);
  const body = {
    membership: { ...params.membership },
  };
  return request.put(url, body);
};

export const updateManager = (params: any) => {
  const url = `${MEMBERSHIP_DETAILS_API.replace(':membershipId', params.membershipId)}/manager`;
  const body = {
    membership: { ...params.membership },
  };
  return request.put(url, body);
};

export const joinMembership = (params: any) => {
  const body = {
    invite_link_token: params.invite_link_token,
  };
  const url = `${MEMBERSHIPS_API}/join`;

  return request.post(url, body);
};

export const deleteMembership = (membershipId: string) => {
  const url = MEMBERSHIP_DETAILS_API.replace(':membershipId', membershipId);
  return request.delete(url);
};

// INTEGRATIONS APIS ====================================================

export const fetchIntegrations = (queryKey: string, workspaceSlug: string) => {
  const url = INTEGRATIONS_API.replace(':workspaceSlug', workspaceSlug);
  return request.get(url);
};

// NOTIFICATIONS APIS ====================================================

export const fetchNotifications = (queryKey: string, workspaceSlug: string, params: any, page: number) => {
  const url = NOTIFICATIONS_API.replace(':workspaceSlug', workspaceSlug);
  return request.get(url, {
    params: {
      page,
      limit: params.limit,
    },
  });
};

export const bulkUpdateNotifications: any = (params: any) => {
  const url = NOTIFICATIONS_API.replace(':workspaceSlug', params.workspaceSlug);
  const body = {
    bulk_action: params.bulk_action,
  };
  return request.put(url, body);
};

export const updateNotification = (params: any) => {
  const url = NOTIFICATION_DETAILS_API.replace(':notificationId', params.notificationId);
  const body = {
    notification: { ...params.notification },
  };
  return request.put(url, body);
};

// ATTACHMENT APIS ====================================================

export const createAttachment: any = (params: any) => {
  const url = ATTACHMENTS_API.replace(':workspaceSlug', params.workspaceSlug);
  const formData = new FormData();
  formData.append('image', params.image);
  return request.post(url, formData, {
    ignoreJsonHeaders: true,
  });
};

// DASHBOARDS APIS =========================================================

export const fetchDashboards = (queryKey: string, workspaceSlug: string, filters: any = {}, page: number) => {
  const params = {
    ...filters,
    page,
  };
  return request.get(DASHBOARDS_API.replace(':workspaceSlug', workspaceSlug), { params });
};

export const searchDashboards = (queryKey: string, _: string, params: any = {}, page: number) => {
  const url = `${WORKSPACE_DETAILS_API.replace(':workspaceSlug', params.workspaceSlug)}/search/dashboards`;
  const body = {
    page,
    filter: params.filter,
    limit: params.limit,
  };
  return request.post(url, body);
};

export const createDashboard = (params: any) => {
  const url = DASHBOARDS_API.replace(':workspaceSlug', params.workspaceSlug);
  const body = {
    dashboard: { ...params.dashboard },
  };
  return request.post(url, body);
};

export const fetchDashboardDetails = (queryKey: string, dashboardId: string) => {
  const url = DASHBOARD_DETAILS_API.replace(':dashboardId', dashboardId);
  return request.get(url);
};

export const updateDashboard = (params: any) => {
  const url = DASHBOARD_DETAILS_API.replace(':dashboardId', params.dashboardId);
  const body = {
    dashboard: { ...params.dashboard },
  };
  return request.put(url, body);
};

export const deleteDashboard = (dashboardId: string) => {
  const url = DASHBOARD_DETAILS_API.replace(':dashboardId', dashboardId);
  return request.delete(url);
};

export const fetchDashboardWidgets = (queryKey: string, dashboardId: string) => {
  return request.get(DASHBOARD_WIDGETS_API.replace(':dashboardId', dashboardId));
};

export const createDashboardWidget = (params: any) => {
  const url = DASHBOARD_WIDGETS_API.replace(':dashboardId', params.dashboardId);
  const body = {
    dashboard_widget: { ...params.dashboard_widget },
  };
  return request.post(url, body);
};

export const updateDashboardWidget = (params: any) => {
  const url = DASHBOARD_WIDGET_DETAILS_API.replace(':dashboardWidgetId', params.dashboardWidgetId);
  const body = {
    dashboard_widget: { ...params.dashboard_widget },
  };
  return request.put(url, body);
};

export const deleteDashboardWidget = (dashboardWidgetId: string) => {
  const url = DASHBOARD_WIDGET_DETAILS_API.replace(':dashboardWidgetId', dashboardWidgetId);
  return request.delete(url);
};

// DASHBOARD FAVORITES =======================
export const createDashboardFavorite = (params: any) => {
  const url = MEMBERSHIP_DASHBOARD_FAVORITES_API.replace(':membershipId', params.membershipId);
  const body = {
    dashboard_id: params.dashboardId,
  };
  return request.post(url, body);
};

export const deleteDashboardFavorite = (params: { dashboardId: string; membershipId: string }) => {
  // /v1/dashboards/:dashboard_id/memberships/:membership_id/favorites
  const url = `${DASHBOARD_DETAILS_API.replace(':dashboardId', params.dashboardId)}/memberships/${
    params.membershipId
  }/favorites`;
  return request.delete(url);
};
// TEAMS APIS ==================================================

export const fetchWorkspaceTeams = (queryKey: string, workspaceSlug: string, filters: any = {}, page: number) => {
  const params = {
    ...filters,
    page,
  };

  return request.get(TEAMS_API.replace(':workspaceSlug', workspaceSlug), { params });
};

export const createTeam = (params: any) => {
  const url = TEAMS_API.replace(':workspaceSlug', params.workspaceSlug);
  const body = {
    team: { ...params.team },
  };
  return request.post(url, body);
};

export const fetchTeamDetails = (queryKey: string, teamId: string) => {
  const url = TEAM_DETAILS_API.replace(':teamId', teamId);
  return request.get(url);
};

export const updateTeam = (params: any) => {
  const url = TEAM_DETAILS_API.replace(':teamId', params.teamId);
  const body = {
    team: { ...params.team },
  };
  return request.put(url, body);
};

export const deleteTeam = (teamId: string) => {
  const url = TEAM_DETAILS_API.replace(':teamId', teamId);
  return request.delete(url);
};

export const searchTeams = (queryKey: string, _: string, params: any, page: number) => {
  const url = `${WORKSPACE_DETAILS_API.replace(':workspaceSlug', params.workspaceSlug)}/search/teams`;
  const body = {
    page,
    ...params,
  };
  return request.post(url, body);
};

// TEAM MEMBERS APIS ====================================================

export const fetchTeamMembers = (queryKey: string, teamId: string) => {
  const url = TEAM_MEMBERS_API.replace(':teamId', teamId);
  return request.get(url);
};

export const createTeamMember: any = (params: any) => {
  const url = TEAM_MEMBERS_API.replace(':teamId', params.teamId);
  const body = {
    team_member: { ...params.teamMember },
  };
  return request.post(url, body);
};

export const fetchTeamMemberDetails = (queryKey: string, teamMemberId: string) => {
  const url = TEAM_MEMBER_DETAILS_API.replace(':teamMemberId', teamMemberId);
  return request.get(url);
};

export const updateTeamMember = (params: any) => {
  const url = TEAM_MEMBER_DETAILS_API.replace(':teamMemberId', params.teamMemberId);
  const body = {
    team_member: { ...params.teamMember },
  };
  return request.put(url, body);
};

export const deleteTeamMember = (teamMemberId: string) => {
  const url = TEAM_MEMBER_DETAILS_API.replace(':teamMemberId', teamMemberId);
  return request.delete(url);
};

// PLAN RECOMMENDATIONS =========================================
export const fetchPlanRecommendations = (queryKey: string, planId: string, params: any, page?: number) => {
  const url = PLAN_RECOMMENDATIONS_API.replace(':planId', planId);
  return request.get(url, {
    params: {
      page,
      filter: params.filter,
    },
  });
};

export const updateRecommendation = (params: any) => {
  const url = PLAN_RECOMMENDATION_DETAILS_API.replace(':recommendationId', params.recommendationId);
  const body = {
    status: params.status,
  };
  return request.put(url, body);
};

// PLAN APIS ====================================================

export const fetchPlans = (queryKey: string, workspaceSlug: string, params: any, page?: number) => {
  const url = PLANS_API.replace(':workspaceSlug', workspaceSlug);
  return request.get(url, {
    params: {
      page,
      limit: params.limit,
      filter: params.filter,
      order_direction: params.order_direction,
      order_attribute: params.order_attribute,
    },
  });
};

export const createPlan: any = (params: any) => {
  const url = PLANS_API.replace(':workspaceSlug', params.workspaceSlug);
  const body = {
    plan: { ...params.plan },
  };
  return request.post(url, body);
};

export const fetchPlanDetails = (queryKey: string, planId: string) => {
  const url = PLAN_DETAILS_API.replace(':planId', planId);
  return request.get(url);
};

export const fetchPlanTrends = (queryKey: string, planId: string) => {
  const url = `${PLAN_DETAILS_API.replace(':planId', planId)}/trends`;
  return request.get(url);
};

export const fetchPlanTrendsPublic = (queryKey: string, planId: string) => {
  const url = `${PLAN_DETAILS_API.replace(':planId', planId)}/trends/public`;
  return request.get(url);
};

export const fetchPlanCheckinsTrends = (queryKey: string, planId: string) => {
  const url = `${PLAN_DETAILS_API.replace(':planId', planId)}/checkins_trends`;
  return request.get(url);
};

export const downloadPlanPDF = (planId: string) => {
  const url = PLAN_DOWNLOAD_PDF_API.replace(':planId', planId);
  return request.get(url, {
    responseType: 'blob',
  });
};

export const updatePlan = (params: any) => {
  const url = PLAN_DETAILS_API.replace(':planId', params.planId);
  const body = {
    plan: { ...params.plan },
  };
  return request.put(url, body);
};

export const fillFromCSV = (params: any) => {
  const url = `${PLAN_DETAILS_API.replace(':planId', params.planId)}/import_csv`;
  const formData = new FormData();
  const { file } = params;
  formData.append('file', file);
  return request.put(url, formData, { ignoreJsonHeaders: true });
};

export const fillFromText = (params: any) => {
  const url = `${PLAN_DETAILS_API.replace(':planId', params.planId)}/import_text`;
  const body = {
    text: params.text,
  };
  return request.put(url, body);
};

export const populatePlan = (params: any) => {
  const url = `${PLAN_DETAILS_API.replace(':planId', params.planId)}/populate`;
  const body = {
    template: params.template,
  };
  return request.put(url, body);
};

export const populatePlanV2 = (params: any) => {
  const url = `${PLAN_DETAILS_API.replace(':planId', params.planId)}/populate_v2`;
  const body = {
    template: params.template,
  };
  return request.put(url, body);
};

export const populatePlanYAML = (params: any) => {
  const url = `${PLAN_DETAILS_API.replace(':planId', params.planId)}/populate_yaml`;
  const body = {
    yaml: params.yaml,
  };
  return request.put(url, body);
};

export const populateAIPlan = (params: any) => {
  const url = `${PLAN_DETAILS_API.replace(':planId', params.planId)}/populate_ai`;
  const body = {
    objective_description: params.objective_description,
  };
  return request.put(url, body);
};

export const populateAIYMLPlan = (params: any) => {
  const url = `${PLAN_DETAILS_API.replace(':planId', params.planId)}/populate_ai_yml`;
  const body = {
    ai_yml: params.ai_yml,
  };
  return request.put(url, body);
};

export const previewAIPlan = (params: any) => {
  const url = `${PLAN_DETAILS_API.replace(':planId', params.planId)}/preview_ai`;
  const body = {
    objective_description: params.objective_description,
  };
  return request.post(url, body);
};

export const analyseAIPlan = (planId: string) => {
  const url = `${PLAN_DETAILS_API.replace(':planId', planId)}/analyse_ai`;
  return request.get(url);
};

export const createAiThreadForOutcomes = (params: any) => {
  const url = `${AI_THREADS_API.replace(':workspaceSlug', params.workspaceSlug)}`;
  const body = {
    content: params.content,
  };
  return request.post(url, body);
};

export const createAiThreadForInitiatives = (params: any) => {
  const url = `${AI_THREADS_INITIATIVES_API.replace(':outcomeId', params.outcomeId)}`;
  const body = {
    content: params.content,
  };
  return request.post(url, body);
};

export const postAiThreadMessage = (params: any) => {
  const url = `${AI_THREADS_DETAILS_API.replace(':aiThreadId', params.aiThreadId)}`;
  const body = {
    content: params.content,
  };
  return request.put(url, body);
};

export const copyPlan = (planId: string) => {
  const url = `${PLAN_DETAILS_API.replace(':planId', planId)}/copy`;
  return request.post(url);
};

export const deletePlan = (planId: string) => {
  const url = PLAN_DETAILS_API.replace(':planId', planId);
  return request.delete(url);
};

export const archivePlan = (params: any) => {
  const url = `${PLAN_DETAILS_API.replace(':planId', params.planId)}/archive`;
  const body = {
    plan: { ...params.plan },
  };
  return request.put(url, body);
};

export const searchPlans = (queryKey: string, _: string, params: any, page: number) => {
  const url = `${WORKSPACE_DETAILS_API.replace(':workspaceSlug', params.workspaceSlug)}/search/plans`;
  const body = {
    page,
    filter: params.filter,
  };
  return request.post(url, body);
};

// Get the stats corresponding to the search filters
export const fetchPlansStats = (queryKey: string, _: string, params: any) => {
  const url = `${WORKSPACE_DETAILS_API.replace(':workspaceSlug', params.workspaceSlug)}/search/plans/stats`;
  const body = {
    filter: params.filter,
  };
  return request.post(url, body);
};

export const exportPlans = (params: any) => {
  const url = `${WORKSPACE_DETAILS_API.replace(':workspaceSlug', params.workspaceSlug)}/search/plans/export`;
  const body = {
    filter: params.filter,
  };
  return request.post(url, body);
};

export const sharePlan = (params: any) => {
  const url = `${PLAN_DETAILS_API.replace(':planId', params.planId)}/share`;
  const body = { ...params.share };
  return request.post(url, body);
};

// REVIEWER APIS ====================================================

export const fetchPlanReviewers = (queryKey: string, planId: string) => {
  const url = PLAN_REVIEWERS_API.replace(':planId', planId);
  return request.get(url);
};

export const createReviewer: any = (params: any) => {
  const url = PLAN_REVIEWERS_API.replace(':planId', params.planId);
  const body = {
    reviewer: { ...params.reviewer },
  };
  return request.post(url, body);
};

export const fetchReviewerDetails = (queryKey: string, reviewerId: string) => {
  const url = REVIEWER_DETAILS_API.replace(':reviewerId', reviewerId);
  return request.get(url);
};

export const updateReviewer = (params: any) => {
  const url = REVIEWER_DETAILS_API.replace(':reviewerId', params.reviewerId);
  const body = {
    reviewer: { ...params.reviewer },
  };
  return request.put(url, body);
};

export const deleteReviewer = (reviewerId: string) => {
  const url = REVIEWER_DETAILS_API.replace(':reviewerId', reviewerId);
  return request.delete(url);
};

// PLAN PERMISSIONS APIS ====================================================

export const fetchPlanPermissions = (queryKey: string, planId: string) => {
  const url = PLAN_PERMISSIONS_API.replace(':planId', planId);
  return request.get(url);
};

export const createPlanPermission: any = (params: any) => {
  const url = PLAN_PERMISSIONS_API.replace(':planId', params.planId);
  const body = {
    plan_permission: { ...params.plan_permission },
  };
  return request.post(url, body);
};

export const fetchPlanPermissionDetails = (queryKey: string, planPermissionId: string) => {
  const url = PLAN_PERMISSION_DETAILS_API.replace(':planPermissionId', planPermissionId);
  return request.get(url);
};

export const updatePlanPermission = (params: any) => {
  const url = PLAN_PERMISSION_DETAILS_API.replace(':planPermissionId', params.planPermissionId);
  const body = {
    plan_permission: { ...params.plan_permission },
  };
  return request.put(url, body);
};

export const deletePlanPermission = (planPermissionId: string) => {
  const url = PLAN_PERMISSION_DETAILS_API.replace(':planPermissionId', planPermissionId);
  return request.delete(url);
};

// OUTCOME CONTRIBUTORS APIS ====================================================

export const fetchOutcomeContributors = (queryKey: string, outcomeId: string) => {
  const url = OUTCOME_CONTRIBUTORS_API.replace(':outcomeId', outcomeId);
  return request.get(url);
};

export const createOutcomeContributor: any = (params: any) => {
  const url = OUTCOME_CONTRIBUTORS_API.replace(':outcomeId', params.outcomeId);
  const body = {
    outcome_contributor: { ...params.outcome_contributor },
  };
  return request.post(url, body);
};

export const fetchOutcomeContributorDetails = (queryKey: string, outcomeContributorId: string) => {
  const url = OUTCOME_CONTRIBUTOR_DETAILS_API.replace(':outcomeContributorId', outcomeContributorId);
  return request.get(url);
};

export const updateOutcomeContributor = (params: any) => {
  const url = OUTCOME_CONTRIBUTOR_DETAILS_API.replace(':outcomeContributorId', params.outcomeContributorId);
  const body = {
    outcome_contributor: { ...params.outcome_contributor },
  };
  return request.put(url, body);
};

export const deleteOutcomeContributor = (outcomeContributorId: string) => {
  const url = OUTCOME_CONTRIBUTOR_DETAILS_API.replace(':outcomeContributorId', outcomeContributorId);
  return request.delete(url);
};

// TEAM RELATIONSHIPS APIS ====================================================

export const fetchOutcomeTeamRelationships = (queryKey: string, outcomeId: string) => {
  const url = OUTCOME_TEAM_RELATIONSHIPS_API.replace(':outcomeId', outcomeId);
  return request.get(url);
};

export const createOutcomeTeamRelationship: any = (params: any) => {
  const url = OUTCOME_TEAM_RELATIONSHIPS_API.replace(':outcomeId', params.outcomeId);
  const body = {
    team_relationship: { ...params.team_relationship },
  };
  return request.post(url, body);
};

export const fetchPlanTeamRelationships = (queryKey: string, planId: string) => {
  const url = PLAN_TEAM_RELATIONSHIPS_API.replace(':planId', planId);
  return request.get(url);
};

export const createPlanTeamRelationship: any = (params: any) => {
  const url = PLAN_TEAM_RELATIONSHIPS_API.replace(':planId', params.planId);
  const body = {
    team_relationship: { ...params.team_relationship },
  };
  return request.post(url, body);
};

export const fetchTeamRelationshipDetails = (queryKey: string, teamRelationshipId: string) => {
  const url = TEAM_RELATIONSHIP_DETAILS_API.replace(':teamRelationshipId', teamRelationshipId);
  return request.get(url);
};

export const updateTeamRelationship = (params: any) => {
  const url = TEAM_RELATIONSHIP_DETAILS_API.replace(':teamRelationshipId', params.teamRelationshipId);
  const body = {
    team_relationship: { ...params.team_relationship },
  };
  return request.put(url, body);
};

export const deleteTeamRelationship = (teamRelationshipId: string) => {
  const url = TEAM_RELATIONSHIP_DETAILS_API.replace(':teamRelationshipId', teamRelationshipId);
  return request.delete(url);
};

// OUTCOME RELATIONSHIPS APIS ====================================================

export const fetchOutcomeRelationships = (queryKey: string, outcomeId: string) => {
  const url = OUTCOME_RELATIONSHIPS_API.replace(':outcomeId', outcomeId);
  return request.get(url);
};

export const createOutcomeRelationship: any = (params: any) => {
  const url = OUTCOME_RELATIONSHIPS_API.replace(':outcomeId', params.outcomeId);
  const body = {
    outcome_relationship: { ...params.outcome_relationship },
  };
  return request.post(url, body);
};

export const deleteOutcomeRelationship = (outcomeRelationshipId: string) => {
  const url = OUTCOME_RELATIONSHIP_DETAILS_API.replace(':outcomeRelationshipId', outcomeRelationshipId);
  return request.delete(url);
};

// RELATIONSHIPS APIS ====================================================

export const fetchRelationshipsForOutcome = (queryKey: string, outcomeId: string, filter: string) => {
  const url = `${RELATIONSHIPS_OUTCOME_API.replace(':outcomeId', outcomeId)}?filter=${filter}`;
  return request.get(url);
};

export const createRelationshipForOutcome: any = (params: any) => {
  const url = RELATIONSHIPS_OUTCOME_API.replace(':outcomeId', params.outcomeId);
  const body = {
    relationship: { ...params.relationship },
  };
  return request.post(url, body);
};

export const fetchRelationshipsForObjective = (queryKey: string, objectiveId: string, filter: string) => {
  const url = `${RELATIONSHIPS_OBJECTIVE_API.replace(':objectiveId', objectiveId)}?filter=${filter}`;
  return request.get(url);
};

export const createRelationshipForObjective: any = (params: any) => {
  const url = RELATIONSHIPS_OBJECTIVE_API.replace(':objectiveId', params.objectiveId);
  const body = {
    relationship: { ...params.relationship },
  };
  return request.post(url, body);
};

export const deleteRelationship = (relationshipId: string) => {
  const url = RELATIONSHIP_DETAILS_API.replace(':relationshipId', relationshipId);
  return request.delete(url);
};

// FOLLOWERS APIS ====================================================

export const fetchFollowers = (queryKey: string, membershipId: string, params: any, page?: number) => {
  const url = FOLLOWERS_API.replace(':membershipId', membershipId);
  return request.get(url, {
    params: {
      page,
      filter: params.filter,
    },
  });
};

export const fetchFollowing = (queryKey: string, membershipId: string, params: any, page?: number) => {
  const url = FOLLOWING_API.replace(':membershipId', membershipId);
  return request.get(url, {
    params: {
      page,
      filter: params.filter,
    },
  });
};

export const createFollower = (membershipId: string) => {
  const url = FOLLOWERS_API.replace(':membershipId', membershipId);
  return request.post(url);
};

export const deleteFollower = (followerId: string) => {
  const url = FOLLOWER_DETAILS_API.replace(':followerId', followerId);
  return request.delete(url);
};

// INITIATIVE CONTRIBUTORS APIS ====================================================

export const fetchInitiativeContributors = (queryKey: string, initiativeId: string) => {
  const url = INITIATIVE_CONTRIBUTORS_API.replace(':initiativeId', initiativeId);
  return request.get(url);
};

export const createInitiativeContributor: any = (params: any) => {
  const url = INITIATIVE_CONTRIBUTORS_API.replace(':initiativeId', params.initiativeId);
  const body = {
    initiative_contributor: { ...params.initiative_contributor },
  };
  return request.post(url, body);
};

export const fetchInitiativeContributorDetails = (queryKey: string, initiativeContributorId: string) => {
  const url = INITIATIVE_CONTRIBUTOR_DETAILS_API.replace(':initiativeContributorId', initiativeContributorId);
  return request.get(url);
};

export const updateInitiativeContributor = (params: any) => {
  const url = INITIATIVE_CONTRIBUTOR_DETAILS_API.replace(':initiativeContributorId', params.initiativeContributorId);
  const body = {
    initiative_contributor: { ...params.initiative_contributor },
  };
  return request.put(url, body);
};

export const deleteInitiativeContributor = (initiativeContributorId: string) => {
  const url = INITIATIVE_CONTRIBUTOR_DETAILS_API.replace(':initiativeContributorId', initiativeContributorId);
  return request.delete(url);
};

// OBJECTIVE APIS ====================================================

export const fetchPlanObjectives = (queryKey: string, planId: string) => {
  const url = PLAN_OBJECTIVES_API.replace(':planId', planId);
  return request.get(url);
};

export const createObjective: any = (params: any) => {
  const url = PLAN_OBJECTIVES_API.replace(':planId', params.planId);
  const body = {
    objective: { ...params.objective },
  };
  return request.post(url, body);
};

export const fetchObjectiveDetails = (queryKey: string, objectiveId: string) => {
  const url = OBJECTIVE_DETAILS_API.replace(':objectiveId', objectiveId);
  return request.get(url);
};

export const fetchObjectiveTrends = (queryKey: string, objectiveId: string) => {
  const url = `${OBJECTIVE_DETAILS_API.replace(':objectiveId', objectiveId)}/trends`;
  return request.get(url);
};

export const updateObjective = (params: any) => {
  const url = OBJECTIVE_DETAILS_API.replace(':objectiveId', params.objectiveId);
  const body = {
    objective: { ...params.objective },
  };
  return request.put(url, body);
};

export const deleteObjective = (objectiveId: string) => {
  const url = OBJECTIVE_DETAILS_API.replace(':objectiveId', objectiveId);
  return request.delete(url);
};

export const searchObjectives = (queryKey: string, _: string, params: any, page: number) => {
  const url = `${WORKSPACE_DETAILS_API.replace(':workspaceSlug', params.workspaceSlug)}/search/objectives`;
  const body = {
    page,
    filter: params.filter,
  };
  return request.post(url, body);
};

export const fetchObjectivesStats = (queryKey: string, _: string, params: any) => {
  const url = `${WORKSPACE_DETAILS_API.replace(':workspaceSlug', params.workspaceSlug)}/search/objectives/stats`;
  const body = {
    filter: params.filter,
  };
  return request.post(url, body);
};

export const archiveObjective = (params: any) => {
  const url = `${OBJECTIVE_DETAILS_API.replace(':objectiveId', params.objectiveId)}/archive`;
  const body = {
    objective: { ...params.objective },
  };
  return request.put(url, body);
};

export const exportObjectives = (params: any) => {
  const url = `${WORKSPACE_DETAILS_API.replace(':workspaceSlug', params.workspaceSlug)}/search/objectives/export`;
  const body = {
    filter: params.filter,
  };
  return request.post(url, body);
};

export const shareObjective = (params: any) => {
  const url = `${OBJECTIVE_DETAILS_API.replace(':objectiveId', params.objectiveId)}/share`;
  const body = { ...params.share };
  return request.post(url, body);
};

// OUTCOME APIS ====================================================

export const fetchPlanOutcomes = (queryKey: string, planId: string) => {
  const url = PLAN_OUTCOMES_API.replace(':planId', planId);
  return request.get(url);
};

export const fetchOutcomes = (queryKey: string, objectiveId: string) => {
  const url = OUTCOMES_API.replace(':objectiveId', objectiveId);
  return request.get(url);
};

export const createOutcome: any = (params: any) => {
  const url = OUTCOMES_API.replace(':objectiveId', params.objectiveId);
  const body = {
    outcome: { ...params.outcome },
  };
  return request.post(url, body);
};

export const updateOutcomeMetric = (params: any) => {
  const url = `${OUTCOME_DETAILS_API.replace(':outcomeId', params.outcomeId)}/update_metric`;
  return request.put(url);
};

export const populateAIOutcome: any = (params: { outcomeId: string; outcomeDescription: string }) => {
  const url = `${OUTCOME_DETAILS_API.replace(':outcomeId', params.outcomeId)}/populate_ai`;
  const body = {
    id: params.outcomeId,
    outcome_description: params.outcomeDescription,
  };

  return request.put(url, body);
};

export const populateAIYMLOutcome = (params: any) => {
  const url = `${OUTCOME_DETAILS_API.replace(':outcomeId', params.outcomeId)}/populate_ai_yml`;
  const body = {
    ai_yml: params.ai_yml,
  };
  return request.put(url, body);
};

export const generateAnalysisOutcome = (params: { outcomeId: string }) => {
  const url = `${OUTCOME_DETAILS_API.replace(':outcomeId', params.outcomeId)}/analysis`;
  return request.post(url);
};

export const deleteAnalysisOutcome = (outcomeId: string) => {
  const url = `${OUTCOME_DETAILS_API.replace(':outcomeId', outcomeId)}/analysis`;
  return request.delete(url);
};

export const fetchOutcomeDetails = (queryKey: string, outcomeId: string) => {
  const url = OUTCOME_DETAILS_API.replace(':outcomeId', outcomeId);
  return request.get(url);
};

export const updateOutcome = (params: any) => {
  const url = OUTCOME_DETAILS_API.replace(':outcomeId', params.outcomeId);
  const body = {
    outcome: { ...params.outcome },
  };
  return request.put(url, body);
};

export const syncOutcomeDataSource = (params: any) => {
  const url = `${OUTCOME_DETAILS_API.replace(':outcomeId', params.outcomeId)}/data_source`;
  return request.post(url);
};

export const updateOutcomeDataSource = (params: any) => {
  const url = `${OUTCOME_DETAILS_API.replace(':outcomeId', params.outcomeId)}/data_source`;
  const body = {
    ...params.body,
  };
  return request.put(url, body);
};

export const fetchOutcomeDataSourceCurrentValue = (queryKey: string, outcomeId: string) => {
  const url = `${OUTCOME_DETAILS_API.replace(':outcomeId', outcomeId)}/data_source/current`;
  return request.get(url);
};

export const deleteOutcomeDataSource = (outcomeId: string) => {
  const url = `${OUTCOME_DETAILS_API.replace(':outcomeId', outcomeId)}/data_source`;
  return request.delete(url);
};

export const manageOutcome = (params: any) => {
  const url = OUTCOME_MANAGE_API.replace(':outcomeId', params.outcomeId);
  const body = {
    outcome: { ...params.outcome },
  };
  return request.put(url, body);
};

export const deleteOutcome = (outcomeId: string) => {
  const url = OUTCOME_DETAILS_API.replace(':outcomeId', outcomeId);
  return request.delete(url);
};

export const searchOutcomes: any = (queryKey: string, _: string, params: any, page: number) => {
  const url = `${WORKSPACE_DETAILS_API.replace(':workspaceSlug', params.workspaceSlug)}/search/outcomes`;
  const body = {
    page,
    filter: params.filter,
    limit: params.limit,
  };
  return request.post(url, body);
};

export const searchOutcomesForMutation = (params: any) => {
  const url = `${WORKSPACE_DETAILS_API.replace(':workspaceSlug', params.workspaceSlug)}/search/outcomes`;
  const body = {
    page: params.page,
    filter: params.filter,
    limit: params.limit,
  };
  return request.post(url, body);
};

// Get the stats corresponding to the search filters
export const fetchOutcomesStats = (queryKey: string, _: string, params: any) => {
  const url = `${WORKSPACE_DETAILS_API.replace(':workspaceSlug', params.workspaceSlug)}/search/outcomes/stats`;
  const body = {
    filter: params.filter,
  };
  return request.post(url, body);
};

export const exportOutcomes: any = (params: any) => {
  const url = `${WORKSPACE_DETAILS_API.replace(':workspaceSlug', params.workspaceSlug)}/search/outcomes/export`;
  const body = {
    filter: params.filter,
  };
  return request.post(url, body);
};

export const archiveOutcome = (params: any) => {
  const url = `${OUTCOME_DETAILS_API.replace(':outcomeId', params.outcomeId)}/archive`;
  const body = {
    outcome: { ...params.outcome },
  };
  return request.put(url, body);
};

export const shareOutcome = (params: any) => {
  const url = `${OUTCOME_DETAILS_API.replace(':outcomeId', params.outcomeId)}/share`;
  const body = { ...params.share };
  return request.post(url, body);
};

// INITIATIVE APIS ====================================================

export const fetchPlanInitiatives = (queryKey: string, planId: string, params: any = {}) => {
  const url = PLAN_INITIATIVES_API.replace(':planId', planId);
  return request.get(url, { params });
};

export const fetchInitiatives = (queryKey: string, outcomeId: string) => {
  const url = INITIATIVES_API.replace(':outcomeId', outcomeId);
  return request.get(url);
};

export const searchInitiatives: any = (queryKey: string, _: string, params: any, page: number) => {
  const url = `${WORKSPACE_DETAILS_API.replace(':workspaceSlug', params.workspaceSlug)}/search/initiatives`;
  const body = {
    page,
    filter: params.filter,
    limit: params.limit,
  };
  return request.post(url, body);
};

export const searchInitiativesForMutation: any = (params: any) => {
  const url = `${WORKSPACE_DETAILS_API.replace(':workspaceSlug', params.workspaceSlug)}/search/initiatives`;
  const body = {
    page: params.page,
    filter: params.filter,
    limit: params.limit,
  };
  return request.post(url, body);
};

// Get the stats corresponding to the search filters
export const fetchInitiativesStats = (queryKey: string, _: string, params: any) => {
  const url = `${WORKSPACE_DETAILS_API.replace(':workspaceSlug', params.workspaceSlug)}/search/initiatives/stats`;
  const body = {
    filter: params.filter,
  };
  return request.post(url, body);
};

export const createInitiative = (params: any) => {
  const url = INITIATIVES_API.replace(':outcomeId', params.outcomeId);
  const body = {
    initiative: { ...params.initiative },
  };
  return request.post(url, body);
};

export const exportInitiatives: any = (params: any) => {
  const url = `${WORKSPACE_DETAILS_API.replace(':workspaceSlug', params.workspaceSlug)}/search/initiatives/export`;
  const body = {
    filter: params.filter,
  };
  return request.post(url, body);
};

export const fetchInitiativeDetails = (queryKey: string, initiativeId: string) => {
  const url = INITIATIVE_DETAILS_API.replace(':initiativeId', initiativeId);
  return request.get(url);
};

export const updateInitiative = (params: any) => {
  const url = INITIATIVE_DETAILS_API.replace(':initiativeId', params.initiativeId);
  const body = {
    initiative: { ...params.initiative },
  };
  return request.put(url, body);
};

export const archiveInitiative = (params: any) => {
  const url = `${INITIATIVE_DETAILS_API.replace(':initiativeId', params.initiativeId)}/archive`;
  const body = {
    initiative: { ...params.initiative },
  };
  return request.put(url, body);
};

export const bulkArchiveInitiativesForPlan = (params: any) => {
  const url = `${PLAN_INITIATIVES_API.replace(':planId', params.planId)}/archive`;
  const body = {
    filter: params.filter,
    initiative: { ...params.initiative },
  };
  return request.put(url, body);
};

export const bulkArchiveInitiativesForOutcome = (params: any) => {
  const url = `${INITIATIVES_API.replace(':planId', params.outcomeId)}/archive`;
  const body = {
    filter: params.filter,
    initiative: { ...params.initiative },
  };
  return request.put(url, body);
};

export const deleteInitiative = (initiativeId: string) => {
  const url = INITIATIVE_DETAILS_API.replace(':initiativeId', initiativeId);
  return request.delete(url);
};

export const shareInitiative = (params: any) => {
  const url = `${INITIATIVE_DETAILS_API.replace(':initiativeId', params.initiativeId)}/share`;
  const body = { ...params.share };
  return request.post(url, body);
};

// SEGMENT APIS ==========================================================
export const fetchSegments = (queryKey: string, workspaceSlug: string, segmentable_type: string, filter: any) => {
  const url = SEGMENTS_API.replace(':workspaceSlug', workspaceSlug).replace(':segmentable_type', segmentable_type);
  return request.get(url, { params: { filter } });
};
export const fetchOutcomeSegments = (queryKey: string, workspaceSlug: string, filter: any) => {
  const url = SEGMENTS_API.replace(':workspaceSlug', workspaceSlug).replace(':segmentable_type', 'outcome');
  return request.get(url, { params: { filter } });
};
export const fetchInitiativeSegments = (queryKey: string, workspaceSlug: string, filter: any) => {
  const url = SEGMENTS_API.replace(':workspaceSlug', workspaceSlug).replace(':segmentable_type', 'initiative');
  return request.get(url, { params: { filter } });
};
export const fetchObjectiveSegments = (queryKey: string, workspaceSlug: string, filter: any) => {
  const url = SEGMENTS_API.replace(':workspaceSlug', workspaceSlug).replace(':segmentable_type', 'objective');
  return request.get(url, { params: { filter } });
};
export const fetchMapSegments = (queryKey: string, workspaceSlug: string, filter: any) => {
  const url = SEGMENTS_API.replace(':workspaceSlug', workspaceSlug).replace(':segmentable_type', 'map');
  return request.get(url, { params: { filter } });
};
export const createOutcomeSegment = (params: { workspaceSlug: string; filterHash: string; title: string }) => {
  const url = SEGMENTS_API.replace(':workspaceSlug', params.workspaceSlug).replace(':segmentable_type', 'outcome');
  const body = {
    filter_hash: params.filterHash,
    title: params.title,
  };
  return request.post(url, body);
};
export const createInitiativeSegment = (params: { workspaceSlug: string; filterHash: string; title: string }) => {
  const url = SEGMENTS_API.replace(':workspaceSlug', params.workspaceSlug).replace(':segmentable_type', 'initiative');
  const body = {
    filter_hash: params.filterHash,
    title: params.title,
  };
  return request.post(url, body);
};
export const createMapSegment = (params: { workspaceSlug: string; filterHash: string; title: string }) => {
  const url = SEGMENTS_API.replace(':workspaceSlug', params.workspaceSlug).replace(':segmentable_type', 'map');
  const body = {
    filter_hash: params.filterHash,
    title: params.title,
  };
  return request.post(url, body);
};

export const createObjectiveSegment = (params: { workspaceSlug: string; filterHash: string; title: string }) => {
  const url = SEGMENTS_API.replace(':workspaceSlug', params.workspaceSlug).replace(':segmentable_type', 'objective');
  const body = {
    filter_hash: params.filterHash,
    title: params.title,
  };
  return request.post(url, body);
};

export const fetchSegmentDetails = (queryKey: string, segmentId: string) => {
  const url = SEGMENT_DETAILS_API.replace(':segmentId', segmentId);
  return request.get(url);
};
export const updateSegment = (params: { id: string; filterHash?: string; title?: string }) => {
  const url = SEGMENT_DETAILS_API.replace(':segmentId', params.id);
  const body = {
    filter_hash: params.filterHash,
    title: params.title,
  };
  return request.put(url, body);
};
export const deleteSegment = (segmentId: string) => {
  const url = SEGMENT_DETAILS_API.replace(':segmentId', segmentId);
  return request.delete(url);
};

// OUTCOME_LINKS APIS ====================================================

export const fetchOutcomeLinks = (queryKey: string, outcomeId: string) => {
  const url = OUTCOME_LINKS_API.replace(':outcomeId', outcomeId);
  return request.get(url);
};

export const createOutcomeLink: any = (params: any) => {
  const url = OUTCOME_LINKS_API.replace(':outcomeId', params.outcomeId);
  const body = {
    outcome_link: { ...params.outcomeLink },
  };
  return request.post(url, body);
};

export const fetchOutcomeLinkDetails = (queryKey: string, outcomeLinkId: string) => {
  const url = OUTCOME_LINK_DETAILS_API.replace(':outcomeLinkId', outcomeLinkId);
  return request.get(url);
};

export const fetchOutcomeLinkMeta = (queryKey: string, outcomeLinkId: string) => {
  const url = `${OUTCOME_LINK_DETAILS_API.replace(':outcomeLinkId', outcomeLinkId)}/meta`;
  return request.get(url);
};

export const updateOutcomeLink = (params: any) => {
  const url = OUTCOME_LINK_DETAILS_API.replace(':outcomeLinkId', params.outcomeLinkId);
  const body = {
    outcome_link: { ...params.outcomeLink },
  };
  return request.put(url, body);
};

export const deleteOutcomeLink = (outcomeLinkId: string) => {
  const url = OUTCOME_LINK_DETAILS_API.replace(':outcomeLinkId', outcomeLinkId);
  return request.delete(url);
};

// INITIATIVE_LINKS APIS ====================================================

export const fetchInitiativeLinks = (queryKey: string, initiativeId: string) => {
  const url = INITIATIVE_LINKS_API.replace(':initiativeId', initiativeId);
  return request.get(url);
};

export const createInitiativeLink: any = (params: any) => {
  const url = INITIATIVE_LINKS_API.replace(':initiativeId', params.initiativeId);
  const body = {
    initiative_link: { ...params.initiativeLink },
  };
  return request.post(url, body);
};

export const fetchInitiativeLinkDetails = (queryKey: string, initiativeLinkId: string) => {
  const url = INITIATIVE_LINK_DETAILS_API.replace(':initiativeLinkId', initiativeLinkId);
  return request.get(url);
};

export const fetchInitiativeLinkMeta = (queryKey: string, initiativeLinkId: string) => {
  const url = `${INITIATIVE_LINK_DETAILS_API.replace(':initiativeLinkId', initiativeLinkId)}/meta`;
  return request.get(url);
};

export const updateInitiativeLink = (params: any) => {
  const url = INITIATIVE_LINK_DETAILS_API.replace(':initiativeLinkId', params.initiativeLinkId);
  const body = {
    initiative_link: { ...params.initiativeLink },
  };
  return request.put(url, body);
};

export const deleteInitiativeLink = (initiativeLinkId: string) => {
  const url = INITIATIVE_LINK_DETAILS_API.replace(':initiativeLinkId', initiativeLinkId);
  return request.delete(url);
};

// CHECKINS API ==================================================
export const fetchCheckins = (queryKey: string, outcomeId: string, params: any = {}) => {
  const url = CHECKINS_API.replace(':outcomeId', outcomeId);
  return request.get(url, { params });
};

export const createCheckin = (params: any) => {
  const url = CHECKINS_API.replace(':outcomeId', params.outcomeId);
  const body = {
    checkin: { ...params.checkin },
  };
  return request.post(url, body);
};

export const fetchCheckinDetails = (queryKey: string, checkinId: string) => {
  const url = CHECKIN_DETAILS_API.replace(':checkinId', checkinId);
  return request.get(url);
};

export const updateCheckin = (params: any) => {
  const url = CHECKIN_DETAILS_API.replace(':checkinId', params.checkinId);
  const body = {
    checkin: { ...params.checkin },
  };
  return request.put(url, body);
};

export const skipCheckin = (params: any) => {
  const url = CHECKIN_SKIP_API.replace(':outcomeId', params.outcomeId);
  const body = {};
  return request.put(url, body);
};

export const deleteCheckin = (checkinId: string) => {
  const url = CHECKIN_DETAILS_API.replace(':checkinId', checkinId);
  return request.delete(url);
};

export const fetchCheckinViewers = (queryKey: string, checkinId: string) => {
  const url = CHECKIN_VIEWERS_API.replace(':id', checkinId);
  return request.get(url);
};

export const exportCheckins = (params: any) => {
  const body = {
    ...params.filter,
  };
  const url = `${WORKSPACE_DETAILS_API.replace(':workspaceSlug', params.workspaceSlug)}/search/checkins/export`;
  return request.post(url, body);
};

// TAGS API ====================================================
export const fetchTags = (queryKey: string, workspaceSlug: string, params: any, page: number) => {
  const url = TAGS_API.replace(':workspaceSlug', workspaceSlug);
  return request.get(url, {
    params: {
      ...params,
      page,
    },
  });
};

export const deleteTag = (params: { workspaceSlug: string; tagId: string }) => {
  const url = TAG_DETAILS_API.replace(':workspaceSlug', params.workspaceSlug).replace(':id', params.tagId);
  return request.delete(url);
};

export const updateTag = (params: { tagId: string; workspaceSlug: string; tag: any }) => {
  const url = TAG_DETAILS_API.replace(':workspaceSlug', params.workspaceSlug).replace(':id', params.tagId);
  const body = {
    tag: { ...params.tag },
  };
  return request.put(url, body);
};

export const createTag = (queryKey: string, workspaceSlug: string, params: any) => {
  const url = TAGS_API.replace(':workspaceSlug', workspaceSlug);
  return request.put(url, { params });
};

// EVENTS API ====================================================
export const fetchEvents = (queryKey: string, workspaceSlug: string, planId: string, page: number) => {
  const url = EVENTS_API.replace(':workspaceSlug', workspaceSlug);
  return request.get(url, {
    params: {
      page,
      plan_id: planId,
    },
  });
};

export const fetchEventsWithFilters = (queryKey: string, workspaceSlug: string, params: any, page: number) => {
  const url = EVENTS_API.replace(':workspaceSlug', workspaceSlug);
  return request.get(url, {
    params: {
      page,
      ...params,
    },
  });
};

export const fetchAuditEvents = (queryKey: string, workspaceSlug: string, page: number) => {
  const url = AUDIT_EVENTS_API.replace(':workspaceSlug', workspaceSlug);
  return request.get(url, {
    params: {
      page,
    },
  });
};

export const fetchPlanAuditEvents = (queryKey: string, workspaceSlug: string, planId: string, page: number) => {
  const url = AUDIT_EVENTS_API.replace(':workspaceSlug', workspaceSlug);
  return request.get(url, {
    params: {
      page,
      plan_id: planId,
    },
  });
};
export const fetchSourceAuditEvents = (
  queryKey: string,
  workspaceSlug: string,
  source_nano_slug: string,
  page: number,
) => {
  const url = AUDIT_EVENTS_API.replace(':workspaceSlug', workspaceSlug);
  return request.get(url, {
    params: {
      page,
      source_nano_slug,
    },
  });
};

// RECENT_VISITS API ====================================================
export const fetchRecentVisits = (queryKey: string, workspaceSlug: string, page: number, visited_type: string) => {
  const url = RECENT_VISITS_API.replace(':workspaceSlug', workspaceSlug);
  return request.get(url, {
    params: {
      visited_type: visited_type || 'Plan',
      page,
    },
  });
};

// STANDUPS APIS ====================================================

export const fetchStandups = (queryKey: string, workspaceSlug: string, params: any, page: number) => {
  const url = STANDUPS_API.replace(':workspaceSlug', workspaceSlug);
  return request.get(url, {
    params: {
      ...params,
      page,
    },
  });
};

export const createStandup = (params: any) => {
  const url = STANDUPS_API.replace(':workspaceSlug', params.workspaceSlug);
  const body = {
    standup: { ...params.standup },
  };
  return request.post(url, body);
};

export const fetchStandupDetails = (queryKey: string, standupId: string) => {
  const url = STANDUP_DETAILS_API.replace(':standupId', standupId);
  return request.get(url);
};

export const updateStandup = (params: any) => {
  const url = STANDUP_DETAILS_API.replace(':standupId', params.standupId);
  const body = {
    standup: { ...params.standup },
  };
  return request.put(url, body);
};

export const deleteStandup = (standupId: string) => {
  const url = STANDUP_DETAILS_API.replace(':standupId', standupId);
  return request.delete(url);
};

// STANDUP_COMMENTS APIS ====================================================

export const fetchStandupComments = (queryKey: string, standupId: string) => {
  const url = STANDUP_COMMENTS_API.replace(':standupId', standupId);
  return request.get(url);
};

export const createStandupComment = (params: any) => {
  const url = STANDUP_COMMENTS_API.replace(':standupId', params.standupId);
  const body = {
    standup_comment: { ...params.standup_comment },
  };
  return request.post(url, body);
};

export const fetchStandupCommentDetails = (queryKey: string, standupCommentId: string) => {
  const url = STANDUP_COMMENT_DETAILS_API.replace(':standupCommentId', standupCommentId);
  return request.get(url);
};

export const updateStandupComment = (params: any) => {
  const url = STANDUP_COMMENT_DETAILS_API.replace(':standupCommentId', params.standupCommentId);
  const body = {
    standup_comment: { ...params.standup_comment },
  };
  return request.put(url, body);
};

export const deleteStandupComment = (standupCommentId: string) => {
  const url = STANDUP_COMMENT_DETAILS_API.replace(':standupCommentId', standupCommentId);
  return request.delete(url);
};

// REVIEW_COMMENTS APIS ====================================================

export const fetchPlanReviewComments = (queryKey: string, planId: string, filter: any = []) => {
  const url = PLAN_REVIEW_COMMENTS_API.replace(':planId', planId);
  return request.get(url, {
    params: {
      filter,
    },
  });
};

export const createReviewComment: any = (params: any) => {
  const url = PLAN_REVIEW_COMMENTS_API.replace(':planId', params.planId);
  const body = {
    review_comment: { ...params.review_comment },
  };
  return request.post(url, body);
};

export const fetchReviewCommentDetails = (queryKey: string, reviewCommentId: string) => {
  const url = REVIEW_COMMENT_DETAILS_API.replace(':reviewCommentId', reviewCommentId);
  return request.get(url);
};

export const updateReviewComment = (params: any) => {
  const url = REVIEW_COMMENT_DETAILS_API.replace(':reviewCommentId', params.reviewCommentId);
  const body = {
    review_comment: { ...params.review_comment },
  };
  return request.put(url, body);
};

export const deleteReviewComment = (reviewCommentId: string) => {
  const url = REVIEW_COMMENT_DETAILS_API.replace(':reviewCommentId', reviewCommentId);
  return request.delete(url);
};

// REVIEW_COMMENTS APIS ====================================================

export const fetchReviewReplies = (queryKey: string, reviewCommentId: string) => {
  const url = REVIEW_REPLIES_API.replace(':reviewCommentId', reviewCommentId);
  return request.get(url);
};

export const createReviewReply: any = (params: any) => {
  const url = REVIEW_REPLIES_API.replace(':reviewCommentId', params.reviewCommentId);
  const body = {
    review_reply: { ...params.review_reply },
  };
  return request.post(url, body);
};

export const fetchReviewReplyDetails = (queryKey: string, reviewReplyId: string) => {
  const url = REVIEW_REPLY_DETAILS_API.replace(':reviewReplyId', reviewReplyId);
  return request.get(url);
};

export const updateReviewReply = (params: any) => {
  const url = REVIEW_REPLY_DETAILS_API.replace(':reviewReplyId', params.reviewReplyId);
  const body = {
    review_reply: { ...params.review_reply },
  };
  return request.put(url, body);
};

export const deleteReviewReply = (reviewReplyId: string) => {
  const url = REVIEW_REPLY_DETAILS_API.replace(':reviewReplyId', reviewReplyId);
  return request.delete(url);
};

// CHECKIN_COMMENTS APIS ====================================================

export const fetchCheckinComments = (queryKey: string, checkinId: string) => {
  const url = CHECKIN_COMMENTS_API.replace(':checkinId', checkinId);
  return request.get(url);
};

export const createCheckinComment = (params: any) => {
  const url = CHECKIN_COMMENTS_API.replace(':checkinId', params.checkinId);
  const body = {
    checkin_comment: { ...params.checkin_comment },
  };
  return request.post(url, body);
};

export const fetchCheckinCommentDetails = (queryKey: string, checkinCommentId: string) => {
  const url = CHECKIN_COMMENT_DETAILS_API.replace(':checkinCommentId', checkinCommentId);
  return request.get(url);
};

export const updateCheckinComment = (params: any) => {
  const url = CHECKIN_COMMENT_DETAILS_API.replace(':checkinCommentId', params.checkinCommentId);
  const body = {
    checkin_comment: { ...params.checkin_comment },
  };
  return request.put(url, body);
};

export const deleteCheckinComment = (checkinCommentId: string) => {
  const url = CHECKIN_COMMENT_DETAILS_API.replace(':checkinCommentId', checkinCommentId);
  return request.delete(url);
};

// INITIATIVE_COMMENTS APIS ====================================================

export const fetchInitiativeComments = (queryKey: string, initiativeId: string, page: number) => {
  const url = INITIATIVE_COMMENTS_API.replace(':initiativeId', initiativeId);
  return request.get(url, {
    params: {
      page,
    },
  });
};

export const createInitiativeComment: any = (params: any) => {
  const url = INITIATIVE_COMMENTS_API.replace(':initiativeId', params.initiativeId);
  const body = {
    initiative_comment: { ...params.initiative_comment },
  };
  return request.post(url, body);
};

export const fetchInitiativeCommentDetails = (queryKey: string, initiativeCommentId: string) => {
  const url = INITIATIVE_COMMENT_DETAILS_API.replace(':initiativeCommentId', initiativeCommentId);
  return request.get(url);
};

export const updateInitiativeComment = (params: any) => {
  const url = INITIATIVE_COMMENT_DETAILS_API.replace(':initiativeCommentId', params.initiativeCommentId);
  const body = {
    initiative_comment: { ...params.initiative_comment },
  };
  return request.put(url, body);
};

export const deleteInitiativeComment = (initiativeCommentId: string) => {
  const url = INITIATIVE_COMMENT_DETAILS_API.replace(':initiativeCommentId', initiativeCommentId);
  return request.delete(url);
};

// INITIATIVE REPLIES API =====================================================

export const fetchInitiativeReplies = (queryKey: string, initiativeCommentId: string) => {
  const url = INITIATIVE_REPLIES_API.replace(':initiativeCommentId', initiativeCommentId);
  return request.get(url);
};

export const createInitiativeReply = (params: any) => {
  const url = INITIATIVE_REPLIES_API.replace(':initiativeCommentId', params.initiativeCommentId);
  const body = {
    initiative_reply: { ...params.initiative_reply },
  };
  return request.post(url, body);
};

export const fetchInitiativeReplyDetails = (queryKey: string, initiativeReplyId: string) => {
  const url = INITIATIVE_REPLY_DETAILS_API.replace(':initiativeReplyId', initiativeReplyId);
  return request.get(url);
};

export const updateInitiativeReply = (params: any) => {
  const url = INITIATIVE_REPLY_DETAILS_API.replace(':initiativeReplyId', params.initiativeReplyId);
  const body = {
    initiative_reply: { ...params.initiative_reply },
  };
  return request.put(url, body);
};

export const deleteInitiativeReply = (initiativeReplyId: string) => {
  const url = INITIATIVE_REPLY_DETAILS_API.replace(':initiativeReplyId', initiativeReplyId);
  return request.delete(url);
};

// RETROSPECTIVE_COMMENTS APIS ====================================================

export const fetchRetrospectiveComments = (queryKey: string, retrospectiveId: string, params: any, page: number) => {
  const url = RETROSPECTIVE_COMMENTS_API.replace(':retrospectiveId', retrospectiveId);
  return request.get(url, { params: { page, retrospective_section_id: params.retrospective_section_id } });
};

export const createRetrospectiveComment = (params: any) => {
  const url = RETROSPECTIVE_COMMENTS_API.replace(':retrospectiveId', params.retrospectiveId);
  const body = { ...params.retrospective_comment };
  return request.post(url, body);
};

export const fetchRetrospectiveCommentDetails = (queryKey: string, retrospectiveCommentId: string) => {
  const url = RETROSPECTIVE_COMMENT_DETAILS_API.replace(':retrospectiveCommentId', retrospectiveCommentId);
  return request.get(url);
};

export const updateRetrospectiveComment = (params: any) => {
  const url = RETROSPECTIVE_COMMENT_DETAILS_API.replace(':retrospectiveCommentId', params.retrospectiveCommentId);
  const body = {
    retrospective_comment: { ...params.retrospective_comment },
  };
  return request.put(url, body);
};

export const deleteRetrospectiveComment = (retrospectiveCommentId: string) => {
  const url = RETROSPECTIVE_COMMENT_DETAILS_API.replace(':retrospectiveCommentId', retrospectiveCommentId);
  return request.delete(url);
};

// RETROSPECTIVE REPLIES API =====================================================

export const fetchRetrospectiveReplies = (queryKey: string, retrospectiveCommentId: string) => {
  const url = RETROSPECTIVE_REPLIES_API.replace(':retrospectiveCommentId', retrospectiveCommentId);
  return request.get(url);
};

export const createRetrospectiveReply = (params: any) => {
  const url = RETROSPECTIVE_REPLIES_API.replace(':retrospectiveCommentId', params.retrospectiveCommentId);
  const body = {
    retrospective_reply: { ...params.retrospective_reply },
  };
  return request.post(url, body);
};

export const fetchRetrospectiveReplyDetails = (queryKey: string, retrospectiveReplyId: string) => {
  const url = RETROSPECTIVE_REPLY_DETAILS_API.replace(':retrospectiveReplyId', retrospectiveReplyId);
  return request.get(url);
};

export const updateRetrospectiveReply = (params: any) => {
  const url = RETROSPECTIVE_REPLY_DETAILS_API.replace(':retrospectiveReplyId', params.retrospectiveReplyId);
  const body = {
    retrospective_reply: { ...params.retrospective_reply },
  };
  return request.put(url, body);
};

export const deleteRetrospectiveReply = (retrospectiveReplyId: string) => {
  const url = RETROSPECTIVE_REPLY_DETAILS_API.replace(':retrospectiveReplyId', retrospectiveReplyId);
  return request.delete(url);
};

// REACTIONS API ==============================================================
export const createCheckinReaction = (params: { checkinId: string; emotion: string }) => {
  const url = CHECKIN_REACTIONS_API.replace(':checkin_id', params.checkinId);
  const body = {
    emotion: params.emotion,
  };
  return request.post(url, body);
};

export const createCheckinCommentReaction = (params: { checkinCommentId: string; emotion: string }) => {
  const url = CHECKIN_COMMENTS_REACTIONS_API.replace(':checkin_comment_id', params.checkinCommentId);
  const body = {
    emotion: params.emotion,
  };
  return request.post(url, body);
};

export const createInitiativeCommentReaction = (params: { initiativeCommentId: string; emotion: string }) => {
  const url = INITIATIVE_COMMENTS_REACTIONS_API.replace(':initiative_comment_id', params.initiativeCommentId);
  const body = {
    emotion: params.emotion,
  };
  return request.post(url, body);
};

export const createInitiativeReplyReaction = (params: { initiativeReplyId: string; emotion: string }) => {
  const url = INITIATIVE_REPLIES_REACTIONS_API.replace(':initiative_reply_id', params.initiativeReplyId);
  const body = {
    emotion: params.emotion,
  };
  return request.post(url, body);
};

export const createReviewCommentReaction = (params: { reviewCommentId: string; emotion: string }) => {
  const url = REVIEW_COMMENT_REACTIONS_API.replace(':review_comment_id', params.reviewCommentId);
  const body = {
    emotion: params.emotion,
  };
  return request.post(url, body);
};

export const createReviewReplyReaction = (params: { reviewReplyId: string; emotion: string }) => {
  const url = REVIEW_REPLY_REACTIONS_API.replace(':review_reply_id', params.reviewReplyId);
  const body = {
    emotion: params.emotion,
  };
  return request.post(url, body);
};

export const createStandupReaction = (params: { standupId: string; emotion: string }) => {
  const url = STANDUP_REACTIONS_API.replace(':standup_id', params.standupId);
  const body = {
    emotion: params.emotion,
  };
  return request.post(url, body);
};

export const createStandupCommentReaction = (params: { standupCommentId: string; emotion: string }) => {
  const url = STANDUP_COMMENT_REACTIONS_API.replace(':standup_comment_id', params.standupCommentId);
  const body = {
    emotion: params.emotion,
  };
  return request.post(url, body);
};

export const createRetrospectiveCommentReaction = (params: { retrospectiveCommentId: string; emotion: string }) => {
  const url = REETROSPECTIVE_COMMENT_REACTIONS_API.replace(':retrospective_comment_id', params.retrospectiveCommentId);
  const body = {
    emotion: params.emotion,
  };
  return request.post(url, body);
};

export const createRetrospectiveReplyReaction = (params: { retrospectiveReplyId: string; emotion: string }) => {
  const url = REETROSPECTIVE_REPLY_REACTIONS_API.replace(':retrospective_reply_id', params.retrospectiveReplyId);
  const body = {
    emotion: params.emotion,
  };
  return request.post(url, body);
};

export const deleteReaction = (reactionId: string) => {
  const url = REACTIONS_API.replace(':id', reactionId);
  return request.delete(url);
};

// PLAN SUBSCRIPTIONS APIS ====================================================

export const fetchCurrentUserPlanSubscriptionDetails = (queryKey: string, planId: string) => {
  const url = `${PLAN_SUBSCRIPTIONS_API.replace(':planId', planId)}/me`;
  return request.get(url);
};
export const fetchPlanSubscriptions = (queryKey: string, planId: string) => {
  const url = `${PLAN_SUBSCRIPTIONS_API.replace(':planId', planId)}`;
  return request.get(url);
};

export const createCurrentUserPlanSubscription = (planId: string) => {
  const url = `${PLAN_SUBSCRIPTIONS_API.replace(':planId', planId)}/me`;
  return request.post(url);
};

export const createPlanSubscription = (params: { planId: string; membershipId: string }) => {
  const url = `${PLAN_SUBSCRIPTIONS_API.replace(':planId', params.planId)}`;
  const body = {
    membership_id: params.membershipId,
  };
  return request.post(url, body);
};

export const deletePlanSubscription = (planSubscriptionId: string) => {
  const url = PLAN_SUBSCRIPTION_DETAILS_API.replace(':planSubscriptionId', planSubscriptionId);
  return request.delete(url);
};

// RETROSPECTIVE APIS ====================================================

export const fetchRetrospectives = (queryKey: string, planId: string) => {
  const url = `${RETROSPECTIVES_API.replace(':planId', planId)}`;
  return request.get(url);
};

export const createRetrospective = (params: any) => {
  const url = `${RETROSPECTIVES_API.replace(':planId', params.planId)}`;
  const body = {
    retrospective: { ...params.retrospective },
  };
  return request.post(url, body);
};

export const updateRetrospective = (params: { retrospectiveId: string; retrospective: any }) => {
  const url = RETROSPECTIVE_DETAILS_API.replace(':retrospectiveId', params.retrospectiveId);
  const body = {
    ...params.retrospective,
  };
  return request.put(url, body);
};

export const deleteRetrospective = (retrospectiveId: string) => {
  const url = RETROSPECTIVE_DETAILS_API.replace(':retrospectiveId', retrospectiveId);
  return request.delete(url);
};

export const fetchRetrospectiveDetails = (queryKey: string, retrospectiveId: string) => {
  const url = RETROSPECTIVE_DETAILS_API.replace(':retrospectiveId', retrospectiveId);
  return request.get(url);
};

export const fetchPublicRetrospectiveDetails = (queryKey: string, retrospectiveId: string) => {
  const url = `${RETROSPECTIVE_DETAILS_API.replace(':retrospectiveId', retrospectiveId)}/public`;
  return request.get(url);
};

export const fetchRetrospectiveSections = (queryKey: string, retrospectiveId: string) => {
  const url = `${RETROSPECTIVE_SECTIONS_API.replace(':retrospectiveId', retrospectiveId)}`;
  return request.get(url);
};

export const fetchPublicRetrospectiveSections = (queryKey: string, retrospectiveId: string) => {
  const url = `${RETROSPECTIVE_DETAILS_API.replace(':retrospectiveId', retrospectiveId)}/public/retrospective_sections`;
  return request.get(url);
};

export const shareRetrospective = (params: any) => {
  const url = `${RETROSPECTIVE_DETAILS_API.replace(':retrospectiveId', params.retrospectiveId)}/share`;
  const body = { ...params.share };
  return request.post(url, body);
};

// RETROSPECTIVE SECTION APIS ========================================

export const createRetrospectiveSection = (params: { retrospectiveId: string; retrospectiveSection: any }) => {
  const url = RETROSPECTIVE_SECTIONS_API.replace(':retrospectiveId', params.retrospectiveId);
  const body = {
    retrospective_section: { ...params.retrospectiveSection },
  };
  return request.post(url, body);
};

export const updateRetrospectiveSection = (params: { retrospectiveSectionId: string; retrospectiveSection: any }) => {
  const url = RETROSPECTIVE_SECTION_DETAILS_API.replace(':retrospectiveSectionId', params.retrospectiveSectionId);
  const body = {
    ...params.retrospectiveSection,
  };
  return request.put(url, body);
};

export const deleteRetrospectiveSection = (retrospectiveSectionId: string) => {
  const url = RETROSPECTIVE_SECTION_DETAILS_API.replace(':retrospectiveSectionId', retrospectiveSectionId);
  return request.delete(url);
};

// PUBLIC REPORTS APIS ===============================================

export const fetchPublicPlanDetails = (queryKey: string, planId: string, params: any = {}) => {
  const url = PUBLIC_PLAN_DETAILS_API.replace(':planId', planId);
  return request.get(url, { params });
};

export const fetchPublicPlanObjectives = (queryKey: string, planId: string, params: any = {}) => {
  const url = PUBLIC_PLAN_OBJECTIVES_API.replace(':planId', planId);
  return request.get(url, { params });
};

export const fetchPublicPlanOutcomes = (queryKey: string, planId: string, params: any = {}) => {
  const url = PUBLIC_PLAN_OUTCOMES_API.replace(':planId', planId);
  return request.get(url, { params });
};

export const fetchPublicObjectiveDetails = (queryKey: string, objectiveId: string, params: any = {}) => {
  const url = PUBLIC_OBJECTIVE_DETAILS_API.replace(':objectiveId', objectiveId);
  return request.get(url, { params });
};

export const fetchPublicOutcomeDetails = (queryKey: string, outcomeId: string, params: any = {}) => {
  const url = PUBLIC_OUTCOME_DETAILS_API.replace(':outcomeId', outcomeId);
  return request.get(url, { params });
};

export const fetchPublicOutcomes = (queryKey: string, objectiveId: string, params: any = {}) => {
  const url = PUBLIC_OBJECTIVE_OUTCOMES_API.replace(':objectiveId', objectiveId);
  return request.get(url, { params });
};

export const fetchPublicCheckins = (queryKey: string, outcomeId: string, params: any = {}) => {
  const url = PUBLIC_OUTCOME_CHECKINS_API.replace(':outcomeId', outcomeId);
  return request.get(url, { params });
};

export const fetchPublicInitiatives = (queryKey: string, outcomeId: string, params: any = {}) => {
  const url = PUBLIC_OUTCOME_INITIATIVES_API.replace(':outcomeId', outcomeId);
  return request.get(url, { params });
};
