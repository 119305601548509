import React, { useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import * as TabilityTypes from 'types';
import { useLocation, Link, useParams } from 'react-router-dom';
import { parseISO, differenceInCalendarDays } from 'date-fns';
import { shallowEqual, useSelector } from 'react-redux';

import { getConfidenceData } from 'utils/planUtils';
import { CustomTermKey, translate } from 'utils/customTermUtils';

import ReactTooltip from 'react-tooltip';

import OutcomeBlock from './OutcomeBlock';
import MarkdownContent from 'components/MarkdownContent';
import KoalaCircularProgress from 'koala/components/CircularProgress';
import KoalaNCSPie from 'koala/components/NCSPie';
import KoalaIcon from 'koala/components/Icons';
import KoalaToggleButton from 'koala/components/ToggleButton';

import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import Loader from 'components/Loader';

// API
import * as remoteApi from 'api/remote';
import PlanStatus from 'components/PlanStatus';
import { Trans, useTranslation } from 'react-i18next';
import { getNCSTextColor } from 'utils/outcomeUtils';
import KoalaButton from 'koala/components/Button';
import Insights from './Insights';

const ObjectiveBlock = styled.div`
  margin-bottom: ${theme.spacing.x3};
`;

const Container = styled.div`
  display: flex;
  position: relative;
  background: ${theme.colors.N0};
  padding: ${theme.spacing.x2};
  overflow-y: auto;
  height: fit-content;
  letter-spacing: 0.01rem;
  line-height: 1.4;
  font-family: ${theme.font.fontFamily};
  font-weight: 400;

  max-width: 80rem;
  width: 80rem;
  @media ${theme.devices.laptop} {
    width: 70rem;
  }
`;

const ObjectiveHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0 0 ${theme.spacing.x3} 0;
  padding-bottom: ${theme.spacing.x2};

  h2 {
    font-size: 2rem;
    font-weight: 800;
  }

  border-bottom: 2px solid #151515;
  margin-bottom: ${theme.spacing.x1};
  padding-bottom: ${theme.spacing.x1};
`;

const EmptyObjective = styled.div`
  padding: ${theme.spacing.x2} ${theme.spacing.x3};
`;

const Grid = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x3};
  margin-bottom: ${theme.spacing.x2};
  max-width: 90rem;

  .spacer {
    padding: ${theme.spacing.x1} ${theme.spacing.x3};
  }
`;

const Panel = styled.div`
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 4px;

  &.no-padding {
    padding: 0;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
`;

const PlanOverview = styled.div`
  display: grid;
  gap: ${theme.spacing.x2};
  grid-template-columns: 1fr 1fr 1fr 1fr;

  @media ${theme.devices.laptop} {
    grid-template-columns: 1fr 1fr;
  }
`;

const InfoBlock = styled.div`
  background: ${theme.colors.N0};
  border: 1px solid ${theme.colors.blockBorder};
  box-sizing: border-box;
  padding: ${theme.spacing.x1} ${theme.spacing.x2};
  border-radius: 4px;
  h3 {
    display: flex;
    gap: ${theme.spacing.half};
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    min-height: 2.4rem;
    text-align: center;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: ${theme.spacing.x1};
    font-weight: 800;
  }
`;

const PDFValue = styled.div<{ color: string }>`
  color: ${(props) => props.color};
  font-weight: 600;
  text-align: center;
  position: relative;
  display: none;
`;

const CollapseActions = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.half};
`;

const ToggleButton = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  font-weight: 500;
  height: 2.4rem;
  line-height: 1.6rem;
  color: ${theme.colors.N70};
  margin-right: ${theme.spacing.x1};
`;

interface Props {
  plan: TabilityTypes.Plan;
}

function Tracker(props: Props) {
  const location = useLocation();
  const { plan } = props;
  const { workspaceSlug, planId } = useParams<{ workspaceSlug: string; planId: string }>();
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const { t } = useTranslation();
  const [objectives, setObjectives] = useState<TabilityTypes.Objective[]>([]);
  const [outcomesByObjective, setOutcomesByObjective] = useState<{ [key: string]: TabilityTypes.Outcome[] }>({});
  const [hideClosedInitiatives, setHideClosedInitiatives] = useState(false);

  const [defaultChanged, setDefaultChanged] = useState(false);
  const [defaultExpandedState, setDefaultExpandedState] = useState(false);

  // Load the data for the nav
  // Get the objectives
  const { isLoading: objectivesAreLoading } = useQuery([queryKeys.objectives, planId], remoteApi.fetchPlanObjectives, {
    staleTime: 0,
    onSuccess: (response: any) => {
      const objectives: TabilityTypes.Objective[] = response.data;
      setObjectives(objectives);
    },
  });

  // Get the outcomes
  const { isLoading: outcomesAreLoading } = useQuery([queryKeys.outcomes, planId], remoteApi.fetchPlanOutcomes, {
    staleTime: 0,
    onSuccess: (response: any) => {
      const outcomes: TabilityTypes.Outcome[] = response.data;
      const outcomesByObjective: { [key: string]: TabilityTypes.Outcome[] } = {};
      // Iterate on each outcome and group items by objective_id in an array
      outcomes.forEach((outcome) => {
        if (outcomesByObjective[outcome.objective_id]) {
          outcomesByObjective[outcome.objective_id].push(outcome);
        } else {
          outcomesByObjective[outcome.objective_id] = [outcome];
        }
      });
      setOutcomesByObjective(outcomesByObjective);
    },
  });

  if (objectivesAreLoading || outcomesAreLoading) {
    return <Loader size="big" />;
  }

  const planUrl = `/${workspaceSlug}/plans/${planId}/outcomes`;

  const planFinishAt = plan && plan.finish_at ? parseISO(plan.finish_at) : null;
  let differenceInDays = planFinishAt ? differenceInCalendarDays(planFinishAt, new Date()) : 0;
  if (differenceInDays < 0) {
    differenceInDays = 0;
  }

  const isBlockSelected = (blockId: string) => {
    return location.hash.includes(blockId);
  };

  // Strip HTML from text to see if we have an empty field.
  let doc = plan.notes ? new DOMParser().parseFromString(plan.notes, 'text/html') : null;
  const notesAreEmpty = !plan.notes || !doc || !doc.body.textContent || doc.body.textContent === '';

  const confidenceData = getConfidenceData(plan);

  const planStartAtISO = plan && plan.start_at ? parseISO(plan.start_at) : null;
  const planFinishAtISO = plan && plan.finish_at ? parseISO(plan.finish_at) : null;
  let planDaysCount = planFinishAtISO && planStartAtISO ? differenceInCalendarDays(planFinishAtISO, planStartAtISO) : 0;
  let daysSpent = planStartAtISO ? differenceInCalendarDays(new Date(), planStartAtISO) : 0;
  let daysLeft = planFinishAtISO ? Math.max(0, differenceInCalendarDays(planFinishAtISO, new Date())) : 0;
  let days_progress_prct = planDaysCount ? (daysSpent / planDaysCount) * 100 : 0;

  const timelineProgressData = {
    progressPercentage: days_progress_prct,
    colorType: 'B',
    centerLabel: [`${daysLeft}`, t('shared.time.daysLeft', { count: daysLeft })],
  };

  const outcomeProgressData = {
    progressPercentage: plan.outcome_progress_prct * 100,
    colorType: 'B',
    centerLabel: `${Math.round(plan.outcome_progress_prct * 100)}%`,
  };

  const initiativeProgressData = {
    progressPercentage: plan.initiative_progress_prct * 100,
    colorType: 'B',
    centerLabel: `${plan.closed_initiatives_count}/${plan.total_initiatives_count}`,
  };

  const toggleHideInitiatives = () => {
    setHideClosedInitiatives((prev) => !prev);
  };

  const toggleExpandOutcomes = (isExpanding: boolean) => {
    setDefaultChanged(false);
    setDefaultExpandedState(isExpanding);
  };

  return (
    <>
      <Container id="pdf-container">
        <Grid>
          <Header>
            <h1>
              <Link to={planUrl}>{plan.title}</Link>
            </h1>
            <PlanStatus plan={plan} />
          </Header>
          <PlanOverview>
            <InfoBlock>
              <h3>{t('publicPlan.timeline')}</h3>
              <KoalaCircularProgress data={timelineProgressData} size="large" className="svg-chart" />
              <PDFValue className="pdf-only-text" color={theme.colors.B50}>
                {timelineProgressData.centerLabel[0] + ' ' + timelineProgressData.centerLabel[1]}
              </PDFValue>
            </InfoBlock>
            <InfoBlock>
              <h3>{translate(currentWorkspace, CustomTermKey.OUTCOME, 2)}</h3>
              <KoalaCircularProgress data={outcomeProgressData} size="large" className="svg-chart" />
              <PDFValue className="pdf-only-text" color={theme.colors.B50}>
                {outcomeProgressData.centerLabel}
              </PDFValue>
            </InfoBlock>
            <InfoBlock>
              <h3>{translate(currentWorkspace, CustomTermKey.INITIATIVE, 2)}</h3>
              <KoalaCircularProgress data={initiativeProgressData} size="large" className="svg-chart" />
              <PDFValue className="pdf-only-text" color={theme.colors.B50}>
                {initiativeProgressData.centerLabel}
              </PDFValue>
            </InfoBlock>
            <InfoBlock>
              <h3>
                {t('shared.ncs.full')}
                <KoalaIcon iconName="tooltip" iconSize="small" data-tip data-for="explain-ncs" />
              </h3>
              <ReactTooltip
                place="bottom"
                type="dark"
                className="tooltip"
                effect="solid"
                id="explain-ncs"
                delayHide={500}
                clickable={true}
              >
                <Trans i18nKey="shared.ncs.tooltip" components={{ br: <br /> }} />{' '}
                <a
                  href="https://www.tability.io/odt-articles/nps-but-for-your-okrs-introducing-the-net-confidence-score-ncs"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('shared.learnMore')}
                </a>
              </ReactTooltip>
              <KoalaNCSPie
                centerLabel={[Math.round(plan.ncs).toString(), t('shared.ncs.acronym')]}
                data={confidenceData}
                size="large"
                className="svg-chart"
              />
              <PDFValue className="pdf-only-text" color={getNCSTextColor(Math.round(plan.ncs))}>
                {Math.round(plan.ncs).toString() + ' ' + t('shared.ncs.acronym')}
              </PDFValue>
            </InfoBlock>
          </PlanOverview>
          <CollapseActions className="collapse-toggle">
            <KoalaButton appearance="secondary" size="small" onClick={() => toggleExpandOutcomes(true)}>
              {t('shared.expandAll')}
            </KoalaButton>
            <KoalaButton appearance="secondary" size="small" onClick={() => toggleExpandOutcomes(false)}>
              {t('shared.collapseAll')}
            </KoalaButton>
            <ToggleButton>
              <KoalaToggleButton
                handleChange={toggleHideInitiatives}
                size="small"
                defaultChecked={hideClosedInitiatives}
                id="hide-completed-initiatives-prez-overview"
              />
              {t('workspacePlan.hideCompleted', {
                initiatives: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2).toLowerCase(),
              })}
            </ToggleButton>
          </CollapseActions>
          {plan.notes && (
            <div>
              <Panel>
                {!notesAreEmpty && <MarkdownContent source={plan.notes} />}
                {notesAreEmpty && (
                  <p className="subtle">
                    <em>{t('publicPlan.noNotes')}</em>
                  </p>
                )}
              </Panel>
            </div>
          )}
          <div>
            <Panel className="no-padding">
              {objectives.map((objective) => {
                const outcomesToDisplay = outcomesByObjective[objective.id] || [];
                return (
                  <ObjectiveBlock key={objective.id}>
                    <ObjectiveHeader>
                      <h2>{objective.title}</h2>
                    </ObjectiveHeader>
                    {outcomesToDisplay.length === 0 && (
                      <EmptyObjective className="subtle">
                        {t('publicPlan.noOutcomes', {
                          outcomes: translate(currentWorkspace, CustomTermKey.OUTCOME, 2).toLowerCase(),
                          objective: translate(currentWorkspace, CustomTermKey.OBJECTIVE, 1).toLowerCase(),
                        })}
                      </EmptyObjective>
                    )}
                    {outcomesToDisplay.map((outcome: any) => {
                      //const outcome = outcomes.find((o) => o.id === outcomeId);
                      if (outcome) {
                        const blockId = `outcome:${outcome.nano_slug}`;
                        const isSelected = isBlockSelected(blockId);
                        return (
                          <OutcomeBlock
                            outcome={outcome}
                            key={outcome.id}
                            isSelected={isSelected}
                            hideClosedInitiatives={hideClosedInitiatives}
                            defaultExpanded={defaultExpandedState}
                            setDefaultChanged={setDefaultChanged}
                            defaultChanged={defaultChanged}
                          />
                        );
                      } else {
                        return null;
                      }
                    })}
                  </ObjectiveBlock>
                );
              })}
            </Panel>
          </div>
          <Insights plan={plan} />
        </Grid>
      </Container>
    </>
  );
}

export default React.memo(Tracker);
