import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';

// Actions
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { shallowEqual, useSelector } from 'react-redux';
import queryKeys from 'config/queryKeys';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import * as remoteApi from 'api/remote';
import { Outcome } from 'types';
import { ValueType } from 'react-select';
import { midString } from 'state/reducers/utils';
import KoalaLoader from 'koala/components/Loader';
import { useTranslation } from 'react-i18next';
import KoalaSelect, { KoalaSelectOption } from 'koala/components/Select';
import { AxiosResponse } from 'axios';
import * as initiativeUtils from 'utils/initiativeUtils';

const BlockHeader = styled.div`
  grid-area: task-title;

  input {
    border: none;
    padding: 0;
    text-align: left;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
`;

const BlockGrid = styled.div`
  display: grid;
  position: relative;
  border: 2px solid ${theme.colors.B20};
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    'outcome'
    'task-title'
    'meta';
  align-items: start;
  gap: ${theme.spacing.x1};
  padding: 1.2rem;
  background: #fff;
  border-radius: 4px;
  margin-top: ${theme.spacing.half};
  margin-bottom: ${theme.spacing.x10};
`;

const BlockOutcome = styled.div`
  grid-area: outcome;
`;

interface Props {
  objectiveId: string;
  workState: string;
  lastColumnRank: string;
  handleCancel: () => void;
}

function CreateInitiativeCard(props: Props) {
  const { objectiveId, workState, handleCancel, lastColumnRank } = props;
  const queryCache = useQueryCache();
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const [selectedOutcome, setSelectedOutcome] = useState<KoalaSelectOption>();
  const [title, setTitle] = useState('');
  const [outcomeOptions, setOutcomeOptions] = useState<KoalaSelectOption[]>([]);

  const outcomesToInitiativesMapping = useSelector((state: any) => state.editorEntities.outcomesToInitiativesMapping);
  const initiatives = useSelector((state: any) => state.editorEntities.initiatives);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      inputRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }, 200);
  }, []);

  const [createInitiativeMutation, { isLoading }]: [any, any] = useMutation(remoteApi.createInitiative, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.initiatives);
      setTitle('');
      setTimeout(() => {
        inputRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      }, 200);
    },
  });

  // Get the outcomes
  const staleTime = 0;
  const { isLoading: isLoadingOutcomes } = useQuery([queryKeys.outcomes, objectiveId], remoteApi.fetchOutcomes, {
    staleTime,
    onSuccess: (response: AxiosResponse<Outcome[]>) => {
      const options: KoalaSelectOption[] = response.data.map((outcome) => ({
        label: outcome.title,
        value: outcome.id,
      }));
      setOutcomeOptions(options);
    },
  });

  const handleSelection = (option: ValueType<KoalaSelectOption, boolean>) => {
    const optionValue = option as KoalaSelectOption;
    setSelectedOutcome(optionValue);
  };

  const handleTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setTitle(value);
  };

  const handleSave = () => {
    if (title === '' || !selectedOutcome) {
      return;
    }
    let selectedOutcomeId = selectedOutcome.value.toString();
    let lastInitiative = null;

    // Get the last initiative of the outcome to set the rank
    const initiativeIds = outcomesToInitiativesMapping[selectedOutcomeId] || [];

    if (initiativeIds.length) {
      const lastInitiativeId = initiativeIds[initiativeIds.length - 1];
      lastInitiative = initiatives[lastInitiativeId];
    }
    const lastRank = lastInitiative ? lastInitiative.rank : '';

    const rank = midString(lastRank, '');
    const column_rank = midString(lastColumnRank, '');
    const stateParams = initiativeUtils.getWorkStateParams(workState);
    const params = {
      outcomeId: selectedOutcomeId,
      initiative: {
        ...stateParams,
        title,
        rank,
        column_rank,
      },
    };
    createInitiativeMutation(params);
  };

  const handlePress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSave();
    }
    if (e.key === 'Escape') {
      handleCancel();
    }
  };
  useEffect(() => {
    function handleClickOutside(event: Event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        handleCancel();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef, handleCancel]);

  const label = translate(currentWorkspace, CustomTermKey.INITIATIVE, 1).toLowerCase();
  const outcomeLabel = translate(currentWorkspace, CustomTermKey.OUTCOME, 1).toLowerCase();

  const outcomePlaceholder =
    t('workspacePlans.table.selectOutcome', { label: outcomeLabel }) ?? `Select ${outcomeLabel}`;
  return (
    <BlockGrid ref={wrapperRef}>
      <BlockOutcome>
        {isLoadingOutcomes && <KoalaLoader />}
        {!isLoadingOutcomes && (
          <KoalaSelect
            handleChange={handleSelection}
            placeholder={outcomePlaceholder}
            selectedOption={selectedOutcome}
            options={outcomeOptions}
            size="small"
          />
        )}
      </BlockOutcome>
      <BlockHeader>
        <input
          ref={inputRef}
          placeholder={t('workspacePlans.table.initiativePlaceholder', { label: label }) ?? `What's your ${label}?`}
          onChange={handleTitleChange}
          onKeyDown={(e) => handlePress(e)}
          value={title}
        />
      </BlockHeader>
      {isLoading && <KoalaLoader />}
    </BlockGrid>
  );
}

export default React.memo(CreateInitiativeCard);
