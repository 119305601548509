import React, { useState } from 'react';
import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import styled from 'styled-components';
import theme from 'theme';
import { useSelector, shallowEqual } from 'react-redux';
//import * as TabilityTypes from 'types';
import { useParams, Link } from 'react-router-dom';

// API
import * as remoteApi from 'api/remote';
import KoalaProgressBar from 'koala/components/ProgressBar';
import { useTranslation } from 'react-i18next';

import * as workspaceUtils from 'utils/workspaceUtils';
import OnboardingTabby from './tabby_explore.png';

const Container = styled.div`
  display: flex;
  gap: ${theme.spacing.x5};

  align-items: center;
  width: 70rem;
  margin: 0 auto;

  img {
    width: 20rem;
  }
  padding-bottom: ${theme.spacing.x4};

  a {
    text-decoration: underline;
    &:hover {
      color: ${theme.colors.N70};
    }
  }
`;

const BarContainer = styled.div`
  padding: ${theme.spacing.x1} 0 ${theme.spacing.x2};
`;
const OnboardingItems = styled.div`
  width: 100%;
  ul {
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing.x1};
    margin: ${theme.spacing.x1} 0;
    li {
      display: flex;
      align-items: center;
      gap: ${theme.spacing.x1};
      a {
        color: ${theme.colors.N100};
        &:hover {
          color: ${theme.colors.N70};
        }
      }
      &.completed {
        text-decoration: line-through;
        input[type='checkbox'] {
          checked: true;
        }
      }
    }
  }
`;

function Panel() {
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const { t } = useTranslation();
  const [stats, setStats]: any = useState(null);
  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  const statsQueryKey = [queryKeys.currentWorkspace, workspaceSlug, 'stats'];

  useQuery(statsQueryKey, remoteApi.fetchWorkspaceStats, {
    staleTime: 0,
    onSuccess: (response) => {
      setStats(response.data);
    },
  });

  let planClass = '';
  let userClass = '';
  let integrationClass = '';
  let checkinClass = '';

  // Return true if one of the following actions have not been done:
  // - There are no published plans
  // - There's only one user
  // - There are no closed tasks
  // - There are no checkins
  const calculateStepsCompleted = (stats: any) => {
    let completedSteps = 1; // counting the workspace creation

    if (stats.published_plans_count > 0) {
      completedSteps++;
      planClass = 'completed';
    }

    if (stats.users_count > 1) {
      completedSteps++;
      userClass = 'completed';
    }

    if (stats.integrations_count > 0) {
      completedSteps++;
      integrationClass = 'completed';
    }

    if (stats.checkins_count > 0) {
      completedSteps++;
      checkinClass = 'completed';
    }
    return completedSteps;
  };

  const isAdmin = ['admin', 'owner'].includes(currentMembership.role);
  if (!isAdmin) {
    return null;
  }

  if (workspaceUtils.hasSubscription(currentWorkspace) && currentWorkspace.billing_subscription_status !== 'trialing') {
    return null;
  }

  if (!stats) {
    return null;
  }

  const stepsCompleted = calculateStepsCompleted(stats);
  const stepsTotal = 5;

  // Don't display if we have completed the 5 steps.
  if (stepsCompleted >= stepsTotal) {
    return null;
  }

  const prct = (stepsCompleted / stepsTotal) * 100;

  return (
    <Container>
      <OnboardingItems>
        <h1>{t('workspaceInbox.onboarding.checkistTitle')}</h1>
        <BarContainer>
          <KoalaProgressBar value={prct} barColor={theme.colors.T80} bgColor={theme.colors.T20} appearance="custom" />
        </BarContainer>
        <p>
          Fast track your onboarding with our{' '}
          <Link to={`/${currentWorkspace.slug}/accelerator`}>Tability Accelerator resources</Link>
        </p>

        <ul>
          <li className="completed">
            <input type="checkbox" checked readOnly={true} />
            {t('workspaceInbox.onboarding.step1')}
          </li>
          <li className={planClass}>
            <input type="checkbox" readOnly={true} checked={planClass === 'completed'} />
            <Link to={`/${workspaceSlug}/plans`} className="primary">
              {t('workspaceInbox.onboarding.step3')}
            </Link>
          </li>
          <li className={userClass}>
            <input type="checkbox" readOnly={true} checked={userClass === 'completed'} />
            <Link to={`/${workspaceSlug}/people`} className="primary">
              {t('workspaceInbox.onboarding.step2')}
            </Link>
          </li>
          <li className={integrationClass}>
            <input type="checkbox" readOnly={true} checked={integrationClass === 'completed'} />
            <Link to={`/${workspaceSlug}/settings/integrations`} className="primary">
              {t('workspaceInbox.onboarding.step4')}
            </Link>
          </li>
          <li className={checkinClass}>
            <input type="checkbox" readOnly={true} checked={checkinClass === 'completed'} />
            <a
              href="https://guides.tability.app/docs/tutorials/track-progress-with-check-ins"
              target="_blank"
              rel="noopener noreferrer"
              className="primary"
            >
              {t('workspaceInbox.onboarding.step5')}
            </a>
          </li>
        </ul>
        <small className="subtle">{t('workspaceInbox.onboarding.checklistDismiss')}</small>
      </OnboardingItems>
      <div>
        <img src={OnboardingTabby} alt="Tabby is content!" />
      </div>
    </Container>
  );
}

export default Panel;
