import React, { useEffect, useState } from 'react';
import OutcomeWeight from 'components/OutcomeWeight';
import { Base64 } from 'js-base64';
import { Link, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'theme';
import { Outcome } from 'types';
import * as checkinUtils from 'utils/checkinUtils';
import * as membershipUtils from 'utils/membershipUtils';
import * as routes from 'routes';

import * as remoteApi from 'api/remote';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import KoalaTextBadge from 'koala/components/TextBadge';
import UserSelectButton from 'components/UserSelectButton';
import { useQueryCache, useMutation } from 'react-query';
import queryKeys from 'config/queryKeys';
import KoalaStackedAvatar from 'koala/components/StackedAvatars';
import RelatedOutcomesForParentOutcome from 'components/RelatedOutcomesForParentOutcome';
import RelatedObjectivesForParentOutcome from 'components/RelatedObjectivesForParentOutcome';
import KoalaIconButton from 'koala/components/IconButton';
import KoalaColorBlock from 'koala/components/ColorBlock';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import PlanIconLabel from 'components/PlanIconLabel';
// import BlockActions from 'components/BlockActions';
import CurrentGrowth from 'components/CurrentGrowth';

import { setGlobalModalContent } from 'state/actions/globalUIActions';

// Components
import Parents from './Parents';
import KoalaIcon from 'koala/components/Icons';
import OutcomeCurrentValueBar from 'components/OutcomeCurrentValueBar';
import OutcomeCard from 'components/OutcomeCard';

const Container = styled.div`
  .show-on-hover {
    opacity: 0;
    transition: all ease 0.2s;
  }

  &:hover {
    .show-on-hover {
      opacity: 1;
    }
  }

  .avatar-tooltip {
    padding: 0px;
    background: transparent;
    border: none;
    &.show {
      opacity: 1 !important;
    }
  }
`;
const OutcomeRow = styled.div`
  display: grid;
  position: relative;

  grid-template-columns: 1fr auto auto;
  grid-template-areas: 'left plan right';
  column-gap: ${theme.spacing.x1};
  align-items: start;
  cursor: pointer;

  &:hover {
    background: ${theme.colors.N3};
  }
  &.selected,
  &:active,
  &:focus {
    background: ${theme.colors.B5};
  }
  &.no-hover-bg {
    &:hover {
      background: transparent;
    }
  }

  &.hide-tags {
    grid-template-columns: 1fr auto auto;
  }

  &.highlight {
    background-color: ${theme.colors.V5};
  }

  @media ${theme.devices.smallDesktop} {
    grid-template-columns: 1fr auto auto;
    grid-template-areas: 'left plan right';
  }
  @media ${theme.devices.laptop} {
    grid-template-columns: 1fr auto;
    grid-template-areas: 'left right';
  }
  @media ${theme.devices.tablet} {
    grid-template-columns: 1fr;
    grid-template-areas: 'left';
  }

  .block-actions {
    opacity: 0;
    z-index: 2;
  }

  &:hover {
    background: ${theme.colors.N3};
    .block-actions {
      opacity: 1;
    }
  }
`;

const BlockWrapper = styled.div`
  padding: 1.2rem 0;
`;

const BlockLeft = styled.div`
  grid-area: left;
  display: flex;
  gap: ${theme.spacing.x1};

  > div {
    &:first-of-type {
      padding-left: 1.2rem;
    }
  }
`;

const BlockPlan = styled.div`
  grid-area: plan;
  width: 28rem;
  padding: 1.2rem 0;

  @media ${theme.devices.smallDesktop} {
    width: 15rem;
  }

  @media ${theme.devices.laptop} {
    display: none;
  }
`;
const BlockRight = styled.div`
  grid-area: right;
  align-items: start;
  justify-content: right;
  gap: ${theme.spacing.x1};
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-template-areas: 'tags progress owners actions';
  padding: 1.2rem 0;

  @media ${theme.devices.smallDesktop} {
    grid-template-columns: auto auto;
    grid-template-areas: 'progress owners';
  }
  @media ${theme.devices.tablet} {
    display: none;
  }
`;

export const BlockGutter = styled.div`
  grid-area: gutter;
  display: flex;
  padding: 1.2rem;
  align-self: flex-start;
  position: relative;
  top: 0.2rem;

  .expand-gutter {
    transition: all ease 0.2s;
  }
  div.expanded {
    transform: rotate(90deg);
  }

  &:hover {
    background: ${theme.colors.N10};
  }

  @media ${theme.devices.mobile} {
    display: none;
  }
`;

export const BlockContent = styled.div`
  font-weight: 400;
  grid-area: title;
  background: transparent;
  margin: 0;
  display: flex;
  align-items: flex-start;
  gap: ${theme.spacing.x1};
  min-width: 0rem;

  width: 100%;

  .color-block {
    min-width: 20px;
  }

  a {
    &:hover {
      text-decoration: none;
    }
  }

  @media ${theme.devices.mobile} {
    padding-left: 1.2rem;
  }
`;

const BlockTags = styled.div`
  grid-area: tags;
  display: flex;
  max-width: 20rem;
  justify-content: flex-end;
  gap: ${theme.spacing.x1};
  flex-wrap: wrap;

  @media ${theme.devices.smallDesktop} {
    display: none;
  }
`;

const BlockActions = styled.div`
  grid-area: actions;
  position: relative;
  width: 7rem;
  display: flex;

  > div {
    width: 100%;
    position: absolute;
    right: ${theme.spacing.x2};
    top: -0.5rem;
    display: flex;
    gap: ${theme.spacing.half};
    align-items: center;
    justify-content: flex-end;
  }
  padding: 0;
`;

const BlockOwners = styled.div`
  grid-area: owners;
  display: flex;
  align-items: center;
  justify-content: left;
  height: 2rem;
  width: 8rem;
  flex-direction: row-reverse;

  .contributors {
    margin-left: -12px;
  }

  @media ${theme.devices.smallDesktop} {
  }

  @media ${theme.devices.mobile} {
    display: none;
  }
`;

const CurrentGrowthContainer = styled.div`
  width: 5rem;
`;

const BlockProgress = styled.div`
  grid-area: progress;
  display: flex;
  width: 16rem;
  gap: ${theme.spacing.x1};
  justify-content: end;
  align-items: center;
  @media ${theme.devices.tablet} {
    display: none;
  }
`;

const ChildrenContainer = styled.div`
  border-top: 1px solid ${theme.colors.blockBorder};
`;

interface Props {
  outcome: Outcome;
  defaultExpanded?: boolean;
  hideClosedInitiatives?: boolean;
  hideOwners?: boolean;
  showPlan?: boolean;
  hideExpand?: boolean;
  hideTags?: boolean;
  hideActions?: boolean;
  outcomeIndex?: string;
  hasEditPermission?: boolean;
  showParentsLabel?: boolean;
  setDefaultChanged?: (value: boolean) => void;
  defaultChanged?: boolean;
}

function OutcomeExpandable(props: Props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryCache = useQueryCache();
  const params = new URLSearchParams(location.search);
  const isOnboardingRoute = params.get('onboarding') === '1';

  const {
    outcome,
    hideExpand,
    hideOwners,
    defaultExpanded,
    hideClosedInitiatives,
    hideActions,
    showPlan,
    hideTags,
    outcomeIndex,
    hasEditPermission,
    showParentsLabel,
    setDefaultChanged,
    defaultChanged,
  } = props;
  const { nano_slug, current_checkin, cached_tag_list, membership, plan } = outcome;
  const { t } = useTranslation();

  const history = useHistory();
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const isReadOnly = membershipUtils.isReadOnly(currentMembership);

  // Expands the first outcome if we're on the onboarding route
  //const shouldBeExpanded = (defaultExpanded ?? false) || (isOnboardingRoute && outcomeIndex === '0-0');
  const [isExpanded, setIsExpanded] = useState(defaultExpanded ?? false);

  const hashPath = `#outcome:${nano_slug}:show`;
  const hashEditPath = `#outcome:${nano_slug}:edit`;
  const handleOutcomeClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(hashPath);
  };

  const handleOutcomeEdit = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(hashEditPath);
  };

  useEffect(() => {
    // when main expand/collapse triggered it will set default changed to false
    // so if default changed is false, reset isExpanded back to default
    if (!defaultChanged) {
      setIsExpanded(defaultExpanded ?? false);
    }
  }, [defaultChanged, defaultExpanded]);

  useEffect(() => {
    const shouldBeExpanded = (defaultExpanded ?? false) || (isOnboardingRoute && outcomeIndex === '0-0');
    if (shouldBeExpanded) {
      setIsExpanded(true);
      if (setDefaultChanged) {
        setDefaultChanged(true);
      }
    }
  }, [defaultExpanded, isOnboardingRoute, outcomeIndex, setDefaultChanged]);

  const toggleExpand = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsExpanded((prev) => !prev);
    if (setDefaultChanged) {
      setDefaultChanged(true);
    }
  };

  const [updateOutcomeMutation] = useMutation(remoteApi.updateOutcome, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.currentOutcome);
      queryCache.invalidateQueries(queryKeys.outcomes);
    },
  });
  const handleAssignUser = (value: string) => {
    const membership_id = value;
    if (membership_id) {
      const params = {
        membership_id,
      };

      updateOutcomeMutation({
        outcomeId: outcome.nano_slug,
        outcome: params,
      });
    }
  };

  const handleUnassignUser = () => {
    const params = {
      membership_id: null,
    };
    updateOutcomeMutation({
      outcomeId: outcome.id,
      outcome: params,
    });
  };

  const handleShowCheckinForm = (e: React.MouseEvent) => {
    e.stopPropagation();
    const blockId = `outcome:${outcome.nano_slug}`;
    dispatch(setGlobalModalContent(`${blockId}:create.checkin`));
  };

  const currentConfidence = current_checkin ? current_checkin.confidence : 'grey';
  const tags = cached_tag_list && cached_tag_list.length > 0 ? cached_tag_list.split(',') : [];

  const expandedClassName = isExpanded ? 'expanded' : '';
  const hideTagsClassName = hideTags ? 'hide-tags' : '';

  const confidenceColor = checkinUtils.confidenceToColor(currentConfidence);

  let indexClassname = outcomeIndex ? `outcome-${outcomeIndex}` : '';

  return (
    <Container className={`outcome-expanded ${indexClassname}`}>
      <OutcomeRow
        className={`outcome-row ${hideTagsClassName} outcome-${outcome.nano_slug}`}
        onClick={handleOutcomeClick}
        id={nano_slug}
      >
        <BlockLeft className="outcome-row--left">
          {!hideExpand && (
            <BlockGutter onClick={toggleExpand}>
              <KoalaIcon iconSize={1.6} className={`${expandedClassName} expand-gutter`} iconName="rightChevron" />
            </BlockGutter>
          )}
          <BlockWrapper>
            <BlockContent>
              <KoalaColorBlock
                color={confidenceColor}
                size="small"
                isPending={outcome.is_pending_checkin}
                isCompleted={outcome.completed}
                dataFor={`outcome-block-${outcome.nano_slug}`}
              />
              <ReactTooltip
                place="bottom"
                type="light"
                effect="solid"
                clickable={true}
                delayHide={200}
                delayShow={200}
                className="avatar-tooltip"
                arrowColor="transparent"
                id={`outcome-block-${outcome.nano_slug}`}
              >
                <OutcomeCard outcome={outcome} />
              </ReactTooltip>
              <OutcomeWeight outcome={outcome} />
              <Link to={hashPath}>{outcome.title}</Link>
              {showParentsLabel && false && <Parents outcomeId={outcome.id} />}
            </BlockContent>
          </BlockWrapper>
        </BlockLeft>
        {showPlan && plan && (
          <BlockPlan className="outcome-row--plan">
            <PlanIconLabel plan={plan} size="small" />
          </BlockPlan>
        )}
        <BlockRight className="outcome-row--right">
          {!hideTags && (
            <BlockTags className="outcome-row--tags">
              {tags.map((tag) => {
                const filterHash = { tag: [{ value: tag, label: tag }] };
                const encodedHash = Base64.encode(JSON.stringify(filterHash));
                const filterPath = routes.WORKSPACE_OUTCOMES_ROUTE_WITH_FILTERS.replace(
                  ':workspaceSlug',
                  currentWorkspace.slug,
                ).replace(':filter', encodedHash);
                return (
                  <>
                    <KoalaTextBadge
                      isLowercase={true}
                      edge="circle"
                      key={tag}
                      variant="neutral-outlined"
                      size="small"
                      maxWidth="10rem"
                      linkTo={filterPath}
                      dataFor={`tag-${tag}`}
                    >
                      {tag}
                    </KoalaTextBadge>
                    <ReactTooltip type="dark" id={`tag-${tag}`} className="tooltip" effect="solid">
                      {tag}
                    </ReactTooltip>
                  </>
                );
              })}
            </BlockTags>
          )}
          <BlockProgress className="outcome-row--progress" data-tip data-for={`outcome-progress-${outcome.nano_slug}`}>
            <OutcomeCurrentValueBar outcome={outcome} checkin={outcome.current_checkin} />
            <CurrentGrowthContainer>
              <CurrentGrowth outcome={outcome} />
            </CurrentGrowthContainer>
          </BlockProgress>
          <ReactTooltip
            place="bottom"
            type="light"
            effect="solid"
            clickable={true}
            delayHide={200}
            delayShow={200}
            className="avatar-tooltip"
            arrowColor="transparent"
            id={`outcome-progress-${outcome.nano_slug}`}
          >
            <OutcomeCard outcome={outcome} />
          </ReactTooltip>
          {!hideOwners && (
            <BlockOwners className="outcome-row--owners">
              <KoalaStackedAvatar
                owner={membership ? membership.user : null}
                contributors={outcome.contributors}
                size="small"
                hideOwner={true}
                className="contributors"
                direction="right"
                showNumber={true}
              />
              <UserSelectButton
                canEdit={!isReadOnly && hasEditPermission === true}
                handleAssignUser={handleAssignUser}
                selectedUser={membership ? membership.user : null}
                handleUnassignUser={handleUnassignUser}
                size="small"
                defaultLink={membership ? `/${currentWorkspace.slug}/people/${membership.id}` : undefined}
              />
            </BlockOwners>
          )}
          {!hideActions && !isReadOnly && (
            <BlockActions className="block-actions">
              <div>
                <KoalaIconButton
                  dataFor={`${outcome.nano_slug}-action-checkin`}
                  edge="square"
                  iconName="writeUpdate"
                  size="small"
                  onClick={handleShowCheckinForm}
                  className="icon-btn--primary"
                />
                <ReactTooltip
                  place="bottom"
                  type="dark"
                  id={`${outcome.nano_slug}-action-checkin`}
                  className="tooltip"
                  effect="solid"
                >
                  {t('shared.createCheckin')}
                </ReactTooltip>
                {hasEditPermission && (
                  <>
                    <KoalaIconButton
                      dataFor={`${outcome.nano_slug}-action-edit`}
                      edge="square"
                      iconName="settings"
                      size="small"
                      onClick={handleOutcomeEdit}
                    />
                    <ReactTooltip
                      place="bottom"
                      type="dark"
                      id={`${outcome.nano_slug}-action-edit`}
                      className="tooltip"
                      effect="solid"
                    >
                      {t('shared.edit')}
                    </ReactTooltip>
                  </>
                )}
              </div>
            </BlockActions>
          )}
        </BlockRight>
      </OutcomeRow>
      {isExpanded && (
        <ChildrenContainer>
          <RelatedObjectivesForParentOutcome
            outcomeId={outcome.id}
            hideClosedInitiatives={hideClosedInitiatives ?? false}
            isInitial={true}
          />
          <RelatedOutcomesForParentOutcome
            outcomeId={outcome.id}
            hideClosedInitiatives={hideClosedInitiatives ?? false}
            isInitial={true}
          />
        </ChildrenContainer>
      )}
    </Container>
  );
}

export default OutcomeExpandable;
