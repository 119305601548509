import { ModalContent, ModalGrid, ModalHeader } from 'components/GlobalModal';
import queryKeys from 'config/queryKeys';
import KoalaIconButton from 'koala/components/IconButton';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import * as remoteApi from 'api/remote';
import KoalaAvatar from 'koala/components/Avatar';
import { useDispatch } from 'react-redux';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import styled from 'styled-components';
import theme from 'theme';
import KoalaLoader from 'koala/components/Loader';
import { useTranslation } from 'react-i18next';
import { Membership } from 'types';

const StreakContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x2};
  padding: ${theme.spacing.x2} 0;
`;

const Meta = styled.div`
  display: flex;
  gap: ${theme.spacing.x2};
  align-items: center;
`;

const UserInfo = styled.div`
  display: flex;
  gap: ${theme.spacing.x2};
  align-items: center;

  b {
    font-size: 2rem;
  }
`;

const StreakData = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.half};
`;

function StreakLadder() {
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const translationKey = 'modals.streakLadder';
  const [streakLadder, setStreakLadder] = useState<any>([]);

  // Get the plan details
  const { isLoading } = useQuery(
    [queryKeys.currentWorkspace, workspaceSlug, 'streak_ladder'],
    remoteApi.fetchWorkspaceStreakStats,
    {
      staleTime: 0,
      onSuccess: (response: any) => {
        setStreakLadder(response.data);
      },
    },
  );

  const handleCancel = (e: any) => {
    e.preventDefault();
    dispatch(setGlobalModalContent(''));
  };

  const handleAvatarClicked = (membership: Membership) => {
    dispatch(setGlobalModalContent(''));
    history.push(`/${workspaceSlug}/people/${membership.id}`);
  };

  return (
    <ModalGrid>
      <ModalHeader>
        <h2>{t(`${translationKey}.title`)}</h2>
        <KoalaIconButton onClick={handleCancel} iconName="close" />
      </ModalHeader>
      <ModalContent>
        <h3>{t(`${translationKey}.top`)}</h3>
        {isLoading && (
          <StreakContainer>
            <KoalaLoader />
          </StreakContainer>
        )}
        {!isLoading && (
          <StreakContainer>
            {streakLadder.length === 0 && <span>{t(`${translationKey}.noActive`)}</span>}
            {streakLadder.map((item: any, index: number) => {
              const { membership, current_streak } = item;
              const { user } = membership;
              return (
                <Meta>
                  <UserInfo>
                    <b>{index + 1}</b>
                    <KoalaAvatar user={user} handleClick={() => handleAvatarClicked(membership)} />
                  </UserInfo>
                  <StreakData>
                    <span>{user.fullname}</span>
                    <small>
                      <span role="img">🔥</span>
                      {t(`${translationKey}.streak`, { count: current_streak })}
                    </small>
                  </StreakData>
                </Meta>
              );
            })}
          </StreakContainer>
        )}
      </ModalContent>
    </ModalGrid>
  );
}

export default StreakLadder;
