import React from 'react';

import KoalaTextBadge from 'koala/components/TextBadge';
import { translateRole } from 'utils/translationUtils';
import { useTranslation } from 'react-i18next';

interface Props {
  role: string;
}

function RoleBadge(props: Props) {
  const { t } = useTranslation();
  const { role } = props;
  const roleString = translateRole(role, t);

  switch (role) {
    case 'readonly':
      return (
        <KoalaTextBadge variant="neutral-outlined" isLowercase={true} size="small">
          {roleString}
        </KoalaTextBadge>
      );
    case 'admin':
      return (
        <KoalaTextBadge variant="violet-outlined" isLowercase={true} size="small">
          {roleString}
        </KoalaTextBadge>
      );
    case 'owner':
      return (
        <KoalaTextBadge variant="red-outlined" isLowercase={true} size="small">
          {roleString}
        </KoalaTextBadge>
      );
    default:
      return null;
  }
}

export default React.memo(RoleBadge);
