import { AxiosResponse } from 'axios';
import PlanIconLabel from 'components/PlanIconLabel';
import PlanStatus from 'components/PlanStatus';
import KoalaIcon from 'koala/components/Icons';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { WORKSPACE_PLAN_TEMPLATE_ROUTE, WORKSPACE_PLAN_TRACK_ROUTE, WORKSPACE_PLAN_WRITE_ROUTE } from 'routes';
import styled from 'styled-components';
import theme from 'theme';
import { Plan, Workspace } from 'types';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import KoalaTextBadge from 'koala/components/TextBadge';
import { capitaliseFirst, formatLocale } from 'utils/dateUtils';
import ProgressBarV2 from 'components/ProgressBarV2';
import ConfidenceBar from 'components/ConfidenceBar';

const PlansLine = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${theme.colors.blockBorder};
  border-radius: 4px;
  overflow: hidden;
  .item:last-of-type {
    border-bottom: 0px;
  }

  > div {
    display: flex;
    align-items: center;
    gap: ${theme.spacing.half};
    min-height: 2.4rem;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
  }
`;
const PlanRow = styled(Link)`
  background: #ffffff;
  padding: 1.2rem ${theme.spacing.x2};
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${theme.colors.blockBorder};

  &:hover {
    background: ${theme.colors.N3};
    cursor: pointer;
  }
  &:focus,
  &:active {
    background: ${theme.colors.B5};
  }
`;

const ColumnHeader = styled.div`
  padding: 0 ${theme.spacing.x2} ${theme.spacing.x1} ${theme.spacing.x2};
  display: grid;
  color: ${theme.colors.N80};
  grid-template-areas: 'plan outcomes initiatives confidence timeline status';
  grid-template-columns: 1fr 9rem 9rem 9rem 10rem 10rem;
  gap: ${theme.spacing.x2};

  > label {
    display: flex;
    align-items: center;
    gap: ${theme.spacing.half};
    min-height: 2.4rem;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
  }
  .plan {
    grid-area: plan;
  }
  .outcomes {
    justify-content: center;
    grid-area: outcomes;
  }
  .initiatives {
    justify-content: center;
    grid-area: initiatives;
  }
  .confidence {
    justify-content: center;
    grid-area: confidence;
  }
  .timeline {
    grid-area: timeline;
  }
  .status {
    grid-area: status;
  }

  @media ${theme.devices.laptop} {
    column-gap: ${theme.spacing.x1};
    grid-template-areas: 'plan status';
    grid-template-columns: 1fr 13rem;
    .timeline,
    .outcomes,
    .initiatives,
    .confidence {
      display: none;
    }
  }

  @media ${theme.devices.tablet} {
    display: none;
  }
`;
const MetaBlock = styled.div`
  display: grid;
  grid-template-columns: 8rem 8rem 9rem 10rem 10rem;
  justify-content: end;
  gap: ${theme.spacing.x2};
  align-items: center;
  @media ${theme.devices.laptop} {
    grid-template-columns: 4.5rem 4.5rem 4.5rem 11rem;
    gap: ${theme.spacing.x1};
  }
  @media ${theme.devices.tablet} {
    grid-template-columns: 11rem;
  }
`;
const StatBlock = styled.div`
  @media ${theme.devices.tablet} {
    display: none;
  }
`;
const TimelineBlock = styled.div`
  width: 10rem;
  div {
    width: fit-content;
  }

  @media ${theme.devices.laptop} {
    display: none;
  }
`;
const StatusBlock = styled.div`
  min-width: 8rem;
  @media ${theme.devices.mobile} {
    display: none;
  }
`;
interface Props {
  plans: AxiosResponse<Plan[]>[];
  workspace: Workspace;
}

function PlanViewList(props: Props) {
  const { plans, workspace } = props;
  const { t, i18n } = useTranslation();
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  return (
    <div>
      <ColumnHeader>
        <label className="plan">{t('workspacePlans.table.plan')}</label>
        <label className="outcomes">{translate(currentWorkspace, CustomTermKey.OUTCOME, 2)}</label>
        <label className="initiatives">{translate(currentWorkspace, CustomTermKey.INITIATIVE, 2)}</label>
        <label className="confidence">
          {t('workspacePlans.table.confidence')}
          <KoalaIcon iconName="tooltip" iconSize="small" data-tip data-for="explain-ncs" />
        </label>
        <label className="timeline">{t('workspacePlans.table.timeline')}</label>
        <label className="status">{t('workspacePlans.table.status')}</label>
      </ColumnHeader>
      <PlansLine>
        {plans.map((group: any, i: number) => {
          return (
            <Fragment key={i}>
              {group.data.map((p: Plan, index: number) => {
                const stateToRouteMapping: any = {
                  draft: WORKSPACE_PLAN_WRITE_ROUTE,
                  published: WORKSPACE_PLAN_TRACK_ROUTE,
                  template: WORKSPACE_PLAN_TEMPLATE_ROUTE,
                };

                const outcomeProgress = p.outcome_progress_prct * 100;
                const initiativeProgress = p.initiative_progress_prct * 100;

                let routeMappingState = p.state || 'draft';
                const routeTarget = stateToRouteMapping[routeMappingState];

                const planRoute = routeTarget.replace(':workspaceSlug', workspace.slug).replace(':planId', p.nano_slug);

                return (
                  <PlanRow key={p.id} to={planRoute} className="item">
                    <PlanIconLabel plan={p} />
                    <MetaBlock>
                      <StatBlock>
                        <ProgressBarV2
                          percentage={outcomeProgress}
                          totalCount={p.total_outcomes_count}
                          label={`${Math.round(outcomeProgress)}%`}
                        />
                      </StatBlock>
                      <StatBlock>
                        <ProgressBarV2
                          percentage={initiativeProgress}
                          totalCount={p.total_initiatives_count}
                          label={`${p.closed_initiatives_count}/${p.total_initiatives_count}`}
                        />
                      </StatBlock>
                      <StatBlock>
                        <ConfidenceBar
                          greenCount={p.green_outcomes_count}
                          greyCount={p.grey_outcomes_count}
                          redCount={p.red_outcomes_count}
                          totalCount={p.total_outcomes_count}
                          yellowCount={p.yellow_outcomes_count}
                          ncs={Math.round(p.ncs)}
                        />
                      </StatBlock>
                      <TimelineBlock>
                        {p.start_at && p.finish_at && (
                          <div>
                            <KoalaTextBadge isLowercase={true} variant="neutral-light" size="small">
                              {capitaliseFirst(formatLocale(p.start_at, 'MMM', i18n))}
                              &nbsp;&#10142;&nbsp;
                              {capitaliseFirst(formatLocale(p.finish_at, 'MMM yyyy', i18n))}
                            </KoalaTextBadge>
                          </div>
                        )}
                      </TimelineBlock>
                      <StatusBlock className="status">
                        <PlanStatus plan={p} />
                      </StatusBlock>
                    </MetaBlock>
                  </PlanRow>
                );
              })}
            </Fragment>
          );
        })}
      </PlansLine>
    </div>
  );
}

export default PlanViewList;
