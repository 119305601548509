import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { shallowEqual, useSelector } from 'react-redux';
import * as TabilityTypes from 'types';
import { useDispatch } from 'react-redux';
import * as planUtils from 'utils/planUtils';
import * as membershipUtils from 'utils/membershipUtils';
import { useTranslation } from 'react-i18next';
import { formatLocale } from 'utils/dateUtils';
import { useHistory } from 'react-router-dom';

// API and State
import { setGlobalModalContent } from 'state/actions/globalUIActions';

// Components
import KoalaButton from 'koala/components/Button';
import Loader from 'components/Loader';
import SlackIntegration from './SlackIntegration';
import TeamRelationships from './TeamRelationships';
import PlanWarnings from './PlanWarnings';
import PlanSubscribers from './PlanSubscribers';
import KoalaTextButton from 'koala/components/TextButton';
import KoalaIcon from 'koala/components/Icons';
import ReactTooltip from 'react-tooltip';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x3};
  padding: ${theme.spacing.x4} 0;
  h3 {
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: 800;
    display: flex;
    align-items: center;
  }

  @media ${theme.devices.laptop} {
    padding-top: 0;
  }
`;

const BlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
  background: ${theme.colors.N0};
  border: 1px solid ${theme.colors.blockBorder};
  padding: ${theme.spacing.x1} ${theme.spacing.x2};
  border-radius: 4px;
  margin-bottom: ${theme.spacing.x1};
`;

const LoadingContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const InnerBlock = styled.div`
  margin-bottom: ${theme.spacing.x2};
  &:last-of-type {
    margin-bottom: 0;
  }
`;

const SampleBlock = styled.div`
  padding: 1.2rem ${theme.spacing.x2};
  margin-bottom: ${theme.spacing.x1};
  background: ${theme.colors.Y20};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  button {
    margin-top: ${theme.spacing.x1};
  }
`;

const TeamsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
`;

interface Props {
  plan: TabilityTypes.Plan;
  outcomes: TabilityTypes.Outcome[];
  objectives: TabilityTypes.Objective[];
}

function PlanInfo(props: Props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { plan, outcomes, objectives } = props;
  const { t, i18n } = useTranslation();
  const handleDeletePlan = () => {
    const blockId = `plan:${plan.id}`;
    dispatch(setGlobalModalContent(`${blockId}:delete`));
  };

  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const isReadOnly = membershipUtils.isReadOnly(currentMembership);

  const hasEditPermission = planUtils.hasEditPermission(plan, currentMembership);

  // Now we can display the app with the Chrome
  if (!plan) {
    return (
      <LoadingContainer>
        <Loader size="medium" />
      </LoadingContainer>
    );
  }

  const hasTimeline = plan ? plan.start_at && plan.finish_at : false;
  const planStartAt = plan && plan.start_at ? formatLocale(plan.start_at, 'MMM do', i18n) : null;
  const planFinishAt = plan && plan.finish_at ? formatLocale(plan.finish_at, 'MMM do', i18n) : null;

  const handlePreviewPlan = () => {
    if (!plan.parent) {
      return;
    }
    const previewUrl = `#plan:${plan.parent.nano_slug}:preview`;
    history.push(previewUrl);
  };

  return (
    <Container>
      {plan.is_dummy && (
        <SampleBlock>
          <p>{t('workspacePlan.sampleTitle')}</p>
          <div>
            <KoalaButton onClick={handleDeletePlan} appearance="subtle">
              {t('workspacePlan.sampleData')}
            </KoalaButton>
          </div>
        </SampleBlock>
      )}
      <BlockWrapper>
        <h3>
          {t('workspacePlan.planSubscribers')}
          <KoalaIcon iconName="tooltip" iconSize="small" data-tip data-for="explain-subscribers" />
        </h3>
        <ReactTooltip
          place="bottom"
          type="dark"
          className="tooltip"
          effect="solid"
          id="explain-subscribers"
          delayHide={500}
          clickable={true}
        >
          {t('modals.planSubscribers.info')}
        </ReactTooltip>
        <PlanSubscribers plan={plan} />
        {!isReadOnly && (
          <>
            <SlackIntegration plan={plan} />
            {plan.parent && (
              <>
                <h3>{t('workspacePlan.parentPlan')}</h3>
                <InnerBlock>
                  <KoalaTextButton onClick={handlePreviewPlan} size="small">
                    {plan.parent.title}
                  </KoalaTextButton>
                </InnerBlock>
              </>
            )}
          </>
        )}
        {!isReadOnly && (
          <>
            <h3>{t('workspacePlan.teams')}</h3>
            <InnerBlock>
              <TeamsList>
                <TeamRelationships plan={plan} canEdit={hasEditPermission} />
              </TeamsList>
            </InnerBlock>
          </>
        )}
        {hasTimeline && (
          <>
            <h3>{t('workspacePlan.timeline')}</h3>
            <InnerBlock>
              <div>
                <small>
                  {planStartAt}
                  &nbsp;&#10142;&nbsp;
                  {planFinishAt}
                </small>
              </div>
              {!plan.expired && (
                <div>
                  <small className="subtle">
                    {plan.reminders_period === 'week'
                      ? t('workspacePlan.checkinWeek', { count: plan.reminders_interval })
                      : t('workspacePlan.checkinMonth', { count: plan.reminders_interval })}
                  </small>
                </div>
              )}
            </InnerBlock>
          </>
        )}
      </BlockWrapper>
      {!isReadOnly && <PlanWarnings plan={plan} outcomes={outcomes} objectives={objectives} />}
    </Container>
  );
}

export default PlanInfo;
