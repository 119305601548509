import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import * as TabilityTypes from 'types';
import * as outcomeUtils from 'utils/outcomeUtils';

const Container = styled.div`
  font-size: 1.1rem;
  color: ${theme.colors.subtleText};
  font-weight: 500;
  &.positive {
    color: ${theme.colors.G60};
  }
  &.negative {
    color: ${theme.colors.R60};
  }
  &.neutral {
    color: ${theme.colors.Y80};
  }
  &.kpi {
    color: ${theme.colors.B60};
  }
`;

const CurrentGrowthContainer = styled.div``;

interface Props {
  outcome: TabilityTypes.Outcome;
  showNull?: boolean;
}

function CurrentGrowth(props: Props) {
  const { outcome, showNull } = props;

  const { current_checkin } = outcome;

  // Get current growth between current checkin and previous checkin (or starting point)
  const currentGrowth = outcomeUtils.getProgressPercentageSinceLastCheckin(outcome);

  const getNullValue = () => {
    if (showNull) {
      return <>–</>;
    }
    return null;
  };

  // Don't display anything if there's no currentGrowth information, or if current_checkin is missing
  if (currentGrowth === null || current_checkin === null || current_checkin === undefined) {
    return getNullValue();
  }

  // Set the default to kpi class, we'll change everything if necessary with the logic below
  let currentGrowthClass = 'kpi';

  // The switch below handles the growth class for improve_metric, stay_above, and stay_below outcome types.
  switch (outcome.outcome_type) {
    case 'improve_metric':
      // Check that we have an outcome with the right data
      if (outcome.from === null || outcome.to === null) {
        return getNullValue();
      }
      const targetDifference = outcome.to - outcome.from;
      if (targetDifference * currentGrowth < 0) {
        currentGrowthClass = 'negative';
      } else if (currentGrowth === 0) {
        currentGrowthClass = 'neutral';
      } else {
        currentGrowthClass = 'positive';
      }
      break;
    case 'stay_above':
      if (outcome.to === null) {
        return getNullValue();
      }
      if (currentGrowth < 0) {
        currentGrowthClass = 'negative';
      } else if (currentGrowth === 0) {
        currentGrowthClass = 'neutral';
      } else {
        currentGrowthClass = 'positive';
      }
      break;
    case 'stay_below':
      if (outcome.to === null) {
        return getNullValue();
      }
      if (currentGrowth > 0) {
        currentGrowthClass = 'negative';
      } else if (currentGrowth === 0) {
        currentGrowthClass = 'neutral';
      } else {
        currentGrowthClass = 'positive';
      }
      break;
  }

  // Only return null if there's no currentGrowth
  // Note: avoid things like if (!currentGrowth), because currentGrowth can be 0, and !0 === true so the
  // logic would return null
  if (currentGrowth === null) {
    return getNullValue();
  }

  return (
    <CurrentGrowthContainer>
      <Container className={currentGrowthClass}>
        {currentGrowth >= 0 && <span>+</span>}
        <span>{outcomeUtils.nFormatter(currentGrowth, 2)}%</span>
      </Container>
    </CurrentGrowthContainer>
  );
}

export default CurrentGrowth;
