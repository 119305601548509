import React, { useEffect, Fragment, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import * as TabilityTypes from 'types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

// Actions
import {
  editorReorderObjective,
  editorReorderOutcome,
  editorMoveOutcome,
  editorReorderInitiative,
  editorMoveInitiative,
  editorSetDisplayNewBlockForm,
  editorSelectBlock,
  resetLoadingMetrics,
} from 'state/actions/workspaceEditorActions';

import { setGlobalModalContent } from 'state/actions/globalUIActions';

// Components
import BlockGrid, { BlockGutter, BlockContent, BlockMeta } from './BlockGrid';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import ObjectiveBlock from './ObjectiveBlock';
import ObjectiveForm from './ObjectiveForm';
import Panel from './Panel';
import PanelLabelButton from './PanelLabelButton';
import PlanActions from './PlanActions';
import PlanTitle from './PlanTitle';
import RemoteSync from './dataSync/RemoteSync';
import Scorecard from './Scorecard';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import PlanStatus from 'components/PlanStatus';
import ParentPlan from './ParentPlan';
import ChevronRightIcon from 'components/_assets/ChevronRightIcon';
import { Trans, useTranslation } from 'react-i18next';
import { formatLocale } from 'utils/dateUtils';
import KoalaButton from 'koala/components/Button';
import KoalaTextBadge from 'koala/components/TextBadge';
import ReactTooltip from 'react-tooltip';

import TabbyQuickstart from './_assets/tabby_magic.png';
import TabbyChatAI from './_assets/TabbyChatAI.png';
import TabbyDIY from './_assets/TabbyDIY.png';
import TabbyContent from './_assets/content_tabby.png';

const MetaInfo = styled.div`
  display: flex;
  align-items: center;
  margin-left: 21rem;

  > div {
    margin-right: ${theme.spacing.x1};
  }
`;

const Container = styled.div`
  height: 100%;
  display: flex;
  position: relative;
  flex-direction: column;

  .use-ai-button {
    border: 1px solid ${theme.colors.V50};
    color: ${theme.colors.V70};

    &:hover {
      background: ${theme.colors.V5};
    }
  }
`;

const ColumnsWrapper = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
  justify-content: center;
  flex: 1;
  overflow: hidden;
`;

const PlanContainer = styled.div`
  background: #fff;
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing.x1};
  padding-top: 0;
  overflow: scroll;
`;

const ChooseExperienceContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x3};
  align-items: center;
  justify-content: center;
  margin: ${theme.spacing.x3};
`;
const ExperienceOptionsContainer = styled.div`
  display: flex;
  gap: ${theme.spacing.x3};
  width: 100%;
`;
const ExperienceOption = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing.x3};
  gap: ${theme.spacing.x2};
  align-items: center;

  border: solid 1px ${theme.colors.N20};
  border-radius: 8px;

  img {
    height: 173px;
  }

  :hover {
    background: ${theme.colors.N5};
    cursor: pointer;
  }
  :active {
    background: ${theme.colors.N10};
  }
`;

const PlanScrollContainer = styled.div`
  flex: 1;
  position: relative;
`;

const ScorecardContainer = styled.div`
  position: relative;
  min-width: 28rem;
  width: 28rem;
  overflow: scroll;

  &.empty {
    opacity: 50%;
  }
`;

const ParentOverview = styled.div`
  position: relative;
  margin-left: 20.6rem;
  margin-bottom: ${theme.spacing.x2};

  @media ${theme.devices.desktop} {
    margin-left: 16.6rem;
  }
`;

const ToggleHeader = styled.div`
  display: flex;
  border-radius: 8px;
  gap: ${theme.spacing.x1};

  &:hover {
    cursor: pointer;
  }

  svg {
    height: 1rem;
  }

  .chevron {
    transition: all ease 0.2s;
    transform: rotate(0deg);
  }
  &.expanded {
    .chevron {
      transform: rotate(90deg);
    }
  }

  span {
    font-size: 2rem;
    color: ${theme.colors.subtleText};
  }
`;

const TimelineWrapper = styled.div`
  padding-bottom: 1.6rem;
`;

const AddObjectiveButton = styled.button`
  margin-bottom: ${theme.spacing.x2};
  margin-right: ${theme.spacing.x2};
`;

const PlanToolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
`;

const PublishBanner = styled.div`
  border-radius: 4px;
  background: ${theme.colors.infoBg};
  padding: 4px 8px;
  font-size: 1.4rem;
`;

const TemplateCreateHelper = styled.div`
  color: ${theme.colors.subtleText};
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.half};
  align-items: center;
  padding-bottom: ${theme.spacing.x2};
  > div {
    display: flex;
    align-items: center;
    gap: ${theme.spacing.x1};
  }
  img {
    width: 2.4rem;
    position: relative;
    bottom: 2px;
  }
  font-size: 1.4rem;
  button {
    font-size: 1.4rem !important;
    color: ${theme.colors.subtleText} !important;
    text-decoration: underline;
    font-weight: 400 !important;
  }
`;

const OnboardingContainer = styled.div`
  padding: ${theme.spacing.x1} ${theme.spacing.x2};
  max-width: 60rem;
  margin: 0 auto ${theme.spacing.x3} auto;
  border: 1px solid ${theme.colors.B20};
  border-radius: 4px;
  color: ${theme.colors.B70};
  background: ${theme.colors.B5};
  display: flex;
  gap: ${theme.spacing.x2};
  img {
    width: 4rem;
  }
`;

interface Props {
  plan: TabilityTypes.Plan;
}

function Editor(props: Props) {
  const history = useHistory();
  const { plan } = props;
  const dispatch = useDispatch();
  const currentPlan = useSelector((state: any) => state.editorEntities.plans[plan.id]);
  const objectiveIds = useSelector((state: any) => state.editorEntities.plansToObjectivesMapping[plan.id] || []);
  const outcomeIds = useSelector((state: any) => state.editorEntities.outcomeIds || []); // We're getting the outcome Ids to see if outcomes have been added.
  const blockId = `plan:${currentPlan.id}`;
  const isTemplate = plan.state === 'template';
  const { t, i18n } = useTranslation();
  const translationKey = 'workspacePlan.write';

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const isOnboarding = params.get('onboarding');

  const [showParent, setShowParent] = useState(false);
  const [isEmpty, setIsEmpty] = useState(true);
  const [showExperience, setShowExperience] = useState(false);

  const displayForm = useSelector((state: any) => {
    return !state.editorUI.selectedBlockId && state.editorUI.displayNewBlockForm;
  });
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  // Display the objective form if we don't have any objectives
  const objectivesCount = currentPlan ? objectiveIds.length : 0;

  useEffect(() => {
    if (objectivesCount === 0) {
      dispatch(editorSelectBlock(''));
      dispatch(editorSetDisplayNewBlockForm(true));
      setIsEmpty(true);
    } else {
      setIsEmpty(false);
    }
  }, [objectivesCount, dispatch]);

  useEffect(() => {
    setShowExperience(true);
    dispatch(resetLoadingMetrics());
  }, [dispatch]);

  /***********************
   *   DRAGGING LOGIGC
   ***********************/

  // Hide the new block form when re-ordering stuff
  const onDragStart = () => {
    dispatch(editorSetDisplayNewBlockForm(false));
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const [draggableEntityType] = result.draggableId.split(':');
    switch (draggableEntityType) {
      case 'objective':
        dispatch(editorReorderObjective(result));
        break;
      case 'outcome':
        if (result.source.droppableId === result.destination.droppableId) {
          dispatch(editorReorderOutcome(result));
        } else {
          dispatch(editorMoveOutcome(result));
        }

        break;
      case 'initiative':
        if (result.source.droppableId === result.destination.droppableId) {
          dispatch(editorReorderInitiative(result));
        } else {
          dispatch(editorMoveInitiative(result));
        }

        break;
    }
  };

  const handleEditTimeline = () => {
    history.push(`#editor:${blockId}:edit.timeline`);
  };

  const handleDisplayForm = () => {
    dispatch(editorSelectBlock(''));
    dispatch(editorSetDisplayNewBlockForm(true));
  };

  // const handleShowTemplateDirectory = (e: React.MouseEvent<any>) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   const action = `${blockId}:show.templates`;
  //   dispatch(setGlobalModalContent(action));
  // };

  const handlePreviewAI = () => {
    let action = `${blockId}:preview.ai`;

    if (true) {
      action = `${blockId}:chat.ai.outcomes`;
    }
    dispatch(setGlobalModalContent(action));
  };

  // const [populateAIYMLPlanMutation, { isLoading }] = useMutation(populatePlanYAML, {
  //   onSuccess: () => {
  //     queryCache.invalidateQueries(queryKeys.currentPlan);
  //     queryCache.invalidateQueries(queryKeys.objectives);
  //     queryCache.invalidateQueries(queryKeys.outcomes);
  //     queryCache.invalidateQueries(queryKeys.initiatives);
  //     queryCache.invalidateQueries(queryKeys.currentPing);
  //   },
  // });

  const handleImportContent = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(setGlobalModalContent(`${blockId}:import.content`));
  };

  //   const handleQuickCreate = (e: React.MouseEvent) => {
  //     e.preventDefault();
  //     e.stopPropagation();

  //     const ymlContent = `
  // ---
  // title: Test
  // objectives:
  // - title: "<Replace this with your objective>"
  //   outcomes:
  //   - title: "<Replace with your key result>"
  //     from: 0.0
  //     to: 100.0
  //     score_format: _number_%
  //     initiatives:
  //     - title: "<Replace with your initiative/task>"
  //   - title: "<Replace with your key result>"
  //     from: 0.0
  //     to: 100.0
  //     score_format: _number_%
  //     initiatives:
  //     - title: "<Replace with your initiative/task>"
  // `;

  //     const params = {
  //       planId: plan.id,
  //       yaml: ymlContent,
  //     };

  //     populateAIYMLPlanMutation(params);
  //   };

  const hasTimeline = currentPlan.start_at && currentPlan.finish_at;
  const hasObjectives = objectiveIds.length > 0;
  const hasOutcomes = hasObjectives && outcomeIds.length > 0;

  // Show the publish info banner
  const showPublishBanner = hasOutcomes && plan.state === 'draft';
  const publishBannerPhrase = hasTimeline ? (
    <Fragment>
      <Trans i18nKey={`${translationKey}.publishPlanBanner`} components={{ b: <b /> }} />
    </Fragment>
  ) : (
    <Fragment>
      <Trans i18nKey={`${translationKey}.setTimelineBanner`} components={{ b: <b /> }} />
    </Fragment>
  );

  // If it's a template return the template layout
  if (isTemplate) {
    return (
      <Container>
        <PlanToolbar>
          <MetaInfo>
            <PlanStatus plan={plan} />
          </MetaInfo>
          <PlanActions plan={plan} hasOutcomes={hasOutcomes} />
        </PlanToolbar>
        <ColumnsWrapper>
          <PlanContainer>
            <Panel />
            <RemoteSync />
            <TimelineWrapper>
              <BlockGrid className="no-hover-bg">
                <BlockGutter></BlockGutter>
                <BlockContent className="normal-font">
                  <PlanTitle plan={plan} />
                </BlockContent>
                <BlockMeta></BlockMeta>
              </BlockGrid>
            </TimelineWrapper>
            <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
              <Droppable droppableId={blockId} type="OBJECTIVES">
                {(provided, snapshot) => (
                  <div
                    className="editor-content"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={{
                      backgroundColor: snapshot.isDraggingOver ? 'rgba(164, 220, 229, 0.4)' : '#fff',
                    }}
                  >
                    {objectiveIds.map((objectiveId: string, index: number) => {
                      return (
                        <ObjectiveBlock
                          objectiveId={objectiveId}
                          objectiveIndex={index}
                          key={`objective:${objectiveId}`}
                        />
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            {displayForm && <ObjectiveForm planId={currentPlan.id} />}

            <BlockGrid className="no-hover-bg spacing">
              <BlockGutter />
              <BlockContent>
                {!displayForm && (
                  <AddObjectiveButton onClick={handleDisplayForm}>
                    {t(`${translationKey}.addObjective`, {
                      label: translate(currentWorkspace, CustomTermKey.OBJECTIVE, 1).toLowerCase(),
                    })}
                  </AddObjectiveButton>
                )}
                <AddObjectiveButton onClick={handleImportContent} className="use-template-button">
                  {t(`${translationKey}.useMagicImport`)}
                </AddObjectiveButton>
                {currentWorkspace.open_ai_enabled && (
                  <AddObjectiveButton onClick={handlePreviewAI} className="use-ai-button">
                    {t(`${translationKey}.generateAI`)}
                  </AddObjectiveButton>
                )}
              </BlockContent>
              <BlockMeta />
            </BlockGrid>
          </PlanContainer>

          <ScorecardContainer className={'scorecard-tips'}>
            <Scorecard plan={currentPlan} />
          </ScorecardContainer>
        </ColumnsWrapper>
      </Container>
    );
  }

  // Here we return the regular layout
  const expandParentClass = showParent ? 'expanded' : '';
  const interval = plan.reminders_interval > 1 ? plan.reminders_interval : null;
  const period_plural = plan.reminders_interval > 1 ? 's' : null;

  return (
    <>
      <RemoteSync />
      {isOnboarding && (
        <OnboardingContainer>
          <div>
            <img src={TabbyContent} alt="Tabby" />
          </div>
          <span>
            Well done! You've created your first plan. Use the editor to finish setting up your plan, then click publish
            to switch to the goal tracking view.
          </span>
        </OnboardingContainer>
      )}
      {isEmpty && showExperience && (
        <Container>
          <PlanToolbar>
            <div></div>
            <PlanActions plan={plan} hasOutcomes={hasOutcomes} />
          </PlanToolbar>
          <ColumnsWrapper>
            <PlanContainer id="plan-content">
              <ChooseExperienceContainer>
                <h4>{t(`${translationKey}.chooseExperience`)}</h4>
                <ExperienceOptionsContainer>
                  <ExperienceOption onClick={() => setShowExperience(false)}>
                    <img
                      src={TabbyDIY}
                      alt={t(`${translationKey}.experienceDIY`) ?? '"I already have some goals in mind"'}
                    />
                    <h3>{t(`${translationKey}.experienceDIY`)}</h3>
                    <p>{t(`${translationKey}.experienceDIYInfo`)}</p>
                    <KoalaButton onClick={() => setShowExperience(false)} appearance="secondary">
                      {t(`${translationKey}.getStarted`)}
                    </KoalaButton>
                  </ExperienceOption>
                  {currentWorkspace.open_ai_enabled && (
                    <>
                      <ExperienceOption onClick={handleImportContent}>
                        <img
                          src={TabbyQuickstart}
                          alt={t(`${translationKey}.experienceMagicImport`) ?? '"I already have some goals in mind"'}
                        />
                        <h3>{t(`${translationKey}.experienceMagicImport`)}</h3>
                        <p>{t(`${translationKey}.experienceMagicImportInfo`)}</p>
                        <KoalaButton onClick={handleImportContent}>
                          {t(`${translationKey}.experienceMagicImportButton`)}
                        </KoalaButton>
                      </ExperienceOption>
                      <ExperienceOption onClick={handlePreviewAI}>
                        <img
                          src={TabbyChatAI}
                          alt={t(`${translationKey}.experienceChatAI`) ?? `"I'm not sure what my goals should be yet."`}
                        />
                        <h3>{t(`${translationKey}.experienceChatAI`)}</h3>
                        <p>{t(`${translationKey}.experienceChatAIInfo`)}</p>
                        <KoalaButton onClick={handlePreviewAI} appearance="special">
                          {t(`${translationKey}.generateAI`)}
                        </KoalaButton>
                      </ExperienceOption>
                    </>
                  )}
                </ExperienceOptionsContainer>
              </ChooseExperienceContainer>
            </PlanContainer>
            <ScorecardContainer className={'scorecard-tips empty'}>
              <Scorecard plan={currentPlan} isReadOnly={true} />
            </ScorecardContainer>
          </ColumnsWrapper>
        </Container>
      )}
      {(!isEmpty || !showExperience) && (
        <Container>
          <PlanToolbar>
            <MetaInfo>
              <PlanStatus plan={plan} />
              <PanelLabelButton onClick={handleEditTimeline} className="timeline">
                {t(`${translationKey}.updateReminders`, {
                  interval: interval ?? '',
                  period: plan.reminders_period,
                  plural: period_plural,
                })}
              </PanelLabelButton>
              {hasTimeline && (
                <PanelLabelButton onClick={handleEditTimeline} className="timeline">
                  &nbsp;
                  {formatLocale(currentPlan.start_at, 'MMM', i18n)}
                  &nbsp;&#10142;&nbsp;
                  {formatLocale(currentPlan.finish_at, 'MMM yyyy', i18n)}
                </PanelLabelButton>
              )}
              {showPublishBanner && false && <PublishBanner>{publishBannerPhrase}</PublishBanner>}
            </MetaInfo>
            <PlanActions plan={plan} hasOutcomes={hasOutcomes} />
          </PlanToolbar>
          <ColumnsWrapper>
            <PlanContainer id="plan-content">
              <Panel />
              <PlanScrollContainer>
                <TimelineWrapper>
                  <BlockGrid className="no-hover-bg">
                    <BlockGutter></BlockGutter>
                    <BlockContent className="normal-font">
                      <PlanTitle plan={plan} />
                    </BlockContent>
                    <BlockMeta className="normal-font">
                      {currentWorkspace.ai_metrics_enabled && (
                        <KoalaTextBadge variant="violet-light" maxWidth="unset" dataFor="ai-metric-detection-enabled">
                          <span role="img">✨</span>
                          {t('workspacePlans.aiMetricEnabled')}
                        </KoalaTextBadge>
                      )}
                      {!currentWorkspace.ai_metrics_enabled && (
                        <KoalaTextBadge variant="violet-outlined" maxWidth="unset">
                          <span role="img">✨</span>
                          {t('workspacePlans.aiMetricDisabled')}
                        </KoalaTextBadge>
                      )}
                      <ReactTooltip
                        id="ai-metric-detection-enabled"
                        place="bottom"
                        type="dark"
                        className="tooltip"
                        effect="solid"
                        delayHide={500}
                        clickable={true}
                      >
                        {t('workspacePlans.aiMetricEnabledInfo')}{' '}
                        <a
                          href="https://guides.tability.app/docs/features/ai-features/ai-metric-detection"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {t('shared.learnMore')}
                        </a>
                      </ReactTooltip>
                    </BlockMeta>
                  </BlockGrid>
                </TimelineWrapper>
                {plan.parent && (
                  <ParentOverview>
                    <ToggleHeader className={expandParentClass} onClick={() => setShowParent(!showParent)}>
                      <div className="chevron">
                        <ChevronRightIcon />
                      </div>
                      {t(`${translationKey}.showParent`)}
                    </ToggleHeader>
                    {showParent && <ParentPlan parent={plan.parent} />}
                  </ParentOverview>
                )}

                <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
                  <Droppable droppableId={blockId} type="OBJECTIVES">
                    {(provided, snapshot) => (
                      <div
                        className="editor-content"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        style={{
                          backgroundColor: snapshot.isDraggingOver ? 'rgba(164, 220, 229, 0.4)' : '#fff',
                        }}
                      >
                        {objectiveIds.map((objectiveId: string, index: number) => {
                          return (
                            <ObjectiveBlock
                              objectiveId={objectiveId}
                              objectiveIndex={index}
                              key={`objective:${objectiveId}`}
                            />
                          );
                        })}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>

                {displayForm && <ObjectiveForm planId={currentPlan.id} />}

                <BlockGrid className="no-hover-bg spacing">
                  <BlockGutter />
                  <BlockContent>
                    {!displayForm && (
                      <AddObjectiveButton onClick={handleDisplayForm}>
                        {t(`${translationKey}.addObjective`, {
                          label: translate(currentWorkspace, CustomTermKey.OBJECTIVE, 1).toLowerCase(),
                        })}
                      </AddObjectiveButton>
                    )}
                    <AddObjectiveButton onClick={handleImportContent} className="use-template-button">
                      {t(`${translationKey}.useMagicImport`)}
                    </AddObjectiveButton>
                    {currentWorkspace.open_ai_enabled && (
                      <AddObjectiveButton onClick={handlePreviewAI} className="use-ai-button">
                        {t(`${translationKey}.generateAI`)}
                      </AddObjectiveButton>
                    )}
                  </BlockContent>
                  <BlockMeta />
                </BlockGrid>
              </PlanScrollContainer>
              <TemplateCreateHelper>
                <div>
                  <img
                    src="https://res.cloudinary.com/tability/image/upload/v1600083943/static_assets/tabby_happy_ldzb8v.png"
                    alt="Tabby"
                  />
                  <span>{t(`${translationKey}.helpBanner.goalHelp`)}</span>
                </div>
                <div>
                  <a
                    href="https://guides.tability.app/docs/tability-university/tability-201-setting-goals/how-do-i-put-my-goals-into-tability"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t(`${translationKey}.helpBanner.tips`)}
                  </a>
                </div>
              </TemplateCreateHelper>
            </PlanContainer>
            <ScorecardContainer className={'scorecard-tips'}>
              <Scorecard plan={currentPlan} />
            </ScorecardContainer>
          </ColumnsWrapper>
        </Container>
      )}
    </>
  );
}

export default React.memo(Editor);
