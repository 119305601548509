import React from 'react';
import styled from 'styled-components';
import theme from 'theme';

import { useSelector, shallowEqual } from 'react-redux';
import { CustomTermKey, translate } from 'utils/customTermUtils';

import { useTranslation } from 'react-i18next';

import PlanPublished from './Chart_tabby.png';

const ImgWrapper = styled.div`
  min-width: 40%;
  width: 40%;
  img {
    width: 100%;
  }
`;

const Container = styled.div`
  display: flex;
  gap: ${theme.spacing.x4};
  align-items: center;
  max-width: 60rem;
  margin: 0 auto;

  h3 {
    font-size: 1.6rem;
    font-weight: 700;
  }

  > div.content {
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing.x2};
  }
`;

function StreakLadder() {
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const { t } = useTranslation();
  const translationKey = 'modals.planPublished';

  return (
    <Container>
      <div className="content">
        <h1>{t(`${translationKey}.title`)}</h1>
        <h3>{t(`${translationKey}.info`)}</h3>
        <p>
          {t(`${translationKey}.content`, {
            label: translate(currentWorkspace, CustomTermKey.OUTCOME, 2).toLowerCase(),
          })}
        </p>
      </div>
      <ImgWrapper>
        <img src={PlanPublished} alt="Tabby is content!" />
      </ImgWrapper>
    </Container>
  );
}

export default StreakLadder;
