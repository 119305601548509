import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import styled from 'styled-components';
import theme from 'theme';
import * as TabilityTypes from 'types';
import * as checkinUtils from 'utils/checkinUtils';
import MarkdownContent from 'components/MarkdownContent';
import KoalaAvatar from 'koala/components/Avatar';
import CurrentGrowth from 'components/CurrentGrowth';
import OutcomeWeight from 'components/OutcomeWeight';
import { useTranslation } from 'react-i18next';
import KoalaCircularProgress, { ProgressData } from 'koala/components/CircularProgress';
import ReactTooltip from 'react-tooltip';
import KoalaTextBadge from 'koala/components/TextBadge';
import * as dateUtils from 'utils/dateUtils';
import { shallowEqual, useSelector } from 'react-redux';

const BlockGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto auto;
  grid-template-areas: 'content meta';
  grid-template-areas:
    'gutter meta'
    'content content'
    'footer footer';
  row-gap: ${theme.spacing.half};
  column-gap: ${theme.spacing.x2};
  border: 1px solid ${theme.colors.N20};
  border-radius: 4px;
  padding: 1.2rem ${theme.spacing.x2};

  @media ${theme.devices.mobile} {
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    grid-template-areas:
      'content'
      'meta';
    gap: 0;
    margin-bottom: ${theme.spacing.x2};
  }
`;

const Gutter = styled.div`
  grid-area: gutter;
  display: flex;
  gap: ${theme.spacing.x2};
  align-items: center;
`;

const Meta = styled.div`
  grid-area: meta;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  position: relative;
  top: 2px;

  > div {
    display: flex;
    align-items: center;
    gap: ${theme.spacing.x1};
  }
`;

const Content = styled.div`
  grid-area: content;
  .subtle {
    padding: ${theme.spacing.x1} 0;
  }
`;

const Footer = styled.div`
  grid-area: footer;
`;

const Header = styled.div`
  font-weight: 600;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  gap: ${theme.spacing.x1};

  a {
    font-size: 1.6rem;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const UserBlock = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
  align-items: center;

  .time-block {
    font-weight: 500;
    font-size: 11px;
    line-height: 15px;
    color: ${theme.colors.N60};
  }
`;

interface Props {
  outcome: TabilityTypes.Outcome;
  isSelected: boolean;
}

function OutcomeBlock(props: Props) {
  const { outcome, isSelected } = props;
  const { membership } = outcome;
  const { t, i18n } = useTranslation();
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const history = useHistory();
  const blockId = `outcome:${outcome.nano_slug}`;
  const hashPath = `#${blockId}:show`;

  const currentCheckin = outcome.current_checkin;

  // Calculate the progress prct

  const progressPrct = outcome.outcome_progress_prct * 100;

  const body = currentCheckin ? currentCheckin.body : t('shared.waiting');
  const formattedScore = currentCheckin ? checkinUtils.checkinScore(currentCheckin, outcome, t) : '—';

  const className = isSelected ? 'selected' : '';
  const currentConfidence = currentCheckin ? currentCheckin.confidence : 'grey';

  const confidenceLetter = checkinUtils.confidenceToColorLetter(currentConfidence);
  const progressData: ProgressData = {
    progressPercentage: progressPrct,
    colorType: confidenceLetter,
    centerLabel: '',
  };

  const handleAvatarClicked = (membership: TabilityTypes.Membership) => {
    history.push(`/${currentWorkspace.slug}/people/${membership.id}`);
  };

  return (
    <BlockGrid className={className}>
      <Gutter>
        <Header className="outcome">
          <OutcomeWeight outcome={outcome} />
          <Link to={hashPath}>{outcome.title}</Link>
        </Header>
      </Gutter>
      <Meta>
        <div>
          <KoalaTextBadge
            isLowercase={true}
            variant={checkinUtils.confidenceToVariant(currentConfidence)}
            edge="circle"
            size="small"
            style={{ border: 'none' }}
            maxWidth="10rem"
            dataFor={`progress-${outcome.nano_slug}`}
          >
            {formattedScore}
          </KoalaTextBadge>
          <ReactTooltip
            place="bottom"
            type="dark"
            className="tooltip"
            effect="solid"
            id={`progress-${outcome.nano_slug}`}
          >
            {formattedScore}
          </ReactTooltip>
          <CurrentGrowth outcome={outcome} />
          <KoalaCircularProgress data={progressData} size="xsmall" dataFor={`percentage-${outcome.nano_slug}`} />
          <ReactTooltip
            place="bottom"
            type="dark"
            className="tooltip"
            effect="solid"
            id={`percentage-${outcome.nano_slug}`}
          >
            {t('shared.percentageComplete', { percentage: progressPrct > 0 ? progressPrct.toFixed(0) : 0 })}
          </ReactTooltip>
        </div>
      </Meta>
      <Content>
        {!currentCheckin && <p className="subtle">{t('publicPlan.noCheckin')}</p>}
        {currentCheckin && body && <MarkdownContent source={body} />}
        {currentCheckin && !body && <p className="subtle">{t('shared.noComment')}</p>}
      </Content>
      <Footer>
        {currentCheckin && (
          <UserBlock>
            <KoalaAvatar user={membership ? membership.user : null} size={2} handleClick={handleAvatarClicked} />
            <div className="time-block">
              {dateUtils.formatDistanceToNowLocale(currentCheckin.checkin_date, i18n, true)}
            </div>
          </UserBlock>
        )}
      </Footer>
    </BlockGrid>
  );
}

export default React.memo(OutcomeBlock);
