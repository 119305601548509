import React from 'react';
import * as TabilityTypes from 'types';
import styled from 'styled-components';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import theme from 'theme';
import KoalaButton from 'koala/components/Button';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import { useTranslation } from 'react-i18next';

import ImageTeaser from 'components/_assets/bring_friends.png';

const ImgWrapper = styled.div`
  img {
    width: 20rem;
  }
`;

const Container = styled.div`
  display: flex;
  gap: ${theme.spacing.x4};
  align-items: center;
  max-width: 65rem;
  margin: ${theme.spacing.x4} auto;
  border-radius: 4px;

  h3 {
    font-size: 1.6rem;
    font-weight: 700;
  }

  > div.content {
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing.x2};
  }
`;

function InviteTeaser() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentWorkspace: TabilityTypes.Workspace = useSelector(
    (state: any) => state.session.currentWorkspace,
    shallowEqual,
  );
  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);

  const handleInvite = (e: any) => {
    e.preventDefault();
    const action = `workspace:${currentWorkspace.slug}:invite`;
    dispatch(setGlobalModalContent(action));
  };

  let showInvites = true;
  const isAdmin = ['admin', 'owner'].includes(currentMembership.role);
  if (currentWorkspace.invites_restricted && !isAdmin) {
    showInvites = false;
  }

  return (
    <Container>
      <div className="content">
        <h2>Tability is better together</h2>
        <p>Invite your team to collaborate on your plans</p>
        {showInvites && (
          <div>
            <KoalaButton onClick={handleInvite}>{t('workspacePeople.inviteButton')}</KoalaButton>
          </div>
        )}
      </div>
      <ImgWrapper>
        <img src={ImageTeaser} alt="Tabby is content!" />
      </ImgWrapper>
    </Container>
  );
}

export default InviteTeaser;
