// @ts-nocheck
import React, { useRef, useEffect, useState } from 'react';
import theme from 'theme';
import { getTime, parseISO, isBefore } from 'date-fns';
import * as checkinUtils from 'utils/checkinUtils';
import * as planUtils from 'utils/planUtils';
import EmptyStatePanel from 'components/EmptyStatePanel';
import NoMetricChart from './NoMetricChart';
import ImproveMetricChart from './ImproveMetricChart';
import FlatChart from './FlatChart';
import KpiChart from './KpiChart';
import styled from 'styled-components';
import { Checkin, checkinType, DownloadDetails, Initiative } from 'types';

// API
import { truncateString } from 'utils/textUtils';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: flex;
  margin-bottom: ${theme.spacing.x1};
  position: relative;
`;

export interface DemoCheckin {
  checkin_date: string;
  score: number;
  confidence: string;
  plan: Plan;
}

interface Props {
  outcome: Outcome;
  checkins: Checkin[] | DemoCheckin[];
  draftCheckin?: checkinType;
  isEdit?: boolean;
  downloadDetails?: DownloadDetails;
  initiatives?: Initiative[];
  showEmptyState?: boolean;
  hideTrendLine?: boolean;
}

function CheckinsChart(props: Props) {
  const { outcome, checkins, draftCheckin, isEdit, downloadDetails } = props;
  const initiatives = props.initiatives || [];
  const { plan } = outcome;
  const { t } = useTranslation();
  let yDomain: Domain = { min: 0, max: 0 };
  const containerRef = useRef(null);

  const [width, setWidth] = useState(downloadDetails?.width ? downloadDetails.width : 956);
  const updateWidth = (ev: Event) => {
    if (containerRef && containerRef.current) {
      setWidth(containerRef.current.offsetWidth);
    }
  };

  // useEffect replaces `componentDidMount` and others
  useEffect(() => {
    window.addEventListener('resize', updateWidth);

    // Removes listener on unmount
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, []);

  useEffect(() => {
    if (containerRef && containerRef.current) {
      setWidth(containerRef.current.offsetWidth);
    }
  }, [containerRef]);

  // If the plan doesn't have a start and end date, display a warning
  if (!planUtils.hasTimeline(plan)) {
    return (
      <EmptyStatePanel>
        <p>{t('workspacePlan.defineTimeline')}</p>
      </EmptyStatePanel>
    );
  }

  let currentData: checkinType[] = []; // This data is used to trace the current line as a solid line
  const targetData: checkinType[] = []; // This data is used to trace the target area
  let closedInitiativesData: any = []; // List of closed initiatives

  const startDate: Date = parseISO(plan.start_at);
  const finishDate: Date = parseISO(plan.finish_at);

  // Set the points required to create the target line
  const firstPoint: checkinType = {
    x: startDate,
    y: outcome.from,
    timestamp: getTime(startDate),
    color: theme.colors.grey,
  };

  const lastPoint: checkinType = {
    x: finishDate,
    y: outcome.to,
    timestamp: getTime(finishDate),
  };

  targetData.push(firstPoint);
  targetData.push(lastPoint);

  yDomain.min = Math.min(firstPoint.y, lastPoint.y);
  yDomain.max = Math.max(firstPoint.y, lastPoint.y);

  // By default we're going to use the first point from the target
  // We'll change that if we find a checkin prior to the first point.
  let useFirstPoint = true;

  checkins.forEach((checkin, index) => {
    const date = parseISO(checkin.checkin_date);

    // If the checkin was done prior to the first point we don't store
    // the first point in the list of current points
    if (isBefore(date, firstPoint.x)) {
      useFirstPoint = false;
    }

    // Create the point using the checkin data
    const point: checkinType = {
      x: date,
      y: checkin.score,
      timestamp: getTime(date),
      color: checkinUtils.confidenceToColor(checkin.confidence),
      checkin,
    };
    currentData.push(point);

    // Update domain
    yDomain.min = Math.min(point.y, yDomain.min);
    yDomain.max = Math.max(point.y, yDomain.max);
  });

  if (draftCheckin) {
    if (isEdit) {
      currentData = currentData.filter((checkin) => {
        if (checkin.checkin && draftCheckin.checkin) {
          return checkin.checkin.id !== draftCheckin.checkin?.id;
        }
        return true;
      });
    }
    currentData.push(draftCheckin);
  }

  // Re-order check-ins so that they don't overlap on each other in reverse in the scatter graph
  function compareCurrentData(a, b) {
    const timestampA = a.checkin?.created_at || a.timestamp;
    const timestampB = b.checkin?.created_at || b.timestamp;
    if (timestampA < timestampB) {
      return -1;
    }
    if (timestampA > timestampB) {
      return 1;
    }
    return 0;
  }

  currentData.sort(compareCurrentData);

  let trendData: checkinType[] = [];
  // const getTrendData = () => {
  //   trendData = checkinUtils.getTrendline(currentData, finishDate, plan.reminders_period);
  //   if (trendData.length > 1) {
  //     const newPointY = trendData[1].y;
  //     if (newPointY < yDomain.min) {
  //       yDomain.min = newPointY;
  //     } else if (newPointY > yDomain.max) {
  //       yDomain.max = newPointY;
  //     }
  //   }
  //   return trendData;
  // };

  const isInsideTimeline = planUtils.isInPlanTimeline(startDate, finishDate, new Date());

  const outcomeType = outcome.outcome_type;
  let content, scores;

  if (outcomeType !== 'no_metric') {
    scores = currentData.map((data) => data.y);

    if (outcomeType !== 'improve_metric') {
      scores = [...scores, outcome.y_axis_min, outcome.y_axis_max];
    } else {
      scores = [...scores, outcome.from, outcome.to];
    }

    yDomain = {
      min: Math.min(...scores),
      max: Math.max(...scores),
    };
  } else {
    yDomain = {
      min: -1.3,
      max: 1.3,
    };
  }

  // Iterate on the initiatves to extract the right data
  initiatives.forEach((initiative: Initiative) => {
    // Only add closed initiatives
    if (initiative.closed_at) {
      const date = parseISO(initiative.closed_at);

      // Extract the day (not the hours, etc) to group all tasks closed on the same day
      const dayDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      const existingPoint = closedInitiativesData.find((point) => point.timestamp === getTime(dayDate));

      // Truncate the initiative title if too long
      const truncatedTitle = `- ${truncateString(initiative.title, 50)}`;

      // Here we group together the iniative titles in titlesArray so we can
      // use a signle scatter point in the chart.
      if (existingPoint && existingPoint.titlesArray.length < 15) {
        // Only display the first 15 tasks
        existingPoint.titlesArray.push(truncatedTitle);
        // TODO: add a way to display "and x more" - too lazy to do it now (Sten)
      } else {
        const point = {
          x: dayDate,
          y: yDomain.min,
          timestamp: getTime(dayDate),
          titlesArray: [truncatedTitle],
        };

        closedInitiativesData.push(point);
      }
    }
  });

  // Create the different types of graphs
  // no_metric
  if (outcomeType === 'no_metric') {
    content = (
      <NoMetricChart
        {...{ outcome, currentData, closedInitiativesData, isInsideTimeline, plan, downloadDetails, width, yDomain }}
      />
    );
  } else if (outcomeType === 'stay_above' || outcomeType === 'stay_below') {
    // trendData = hideTrendLine ? [] : getTrendData();
    content = (
      <FlatChart
        {...{
          outcome,
          currentData,
          trendData,
          closedInitiativesData,
          isInsideTimeline,
          plan,
          yDomain,
          downloadDetails,
          width,
        }}
      />
    );
  } else if (outcomeType === 'kpi') {
    if (useFirstPoint) {
      currentData.push(firstPoint);
    }
    currentData = currentData.map((data) => {
      return { ...data, color: theme.colors.B50 };
    });
    content = (
      <KpiChart
        {...{ outcome, currentData, closedInitiativesData, isInsideTimeline, yDomain, plan, downloadDetails, width }}
      />
    );
  } else {
    // basic improve metric
    if (useFirstPoint) {
      currentData.push(firstPoint);
    }
    // trendData = hideTrendLine ? [] : getTrendData();
    content = (
      <ImproveMetricChart
        {...{
          outcome,
          currentData,
          trendData,
          closedInitiativesData,
          isInsideTimeline,
          plan,
          yDomain,
          downloadDetails,
          width,
        }}
      />
    );
  }

  return (
    <Container ref={containerRef} className="checkin-chart">
      {content}
    </Container>
  );
}

export default CheckinsChart;
