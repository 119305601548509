import { TFunction } from 'i18next';
import KoalaColorBlock from 'koala/components/ColorBlock';
import KoalaLegend from 'koala/components/Legend';
import React from 'react';
import theme from 'theme';
import { Checkin, Outcome } from 'types';

export const isNumber = (n: any) => {
  if (n === undefined || n === null) {
    return false;
  }
  return Number(n) === n;
};

export const nFormatter = (num: number, digits: number) => {
  const si = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = si.length - 1; i > 0; i--) {
    if (Math.abs(num) >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol;
};

export const isMeasurable = (outcome: Outcome) => {
  return outcome.outcome_type !== 'no_metric';
};

export const hasDefaultTarget = (outcome: Outcome) => {
  return (
    outcome.outcome_type === 'improve_metric' &&
    outcome.from === 0 &&
    outcome.to === 100 &&
    outcome.score_format === '_number_%'
  );
};

// Outcome has a target if:
// - there's a value for From and To
// - there's a format
export const hasTarget = (outcome: Outcome) => {
  const { outcome_type } = outcome;
  if (!outcome_type || outcome_type === 'improve_metric') {
    return isNumber(outcome.from) && isNumber(outcome.to) && outcome.score_format !== null;
  }
  return false;
};

export const applyFormat = (score_format: any, scoreString: any) => {
  if (!score_format) {
    return '–';
  }
  return score_format.replace('_number_', scoreString);
};

export const startingScore = (outcome: Outcome, t: TFunction) => {
  // Check if the outcome has a score_format
  if (outcome.outcome_type !== 'no_metric') {
    const humanScore = nFormatter(outcome.from || 0, 2);
    return applyFormat(outcome.score_format, humanScore);
  }
  // If there's no target use the confidence instead of the score
  return t('shared.confidence.pending');
};

// Get the normalized value of the percentage.
export const getNormalizedProgress = (outcome: Outcome) => {
  let progressPrct = 0;
  const currentCheckin = outcome.current_checkin;

  if (!hasTarget(outcome)) {
    return null;
  }

  // Return 0 if we don't have a checkin.
  if (!currentCheckin) {
    return 0;
  }

  if (hasTarget(outcome)) {
    const valueFrom = outcome.from || 0;
    const valueTo = outcome.to || 0;
    // Get the distance from current checkin
    if (currentCheckin && valueTo !== valueFrom) {
      const distance = valueTo - valueFrom;
      const currentDistance = currentCheckin.score - valueFrom;
      progressPrct = Math.round((currentDistance / distance) * 100);
    }
  }

  // Normalize the progress to be between 0 and 1.
  if (progressPrct > 100) {
    progressPrct = 100;
  }

  if (progressPrct < 0) {
    progressPrct = 0;
  }

  return progressPrct;
};

// Get the projected score

export const getProjectedScoreForDate = (outcome: any, date: any) => {
  //const { plan } = outcome;
  // return 0 if we don't have a plan
  // return 0 if the plan doesn't have a timeline
  // Now get the projected score based on the date of the day
};

const getOutcomesStats = (outcomes: Outcome[]) => {
  const stats: any = {
    red: 0,
    yellow: 0,
    green: 0,
    grey: 0,
  };

  outcomes.forEach((o: Outcome) => {
    if (o.current_checkin) {
      const checkinRef = o.current_checkin;
      stats[checkinRef.confidence]++;
    } else {
      stats['grey']++;
    }
    stats['total']++;
  });

  return stats;
};

export const getNCSTextColor = (ncsScore: number) => {
  let labelColor = theme.colors.G50;
  if (ncsScore <= 30) {
    labelColor = theme.colors.Y90;
  }
  if (ncsScore < 0) {
    labelColor = theme.colors.R50;
  }
  return labelColor;
};

interface ConfidenceData {
  x: number;
  y: number;
  color: string;
  legendTitle: string;
}

export const getOutcomeConfidenceData = (outcomes: Outcome[]) => {
  const outcomeStats = getOutcomesStats(outcomes);
  const data: ConfidenceData[] = [];
  let dataIndex = 1;

  if (outcomeStats['green']) {
    data.push({
      x: dataIndex++,
      y: outcomeStats['green'],
      color: theme.colors.green,
      legendTitle: 'on track',
    });
  }
  if (outcomeStats['yellow']) {
    data.push({
      x: dataIndex++,
      y: outcomeStats['yellow'],
      color: theme.colors.yellow,
      legendTitle: 'at risk',
    });
  }
  if (outcomeStats['red']) {
    data.push({
      x: dataIndex++,
      y: outcomeStats['red'],
      color: theme.colors.red,
      legendTitle: 'off track',
    });
  }
  if (outcomeStats['grey']) {
    data.push({
      x: dataIndex++,
      y: outcomeStats['grey'],
      color: theme.colors.grey,
      legendTitle: 'pending',
    });
  }

  return data;
};

export const getOutcomeConfidenceLegend = (confidenceData: ConfidenceData[]) => {
  const legendData: { icon: any; title: string }[] = confidenceData.map((data) => {
    return {
      icon: <KoalaColorBlock borderRadius="50%" color={data.color} size="xsmall" />,
      title: `${data.y} ${data.legendTitle}`,
    };
  });

  return <KoalaLegend data={legendData} />;
};

export const getGrowthPercentage = (previousValue: number | null, currentValue: number | null) => {
  // Had to put this for TypeScript to work 👇 (it's covered by hasTarget..)
  if (!previousValue || !currentValue) {
    return null;
  }

  // Can't calculate growth from a 0
  if (previousValue === 0) {
    return null;
  }

  // Return 0 for flat growth
  if (previousValue === currentValue) {
    return 0;
  }

  return Math.round(((currentValue - previousValue) / previousValue) * 100);
};

export const getProgressPercentageSinceLastCheckin = (outcome: Outcome) => {
  if (!['improve_metric', 'kpi', 'stay_above', 'stay_below'].includes(outcome.outcome_type)) {
    return null;
  }

  const { current_checkin, previous_checkin } = outcome;

  if (!current_checkin) {
    return null;
  }

  let currentScore = current_checkin.score;
  let previousScore = previous_checkin ? previous_checkin.score : outcome.from || 0;

  return getGrowthPercentage(previousScore, currentScore);
};

export const getOutcomeGrowthTarget = (outcome: Outcome) => {
  if (!hasTarget(outcome)) {
    return null;
  }

  return getGrowthPercentage(outcome.from, outcome.to);
};

export const getOutcomeGrowthCurrent = (outcome: Outcome) => {
  if (!hasTarget(outcome)) {
    return null;
  }

  const { current_checkin, previous_checkin } = outcome;

  // Can't calculate growth without 2 checkins
  if (!current_checkin || !previous_checkin) {
    return null;
  }

  const currentScore = current_checkin.score;
  const previousScore = previous_checkin.score;

  return getGrowthPercentage(previousScore, currentScore);
};

export const outcomeTypeToLabel = (outcome: Outcome, t: TFunction) => {
  const mapping: any = {
    no_metric: t('shared.outcomeTypes.noMetric') ?? 'No metric',
    improve_metric: t('shared.outcomeTypes.improvement') ?? 'Improvement',
    stay_above: t('shared.outcomeTypes.stayAbove') ?? 'Stay above',
    stay_below: t('shared.outcomeTypes.stayBelow') ?? 'Stay below',
    kpi: t('shared.outcomeTypes.kpi') ?? 'KPI',
  };

  return mapping[outcome.outcome_type];
};

export const outcomeIdToBackgroundColor = (outcomeId: string) => {
  const borderColors = [
    theme.colors.B5,
    theme.colors.B10,
    theme.colors.R5,
    theme.colors.R10,
    theme.colors.V5,
    theme.colors.V10,
    theme.colors.T5,
    theme.colors.T10,
    theme.colors.O5,
    theme.colors.O10,
    theme.colors.Y5,
    theme.colors.Y10,
    theme.colors.G5,
    theme.colors.G10,
    '#FDF5F4',
    '#B6CBCC',
  ];
  const hash = outcomeId.charCodeAt(0) + outcomeId.charCodeAt(10) + outcomeId.charCodeAt(1) + outcomeId.charCodeAt(2);
  const colorIndex = hash % borderColors.length;
  return borderColors[colorIndex];
};

export const formattedGoal = (outcome: Outcome) => {
  const { outcome_type, score_format, from, to } = outcome;

  let formattedOutcomeFrom = null;
  let formattedOutcomeTo = null;

  if (score_format) {
    formattedOutcomeFrom = from || from === 0 ? nFormatter(from, 2) : null;
    formattedOutcomeTo = to || to === 0 ? score_format.replace('_number_', nFormatter(to, 2)) : null;
  }
  let content = '—';

  if (outcome_type === 'improve_metric') {
    content = `${formattedOutcomeFrom} ➞ ${formattedOutcomeTo}`;
  } else if (outcome_type === 'stay_above') {
    content = `x > ${formattedOutcomeTo}`;
  } else if (outcome_type === 'stay_below') {
    content = `x < ${formattedOutcomeTo}`;
  } else if (outcome_type === 'kpi') {
    if (score_format) {
      formattedOutcomeFrom = formattedOutcomeFrom ? score_format.replace('_number_', formattedOutcomeFrom) : null;
    }
    content = formattedOutcomeFrom ? `KPI ${formattedOutcomeFrom}` : '—';
  }

  return content;
};

export const formattedFrom = (outcome: Outcome) => {
  const { score_format, from } = outcome;
  if (!score_format) {
    return null;
  }
  return from || from === 0 ? score_format.replace('_number_', nFormatter(from, 2)) : null;
};
export const formattedTo = (outcome: Outcome) => {
  const { score_format, to } = outcome;
  if (!score_format) {
    return null;
  }
  return to || to === 0 ? score_format.replace('_number_', nFormatter(to, 2)) : null;
};

export const atRiskStreakLimitReached = (checkins: Checkin[], streakLimit: number) => {
  let streak = 0;
  const sortedCheckins = checkins
    .slice()
    .sort((a, b) => new Date(b.checkin_date).getTime() - new Date(a.checkin_date).getTime());
  for (const checkin of sortedCheckins) {
    if (checkin.confidence === 'yellow') {
      streak += 1;
    } else {
      return false;
    }
    if (streak >= streakLimit) {
      return true;
    }
  }
  return false;
};
